import { useMutation, useQuery } from '@tanstack/react-query';

import { convertUsersResponseToUsers } from './converters';
import {
  APIUser,
  UserImportRequest,
  UsersResponse,
} from './types';
import { config } from '../../config/config';
import { useAuth } from '../../hooks/useAuth';
import { QueryKey } from '../../types/enums';
import { axiosWithPayloadContext, createHeaders } from '../axios';

type Role = 'prospect_user' | 'owner' | 'pm' | 'agent' |
'rm' | 'marketplace_user' | 'admin' |
'portfolio_manager' | 'sale_manager';

export const useGetUsers = (roles: Role[]) => {
  const { user, getAccessTokenSilently } = useAuth();

  const pm = user?.pm ?? '';

  return useQuery([QueryKey.USERS, roles], async (): Promise<APIUser[]> => {
    const token = await getAccessTokenSilently();

    let url = `${config.apiBaseURL}/users?property_manager=${pm}`;

    if (roles.join(',')) {
      url += `&role=${roles.join(',')}`;
    }

    const { data } = await axiosWithPayloadContext<UsersResponse>({
      url,
      headers: createHeaders(token),
      method: 'GET',
    });

    return convertUsersResponseToUsers(pm, data.users);
  }, { enabled: !!user?.pm });
};

export const useInviteUser = () => {
  const { user, getAccessTokenSilently } = useAuth();

  return useMutation(async (
    { email, communicationOption }: { email: string, communicationOption: string },
  ) => {
    const token = await getAccessTokenSilently();

    return axiosWithPayloadContext({
      url: `${config.apiBaseURL}/invitations?property_manager=${user?.pm}`,
      method: 'POST',
      headers: createHeaders(token),
      data: JSON.stringify({ email, communicationOption }),
    });
  });
};

export const useCreateUsers = () => {
  const { getAccessTokenSilently } = useAuth();

  return useMutation(async (
    { users }: { users: UserImportRequest[] },
  ) => {
    const token = await getAccessTokenSilently();

    return axiosWithPayloadContext({
      url: `${config.apiBaseURL}/users`,
      method: 'POST',
      headers: createHeaders(token),
      data: users,
    });
  });
};

export type UploadUsersFileType = 'prospects';

export const useUploadUsers = () => {
  const { getAccessTokenSilently } = useAuth();

  return useMutation(async ({ file, type }: { file: File, type: UploadUsersFileType }) => {
    const token = await getAccessTokenSilently();
    const formData = new FormData();
    formData.append('users', file);
    formData.append('fileType', type);

    return axiosWithPayloadContext({
      url: `${config.apiBaseURL}/upload-users`,
      method: 'POST',
      headers: {
        ...createHeaders(token),
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    });
  });
};

export const useImpersonate = () => {
  const { getAccessTokenSilently } = useAuth();

  return useMutation(async (
    email: string,
  ) => {
    const token = await getAccessTokenSilently();

    return axiosWithPayloadContext<{ token: string }>({
      url: `${config.apiBaseURL}/impersonate`,
      method: 'POST',
      headers: createHeaders(token),
      data: { actAsEmail: email },
    });
  });
};
