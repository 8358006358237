import {
  useEffect, useMemo,
} from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  APIUser, pmState, Role, useFeatures, useGetUsers,
} from 'lib';
import { toast } from 'react-toastify';
import {
  ActivatedOwner, AdminView, DetailedMetric, formatDecimalToPercentage, InvitedOwner, PercentRedBig, useLabels,
} from 'ui';
import {
  Stack,
  Typography,
  useMediaQuery, useTheme,
} from '@mui/material';
import {
  ColumnDef,
  createColumnHelper,
  Row,
} from '@tanstack/react-table';

import {
  ImpersonateOwnerButton, InviteUserButton, PerformanceReviewButton,
} from './buttons';
import { useGetNotificationTemplate } from '../../api/notification-templates';

const sortByActivated = (a: APIUser, b: APIUser) => {
  if (a.isActivated && !b.isActivated) {
    return 1;
  }

  if (!a.isActivated && b.isActivated) {
    return -1;
  }

  return 0;
};

const sortByIsOwner = (a: APIUser, b: APIUser) => {
  const aHasOwnerRole = a.roles.includes(Role.OWNER);
  const bHasOwnerRole = b.roles.includes(Role.OWNER);

  if (aHasOwnerRole && !bHasOwnerRole) {
    return 1;
  }

  if (!aHasOwnerRole && bHasOwnerRole) {
    return -1;
  }

  return 0;
};

const sortColumnByActivated = (a: Row<APIUser>, b: Row<APIUser>) => sortByActivated(a.original, b.original);

const sortColumnByIsOwner = (a: Row<APIUser>, b: Row<APIUser>) => sortByIsOwner(a.original, b.original);

export const Owners = ({ renderedOnRoot = true }: { renderedOnRoot?: boolean }) => {
  const theme = useTheme();
  const l = useLabels();
  const [searchParams] = useSearchParams();
  const { isLoading: isLoadingFeatures, ...features } = useFeatures();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const {
    data,
    isLoading: isLoadingUsers,
    isError,
  } = useGetUsers(['owner', 'marketplace_user']);

  const isLoading = isLoadingUsers || isLoadingFeatures;

  const {
    data: inviteNotificationTemplate,
  } = useGetNotificationTemplate('invitation_user');

  const getSMSText = (user: APIUser) => {
    if (!inviteNotificationTemplate?.template) return '';

    return inviteNotificationTemplate.template
      .replace('{{ .PMCompanyName }}', pmState.value?.name || '')
      .replace('{{ .Name }}', user.name)
      .replace(
        '{{ .Link }}', '<a href="#">https://api.blankethomes.com/link</a>',
      ).replaceAll('\n', '<br />');
  };

  const activatedOwners = data?.filter((owner) => owner.isActivated) || [];

  const columnHelper = createColumnHelper<APIUser>();
  const columns = useMemo(() => {
    const cols: ColumnDef<APIUser, string>[] = [
      columnHelper.accessor('name', {
        header: l['pm-dashboard.owner.name'],
        enableSorting: true,
        cell: (info) => info.getValue(),
        meta: { shownAsText: true },
      }),
      columnHelper.accessor('email', {
        header: l['pm-dashboard.owner.email'],
        cell: (info) => info.getValue(),
        meta: { shownAsText: true },
      }),
      columnHelper.accessor('phoneNumber', {
        header: l['pm-dashboard.owner.phone'],
        cell: (info) => info.getValue(),
        meta: { shownAsText: true },
      }),
    ];

    cols.push({
      header: l.activated,
      accessorKey: 'login-as',
      size: 100,
      maxSize: 210,
      enableSorting: true,
      sortingFn: sortColumnByActivated,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (info) => (
        <Typography variant="body2">{info.row.original.isActivated ? l.yes : l.no}</Typography>
      ),
    });

    cols.push({
      header: l.owner,
      accessorKey: 'isOwner',
      minSize: 80,
      maxSize: 210,
      enableSorting: true,
      sortingFn: sortColumnByIsOwner,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (info) => (
        <Typography variant="body2">
          {info.row.original.roles.filter((r) => r === 'owner').length > 0 ? l.yes : l.no}
        </Typography>
      ),
      meta: { textNoWrap: true },
    });

    cols.push({
      header: ' ',
      accessorKey: 'actions',
      enableSorting: false,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (info) => (
        <Stack spacing={2} direction="row">
          {(info.row.original.isActivated || features.isRetainProductEnabled) && info.row.original.roles.includes(Role.OWNER) && (
            <>
              <ImpersonateOwnerButton
                user={info.row.original}
              />
              <PerformanceReviewButton user={info.row.original} />
            </>
          )}
          {!info.row.original.isActivated && (
            <InviteUserButton
              user={info.row.original}
              smsText={getSMSText(info.row.original)}
            />
          )}
        </Stack>
      ),
    });

    return cols;
  }, [isMobile, inviteNotificationTemplate, features]);

  useEffect(() => {
    if (isError) {
      toast.error(l['error.unknownError']);
    }
  }, [isError]);

  const owners = useMemo(() => {
    if (data && inviteNotificationTemplate) {
      return data?.sort(sortByActivated) as APIUser[];
    }

    return [];
  }, [data, inviteNotificationTemplate]);

  return (
    <AdminView
      title={renderedOnRoot ? l['menu-owners'] : undefined}
      searchPlaceholder={l['pm-dashboard.owner.search']}
      data={isLoading ? [] : owners}
      columns={columns}
      searchTermFilter={(currProperties, search) => currProperties.filter(
        (p) => (p.email + p.name).toLowerCase().includes(search.toLowerCase()),
      )}
      initSearch={searchParams.get('search') ?? ''}
      pageHeader={(
        <DetailedMetric
          title=""
          metrics={[
            {
              id: 'signed-up-owners',
              value: activatedOwners.length,
              label: l.activated,
              icon: <ActivatedOwner />,
            },
            {
              id: 'invited-owners',
              value: owners?.length,
              label: l.invited,
              icon: <InvitedOwner />,
            },
            {
              id: 'owners-conversion-rate',
              value: formatDecimalToPercentage(owners?.length ? activatedOwners.length / owners.length : 0, 2),
              label: l['pm-dashboard.owner.conversion-rate'],
              icon: <PercentRedBig />,
            },
          ]}
          onClick={() => { }}
        />
      )}
    />
  );
};
