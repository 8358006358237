import { MouseEvent, useState } from 'react';

import { useAnalytics } from 'lib';
import { MdKeyboardArrowRight } from 'react-icons/md';
import {
  BoldTypography, formatNumberToCurrency, SemiBoldTypography, useLabels,
} from 'ui';
import { Collapse, IconButton, Stack } from '@mui/material';

import { EstimatedRent } from './EstimatedRent';
import { EstimatedVacancy } from './EstimatedVacancy';
import { GrossYield } from './GrossYield';
import { DetailRow } from './Row';
import { RowProps } from '../types';
import { useProjections } from '../useProjections';

export const ExpectedRent = ({ form }: RowProps) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const [expectedRentExpanded, setExpectedRentExpanded] = useState(false);

  const projections = useProjections(form);

  const handleClickToggleExpectedRent = (e: MouseEvent) => {
    e.stopPropagation();

    analytics.track('Button Clicked', {
      buttonName: 'Toggle Expected Rent',
      value: !expectedRentExpanded,
    });

    setExpectedRentExpanded((expanded) => !expanded);
  };

  return (
    <>
      <DetailRow
        rowID="expected-rent"
        bottomBorder={false}
        onClickRow={handleClickToggleExpectedRent}
        columns={[
          {
            id: 'expected-rent',
            content: (
              <Stack direction="row" alignItems="center" gap={1}>
                <IconButton onClick={handleClickToggleExpectedRent} size="small" color="primary">
                  <MdKeyboardArrowRight
                    style={{
                      transform: expectedRentExpanded ? 'rotate(90deg)' : 'none',
                      transition: 'transform 0.3s',
                    }}
                  />
                </IconButton>
                <SemiBoldTypography variant="body1">
                  {l.expectedRent}
                </SemiBoldTypography>
              </Stack>
            ),
          },
          ...projections.years.map((year, i) => ({
            id: `expected-rent-year-${year}`,
            content: (
              <BoldTypography variant="body1" color="success.main">
                {formatNumberToCurrency(projections.expectedRent[i] || 0, 0)}
              </BoldTypography>
            ),
          })),
        ]}
      />
      <Collapse in={expectedRentExpanded}>
        <GrossYield form={form} />
        <EstimatedRent form={form} />
        <EstimatedVacancy form={form} />
      </Collapse>
    </>
  );
};
