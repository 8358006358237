import { useState } from 'react';

import {
  PartnerType, SuggestedPartnerStatus, SuggestedPartnerType, useFeatures,
} from 'lib';
import { toast } from 'react-toastify';
import { useConfetti, useLabels } from 'ui';
import { useQueryClient } from '@tanstack/react-query';

import { useCreatePartner } from '../../api/partners';
import { useUpdateSuggestedPartner } from '../../api/suggested-partners';
import { SuggestedPartner } from '../../api/suggested-partners/types';
import { QueryKey } from '../../types/enums';

export const useAddToNetwork = (partner: SuggestedPartner, referralAmount: number = 0) => {
  const l = useLabels();
  const queryClient = useQueryClient();
  const [invalidatingQueries, setInvalidatingQueries] = useState(false);
  const { mutateAsync: updateSuggestedPartner, isLoading: isUpdatingSuggestedPartner } = useUpdateSuggestedPartner();
  const { mutateAsync: createPartner, isLoading: isCreatingPartner } = useCreatePartner();
  const confetti = useConfetti();
  const { isLoading: isLoadingFeatures, ...features } = useFeatures();

  const getPartnerType = (suggestedPartnerType: SuggestedPartnerType): PartnerType => {
    switch (suggestedPartnerType) {
      case SuggestedPartnerType.AGENT:
        return PartnerType.AGENT;
      case SuggestedPartnerType.OWNER:
        return PartnerType.OWNER;
      case SuggestedPartnerType.PM:
        return PartnerType.PM;
      case SuggestedPartnerType.CONTRACTOR:
        return PartnerType.CONTRACTOR;
      case SuggestedPartnerType.LENDER:
        return PartnerType.LENDER;
      default:
        console.error(`Unknown partner type: ${suggestedPartnerType}`);

        return PartnerType.AGENT;
    }
  };

  const addToNetwork = async () => {
    try {
      await Promise.all([
        updateSuggestedPartner({
          id: partner.id,
          status: SuggestedPartnerStatus.WON,
        }),
        createPartner({
          email: partner.email,
          firstName: partner.firstName,
          lastName: partner.lastName,
          partnerType: getPartnerType(partner.suggestedPartnerType),
          referralAmount,
          suggestedPartnerId: partner.id,
        }),
      ]);
      setInvalidatingQueries(true);
      await queryClient.invalidateQueries([QueryKey.SUGGESTED_PARTNERS]);
      setInvalidatingQueries(false);
      toast.success(l['pm-dashboard.network.messageSuccessInviteNewPartner']);
      if (features.isFreemium) {
        confetti.trigger();
      }
    } catch (e) {
      console.error(e);

      toast.error(l['error.unknownError']);
    }
  };

  return {
    addToNetwork,
    isLoading: isUpdatingSuggestedPartner || isCreatingPartner || invalidatingQueries,
  };
};
