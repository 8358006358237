import {
  convertArrayToFirstElement,
  filterNulls, OwnerLead, OwnerLeadReferralLink, OwnerLeadsWorkflowStatus, Partner, sortByDesc,
} from 'lib';

import { ListPartner, OwnerLeadItem } from './types';

export const convertPartner = (partner: Partner): ListPartner => {
  const apiOwnerLeads = sortByDesc<OwnerLead>(
    filterNulls<OwnerLead>(partner?.ownerLead?.items),
    'createdTime',
  );
  const ownerReferralLink = convertArrayToFirstElement<OwnerLeadReferralLink>(partner.ownerLeadReferralLink);

  const ownerLeads: OwnerLeadItem[] = apiOwnerLeads.map((ownerLead) => ({
    id: ownerLead.id,
    firstName: ownerLead.firstName ?? '',
    lastName: ownerLead.lastName ?? '',
    createdTime: ownerLead.createdTime,
    email: ownerLead.email,
    numOfProperties: ownerLead.numOfProperties,
    status: ownerLead.ownerLeadWorkflowItem?.items[0]?.status ?? OwnerLeadsWorkflowStatus.NEW,
    phoneNumber: ownerLead.phoneNumber ?? '',
    addresses: (ownerLead.addresses ?? []).filter(Boolean) as string[],
  }));

  return {
    firstName: partner.firstName ?? '',
    lastName: partner.lastName ?? '',
    ownerReferralLink,
    leads: ownerLeads,
    partnerType: partner.partnerType,
    referralAmount: partner.referralAmount ?? 0,
    id: partner.id,
    createdTime: partner.createdTime,
    phoneNumber: partner.phoneNumber ?? undefined,
    avatar: partner.avatar ?? undefined,
    website: partner.website ?? undefined,
    address: partner.address ?? undefined,
    email: partner.email ?? undefined,
    minPrice: partner.minPrice ?? 0,
    maxPrice: partner.maxPrice ?? 0,
    doorCount: partner.doorCount ?? 0,
    experienceInYears: partner.experienceInYears ?? 0,
    propertyTypes: partner.propertyTypes ?? [],
    workingCounties: partner.workingCounties ?? [],
  };
};
