import {
  useCallback, useEffect, useState,
} from 'react';

import {
  AppUserActions, PMPreferences,
} from 'lib';
import { useForm } from 'react-hook-form';
import { BsArrowRightCircle } from 'react-icons/bs';
import {
  SemiBoldTypography, useLabels,
} from 'ui';
import {
  Grid,
  IconButton, Stack, useTheme,
} from '@mui/material';

import { AskingPrice } from './AskingPrice';
import { CapRate } from './CapRate';
import { CashFlow } from './CashFlow';
import { EditableMetrics } from './EditableMetrics';
import { ExpectedRent } from './ExpectedRent';
import { Expenses } from './Expenses';
import { EquityBuildUp, InvestmentValueOverTime } from './Graphs';
import { HomePrice } from './HomePrice';
import { Income } from './Income';
import { LoanPayments } from './LoanPayments';
import { OperatingIncome } from './OperatingIncome';
import { ProformaHeader } from './ProformaHeader';
import { DetailRow } from './Row';
import { Property } from '../../../types';
import { createInitialFormData } from '../form';
import { editModeSignal } from '../state';
import {
  Form, PropertyProformaFormData,
} from '../types';
import { useProjections } from '../useProjections';

const tableWidth = 1200;

const Proforma = ({ form }: { form: Form }) => {
  const l = useLabels();
  const theme = useTheme();
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const [scrollButtonClicked, setScrollButtonClicked] = useState(false);
  const [width, setWidth] = useState(0);

  const { years } = useProjections(form);

  const ref = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setContainer(node);
      setWidth(node.offsetWidth);
    }
  }, []);

  const handleClickArrow = () => {
    setScrollButtonClicked(true);
    container?.scrollBy({
      left: tableWidth,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const handleResize = () => {
      if (container) {
        setWidth(container.offsetWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [container]);

  return (
    <Stack position="relative" sx={{ background: theme.palette.background.paper }}>
      <Stack
        ref={ref}
        sx={{
          overflowX: 'scroll',
          overflowY: 'hidden',
          maxWidth: '100%',
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: '6px',
        }}
      >
        {width < tableWidth && !scrollButtonClicked && (
          <Stack
            sx={{
              position: 'absolute',
              top: theme.spacing(9),
              right: theme.spacing(4),
              bottom: 0,
            }}
          >
            <IconButton
              onClick={handleClickArrow}
              sx={{
                boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                background: `${theme.palette.background.paper} !important`,
              }}
            >
              <BsArrowRightCircle />
            </IconButton>
          </Stack>
        )}
        <Stack minWidth={tableWidth}>
          <DetailRow
            rowID="year-projections"
            bottomBorder={false}
            columns={[
              {},
              ...years.map((year) => ({
                id: `year-${year}`,
                content: (
                  <SemiBoldTypography variant="body1">
                    {`${year} ${l.year}`}
                  </SemiBoldTypography>
                ),
              })),
            ]}
          />
          <HomePrice form={form} />
          <AskingPrice form={form} />
          <Income form={form} />
          <ExpectedRent form={form} />
          <Expenses form={form} />
          <OperatingIncome form={form} />
          <CapRate form={form} />
          <LoanPayments form={form} />
          <CashFlow form={form} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export const PropertyProforma = ({
  properties,
  userActions = undefined,
  pmPreferences,
}: {
  properties: Property[],
  userActions?: AppUserActions,
  pmPreferences: PMPreferences,
}) => {
  const form = useForm<PropertyProformaFormData>({
    defaultValues: createInitialFormData(properties, pmPreferences, userActions),
  });

  useEffect(() => () => {
    editModeSignal.value = false;
  }, []);

  return (
    <>
      <ProformaHeader properties={properties} pmPreferences={pmPreferences} userActions={userActions} form={form} />
      <Stack gap={3} pb={3}>
        <Stack>
          <EditableMetrics form={form} properties={properties} />
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
            <InvestmentValueOverTime form={form} />
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 3, md: 2 }}>
            <EquityBuildUp form={form} />
          </Grid>
          <Grid item xs={12} order={{ xs: 1, md: 3 }}>
            <Proforma form={form} />
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};
