import { useState } from 'react';

import { useAnalytics } from 'lib';
import { useDropzone } from 'react-dropzone';
import { UseFormReturn } from 'react-hook-form';
import { MdCameraAlt } from 'react-icons/md';
import {
  Avatar, InvertedButton, Spinner, useLabels,
} from 'ui';
import {
  Button, Stack, Typography, useTheme,
} from '@mui/material';

import { StyledCompactLabelField } from './styled';
import { useUploadGrowthPolicyPmAvatar } from '../../api/growth-policy';
import { GrowthPolicyData } from '../../api/suggested-partners/forms';

export const PmAvatarField = ({ form }: { form: UseFormReturn<GrowthPolicyData> }) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const avatarSize = 80;

  const [error, setError] = useState<string | null>(null);
  const { mutateAsync: uploadPmAvatar, isLoading: isUploadingPmAvatar } = useUploadGrowthPolicyPmAvatar();

  const avatarURL = form.watch('pmAvatarURL');

  const handleUploadClick = () => {
    analytics.track('Button Clicked', {
      buttonName: 'Upload Avatar',
    });

    inputRef.current?.click();
  };

  const uploadFiles = async (acceptedFiles: File[]) => {
    if (acceptedFiles.length <= 0) return;

    const file = acceptedFiles[0];
    const url = await uploadPmAvatar(file);
    form.setValue('pmAvatarURL', url);
  };

  const handleClickReset = () => {
    analytics.track('Button Clicked', {
      buttonName: 'Reset Avatar',
    });

    form.setValue('pmAvatarURL', '');
    setError(null);

    if (inputRef.current?.value) {
      inputRef.current.value = '';
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    inputRef,
  } = useDropzone({
    onDrop: uploadFiles,
    multiple: false,
    noClick: true,
    noDrag: true,
    noKeyboard: true,
    noDragEventsBubbling: true,
    maxSize: 800000,
    onDropAccepted: () => {
      setError(null);
    },
    onDropRejected: (rejections) => {
      const err = rejections[0].errors[0];

      setError(err.code === 'file-too-large' ? l['partners.wizard.question.pmAvatar.fileTooLarge'] : err.message);
    },
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    },
  });

  return (
    <StyledCompactLabelField id="avatar">
      <Stack gap={2}>
        <Typography variant="body1">
          {l['partners.wizard.question.pmAvatar']}
        </Typography>
        <Typography variant="body2" color="secondary">
          {l['partners.wizard.question.pmAvatar.description']}
        </Typography>
      </Stack>
      <Stack gap={2}>
        <Stack
          gap={3}
          direction="row"
          alignItems="center"
          {...getRootProps()}
          sx={{ border: isDragActive ? '2px dashed' : 'none' }}
        >
          {avatarURL ? (
            <Avatar src={avatarURL} sx={{ width: avatarSize, height: avatarSize, '& img': { objectFit: 'contain' } }} />
          ) : (
            <Stack
              justifyContent="center"
              alignItems="center"
              borderRadius="50%"
              sx={{ background: theme.palette.grey[200] }}
              width={avatarSize}
              height={avatarSize}
            >
              <MdCameraAlt size={24} color={theme.palette.secondary.main} />
            </Stack>
          )}
          <Stack gap={4}>
            <Stack direction="row" gap={2} alignItems="center">
              <Stack component="span">
                <InvertedButton
                  variant="contained"
                  size="small"
                  disabled={isUploadingPmAvatar}
                  onClick={handleUploadClick}
                >
                  {isUploadingPmAvatar ? (
                    <>
                      &nbsp;
                      <Spinner size={20} />
                    </>
                  ) : l['partners.wizard.question.pmAvatar.upload']}
                </InvertedButton>
                <input {...getInputProps()} />
              </Stack>
              <Button variant="outlined" size="small" onClick={handleClickReset} disabled={isUploadingPmAvatar}>
                {isUploadingPmAvatar ? (
                  <>
                    &nbsp;
                    <Spinner size={20} />
                  </>
                ) : l.reset}
              </Button>
            </Stack>
            <Typography variant="body2" color="secondary">
              {l['partners.wizard.question.pmAvatar.limit']}
            </Typography>
          </Stack>
        </Stack>
        {error && (
          <Typography variant="body2" color="error.main">
            {error}
          </Typography>
        )}
      </Stack>
    </StyledCompactLabelField>
  );
};
