import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAnalytics, useFeatures } from 'lib';
import CountUp from 'react-countup';
import {
  ActiveListings,
  BoldTypography,
  SemiBoldTypography,
  useLabels,
} from 'ui';
import { Stack } from '@mui/material';

import { ClickableCard } from './styled';
import { useCountActiveListings } from '../../api/properties';

export const ActiveListingsCounter = () => {
  const minActiveListings = 5;

  const l = useLabels();
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const [lastCount, setLastCount] = useState(0);
  const { isLoading: isLoadingFeatures, ...features } = useFeatures();
  const {
    data: countPages,
    isLoading: isLoadingCount,
  } = useCountActiveListings(!isLoadingFeatures && features.isMarketplaceEnabled);

  const handleClick = () => {
    analytics.track('Button Clicked', {
      buttonName: 'Active Listings Widget',
    });

    navigate('/marketplace');
  };

  const getPropertyIDs = (pages?: { propertyIDs: Set<string> }[]) => {
    const propertyIDs = new Set<string>();

    pages?.forEach((page) => {
      page.propertyIDs.forEach((id) => {
        propertyIDs.add(id);
      });
    });

    return propertyIDs;
  };

  const numOfActiveListings = getPropertyIDs(countPages?.pages).size;

  useEffect(() => {
    if (!countPages) return;

    const propertyIDs = getPropertyIDs(countPages.pages.slice(0, -1));

    setLastCount(propertyIDs.size);
  }, [countPages, getPropertyIDs]);

  if (!isLoadingFeatures || isLoadingCount
    || !numOfActiveListings || numOfActiveListings < minActiveListings) {
    return null;
  }

  return (
    <ClickableCard sx={{ p: 5, mt: 2 }} onClick={() => handleClick()}>
      <Stack direction="row" alignItems="center" gap={3}>
        <ActiveListings />
        <SemiBoldTypography variant="body1">
          {l.activeListings}
        </SemiBoldTypography>
      </Stack>
      <BoldTypography variant="body1">
        <CountUp start={lastCount} end={numOfActiveListings} duration={4} />
      </BoldTypography>
    </ClickableCard>
  );
};
