import { useEffect, useState } from 'react';

import { useAnalytics, useFeatures } from 'lib';
import { MdClose } from 'react-icons/md';
import {
  Dialog, Grid, IconButton, lighten, Stack, Typography, useMediaQuery, useTheme,
} from '@mui/material';

import { recommendationBackgroundURL, WordCurve } from '../assets';
import { InvertedButton } from '../button/styled';
import { HubSpotCalendar } from '../calendar';
import { DoubleCheckIcon } from '../icons/DoubleCheckIcon';
import { useLabels } from '../lib/translations';
import { BoldTypography } from '../typography/BoldTypography';
import { LightTypography } from '../typography/LightTypography';

export type UpgradableProduct = 'grow' | 'retain';

export const UpgradeProductDialog = ({
  open,
  onClose,
}: {
  open: boolean,
  onClose: () => void,
}) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const isLowerThanSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [meetingDialogOpen, setMeetingDialogOpen] = useState(false);
  const features = useFeatures();

  const handleClickBookDemo = () => {
    analytics.track('Button Clicked', {
      buttonName: 'In App Up Sell Popup - Book a Demo',
    });

    setMeetingDialogOpen(true);
  };

  const productItems = [
    {
      title: l['product.unlockPopup.item1.title'],
      subtitle: l['product.unlockPopup.item1.subtitle'],
    },
    {
      title: l['product.unlockPopup.item2.title'],
      subtitle: l['product.unlockPopup.item2.subtitle'],
    },
  ];

  const discountItems = [
    {
      title: l['product.unlockPopup.discount.item1.title'],
      subtitle: l['product.unlockPopup.discount.item1.subtitle'],
    },
    {
      title: l['product.unlockPopup.discount.item2.title'],
      subtitle: l['product.unlockPopup.discount.item2.subtitle'],
    },
  ];

  useEffect(() => {
    if (open) {
      analytics.track('Popup Opened', {
        popupName: 'In App Up Sell Popup',
      });
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open && !meetingDialogOpen}
        onClose={onClose}
        fullScreen={isLowerThanSm}
        PaperProps={{ sx: { maxWidth: '750px !important', width: '100%' } }}
        sx={{ zIndex: 1800 }}
      >
        <Stack direction="row" sx={{ background: theme.palette.primary.main, justifyContent: 'flex-end' }} px={4} pt={4}>
          <IconButton onClick={onClose} sx={{ color: theme.palette.primary.contrastText }}>
            <MdClose />
          </IconButton>
        </Stack>
        <Stack
          p={{ xs: 4, sm: 10 }}
          pt="0 !important"
          gap={6}
          sx={{ background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
          alignItems="center"
        >
          <Stack gap={3} alignItems="center">
            <BoldTypography variant="h5" color="primary.contrastText">
              {l['product.unlockPopup.title']}
            </BoldTypography>
            <Stack direction="row" gap={1}>
              <LightTypography variant="body2" color="primary.contrastText" sx={{ opacity: 0.6 }}>
                {l['product.unlockPopup.subtitle.start']}
              </LightTypography>
              <LightTypography
                component="span"
                variant="body2"
                color="primary.contrastText"
                sx={{ opacity: 0.6, position: 'relative' }}
              >
                {l['product.unlockPopup.subtitle.mid']}
                <Stack
                  component="span"
                  position="absolute"
                  left={0}
                  bottom={-12}
                >
                  <WordCurve width="100%" />
                </Stack>
              </LightTypography>
              <LightTypography variant="body2" color="primary.contrastText" sx={{ opacity: 0.6 }}>
                {l['product.unlockPopup.subtitle.end']}
              </LightTypography>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            gap={3}
            justifyContent="space-between"
            rowGap={6}
            sx={{
              flexWrap: 'nowrap',
              [theme.breakpoints.down(700)]: {
                flexWrap: 'wrap',
              },
            }}
          >
            <Stack gap={3}>
              {productItems.map((item) => (
                <Stack direction="row" gap={3} key={item.title}>
                  <Stack
                    sx={{ background: lighten(theme.palette.primary.dark, 0.88) }}
                    minHeight={36}
                    maxHeight={36}
                    minWidth={36}
                    maxWidth={36}
                    borderRadius="50%"
                    p={1.5}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <DoubleCheckIcon color={theme.palette.primary.dark} size={20} />
                  </Stack>
                  <Stack gap={2}>
                    <BoldTypography variant="body1" color="primary.contrastText">
                      {item.title}
                    </BoldTypography>
                    <LightTypography variant="body2" color="primary.contrastText" sx={{ opacity: 0.6 }}>
                      {item.subtitle}
                    </LightTypography>
                  </Stack>
                </Stack>
              ))}
            </Stack>
            <Stack sx={{
              background: `url(${recommendationBackgroundURL})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              minWidth: '280px !important',
              width: { xs: '100%', md: 'unset' },
              height: 'fit-content',
              borderRadius: '6px',
              p: 6,
              gap: 3,
            }}
            >
              <BoldTypography variant="h5">
                {l['product.unlockPopup.purchase.startNow']}
              </BoldTypography>
              <Stack direction="row" gap={2} alignItems="flex-end">
                <Stack direction="row" gap={1}>
                  <Typography variant="body2">
                    {l.dollarCurrency}
                  </Typography>
                  <BoldTypography variant="h3" color="primary.dark">
                    {299}
                  </BoldTypography>
                </Stack>
                <BoldTypography variant="h6">
                  {l['product.unlockPopup.purchase.basePrice']}
                </BoldTypography>
              </Stack>
              <Stack gap={1}>
                <Typography variant="body1" sx={{ pr: 3 }}>
                  {l['product.unlockPopup.purchase.afterDue.item1']}
                </Typography>
                <Typography variant="body1" sx={{ pr: 3 }}>
                  {l['product.unlockPopup.purchase.afterDue.item2']}
                </Typography>
              </Stack>
              <InvertedButton onClick={handleClickBookDemo}>
                {l['product.unlockPopup.purchase.bookDemo']}
              </InvertedButton>
            </Stack>
          </Stack>
        </Stack>
        <Stack gap={6} p={{ xs: 4, sm: 10 }}>
          <BoldTypography typography="h5">
            {l.dynamic['product.unlockPopup.discount.title'](features)}
          </BoldTypography>
          <Grid container spacing={3}>
            {discountItems.map((item) => (
              <Grid item xs={12} md={6} key={item.title}>
                <Stack direction="row" gap={3}>
                  <Stack>
                    <DoubleCheckIcon size={22} color={theme.palette.primary.dark} />
                  </Stack>
                  <Stack gap={2}>
                    <BoldTypography variant="body1">
                      {item.title}
                    </BoldTypography>
                    <Typography variant="body2" color="secondary">
                      {item.subtitle}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            ))}
          </Grid>
          <Stack>
            <Typography
              onClick={handleClickBookDemo}
              variant="body1"
              sx={{
                color: theme.palette.primary.dark,
                '&:hover': {
                  cursor: 'pointer',
                  textDecoration: 'underline',
                },
              }}
            >
              {l['product.unlockPopup.discount.action']}
            </Typography>
          </Stack>
        </Stack>
      </Dialog>
      <HubSpotCalendar open={meetingDialogOpen} onClose={() => setMeetingDialogOpen(false)} />
    </>
  );
};
