import { calculateCapRate, calculateNOI, sum } from 'lib';

import { calculateLoanBalance, calculateMonthlyMortgagePayment, calculateProjections } from './calc';
import { getAllLoans } from './form';
import { PORTFOLIO, useGetPropertyIdParam } from './state';
import {
  Form, PropertyProformaData,
} from './types';

const projectionYears = [1, 5, 10, 15, 20, 25];

export const useProjections = (form: Form, overrideYears?: number[]) => {
  const years = overrideYears ?? projectionYears;

  const propertyID = useGetPropertyIdParam();

  const rentMultiplierByYear = (form.watch(`${propertyID}.metrics.annualRentGrowth`) || 0) / 100;
  const askingPriceMultiplierByYear = (form.watch(`${propertyID}.metrics.annualHomeAppreciation`) || 0) / 100;

  const rentAmount = form.watch(`${propertyID}.income.rent`);
  const estimatedVacancyPercentage = form.watch(`${propertyID}.metrics.estimatedVacancy`);
  const askingPriceAmount = form.watch(`${propertyID}.askingPrice`);
  const expenses = form.watch(`${propertyID}.expenses`);
  const inflationRate = (form.watch(`${propertyID}.metrics.inflationRate`) || 0) / 100;

  const rents = calculateProjections({
    years,
    value: rentAmount,
    multiplier: rentMultiplierByYear,
  });
  const askingPrices = calculateProjections({
    years,
    value: askingPriceAmount,
    multiplier: askingPriceMultiplierByYear,
  });
  const estimatedVacancy = rents.map((rent) => rent * (estimatedVacancyPercentage / 100));
  const expectedRent = years.map((_, i) => rents[i] - estimatedVacancy[i]);
  const loan = form.watch(`${propertyID}.loan`);
  const formData = form.watch();
  const loanBalance = years.map((_, i) => {
    const loans = propertyID === PORTFOLIO ? getAllLoans(formData) : [loan];

    return sum(loans.map((l) => calculateLoanBalance(l, i)));
  });
  const equity = years.map((_, i) => askingPrices[i] - loanBalance[i]);

  const expenseProjections: Record<keyof PropertyProformaData['expenses'], number[]> = {
    tax: [],
    insurance: [],
    hoa: [],
    managementFee: [],
    maintenance: [],
    capEx: [],
    estimatedVacancy: [],
  };

  Object.entries(expenses).forEach(([expenseKey, expense]) => {
    expenseProjections[expenseKey as keyof PropertyProformaData['expenses']] = calculateProjections({
      value: expense,
      years,
      multiplier: inflationRate,
    });
  });

  const totalExpenses = years.map((_, i) => sum([
    expenseProjections.tax[i],
    expenseProjections.insurance[i],
    expenseProjections.hoa[i],
    expenseProjections.managementFee[i],
    expenseProjections.maintenance[i],
    expenseProjections.capEx[i],
  ]));

  const calculations = {
    getNOI: (index: number) => calculateNOI(expectedRent[index], totalExpenses[index]),
    getCapRate: (index: number) => calculateCapRate(
      calculateNOI(expectedRent[index], totalExpenses[index]) / 12,
      askingPrices[index],
    ),
    getNetCashFlow: (index: number) => {
      const calculatedMortgage = calculateMonthlyMortgagePayment(form.getValues(), propertyID) * 12;
      const noi = calculations.getNOI(index);

      return noi - calculatedMortgage;
    },
    getCashOnCash: (index: number) => {
      const askingPrice = askingPrices[index];
      const netCashFlow = calculations.getNetCashFlow(index);

      return netCashFlow / askingPrice;
    },
    getCumulativeNetCashflow: (index: number) => sum(years.slice(0, index + 1).map((_, i) => calculations.getNetCashFlow(i))),
    getAppreciation: (index: number) => {
      if (index <= 0) return 0;

      const previousYearAskingPrice = askingPrices[index - 1];
      const currentYearAskingPrice = askingPrices[index];

      return currentYearAskingPrice - previousYearAskingPrice;
    },
    getCumulativeAppreciation: (index: number) => sum(years.slice(0, index + 1).map((_, i) => calculations.getAppreciation(i))),
  };

  return {
    years,
    rents,
    expectedRent,
    loanBalance,
    equity,
    askingPrices,
    ...expenseProjections,
    totalExpenses,
    calculations,
  };
};
