import {
  axiosWithPayloadContext,
  config,
  createHeaders,
  ListSuggestedPartnerByPmQuery, queryGraphQL, SuggestedPartner as APISuggestedPartner,
  SuggestedPartnerStatus, SuggestedPartnerType,
  useAuth,
  useFeatures,
} from 'lib';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';

import { convertSuggestedPartner } from './converters';
import { QueryKey } from '../../types/enums';
import { listNewSuggestedPartnerCount, listSuggestedPartnersByPm } from '../graphql/queries';

export const useGetSuggestedPartners = () => {
  const { user, getAccessTokenSilently } = useAuth();

  const query = useInfiniteQuery({
    queryKey: [QueryKey.SUGGESTED_PARTNERS],
    queryFn: async ({ pageParam: nextToken }) => {
      const token = await getAccessTokenSilently();

      const res = await queryGraphQL({
        query: listSuggestedPartnersByPm,
        variables: {
          pm: user?.pm,
          nextToken,
        },
        authToken: token,
      }) as GraphQLResult<ListSuggestedPartnerByPmQuery>;

      if (!res.data?.listSuggestedPartnerByPm?.items?.length) {
        return { partners: [], nextToken: null };
      }

      const partners = res.data.listSuggestedPartnerByPm.items.filter((p) => !!p).map(
        // we validated that the partner is not null in the filter and this is sadly necessary for tsc
        (partner) => convertSuggestedPartner(partner as APISuggestedPartner),
      );

      return {
        partners,
        nextToken: res.data.listSuggestedPartnerByPm.nextToken,
      };
    },
    getNextPageParam: (lastPage) => lastPage.nextToken,
    onSuccess: (data) => {
      if (data.pages[data.pages.length - 1]?.nextToken) {
        query.fetchNextPage();
      }
    },
  });

  return query;
};

export const useGetNewSuggestedPartnerCount = () => {
  const { user, getAccessTokenSilently } = useAuth();
  const { isLoading: isLoadingFeatures, ...features } = useFeatures();

  const query = useQuery({
    enabled: !isLoadingFeatures,
    queryKey: [QueryKey.SUGGESTED_PARTNER_COUNT],
    queryFn: async () => {
      const token = await getAccessTokenSilently();

      const res = await queryGraphQL({
        query: listNewSuggestedPartnerCount,
        variables: {
          pm: user?.pm,
          type: features.isFreemiumPM ? SuggestedPartnerType.PM : SuggestedPartnerType.AGENT,
        },
        authToken: token,
      }) as GraphQLResult<ListSuggestedPartnerByPmQuery>;

      return res.data?.listSuggestedPartnerByPm?.items.length ?? 0;
    },
  });

  return query;
};

type UpdateSuggestedPartnerResponse = {
  id: string,
  status: SuggestedPartnerStatus,
  contactEmailBody: string,
  contactEmailSubject: string,
};

export const useUpdateSuggestedPartner = () => {
  const { getAccessTokenSilently } = useAuth();

  return useMutation(async ({
    id,
    ...data
  }: {
    id: string,
    status?: SuggestedPartnerStatus,
    generatesContactEmailBody?: boolean,
  }) => {
    const token = await getAccessTokenSilently();

    return axiosWithPayloadContext<UpdateSuggestedPartnerResponse>({
      url: `${config.apiBaseURL}/suggested-partner/${id}`,
      method: 'PATCH',
      headers: createHeaders(token),
      data,
    });
  });
};
