import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetPMPreferences, useGetUserActions } from 'lib';
import { MdTrendingUp } from 'react-icons/md';
import {
  EmptyFullPageContainer,
  FallbackSpinner,
  useLabels,
} from 'ui';
import {
  Stack,
  Typography,
} from '@mui/material';

import { useListOwnerProperties } from '../../api/properties';
import { PropertyProforma } from '../../templates/property-proforma/components/PropertyProforma';

export const Proforma = () => {
  const l = useLabels();
  const navigate = useNavigate();
  const { propertyID } = useParams();
  const { data: ownerProperties, isLoading } = useListOwnerProperties();
  const { data: userActions, isLoading: isLoadingUserActions } = useGetUserActions();
  const { data: pmPreferences, isLoading: pmPreferencesLoading } = useGetPMPreferences();
  const properties = ownerProperties?.properties ?? [];

  useEffect(() => {
    if (!properties || isLoading) return;

    if (!propertyID && properties.length === 1) {
      navigate(`/proforma/${properties[0].id}`);
    }

    if (!propertyID) return;

    const property = properties.find((p) => p.id === propertyID);

    if (!property) {
      navigate('/proforma');
    }
  }, [propertyID, properties, isLoading]);

  const invalidPropertyID = propertyID && !properties.find((p) => p.id === propertyID);

  if (isLoading || !properties || isLoadingUserActions || pmPreferencesLoading || invalidPropertyID) {
    return <FallbackSpinner />;
  }

  if (!properties.length) {
    return (
      <EmptyFullPageContainer>
        <Stack gap={3} p={3} alignItems="center">
          <MdTrendingUp size={36} />
          <Typography variant="h6">
            {l.propertiesNotFound}
          </Typography>
        </Stack>
      </EmptyFullPageContainer>
    );
  }

  return (
    <Stack height="100%" p={3} gap={3}>
      <PropertyProforma properties={properties} userActions={userActions} pmPreferences={pmPreferences!} />
    </Stack>
  );
};
