import { useEffect, useState } from 'react';

import { useAnalytics } from 'lib';
import { Controller } from 'react-hook-form';
import { MdEdit } from 'react-icons/md';
import {
  BoldTypography,
  ControlledFormattedNumberField, DollarRect, formatNumberToCurrency,
  InvertedButton, SemiBoldTypography, Spacer, TooltipMetric, useLabels,
} from 'ui';
import {
  Collapse,
  Divider,
  IconButton, Menu, MenuItem, Select, Stack,
  Typography,
  useTheme,
} from '@mui/material';

import { Property } from '../../../types';
import { calculateMonthlyMortgagePayment } from '../calc';
import { editModeSignal, PORTFOLIO, useGetPropertyIdParam } from '../state';
import { Form, RowProps } from '../types';

const inputWidth = 130;

const EditableMortgage = ({
  form,
  onClose,
  properties,
}: RowProps & {
  onClose: () => void
  properties: Property[]
}) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const propertyIdParam = useGetPropertyIdParam();
  const paramIsRelevant = properties.map((p) => p.id).includes(propertyIdParam);
  const [selectedPropertyID, setSelectedPropertyID] = useState<string>(
    paramIsRelevant && propertyIdParam !== PORTFOLIO ? propertyIdParam : properties[0]?.id ?? '',
  );
  const {
    control, watch,
  } = form;

  const purchasePrice = watch(`${selectedPropertyID}.loan.purchasePrice`);
  const loanAmount = watch(`${selectedPropertyID}.loan.amount`);
  const interestRate = watch(`${selectedPropertyID}.loan.interestRate`);
  const loanTerm = watch(`${selectedPropertyID}.loan.term`);
  const loanTermOther = watch(`${selectedPropertyID}.loan.termOther`);
  const showOtherLoanTerm = loanTerm === 'other';

  const handleClickClose = () => {
    analytics.track('Button Clicked', {
      buttonName: 'Close',
      inputName: 'Property Proforma - Close',
    });

    onClose();
  };

  const handleSelectProperty = (id: string) => {
    analytics.track('Selector Changed', {
      selectorName: 'Property Proforma - Mortgage Property',
      value: id,
    });

    setSelectedPropertyID(id);
  };

  useEffect(() => {
    if (paramIsRelevant) setSelectedPropertyID(propertyIdParam);
  }, [propertyIdParam, paramIsRelevant]);

  if (properties.length === 0) return null;

  return (
    <Stack px={3} py={2}>
      <BoldTypography variant="body1">
        {l.monthlyMortgagePayment}
      </BoldTypography>
      {propertyIdParam === PORTFOLIO && properties.length > 0 && (
        <>
          <Spacer spacing={1} />
          <Select
            size="small"
            value={selectedPropertyID}
            onChange={(e) => handleSelectProperty(e.target.value)}
            displayEmpty
          >
            <MenuItem value="" disabled>
              {l.property}
            </MenuItem>
            {properties.map((property) => (
              <MenuItem key={property.id} value={property.id}>
                {property.displayName}
              </MenuItem>
            ))}
          </Select>
        </>
      )}
      <Divider sx={{ mx: -3 }} />
      <Spacer spacing={1} />
      <Stack gap={3}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={5}>
          <SemiBoldTypography variant="body1" sx={{ opacity: 0.8 }}>
            {l.purchasePrice}
          </SemiBoldTypography>
          <ControlledFormattedNumberField
            control={control}
            name={`${selectedPropertyID}.loan.purchasePrice`}
            prefix="$"
            size="small"
            sx={{ width: inputWidth }}
            useExternalValue
            externalValue={purchasePrice}
            onBlur={() => {
              form.setValue(`${selectedPropertyID}.loan.purchasePrice`, purchasePrice);

              analytics.track('Input Changed', {
                value: purchasePrice,
                loanAmount,
                loanTerm,
                interestRate,
                loanTermOther,
                inputName: 'Property Proforma - Purchase Price',
              });
            }}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={5}>
          <SemiBoldTypography variant="body1" sx={{ opacity: 0.8 }}>
            {l.loanAmount}
          </SemiBoldTypography>
          <ControlledFormattedNumberField
            control={control}
            name={`${selectedPropertyID}.loan.amount`}
            prefix="$"
            size="small"
            sx={{ width: inputWidth }}
            useExternalValue
            externalValue={loanAmount}
            onBlur={() => {
              form.setValue(`${selectedPropertyID}.loan.amount`, loanAmount);

              analytics.track('Input Changed', {
                value: loanAmount,
                purchasePrice,
                loanTerm,
                interestRate,
                loanTermOther,
                inputName: 'Property Proforma - Loan Amount',
              });
            }}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={5}>
          <SemiBoldTypography variant="body1" sx={{ opacity: 0.8 }}>
            {l.interestRate}
          </SemiBoldTypography>
          <ControlledFormattedNumberField
            control={control}
            name={`${selectedPropertyID}.loan.interestRate`}
            suffix="%"
            prefix=""
            size="small"
            sx={{ width: inputWidth }}
            useExternalValue
            externalValue={interestRate}
            onBlur={() => {
              analytics.track('Input Changed', {
                value: interestRate,
                inputName: 'Property Proforma - Interest Rate',
              });
            }}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={5}>
          <SemiBoldTypography variant="body1" sx={{ opacity: 0.8, textWrap: 'nowrap' }}>
            {l.mortgageAmortizationPeriod}
          </SemiBoldTypography>
          <Stack sx={{ width: inputWidth }} gap={showOtherLoanTerm ? 3 : 0}>
            <Controller
              name={`${selectedPropertyID}.loan.term`}
              control={control}
              render={({ field }) => (
                <Select<typeof loanTerm>
                  size="small"
                  fullWidth
                  data-hj-allow
                  {...field}
                  onChange={(e, ...args) => {
                    field.onChange(e, ...args);

                    analytics.track('Input Changed', {
                      value: e.target.value,
                      inputName: 'Property Proforma - Mortgage Amortization Period',
                    });
                  }}
                >
                  <MenuItem value={120}>
                    {l['120-months']}
                  </MenuItem>
                  <MenuItem value={180}>
                    {l['180-months']}
                  </MenuItem>
                  <MenuItem value={240}>
                    {l['240-months']}
                  </MenuItem>
                  <MenuItem value={360}>
                    {l['360-months']}
                  </MenuItem>
                  <MenuItem value="other">
                    {l.other}
                  </MenuItem>
                </Select>
              )}
            />
            <Collapse in={showOtherLoanTerm}>
              <ControlledFormattedNumberField
                name={`${selectedPropertyID}.loan.termOther`}
                control={control}
                label={l.otherMonths}
                useExternalValue
                externalValue={loanTermOther}
                fullWidth
                size="small"
                prefix=""
                suffix=""
                onBlur={() => {
                  analytics.track('Input Changed', {
                    value: loanTermOther,
                    inputName: 'Property Proforma - Other Mortgage Amortization Period',
                  });
                }}
              />
            </Collapse>
          </Stack>
        </Stack>
      </Stack>
      <Divider sx={{ mx: -3 }} />
      <Stack width="100%" alignItems="flex-end">
        <InvertedButton onClick={handleClickClose}>
          {l.close}
        </InvertedButton>
      </Stack>
    </Stack>
  );
};

export const MortgageMetric = ({ form, properties }: { form: Form, properties: Property[] }) => {
  const l = useLabels();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const propertyID = useGetPropertyIdParam();

  const mortgage = calculateMonthlyMortgagePayment(form.watch(), propertyID);

  const relevantProperties = properties.filter((p) => !!p.mortgage && !!p.amortizationTable);
  const relevantPropertyIDs = relevantProperties.map((p) => p.id);
  const editEnabled = (
    editModeSignal.value && ((propertyID !== PORTFOLIO || properties.length === 1) && relevantPropertyIDs.includes(propertyID))
  );

  if (relevantProperties.length === 0) return null;

  return (
    <TooltipMetric
      label={l.monthlyMortgagePayment}
      displayValue={(
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant="body1">
            {formatNumberToCurrency(mortgage, 0)}
          </Typography>
          <IconButton
            size="small"
            onClick={(e) => setAnchorEl(e.currentTarget)}
            disabled={!editEnabled}
          >
            <MdEdit />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={() => setAnchorEl(null)}
            sx={{ '& .MuiList-root': { p: 0 } }}
          >
            <EditableMortgage form={form} onClose={() => setAnchorEl(null)} properties={relevantProperties} />
          </Menu>
        </Stack>
      )}
      icon={(
        <Stack
          sx={{
            borderRadius: '6px',
            border: `1px solid ${theme.palette.divider}`,
            height: 40,
            width: 40,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <DollarRect />
        </Stack>
      )}
      tooltipName="mortgage"
      tooltipContent={(
        <Typography variant="body2" sx={{ p: 1 }}>
          {l['proforma.monthlyMortgagePayment.tooltip']}
        </Typography>
      )}
      cardSx={{ height: '100%' }}
    />
  );
};
