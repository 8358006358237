import { useMemo, useState } from 'react';

import { useAnalytics, useListStates } from 'lib';
import { MdFilterList } from 'react-icons/md';
import { RiCloseCircleLine } from 'react-icons/ri';
import { TiDelete } from 'react-icons/ti';
import { hexToRGBA, useLabels } from 'ui';
import {
  Autocomplete, Button, ButtonGroup, Chip, Menu, Stack, TextField, useTheme,
} from '@mui/material';

export const PartnerFilters = ({
  selectedStates,
  setSelectedStates,
  availableStates,
  disabled = false,
}: {
  selectedStates: string[],
  setSelectedStates: (states: string[]) => void,
  availableStates: Set<string>,
  disabled?: boolean
}) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { data: states, isLoading: isLoadingStates } = useListStates();

  const options = useMemo(() => {
    const stateArray: string[] = [];

    if (states) {
      states.statesByAbbreviation.forEach((state) => {
        if (availableStates.has(state.abbreviation)) {
          stateArray.push(state.name);
        }
      });
    }

    return stateArray.sort();
  }, [states, availableStates]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showClearFiltersButton = selectedStates.length > 0;

  if (isLoadingStates || availableStates.size === 0) return null;

  return (
    <Stack>
      <ButtonGroup sx={{ transition: '0.3s' }}>
        <Button variant="outlined" onClick={handleClick} endIcon={<MdFilterList />} disabled={disabled}>
          {l.filter}
        </Button>
        {showClearFiltersButton && (
          <Button
            sx={{ px: 2.5, pr: 0 }}
            variant="outlined"
            onClick={() => {
              setSelectedStates([]);
              handleClose();
            }}
            startIcon={<RiCloseCircleLine />}
          />
        )}
      </ButtonGroup>
      <Menu open={open} anchorEl={anchorEl} onClose={handleClose} sx={{ zIndex: 1001 }}>
        <Stack p={3}>
          <Autocomplete<string, true>
            multiple
            limitTags={2}
            fullWidth
            size="small"
            filterSelectedOptions
            value={selectedStates}
            getLimitTagsText={(more) => `+${more} ${l.more}`}
            sx={{ zIndex: 1002 }}
            onChange={(_, newValues) => {
              analytics.track('Autocomplete Options Changed', {
                autocompleteName: 'State Filter',
                value: newValues.join(';'),
              });

              setSelectedStates(newValues);
            }}
            options={options ?? []}
            renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                label={option}
                key={option}
                deleteIcon={<TiDelete color={theme.palette.grey[400]} />}
                sx={{
                  backgroundColor: hexToRGBA(theme.palette.grey.A100, 0.3),
                  color: theme.palette.primary.main,
                  fontWeight: theme.typography.fontWeightMedium,
                }}
              />
            ))}
            renderInput={(params) => (
              <TextField
                {...params}
                label={l.filterByStates}
                sx={{ minWidth: 200 }}
                InputProps={{ sx: { borderRadius: '4px' }, ...params.InputProps }}
              />
            )}
          />
        </Stack>
      </Menu>
    </Stack>
  );
};
