import {
  axiosWithPayloadContext, config, createHeaders, filterNulls,
  ListPartnersByPmQuery, PartnerType, queryGraphQL, useAuth,
} from 'lib';
import { z } from 'zod';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';

import { convertPartner } from './converters';
import { ListPartner } from './types';
import { QueryKey } from '../../types/enums';
import { listPartnersByPm } from '../graphql/queries';

export const useListPartnersByPMInfinite = () => {
  const { getAccessTokenSilently, user } = useAuth();

  const query = useInfiniteQuery({
    queryKey: [QueryKey.PARTNERS],
    queryFn: async ({ pageParam: nextToken }): Promise<{
      partners: ListPartner[];
      nextToken: string | null | undefined;
    }> => {
      const token = await getAccessTokenSilently();

      const res = await queryGraphQL({
        query: listPartnersByPm,
        authToken: token,
        variables: { pm: user?.pm, nextToken },
      }) as GraphQLResult<ListPartnersByPmQuery>;

      if (!res.data?.listPartnersByPm?.items?.length) {
        return { partners: [], nextToken: null };
      }

      const partners = filterNulls(res.data.listPartnersByPm.items).map((partner) => convertPartner(partner));

      return {
        partners,
        nextToken: res.data.listPartnersByPm.nextToken,
      };
    },
    getNextPageParam: (lastPage) => lastPage.nextToken,
    onSuccess: (data) => {
      if (data.pages[data.pages.length - 1]?.nextToken) {
        query.fetchNextPage();
      }
    },
  });

  return query;
};

export class PartnerAlreadyExistsError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'PartnerAlreadyExistsError';
  }
}

export const addPartnerFormSchema = z.object({
  email: z.string().email('This is not a valid email').min(1),
  firstName: z.string().trim().min(1, 'First name is required'),
  lastName: z.string().trim().min(1, 'Last name is required'),
  partnerType: z.nativeEnum(PartnerType),
  referralAmount: z.number().min(0),
  workingCounties: z.array(z.object({
    state: z.string(),
    county: z.string(),
  })).optional(),
});

export type AddPartnerData = z.infer<typeof addPartnerFormSchema>;

export const useCreatePartner = () => {
  const { getAccessTokenSilently } = useAuth();

  return useMutation(async (data: AddPartnerData & {
    suggestedPartnerId?: string
  }) => {
    const token = await getAccessTokenSilently();

    try {
      await axiosWithPayloadContext({
        url: `${config.apiBaseURL}/partner`,
        method: 'POST',
        headers: createHeaders(token),
        data,
      });
    } catch (e: any) {
      if (e.response?.status === 409) {
        throw new PartnerAlreadyExistsError(`Partner ${data.email} already exists`);
      }

      throw e;
    }
  });
};
