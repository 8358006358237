import { useLocation, useNavigate } from 'react-router-dom';

import { ProductMenuItems as DashboardProductMenuItems } from 'dashboard';
import {
  isImpersonationUser,
  toggleChatOpen,
  useAnalytics,
  useAuth,
  useFeatures,
} from 'lib';
import {
  ProductMenuItems as MarketplaceProductMenuItems,
  ReferAnOwnerNavItem,
} from 'marketplace';
import {
  CraneCommunityMenuItem,
  MenuItems as PMProductMenuItems,
  PMDMenuItems,
  UpgradeGrowProductButton,
} from 'pm-dashboard';
import {
  ProductMenuItems as ReferralPartnersMenuItems,
} from 'referral-partners';
import {
  BoldTypography,
  LightTypography,
  Logout,
  MenuItem,
  SemiBoldTypography,
  SettingsIcon,
  Spacer,
  Star,
  Support,
  useLabels,
} from 'ui';
import {
  Box, Button, Divider, Stack, useTheme,
} from '@mui/material';

import { ReactComponent as BlanketLogo } from '../../../assets/blanket-logo.svg';

const menuIconSize = 18;

type Props = { onNavigate?: () => void };

export const NavigationMenu = ({ onNavigate = () => { } }: Props) => {
  const l = useLabels();
  const theme = useTheme();
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const { pathname } = useLocation();
  const { user, logout } = useAuth();
  const impersonationUser = isImpersonationUser();
  const { isLoading: isLoadingFeatures, ...features } = useFeatures();

  const userName = `${user?.givenName} ${user?.familyName}`;

  const handleNavigate = (path: string) => {
    onNavigate?.();
    navigate(path);
  };

  if (isLoadingFeatures) return null;

  return (
    <Stack height="100%">
      <Box sx={{ px: 4, pt: 5 }}>
        <BoldTypography variant="h6" sx={{ mb: 1, textTransform: 'capitalize' }}>
          {impersonationUser ? `${l.loggedInAs}:` : userName}
        </BoldTypography>
        <SemiBoldTypography
          variant="body2"
          sx={{ color: theme.palette.text.primary, overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {user?.email}
        </SemiBoldTypography>
      </Box>
      <Divider sx={{ ml: -2, mt: 4, mb: 0 }} />
      <Stack
        justifyContent="space-between"
        flexGrow={1}
        sx={{
          overflow: 'auto',
          scrollbarWidth: 'thin',
          '&::-webkit-scrollbar': {
            width: '5px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.05)',
          },
        }}
      >
        <Stack>
          <PMProductMenuItems onNavigate={onNavigate} />
          <ReferralPartnersMenuItems onNavigate={onNavigate} />
          <DashboardProductMenuItems onNavigate={onNavigate} />
          <MarketplaceProductMenuItems onNavigate={onNavigate} />
          {features.isObpEnabled && (
            <MenuItem
              dataTestId="owner-benefit-btn"
              label={l['menu-owner-benefits']}
              isActive={pathname?.startsWith('/owner-benefits')}
              onClick={() => handleNavigate('/owner-benefits')}
              icon={<Star />}
            />
          )}
          <PMDMenuItems onNavigate={onNavigate} />
        </Stack>
        <Box>
          <Divider />
          {features.isSettingsViewEnabled && (
            <MenuItem
              label={l.settings}
              isActive={pathname === '/settings'}
              onClick={() => handleNavigate('/settings')}
              icon={<SettingsIcon height={menuIconSize} width={menuIconSize} />}
            />
          )}
          <MenuItem
            label={l.support}
            onClick={() => {
              toggleChatOpen(true);

              analytics.track('Button Clicked', {
                buttonName: 'Support',
              });
            }}
            icon={<Support height={menuIconSize} width={menuIconSize} />}
          />
          <CraneCommunityMenuItem onNavigate={onNavigate} />
          {features.isReferOwnerNavWidgetEnabled && (
            <>
              <Spacer spacing={2} />
              <ReferAnOwnerNavItem onNavigate={() => onNavigate()} />
            </>
          )}
          {features.isUpgradeGrowProductNavWidgetEnabled && (
            <>
              <Spacer spacing={2} />
              <UpgradeGrowProductButton />
            </>
          )}
          <Spacer spacing={2} />
          <Button
            fullWidth
            sx={{
              textTransform: 'none', py: 3, px: 4, borderRadius: 0, justifyContent: 'space-between',
            }}
            onClick={() => {
              localStorage.clear();
              logout();
            }}
          >
            <Stack direction="row" alignItems="center" gap={3}>
              <Logout height={menuIconSize} width={menuIconSize} />
              <SemiBoldTypography variant="body1">
                {l.logout}
              </SemiBoldTypography>
            </Stack>
            <Stack alignItems="flex-end" justifyContent="center">
              <LightTypography sx={{ fontSize: 9, fontWeight: 300 }}>
                {l.poweredBy}
              </LightTypography>
              <BlanketLogo style={{ maxWidth: '75px', maxHeight: '20px' }} />
            </Stack>
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};
