import { useEffect, useMemo } from 'react';

import { useAnalytics } from 'lib';
import { UseFormReturn } from 'react-hook-form';
import { TiDelete } from 'react-icons/ti';
import { Chip } from 'ui';
import {
  Autocomplete, AutocompleteChangeDetails, lighten, TextField, useTheme,
} from '@mui/material';

import { GrowthPolicyData } from '../../api/suggested-partners/forms';

type AutocompleteFieldName = (
  'typeOfPropertiesManaged' |
  'companyServices' |
  'companyGuarantees' |
  'agentProvidedBenefits' |
  'workingCounties'
);

type AutocompleteOption<T extends AutocompleteFieldName> = {
  label: string,
  value: GrowthPolicyData[T][number]
};

export const GrowthPolicyAutocompleteField = <TName extends AutocompleteFieldName>({
  name,
  options,
  form,
  placeholder = undefined,
  normalizeValues = (values) => values.map((o) => o.value),
  getSelectedOptions = undefined,
}: {
  name: TName,
  options: AutocompleteOption<TName>[];
  form: UseFormReturn<GrowthPolicyData>;
  placeholder?: string;
  getSelectedOptions?: (values: GrowthPolicyData[TName][number][]) => AutocompleteOption<TName>[];
  normalizeValues?: (
    values: AutocompleteOption<TName>[],
    details?: AutocompleteChangeDetails<AutocompleteOption<TName>>,
  ) => GrowthPolicyData[TName][number][];
}) => {
  const theme = useTheme();
  const analytics = useAnalytics();

  const autocompleteValues: GrowthPolicyData[TName][number][] = form.watch(name);
  const selectedOptions = useMemo(() => (
    getSelectedOptions
      ? getSelectedOptions(autocompleteValues)
      : options.filter((o) => autocompleteValues.includes(o.value))),
  [JSON.stringify(autocompleteValues), getSelectedOptions]);

  useEffect(() => {
    if (autocompleteValues?.length) form.trigger(name);
  }, [autocompleteValues]);

  return (
    <Autocomplete
      multiple
      fullWidth
      size="small"
      options={options}
      value={selectedOptions}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      disableCloseOnSelect
      onChange={(_, values, __, option) => {
        analytics.track('Autocomplete Options Changed', {
          autocompleteName: `Growth Policy - ${name}`,
          value: values.map((o) => o.value).join(','),
        });

        // TODO: bring a typescript genius to fix this cast
        form.setValue(name, normalizeValues(values, option) as any);

        if (values.length) form.trigger(name);
      }}
      renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
        <Chip
          {...getTagProps({ index })}
          label={option.label}
          key={option.label}
          deleteIcon={<TiDelete color={theme.palette.primary.contrastText} />}
          sx={{
            backgroundColor: lighten(theme.palette.primary.dark, 0.2),
            color: theme.palette.primary.contrastText,
            fontWeight: theme.typography.fontWeightMedium,
          }}
        />
      ))}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            sx: {
              minWidth: 200,
              borderRadius: '8px',
            },
            ...params.InputProps,
          }}
          placeholder={selectedOptions.length ? undefined : placeholder}
          error={!!form.formState.errors[name]}
          helperText={form.formState.errors[name]?.message}
        />
      )}
    />
  );
};
