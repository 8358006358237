import { useState } from 'react';

import {
  QueryKey,
  useAnalytics, useAuth, useFeatures, useGetPMPreferences,
  useUpdatePmPreferences,
} from 'lib';
import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  BoldTypography, flowChartImageURL, InvertedButton, LazyImage, manIpadImageURL, Spinner, useLabels,
  womanIpadImageURL,
  zapierAlertBackgroundURL,
} from 'ui';
import {
  Alert,
  Box, IconButton, Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { signal } from '@preact/signals-react';
import { useQueryClient } from '@tanstack/react-query';

const CLOSED_REQUEST_ALERT_KEY = 'closeThirdPartyIntegrationRequestAlert';

const isRequestAlertClosed = (): boolean => !!localStorage.getItem(CLOSED_REQUEST_ALERT_KEY);

const closeRequestAlert = (): void => localStorage.setItem(CLOSED_REQUEST_ALERT_KEY, 'true');
const closedState = signal<boolean>(isRequestAlertClosed());

export const useThirdPartyIntegrationState = () => {
  const { isLoading: isLoadingFeatures, ...features } = useFeatures();
  const { isLoading: isLoadingPmPreferences, data: pmPreferences } = useGetPMPreferences();

  const showThirdPartyEnabledAlert = features.isLeadsThirdPartyIntegrationEnabled;
  const showThirdPartyRequestAlert = (
    !features.isLeadsThirdPartyIntegrationEnabled && !pmPreferences?.leadsThirdPartyIntegrationRequested && !closedState.value
  );

  const getHeight = () => {
    if (isLoadingPmPreferences || isLoadingFeatures) return 0;

    if (showThirdPartyEnabledAlert) return 48;

    if (showThirdPartyRequestAlert) return 140;

    return 0;
  };

  const height = getHeight();

  return {
    height,
    showThirdPartyEnabledAlert,
    showThirdPartyRequestAlert,
    closeRequestAlert: () => {
      closeRequestAlert();
      closedState.value = true;
    },
    isLoading: isLoadingPmPreferences || isLoadingFeatures,
  };
};

export const ThirdPartyIntegrationAlert = () => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const [invalidatingQuery, setInvalidatingQuery] = useState(false);
  const { isLoading: isUpdatingPmPreferences, mutateAsync: updatePmPreferences } = useUpdatePmPreferences();
  const { isLoading, ...state } = useThirdPartyIntegrationState();
  const hideWomanIpadBreakpoint = useMediaQuery(theme.breakpoints.down(1432));
  const hideFlowChartBreakpoint = useMediaQuery(theme.breakpoints.down(1272));
  const hideManIpadBreakpoint = useMediaQuery(theme.breakpoints.down(780));

  const isMutating = isUpdatingPmPreferences || invalidatingQuery;

  const handleClickConnectNow = async () => {
    analytics.track('Button Clicked', {
      buttonName: 'Zapier Integration - Connect Now',
    });

    try {
      await updatePmPreferences({
        leadsThirdPartyIntegrationRequested: true,
      });
      setInvalidatingQuery(true);
      await queryClient.invalidateQueries([QueryKey.PM_PREFERENCES, user?.pm]);
      state.closeRequestAlert();
      toast.success(l['leads.thirdPartyIntegrationRequested']);
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
    }

    setInvalidatingQuery(false);
  };

  const handleClickClose = async () => {
    analytics.track('Button Clicked', {
      buttonName: 'Third Party Leads Integration - Close',
    });

    try {
      state.closeRequestAlert();
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
    }
  };

  const spinner = (
    <>
      &nbsp;
      <Spinner size={20} />
    </>
  );

  if (isLoading) return null;

  if (state.showThirdPartyEnabledAlert) {
    return (
      <Alert severity="info">
        {l['leads.leadsThirdPartyIntegrationEnabled']}
      </Alert>
    );
  }

  return state.showThirdPartyRequestAlert && (
    <Stack
      direction="row"
      gap={2}
      sx={{
        borderRadius: '6px',
        backgroundImage: `url(${zapierAlertBackgroundURL})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Stack p={{ xs: 3, md: 6 }} gap={3}>
        <Stack>
          <BoldTypography variant="body1">
            {l['leads.leadsThirdPartyIntegrationEnabled.one']}
          </BoldTypography>
          <BoldTypography variant="body1">
            {l['leads.leadsThirdPartyIntegrationEnabled.two']}
          </BoldTypography>
        </Stack>
        <Box>
          <InvertedButton size="small" onClick={handleClickConnectNow} disabled={isMutating}>
            {isMutating ? spinner : l.connectNow}
          </InvertedButton>
        </Box>
      </Stack>
      <Stack direction="row" gap={2} ml="auto">
        <Stack direction="row">
          {!hideWomanIpadBreakpoint && <LazyImage img={womanIpadImageURL} style={{ width: 160 }} />}
          {!hideFlowChartBreakpoint && <LazyImage img={flowChartImageURL} style={{ width: 220 }} />}
          {!hideManIpadBreakpoint && <LazyImage img={manIpadImageURL} style={{ width: 160 }} />}
        </Stack>
        <Stack p={3}>
          <IconButton onClick={handleClickClose}>
            <MdClose />
          </IconButton>
        </Stack>
      </Stack>
    </Stack>
  );
};
