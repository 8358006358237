import { Navigate, Route, Routes } from 'react-router-dom';

import { useFeatures } from 'lib';

import { Activities } from './views/activities';
import { ListingsPage } from './views/listings';
import { DetailedListing } from './views/listings/detailed-listing';
import { SellProperty } from './views/sell-property';

export const App = () => {
  const { isLoading: isLoadingFeatures, ...features } = useFeatures();

  if (isLoadingFeatures) return null;

  return (
    <Routes>
      {features.isMarketplaceEnabled && (
        <>
          <Route path="/" element={<ListingsPage />} />
          <Route path="/activities" element={<Activities />} />
          <Route path="/:propertyID" element={<DetailedListing />} />
          <Route path="/sell-property" element={<SellProperty />} />
        </>
      )}
      <Route
        path="*"
        element={
          <Navigate to="/" />
        }
      />
    </Routes>
  );
};
