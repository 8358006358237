import { useEffect } from 'react';

import { pmState, useAppSettings } from 'lib';

export const AppSettingsMonitor = () => {
  const { data: appSettings } = useAppSettings();

  useEffect(() => {
    if (appSettings) {
      if (appSettings.title) {
        document.title = appSettings.title;
      }

      if (appSettings.pmName) {
        pmState.value = {
          ...pmState.value,
          name: appSettings.pmName,
        };
      }

      if (appSettings.favicon) {
        const link = document.createElement('link');
        link.rel = 'icon';
        link.href = appSettings.favicon;
        document.head.appendChild(link);

        const oldLinks = document.querySelectorAll('link[rel="icon"]');
        oldLinks.forEach((oldLink) => {
          if (oldLink !== link) {
            oldLink.remove();
          }
        });
      }
    }
  }, [appSettings]);

  return null;
};
