import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import {
  ProspectLeadsWorkflowStatus, ProspectLeadWorkflowItem, useAnalytics,
} from 'lib';
import { toast } from 'react-toastify';
import {
  FallbackSpinner, formatPotentialTodayDate, ListBoard, RowGroup, RowGroupConfig, useLabels,
} from 'ui';
import { signal } from '@preact/signals-react';
import { useQueryClient } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';

import { useListProspectLeads, useMutateProspectLead } from '../../../api/workflows';
import { QueryKey } from '../../../types/enums';
import { Contact } from '../components/Contact';
import { EmptyDash } from '../components/EmptyDash';
import { StatusChanger } from '../components/StatusChanger';
import { sortContacts } from '../utils';
import { useProspectLeadStatuses } from '../WorkflowStatus';

const rowGroupsSignal = signal<RowGroup<ProspectLeadWorkflowItem>[]>([]);

export const ProspectLeadsList = ({ orderedStatuses }: { orderedStatuses: ProspectLeadsWorkflowStatus[] }) => {
  const l = useLabels();
  const { getStatus } = useProspectLeadStatuses();
  const { data, isLoading } = useListProspectLeads();

  useEffect(() => {
    const columns: ColumnDef<ProspectLeadWorkflowItem, string>[] = [
      {
        header: l.status,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => (
          <ProspectLeadStatusChanger item={info.row.original} />
        ),
        meta: { sticky: true },
      },
      {
        header: l.name,
        id: l.name,
        accessorKey: 'lead.email',
        sortingFn: (a, b) => sortContacts(a.original.lead, b.original.lead),
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => <Contact contact={info.row.original.lead} />,
      },
      {
        header: l.phone,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => info.row.original.lead?.phoneNumber || <EmptyDash />,
        meta: { shownAsText: true },
      },
      {
        id: l.updated,
        header: l.updated,
        accessorKey: 'updatedTime',
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => formatPotentialTodayDate(info.row.original.updatedTime),
        meta: { shownAsText: true },
        sortingFn: (a, b) => dayjs(a.original.updatedTime).diff(dayjs(b.original.updatedTime)),
      },
    ];

    const leads = data?.pages.flatMap((page) => page.leads) ?? [];

    const groups: RowGroupConfig<ProspectLeadWorkflowItem>[] = [];

    orderedStatuses.forEach((status) => {
      const statusConfig = getStatus(status);

      groups.push({
        rows: leads?.filter((lead) => lead.status === status) ?? [],
        columnDefs: columns,
        id: status,
        color: statusConfig.color,
        icon: statusConfig.smallIcon,
        title: statusConfig.displayValue,
        tooltip: statusConfig.tooltip,
      });
    });

    rowGroupsSignal.value = groups;
  }, [data]);

  if (isLoading) return <FallbackSpinner />;

  return (
    <ListBoard rowGroups={rowGroupsSignal.value} columnSort={[{ id: l.updated, desc: true }]} />
  );
};

const ProspectLeadStatusChanger = ({ item }: { item: ProspectLeadWorkflowItem }) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const { statuses } = useProspectLeadStatuses();
  const queryClient = useQueryClient();

  const [invalidatingQueries, setInvalidatingQueries] = useState(false);
  const { mutateAsync: updateStatus, isLoading: isUpdatingStatus } = useMutateProspectLead();

  const handleStatusChange = async (status: ProspectLeadsWorkflowStatus) => {
    analytics.track('Selector Changed', {
      selectorName: 'Prospect Lead Status',
      value: status,
    });

    try {
      await updateStatus({
        id: item.id,
        status,
      });
      setInvalidatingQueries(true);
      await queryClient.invalidateQueries([QueryKey.PROSPECT_LEADS]);
      setInvalidatingQueries(false);
    } catch (err) {
      console.error(err);

      toast.error(l['error.unknownError']);
    }
  };

  const isLoading = invalidatingQueries || isUpdatingStatus;

  return (
    <StatusChanger
      id={item.id}
      status={item.status}
      statuses={statuses}
      changeStatus={handleStatusChange}
      isLoading={isLoading}
    />
  );
};
