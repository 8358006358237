import {
  OwnerLeadsWorkflowStatus
  ,
} from 'lib';
import { Stack } from '@mui/material';

import { ThirdPartyIntegrationAlert } from './components/ThirdPartyIntegrationAlert';
import { OwnerLeadsKanban } from './kanban/OwnerLeadsKanban';
import { OwnerLeadsList } from './list/OwnerLeadsList';

const orderedStatuses = [
  OwnerLeadsWorkflowStatus.NEW,
  OwnerLeadsWorkflowStatus.IN_PROGRESS,
  OwnerLeadsWorkflowStatus.WON,
  OwnerLeadsWorkflowStatus.LOST,
];

export const OwnerLeads = ({ isKanbanViewEnabled }: { isKanbanViewEnabled: boolean }) => (
  <Stack flexGrow={1} height="100%" gap={3}>
    <ThirdPartyIntegrationAlert />
    {isKanbanViewEnabled
      ? <OwnerLeadsKanban orderedStatuses={orderedStatuses} />
      : <OwnerLeadsList orderedStatuses={orderedStatuses} />}
  </Stack>
);
