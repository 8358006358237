import { MouseEvent, useState } from 'react';

import {
  APIUser as User, useAnalytics, useImpersonate,
  useInviteUser,
} from 'lib';
import { FaPlus } from 'react-icons/fa6';
import {
  MdOutlineLogin,
  MdPlayCircleOutline,
} from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  InfoTooltip,
  InvertedButton, useLabels, YesNoDialog,
} from 'ui';
import {
  Menu, MenuItem, Typography,
} from '@mui/material';

import { QuestionnaireDialog } from './performance-review/QuestionnaireDialog';

export const InviteUserButton = ({ user, smsText }: { user: User, smsText: string }) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const { mutateAsync: invitingUser, isLoading: isInvitingUser } = useInviteUser();
  const [confirmationEmailOpen, setConfirmationEmailOpen] = useState(false);
  const [confirmationSMSOpen, setConfirmationSMSOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const closeMenu = () => setAnchorEl(null);

  const handleSubmit = async (communicationOption: 'EMAIL' | 'SMS') => {
    analytics.track('Button Clicked', {
      buttonName: 'Invite Owner',
      ownerEmail: user.email,
      communicationOption,
    });

    try {
      await invitingUser({ email: user.email, communicationOption });
      setConfirmationEmailOpen(false);
      setConfirmationSMSOpen(false);
      toast.success(l.dynamic['pm-dashboard.inviteUser.success'](communicationOption));
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
    }
  };

  const handleClickInvite = (e: MouseEvent<HTMLButtonElement>) => {
    analytics.track('Button Clicked', {
      buttonName: 'Invite Owner - Open Menu',
      ownerEmail: user.email,
    });

    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleClickEmail = (e: MouseEvent<HTMLLIElement>) => {
    analytics.track('Button Clicked', {
      buttonName: 'Invite Owner - Email',
      ownerEmail: user.email,
    });

    e.stopPropagation();
    setConfirmationEmailOpen(true);
    closeMenu();
  };

  const handleClickSMS = (e: MouseEvent<HTMLLIElement>) => {
    analytics.track('Button Clicked', {
      buttonName: 'Invite Owner - SMS',
      ownerEmail: user.email,
    });

    e.stopPropagation();
    setConfirmationSMSOpen(true);
    closeMenu();
  };

  if (user.isActivated) {
    return null;
  }

  return (
    <>
      <InvertedButton size="small" onClick={handleClickInvite} startIcon={<FaPlus size={16} />}>
        {l.invite}
      </InvertedButton>
      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={closeMenu}>
        <MenuItem onClick={handleClickEmail}>
          <Typography variant="body2">
            {l.inviteOwnerByEmail}
          </Typography>
        </MenuItem>
        {!!user.phoneNumber && (
          <MenuItem onClick={handleClickSMS}>
            <Typography variant="body2">
              {l.inviteOwnerBySMS}
            </Typography>
          </MenuItem>
        )}
      </Menu>
      <YesNoDialog
        open={confirmationEmailOpen}
        onClose={() => setConfirmationEmailOpen(false)}
        isLoading={isInvitingUser}
        onSubmit={() => handleSubmit('EMAIL')}
        title={l.areYouSure}
        description={l['pm-dashboard.owner.confirmation']}
      />
      <YesNoDialog
        open={confirmationSMSOpen}
        onClose={() => setConfirmationSMSOpen(false)}
        isLoading={isInvitingUser}
        onSubmit={() => handleSubmit('SMS')}
        title={l.dynamic['pm-dashboard.inviteUser.smsConfirm.title'](user.email)}
        // eslint-disable-next-line react/no-danger
        description={<span dangerouslySetInnerHTML={{ __html: smsText }} />}
      />
    </>
  );
};

export const ImpersonateOwnerButton = ({ user }: { user: User }) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const { isLoading, mutateAsync: impersonate } = useImpersonate();

  const handleClick = async () => {
    analytics.track('User Impersonated', {
      userEmail: user.email,
      actionName: l.view,
    });

    try {
      const impersonationToken = await impersonate(user.email);
      window.open(`/?impersonation-token=${impersonationToken.data.token}`, '_blank');
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
    }
  };

  return (
    <InfoTooltip
      arrow
      isLight
      iconProps={{ disableRipple: true }}
      title={(
        <Typography variant="body2" sx={{ p: 1 }}>
          {l.viewOwnerDashboard}
        </Typography>
      )}
      icon={(
        <InvertedButton
          disabled={isLoading}
          size="small"
          startIcon={<MdOutlineLogin />}
          onClick={handleClick}
        >
          {l.view}
        </InvertedButton>
      )}
    />
  );
};

export const PerformanceReviewButton = ({ user }: { user: User }) => {
  const l = useLabels();

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <>
      <InfoTooltip
        arrow
        isLight
        iconProps={{ disableRipple: true }}
        title={(
          <Typography variant="body2" sx={{ p: 1 }}>
            {l['pm-dashboard.contacts.performanceReview.tooltip']}
          </Typography>
        )}
        icon={(
          <InvertedButton size="small" startIcon={<MdPlayCircleOutline />} onClick={handleClick}>
            {l.review}
          </InvertedButton>
        )}
      />
      <QuestionnaireDialog open={open} setOpen={setOpen} user={user} />
    </>
  );
};
