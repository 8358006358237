import {
  BoldTypography, formatNumberToCurrency, InfoTooltip, SemiBoldTypography, useLabels,
} from 'ui';
import {
  Stack, Typography,
} from '@mui/material';

import { DetailRow } from './Row';
import { calculateMonthlyMortgagePayment } from '../calc';
import { useGetPropertyIdParam } from '../state';
import { RowProps } from '../types';
import { useProjections } from '../useProjections';

export const LoanPayments = ({ form }: RowProps) => {
  const l = useLabels();
  const propertyID = useGetPropertyIdParam();
  const projections = useProjections(form);

  const mortgage = calculateMonthlyMortgagePayment(form.watch(), propertyID) * 12;

  return (
    <>
      <DetailRow
        rowID="loan-payments"
        bottomBorder={false}
        columns={[
          {
            id: 'loan-payments',
            content: (
              <Stack direction="row" gap={3}>
                <BoldTypography variant="body1">
                  {l.loanPayments}
                </BoldTypography>
                <InfoTooltip
                  title={(
                    <Typography variant="body2">
                      {l['tooltip.proforma.loanPayments']}
                    </Typography>
                  )}
                  isLight
                  isOutlined
                />
              </Stack>
            ),
          },
          ...projections.years.map(() => ({})),
        ]}
      />
      <DetailRow
        rowID="mortgage"
        bottomBorder={false}
        alignTopOnEdit
        columns={[
          {
            id: 'mortgage',
            content: (
              <SemiBoldTypography variant="body1">
                {l.mortgage}
              </SemiBoldTypography>
            ),
          },
          ...projections.years.map((year) => ({
            id: `mortgage-year-${year}`,
            content: (
              <BoldTypography variant="body1">
                {formatNumberToCurrency(mortgage, 0)}
              </BoldTypography>
            ),
          })),
        ]}
      />
    </>
  );
};
