import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  OwnerRiskStatus,
  useAnalytics,
  useFeatures,
} from 'lib';
import {
  BoldTypography,
  CollapsibleNavMenu, CollapsibleNavMenuItem,
  CraneLogo, Home, InvertedButton, MenuItem, NarpmLogo, NetworkIcon, NewGrowIcon, NewOpportunitiesIcon,
  RetainIcon, upgradeGrowProductBackgroundURL, UpgradeProductDialog, useLabels,
} from 'ui';
import {
  Stack,
} from '@mui/material';

import { useGetOwnerRisksByPM } from '../../api/owner-risk';
import { useGetNewSuggestedPartnerCount } from '../../api/suggested-partners';

const menuIconSize = 18;
const subMenuIconSize = 16;

export const paths = {
  home: '/pm',
  properties: '/pm/properties',
  contacts: '/pm/contacts',
  leads: '/pm/leads',
  retain: '/pm/retain',
  growOpportunities: '/pm/grow/opportunities',
  growNetwork: '/pm/grow/network',
} as const;

const maxSuggestedPartnerCount = 50;

export const MenuItems = ({ onNavigate = () => { } }: { onNavigate?: () => void }) => {
  const l = useLabels();
  const location = useLocation();
  const [upgradeProductDialogOpen, setUpgradeProductDialogOpen] = useState(false);
  const { isLoading: isLoadingFeatures, ...features } = useFeatures();
  const {
    data: suggestedPartnerCount,
    isLoading: isLoadingSuggestedPartnerCount,
  } = useGetNewSuggestedPartnerCount();
  const {
    data: ownerRisksPages,
    isLoading: isLoadingOwnerRisks,
    hasNextPage: hasNextPageOwnerRisks,
  } = useGetOwnerRisksByPM();

  const ownerRiskCount = ownerRisksPages?.pages.flatMap((page) => page.ownerRisks)
    .filter((p) => p.status === OwnerRiskStatus.NEW && !p.isNoRisk).length ?? 0;

  const navigate = useNavigate();
  const handleNavigate = (path: string) => {
    onNavigate();
    navigate(path);
  };

  const growActive = [
    location.pathname.startsWith(paths.growOpportunities),
    location.pathname.startsWith(paths.growNetwork),
  ].includes(true);

  if (isLoadingFeatures) return null;

  return (
    <>
      {features.isPmHomePageEnabled && (
        <MenuItem
          icon={<Home height={menuIconSize} width={menuIconSize} />}
          label={l['menu-blanket-overview']}
          onClick={() => handleNavigate(paths.home)}
          isActive={location.pathname === paths.home}
        />
      )}
      {features.isRetainProductEnabled ? (
        <MenuItem
          icon={<RetainIcon height={menuIconSize} width={menuIconSize} />}
          label={l.retain}
          onClick={() => handleNavigate(paths.retain)}
          isActive={location.pathname === paths.retain}
          chipContent={!isLoadingOwnerRisks && !hasNextPageOwnerRisks && (ownerRiskCount || undefined)}
        />
      ) : features.isRetainNavItemLocked && (
        <MenuItem
          locked
          icon={<RetainIcon height={menuIconSize} width={menuIconSize} />}
          label={l.retain}
          onClick={() => setUpgradeProductDialogOpen(true)}
        />
      )}
      {features.isGrowProductEnabled && (
        <CollapsibleNavMenu
          initialOpen={growActive}
          icon={<NewGrowIcon height={menuIconSize} width={menuIconSize} />}
          label={l.grow}
          isActive={growActive}
        >
          <CollapsibleNavMenuItem
            icon={<NewOpportunitiesIcon height={subMenuIconSize} width={subMenuIconSize} />}
            text={l.opportunities}
            onNavigate={() => handleNavigate(paths.growOpportunities)}
            isActive={location.pathname.startsWith(paths.growOpportunities)}
            chipContent={!isLoadingSuggestedPartnerCount && (
              (suggestedPartnerCount ?? 0) > maxSuggestedPartnerCount
                ? `${maxSuggestedPartnerCount}+`
                : suggestedPartnerCount || undefined
            )}
          />
          <CollapsibleNavMenuItem
            icon={<NetworkIcon height={subMenuIconSize} width={subMenuIconSize} />}
            text={l.network}
            onNavigate={() => handleNavigate(paths.growNetwork)}
            isActive={location.pathname === paths.growNetwork}
          />
        </CollapsibleNavMenu>
      )}
      <UpgradeProductDialog open={upgradeProductDialogOpen} onClose={() => setUpgradeProductDialogOpen(false)} />
    </>
  );
};

export const UpgradeGrowProductButton = () => {
  const l = useLabels();
  const analytics = useAnalytics();
  const [open, setOpen] = useState(false);
  const { isLoading: isLoadingFeatures, ...features } = useFeatures();

  const handleClick = () => {
    analytics.track('Button Clicked', {
      buttonName: 'In App Up Sell Popup',
    });

    setOpen(true);
  };

  if (!features.isFreemiumPM) return null;

  return (
    <>
      <Stack px={4}>
        <Stack
          p={3}
          gap={2}
          onClick={handleClick}
          sx={{
            background: `url(${upgradeGrowProductBackgroundURL}) no-repeat`,
            backgroundSize: 'cover',
            borderRadius: '6px',
            '&:hover': {
              cursor: 'pointer',
            },
          }}
        >
          <BoldTypography variant="body2">
            {l['product.navWidget.title']}
          </BoldTypography>
          <InvertedButton size="small" onClick={handleClick}>{l.getStarted}</InvertedButton>
        </Stack>
      </Stack>
      <UpgradeProductDialog open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export const CraneCommunityMenuItem = ({ onNavigate = () => { } }: { onNavigate?: () => void }) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const features = useFeatures();

  const handleClick = () => {
    analytics.track('Button Clicked', {
      buttonName: 'Crane Community',
    });

    onNavigate?.();
    window.open('https://crane.circle.so', '_blank');
  };

  if (features.isLoading || !features.isCraneCommunityNavItemEnabled) return null;

  return (
    <MenuItem
      label={l.dynamic.craneCommunity(features)}
      icon={features.isCraneAlternativeTextEnabled ? (
        <NarpmLogo width={30} />
      ) : (
        <CraneLogo height={menuIconSize} width={menuIconSize} />
      )}
      onClick={handleClick}
    />
  );
};
