import {
  OwnerReferralsIncentive, useAnalytics,
} from 'lib';
import {
  BoldTypography, InvertedButton, recommendationBackgroundURL,
  useLabels,
} from 'ui';
import { Stack, Typography, useTheme } from '@mui/material';

export const ReferAnOwnerButton = ({ onClick, ownerReferralsIncentive }: { onClick: () => void,
  ownerReferralsIncentive: OwnerReferralsIncentive }) => {
  const analytics = useAnalytics();
  const theme = useTheme();

  const l = useLabels();

  const ownerReferralsIncentiveToText = {
    [OwnerReferralsIncentive.NO_INCENTIVE]: l['referAnOwner.referralButtonText.noIncentive'],
    [OwnerReferralsIncentive.ZERO_MGMT_FEE_ONE_MONTH]: l['referAnOwner.referralButtonText.oneMonth'],
    [OwnerReferralsIncentive.ZERO_MGMT_FEE_TWO_MONTHS]: l['referAnOwner.referralButtonText.twoMonths'],
    [OwnerReferralsIncentive.ZERO_MGMT_FEE_THREE_MONTHS]: l['referAnOwner.referralButtonText.threeMonths'],
  } as const;

  const handleClick = () => {
    onClick();
    analytics.track('Button Clicked', {
      buttonName: 'Refer An Owner',
    });
  };

  return (
    <Stack px={4}>
      <Stack
        p={3}
        gap={2}
        onClick={handleClick}
        sx={{
          background: `url(${recommendationBackgroundURL}) no-repeat`,
          backgroundSize: 'cover',
          borderRadius: '6px',
          '&:hover': {
            cursor: 'pointer',
          },
        }}
      >
        <BoldTypography variant="body1">{l['referAnOwner.referAndEarn']}</BoldTypography>
        <Typography variant="body3">
          {ownerReferralsIncentiveToText[ownerReferralsIncentive]}
          {ownerReferralsIncentive !== OwnerReferralsIncentive.NO_INCENTIVE
              && <span style={{ color: theme.palette.primary.dark }}>{l['referAnOwner.referralButtonText.free']}</span>}
        </Typography>
        <InvertedButton onClick={handleClick} size="small">
          {l['referAnOwner.referAClient']}
        </InvertedButton>
      </Stack>
    </Stack>
  );
};
