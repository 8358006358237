import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';
import {
  OwnerLeadsWorkflowStatus, OwnerLeadWorkflowItem, Partner,
  useAnalytics, useFeatures,
} from 'lib';
import { BsDoorClosed } from 'react-icons/bs';
import { FaHandshake } from 'react-icons/fa';
import { MdPhone } from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  BoldTypography, Chip, ColumnCard, Columns, FallbackSpinner, KanbanBoard, LightTooltip, useLabels,
} from 'ui';
import {
  Box, lighten, Stack, Typography, useTheme,
} from '@mui/material';
import { signal } from '@preact/signals-react';

import { CardDetail, ColCard } from './ColCard';
import { useListOwnerLeads, useMutateOwnerLead } from '../../../api/workflows';
import { LeadCardHeader } from '../components/LeadCardHeader';
import { StatusChanger } from '../components/StatusChanger';
import { useThirdPartyIntegrationState } from '../components/ThirdPartyIntegrationAlert';
import { moveCardToColumn, updateCard } from '../utils';
import { useOwnerLeadStatuses } from '../WorkflowStatus';

const columnsSignal = signal<Columns<OwnerLeadCard>>({});

export const OwnerLeadsKanban = ({ orderedStatuses }: { orderedStatuses: OwnerLeadsWorkflowStatus[] }) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const { getStatus } = useOwnerLeadStatuses();
  const { data, isLoading, isError } = useListOwnerLeads();
  const { mutateAsync } = useMutateOwnerLead();
  const { height: topContentHeight } = useThirdPartyIntegrationState();

  useEffect(() => {
    if (data) {
      const cols: Columns<OwnerLeadCard> = {};

      orderedStatuses.forEach((columnID) => {
        const status = getStatus(columnID);

        cols[columnID] = {
          id: columnID,
          icon: status.icon,
          title: status.displayValue,
          color: status.color,
          tooltip: status.tooltip,
          cards: [],
        };
      });

      data.pages.forEach((page) => {
        page.leads.forEach((lead) => {
          const columnID = lead.status;

          cols[columnID].cards.push({
            ...lead,
            originalColumnID: columnID,
            disabled: false,
            disableStatusChange: true,
          });
        });
      });

      Object.keys(cols).forEach((columnID) => {
        cols[columnID].cards.sort((a, b) => dayjs(b.updatedTime).diff(dayjs(a.updatedTime)));
      });

      columnsSignal.value = cols;
    }

    if (isError) toast.error(l['error.unknownError']);
  }, [data, isLoading, isError, columnsSignal]);

  const handleColumnChange = async (lead: OwnerLeadCard, newStatus: string) => {
    analytics.track('Card Moved', {
      cardName: 'Owner Lead',
      from: lead.status,
      to: newStatus,
      id: lead.id,
    });

    const oldStatus = lead.status;

    columnsSignal.value = updateCard(columnsSignal.value, {
      ...lead,
      disabled: true,
    });

    try {
      await mutateAsync({
        id: lead.id,
        status: newStatus as OwnerLeadsWorkflowStatus,
      });
      columnsSignal.value = updateCard(columnsSignal.value, {
        ...lead,
        disabled: false,
        status: newStatus as OwnerLeadsWorkflowStatus,
        originalColumnID: newStatus as OwnerLeadsWorkflowStatus,
      });
    } catch (err) {
      console.error(err);

      toast.error(l['error.unknownError']);

      columnsSignal.value = moveCardToColumn(columnsSignal.value, {
        ...lead,
        disabled: false,
        status: oldStatus,
        originalColumnID: oldStatus,
      });
    }
  };

  useEffect(() => {
    document.querySelector('html')?.classList.add('full-height');

    return () => {
      document.querySelector('html')?.classList.remove('full-height');
    };
  }, []);

  if (isLoading) {
    return <FallbackSpinner />;
  }

  return (
    <KanbanBoard
      colWidth={250}
      orderedColumnIDs={orderedStatuses}
      CardComponent={OwnerLeadColCard}
      columnsSignal={columnsSignal}
      onColumnChange={handleColumnChange}
      topContentHeight={topContentHeight}
    />
  );
};

type OwnerLeadCard = OwnerLeadWorkflowItem & ColumnCard;

const OwnerLeadColCard = ({
  card,
  isOverlay = false,
}: {
  card: OwnerLeadCard,
  isOverlay?: boolean,
}) => {
  const l = useLabels();
  const analytics = useAnalytics();

  const { statuses } = useOwnerLeadStatuses();
  const { mutateAsync: updateStatus, isLoading: isUpdatingStatus } = useMutateOwnerLead();
  const { isLoading: isLoadingFeatures, ...features } = useFeatures();

  const handleStatusChange = async (status: OwnerLeadsWorkflowStatus) => {
    analytics.track('Selector Changed', {
      selectorName: 'Owner Lead Status',
      value: status,
    });

    try {
      await updateStatus({
        id: card.id,
        status,
      });
      columnsSignal.value = moveCardToColumn(columnsSignal.value, {
        ...card,
        disabled: false,
        status,
        originalColumnID: status,
      });
    } catch (err) {
      console.error(err);

      toast.error(l['error.unknownError']);
    }
  };

  return (
    <ColCard
      revealed
      card={card}
      createdTime={card.createdTime}
      updatedTime={card.updatedTime}
      isOverlay={isOverlay}
      footer={null}
      afterContent={(
        <>
          {card.requestRentalAnalysis && (
            <RentalAnalysisChip />
          )}
          {!isLoadingFeatures && !features.isLeadsThirdPartyIntegrationEnabled && (
            <StatusChanger
              id={card.id}
              status={card.status}
              statuses={statuses}
              changeStatus={handleStatusChange}
              isLoading={isUpdatingStatus}
            />
          )}
        </>
      )}
    >
      {card.lead && (
        <LeadCardHeader firstName={card.lead.firstName} lastName={card.lead.lastName} email={card.lead.email} />
      )}
      {card.lead?.phoneNumber && (
        <CardDetail
          label={(
            <Stack>
              <MdPhone />
            </Stack>
          )}
          content={card.lead?.phoneNumber}
        />
      )}
      <PartnerName partner={card.ownerLead?.partner} />
      {(card.addresses?.length ?? 0) > 0 && (
        <CardDetail
          label={(
            <Stack>
              <BsDoorClosed />
            </Stack>
          )}
          content={`${card.addresses?.length ?? 0} ${card.addresses?.length === 1 ? l.property : l.properties}`}
          tooltipName="Owner Lead Addresses"
          tooltipContent={(
            <Stack gap={2} p={1}>
              <BoldTypography variant="body1">{l.properties}</BoldTypography>
              {card.addresses?.map((address) => address.trim() && (
                <Stack key={address} direction="row" gap={1} alignItems="center">
                  <Typography variant="body2">{address}</Typography>
                </Stack>
              ))}
            </Stack>
          )}
        />
      )}
    </ColCard>
  );
};

const RentalAnalysisChip = () => {
  const l = useLabels();
  const theme = useTheme();

  return (
    <LightTooltip
      arrow
      placement="top"
      title={(
        <Typography variant="body2" sx={{ p: 1 }}>
          {l['pm-dashboard.leads.requestRentalAnalysis.tooltip']}
        </Typography>
      )}
    >
      <Chip
        label={l['pm-dashboard.leads.requestRentalAnalysis']}
        size="small"
        sx={{
          px: 1,
          maxWidth: 'fit-content',
          color: theme.palette.primary.contrastText,
          backgroundColor: lighten(theme.palette.primary.dark, 0.5),
        }}
      />
    </LightTooltip>
  );
};

const PartnerName = ({ partner = undefined, showIcon = true }: { partner?: Partner | null, showIcon?: boolean }) => {
  const navigate = useNavigate();

  const partnerName = partner ? `${partner?.firstName} ${partner?.lastName}` : '';

  const navigateToNetworksPage = (referral: string) => {
    navigate(`/pm/grow/network?partner=${referral}`);
  };

  if (!partner || (!partner.firstName && !partner.lastName)) return null;

  return partnerName && (
    <CardDetail
      label={showIcon ? (
        <Stack
          onClick={() => navigateToNetworksPage(partnerName)}
          sx={{
            cursor: 'pointer',
          }}
        >
          <FaHandshake />
        </Stack>
      ) : ''}
      content={(
        <Box
          component={Typography}
          onClick={() => navigateToNetworksPage(partnerName)}
          sx={{
            cursor: 'pointer',
            textWrap: 'nowrap',
          }}
        >
          {partnerName}
        </Box>
      )}
    />
  );
};
