import { useState } from 'react';

import { useAnalytics } from 'lib';
import { toast } from 'react-toastify';
import {
  useLabels, YesNoDialog,
} from 'ui';
import { useQueryClient } from '@tanstack/react-query';

import { DeleteTransactionModalProps } from './types';
import { useDeleteExpense } from '../../api/properties';
import { QueryKey } from '../../types/enums';

export const DeleteTransactionsModal = ({
  isOpen, toggle, id,
}: DeleteTransactionModalProps) => {
  const l = useLabels();
  const queryClient = useQueryClient();

  const { mutateAsync: deleteTransaction } = useDeleteExpense();
  const [falseLoading, setFalseLoading] = useState(false);

  const analytics = useAnalytics();

  const onSubmit = async () => {
    analytics.track('Form Submitted', {
      formName: 'Delete Transaction',
      transactionId: id,
    });

    setFalseLoading(true);
    try {
      await deleteTransaction(id);
      await queryClient.invalidateQueries([QueryKey.PROPERTIES]);
      toggle();
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
    }
    setFalseLoading(false);
  };

  return (
    <YesNoDialog
      open={isOpen}
      onClose={toggle}
      isLoading={falseLoading}
      onSubmit={onSubmit}
      title={l['delete-modal-title']}
      description=""
      actionID="delete"
    />
  );
};
