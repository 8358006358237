import { useState } from 'react';

import { Box } from '@mui/material';

type Props = {
  img: string;
  style?: React.CSSProperties;
};

export const LazyImage = ({ img, style = undefined }: Props) => {
  const [loaded, setLoaded] = useState(false);

  const handleLoad = () => {
    setLoaded(true);
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${img})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        filter: loaded ? 'none' : 'blur(5px)',
        transition: 'filter 0.5s',
        ...style,
      }}
    >
      <img src={img} alt={img} onLoad={handleLoad} style={{ display: 'none' }} />
    </Box>
  );
};
