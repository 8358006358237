import { useState } from 'react';

import { useAnalytics } from 'lib';
import {
  Banner, UpgradeProductDialog, useLabels,
} from 'ui';

export const InviteOwnersBanner = () => {
  const l = useLabels();
  const analytics = useAnalytics();
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClickInviteOwner = () => {
    analytics.track('Button Clicked', {
      buttonName: 'Freemium Marketplace - Invite Owners',
    });

    setDialogOpen(true);
  };

  return (
    <>
      <Banner
        title={l['freemium.inviteOwnersBanner.title']}
        buttonText={l.inviteOwners}
        onClick={handleClickInviteOwner}
      />
      <UpgradeProductDialog open={dialogOpen} onClose={() => setDialogOpen(false)} />
    </>
  );
};
