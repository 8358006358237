import { MdClose } from 'react-icons/md';
import {
  Dialog, DialogContent, IconButton, Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';

export const HubSpotCalendar = ({ open, onClose }: { open: boolean, onClose: () => void }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const calendarMobileBreakpoint = useMediaQuery(theme.breakpoints.down(647));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={isMobile}
      fullWidth
      maxWidth="md"
      sx={{ zIndex: 1800 }}
      PaperProps={{ sx: { height: calendarMobileBreakpoint ? '100%' : 860 } }}
    >
      <DialogContent sx={{ p: 2, position: 'relative', background: '#f5f8fa' }}>
        <Stack
          direction="row"
          sx={{
            justifyContent: 'flex-end',
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 1000,
          }}
          p={4}
        >
          <IconButton onClick={onClose}>
            <MdClose />
          </IconButton>
        </Stack>
        <iframe
          src="https://meetings.hubspot.com/bennemecek/15-min-with-ben"
          title="Schedule a meeting"
          style={{
            width: '100%', height: 'calc(100% - 20px)', borderStyle: 'none', zIndex: 999,
          }}
        />
      </DialogContent>
    </Dialog>
  );
};
