import {
  CompanyGuarantees, CompanyServices, ManagedPropertyType, useAnalytics,
  useGetCounties,
} from 'lib';
import { Controller, UseFormReturn } from 'react-hook-form';
import {
  BoldTypography, ControlledFormattedNumberField, FallbackSpinner, useLabels,
} from 'ui';
import {
  Collapse, Divider, Stack, TextField, Typography, useTheme,
} from '@mui/material';

import { GrowthPolicyAutocompleteField } from './GrowthPolicyAutocompleteField';
import { PmAvatarField } from './PmAvatarField';
import { StyledCompactLabelField } from './styled';
import { GrowthPolicyData } from '../../api/suggested-partners/forms';

const maxAvgDayOnMarket = 100;
const maxGoogleReviews = 5;
const maxRenewalRate = 100;

export const ReferralLandingPageForm = ({ form }: { form: UseFormReturn<GrowthPolicyData> }) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const countyData = useGetCounties();

  const typeOfPropertiesManagedOptions = [
    { label: l['partners.wizard.question.typeOfPropertiesManaged.singleFamily'], value: ManagedPropertyType.SINGLE_FAMILY },
    { label: l['partners.wizard.question.typeOfPropertiesManaged.townhome'], value: ManagedPropertyType.TOWNHOME },
    {
      label: l['partners.wizard.question.typeOfPropertiesManaged.smallMultiFamily'],
      value: ManagedPropertyType.SMALL_MULTI_FAMILY,
    },
    { label: l['partners.wizard.question.typeOfPropertiesManaged.multiFamily'], value: ManagedPropertyType.MULTI_FAMILY },
    { label: l['partners.wizard.question.typeOfPropertiesManaged.condo'], value: ManagedPropertyType.CONDO },
    { label: l['partners.wizard.question.typeOfPropertiesManaged.section8'], value: ManagedPropertyType.SECTION_8 },
    { label: l['partners.wizard.question.typeOfPropertiesManaged.commercial'], value: ManagedPropertyType.COMMERCIAL },
    { label: l.all, value: ManagedPropertyType.ALL },
  ];

  const workingCounties = countyData.counties.map((county) => ({
    label: `${county.county}, ${county.state}`,
    value: county,
  }));

  if (countyData.isLoading) {
    return <FallbackSpinner />;
  }

  return (
    <Stack gap={6}>
      <Stack gap={4}>
        <BoldTypography variant="body1">
          {l['partners.wizard.companyDetails']}
        </BoldTypography>
        <Stack sx={{ borderRadius: '10px', border: `1px solid ${theme.palette.divider}` }}>
          <StyledCompactLabelField id="website">
            <Typography variant="body1">
              {l['partners.wizard.question.website']}
            </Typography>
            <Controller
              name="website"
              control={form.control}
              render={({ field }) => (
                <TextField
                  size="small"
                  autoComplete="off"
                  label={l['partners.wizard.question.website']}
                  variant="outlined"
                  error={!!form.formState.errors.website}
                  helperText={form.formState.errors.website?.message}
                  {...field}
                  value={field.value}
                  onBlur={() => {
                    analytics.track('Input Changed', {
                      inputName: 'Referral Program - website',
                      value: form.getValues('website') ?? '',
                    });
                  }}
                  onChange={(e, ...args) => {
                    field.onChange(e, ...args);

                    if (e.target.value) form.trigger('website');
                  }}
                />
              )}
            />
          </StyledCompactLabelField>
          <Divider />
          <StyledCompactLabelField id="company-description">
            <Stack gap={2}>
              <Typography variant="body1">
                {l['partners.wizard.question.companyDescription']}
              </Typography>
              <Typography variant="body2" color="secondary">
                {l['partners.wizard.question.companyDescription.description']}
              </Typography>
            </Stack>
            <Controller
              name="companyDescription"
              control={form.control}
              render={({ field }) => (
                <TextField
                  multiline
                  minRows={3}
                  autoComplete="off"
                  label={l['partners.wizard.question.companyDescription']}
                  variant="outlined"
                  error={!!form.formState.errors.companyDescription}
                  helperText={form.formState.errors.companyDescription?.message}
                  {...field}
                  value={field.value}
                  onChange={(e, ...args) => {
                    field.onChange(e, ...args);

                    if (e.target.value) form.trigger('companyDescription');
                  }}
                />
              )}
            />
          </StyledCompactLabelField>
          <Divider />
          <StyledCompactLabelField id="door-count">
            <Typography variant="body1">
              {l['partners.wizard.question.doorCount']}
            </Typography>
            <ControlledFormattedNumberField
              name="doorCount"
              control={form.control}
              fullWidth
              size="small"
              prefix=""
              showZero={false}
              error={!!form.formState.errors.doorCount}
              helperText={form.formState.errors.doorCount?.message}
              placeholder={l.amount}
              onValueChange={(val) => {
                if (val) form.trigger('doorCount');
              }}
              onBlur={() => {
                analytics.track('Input Changed', {
                  inputName: 'Referral Program - Door Count',
                  value: form.getValues('doorCount'),
                });
              }}
            />
          </StyledCompactLabelField>
          <Divider />
          <StyledCompactLabelField id="type-of-properties-managed">
            <Typography variant="body1">
              {l['partners.wizard.question.typeOfPropertiesManaged']}
            </Typography>
            <GrowthPolicyAutocompleteField
              name="typeOfPropertiesManaged"
              form={form}
              options={typeOfPropertiesManagedOptions}
              placeholder={l.pleaseSpecify}
              normalizeValues={(values, details) => {
                if (details?.option.value === ManagedPropertyType.ALL) {
                  return [ManagedPropertyType.ALL];
                }

                return values.map((o) => o.value).filter((v) => v !== ManagedPropertyType.ALL);
              }}
            />
          </StyledCompactLabelField>
          <Divider />
          <StyledCompactLabelField id="working-counties">
            <Typography variant="body1">
              {l['partners.wizard.question.workingCounties']}
            </Typography>
            <GrowthPolicyAutocompleteField
              name="workingCounties"
              form={form}
              options={workingCounties}
              getSelectedOptions={(values) => workingCounties.filter((county) => (
                values.findIndex((value) => value.county === county.value.county && value.state === county.value.state) !== -1
              ))}
              placeholder={l.pleaseSpecify}
            />
          </StyledCompactLabelField>
          <Divider />
          <StyledCompactLabelField id="company-metrics">
            <Typography variant="body1">
              {l['partners.wizard.companyMetrics']}
            </Typography>
            <Stack direction="row" gap={2}>
              <Stack gap={2} justifyContent={{ sm: 'space-between' }}>
                <BoldTypography variant="body2">
                  {l['partners.wizard.question.companyGoogleReviews']}
                </BoldTypography>
                <ControlledFormattedNumberField
                  name="companyGoogleReviews"
                  control={form.control}
                  size="small"
                  prefix=""
                  showZero={false}
                  error={!!form.formState.errors.companyGoogleReviews}
                  helperText={form.formState.errors.companyGoogleReviews?.message}
                  placeholder="4.9"
                  onValueChange={(val) => {
                    if (val) form.trigger('companyGoogleReviews');
                  }}
                  onBlur={() => {
                    analytics.track('Input Changed', {
                      inputName: 'Referral Program - Company Google Reviews',
                      value: form.getValues('companyGoogleReviews'),
                    });

                    const val = form.getValues('companyGoogleReviews');

                    if (val && val > maxGoogleReviews) {
                      form.setValue('companyGoogleReviews', maxGoogleReviews);
                    }
                  }}
                />
              </Stack>
              <Stack gap={2} justifyContent={{ sm: 'space-between' }}>
                <BoldTypography variant="body2">
                  {l['partners.wizard.question.companyAVGDayOnMarket']}
                </BoldTypography>
                <ControlledFormattedNumberField
                  name="companyAVGDayOnMarket"
                  control={form.control}
                  size="small"
                  prefix=""
                  showZero={false}
                  error={!!form.formState.errors.companyAVGDayOnMarket}
                  helperText={form.formState.errors.companyAVGDayOnMarket?.message}
                  placeholder="29"
                  onValueChange={(val) => {
                    if (val) form.trigger('companyAVGDayOnMarket');
                  }}
                  onBlur={() => {
                    analytics.track('Input Changed', {
                      inputName: 'Referral Program - Company AVG Day On Market',
                      value: form.getValues('companyAVGDayOnMarket'),
                    });

                    const val = form.getValues('companyAVGDayOnMarket');

                    if (val && val > maxAvgDayOnMarket) {
                      form.setValue('companyAVGDayOnMarket', maxAvgDayOnMarket);
                    }
                  }}
                />
              </Stack>
              <Stack gap={2} justifyContent={{ sm: 'space-between' }}>
                <BoldTypography variant="body2">
                  {l['partners.wizard.question.companyTenantRenewalRate']}
                </BoldTypography>
                <ControlledFormattedNumberField
                  name="companyTenantRenewalRate"
                  control={form.control}
                  size="small"
                  showZero={false}
                  error={!!form.formState.errors.companyTenantRenewalRate}
                  helperText={form.formState.errors.companyTenantRenewalRate?.message}
                  placeholder="98%"
                  prefix=""
                  suffix="%"
                  onValueChange={(val) => {
                    if (val) form.trigger('companyTenantRenewalRate');
                  }}
                  onBlur={() => {
                    analytics.track('Input Changed', {
                      inputName: 'Referral Program - Company Tenant Renewal Rate',
                      value: form.getValues('companyTenantRenewalRate'),
                    });

                    const val = form.getValues('companyTenantRenewalRate');

                    if (val && val > maxRenewalRate) {
                      form.setValue('companyTenantRenewalRate', maxRenewalRate);
                    }
                  }}
                />
              </Stack>
            </Stack>
          </StyledCompactLabelField>
          <Divider />
          <StyledCompactLabelField id="company-services">
            <Typography variant="body1">
              {l['partners.wizard.question.companyServices']}
            </Typography>
            <Stack gap={3}>
              <GrowthPolicyAutocompleteField
                name="companyServices"
                form={form}
                options={[
                  { label: l['partners.wizard.question.companyServices.marketing'], value: CompanyServices.MARKETING },
                  {
                    label: l['partners.wizard.question.companyServices.tenantScreening'],
                    value: CompanyServices.TENANT_SCREENING,
                  },
                  {
                    label: l['partners.wizard.question.companyServices.rentCollection'],
                    value: CompanyServices.RENT_COLLECTION,
                  },
                  { label: l['partners.wizard.question.companyServices.maintenance'], value: CompanyServices.MAINTENANCE },
                  { label: l['partners.wizard.question.companyServices.leasing'], value: CompanyServices.LEASING },
                  { label: l['partners.wizard.question.companyServices.eviction'], value: CompanyServices.EVICTION },
                  {
                    label: l['partners.wizard.question.companyServices.financialReporting'],
                    value: CompanyServices.FINANCIAL_REPORTING,
                  },
                  { label: l.other, value: CompanyServices.OTHER },
                ]}
                placeholder={l.services}
              />
              <Collapse in={!!form.watch('companyServices')?.includes(CompanyServices.OTHER)}>
                <Controller
                  name="companyServicesOther"
                  control={form.control}
                  render={({ field }) => (
                    <TextField
                      label={l.other}
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      fullWidth
                      error={!!form.formState.errors.companyServicesOther}
                      helperText={form.formState.errors.companyServicesOther?.message}
                      {...field}
                      value={field.value}
                      onChange={(e, ...args) => {
                        field.onChange(e, ...args);

                        if (e.target.value) form.trigger('companyServicesOther');
                      }}
                    />
                  )}
                />
              </Collapse>
            </Stack>
          </StyledCompactLabelField>
          <Divider />
          <StyledCompactLabelField id="company-guarantees">
            <Typography variant="body1">
              {l['partners.wizard.question.companyGuarantees']}
            </Typography>
            <Stack gap={3}>
              <GrowthPolicyAutocompleteField
                name="companyGuarantees"
                form={form}
                options={[
                  {
                    label: l['partners.wizard.question.companyGuarantees.leaseGuarantee'],
                    value: CompanyGuarantees.LEASE_GUARANTEE,
                  },
                  { label: l['partners.wizard.question.companyGuarantees.petGuarantee'], value: CompanyGuarantees.PET_GUARANTEE },
                  {
                    label: l['partners.wizard.question.companyGuarantees.evictionGuarantee'],
                    value: CompanyGuarantees.EVICTION_GUARANTEE,
                  },
                  {
                    label: l['partners.wizard.question.companyGuarantees.onTimeRentGuarantee'],
                    value: CompanyGuarantees.ONTIME_RENT_GUARANTEE,
                  },
                  { label: l.other, value: CompanyGuarantees.OTHER },
                ]}
                placeholder={l.guarantees}
              />
              <Collapse in={!!form.watch('companyGuarantees')?.includes(CompanyGuarantees.OTHER)}>
                <Controller
                  name="companyGuaranteesOther"
                  control={form.control}
                  render={({ field }) => (
                    <TextField
                      label={l.other}
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      fullWidth
                      error={!!form.formState.errors.companyGuaranteesOther}
                      helperText={form.formState.errors.companyGuaranteesOther?.message}
                      {...field}
                      onChange={(e, ...args) => {
                        field.onChange(e, ...args);

                        if (e.target.value) form.trigger('companyGuaranteesOther');
                      }}
                    />
                  )}
                />
              </Collapse>
            </Stack>
          </StyledCompactLabelField>
        </Stack>
      </Stack>
      <Stack gap={4}>
        <BoldTypography variant="body1">
          {l['partners.wizard.contactDetails']}
        </BoldTypography>
        <Stack sx={{ borderRadius: '10px', border: `1px solid ${theme.palette.divider}` }}>
          <StyledCompactLabelField id="pm-names">
            <Typography variant="body1">
              {l['partners.wizard.question.pmName']}
            </Typography>
            <Stack direction="row" gap={2}>
              <Controller
                name="pmFirstName"
                control={form.control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    autoComplete="off"
                    size="small"
                    label={l['partners.wizard.question.pmFirstName']}
                    variant="outlined"
                    error={!!form.formState.errors.pmFirstName}
                    helperText={form.formState.errors.pmFirstName?.message}
                    {...field}
                    value={field.value}
                    onChange={(e, ...args) => {
                      field.onChange(e, ...args);

                      if (e.target.value) form.trigger('pmFirstName');
                    }}
                  />
                )}
              />
              <Controller
                name="pmLastName"
                control={form.control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    size="small"
                    autoComplete="off"
                    label={l['partners.wizard.question.pmLastName']}
                    variant="outlined"
                    error={!!form.formState.errors.pmLastName}
                    helperText={form.formState.errors.pmLastName?.message}
                    {...field}
                    value={field.value}
                    onChange={(e, ...args) => {
                      field.onChange(e, ...args);

                      if (e.target.value) form.trigger('pmLastName');
                    }}
                  />
                )}
              />
            </Stack>
          </StyledCompactLabelField>
          <Divider />
          <StyledCompactLabelField id="pm-role">
            <Typography variant="body1">
              {l['partners.wizard.question.pmTitle']}
            </Typography>
            <Controller
              name="pmTitle"
              control={form.control}
              render={({ field }) => (
                <TextField
                  size="small"
                  autoComplete="off"
                  label={l['partners.wizard.question.pmTitle']}
                  variant="outlined"
                  error={!!form.formState.errors.pmTitle}
                  helperText={form.formState.errors.pmTitle?.message}
                  {...field}
                  value={field.value}
                  onChange={(e, ...args) => {
                    field.onChange(e, ...args);

                    if (e.target.value) form.trigger('pmTitle');
                  }}
                />
              )}
            />
          </StyledCompactLabelField>
          <Divider />
          <StyledCompactLabelField id="years-in-business">
            <Typography variant="body1">
              {l['partners.wizard.question.yearsInBusiness']}
            </Typography>
            <ControlledFormattedNumberField
              name="yearsInBusiness"
              control={form.control}
              fullWidth
              size="small"
              prefix=""
              showZero={false}
              error={!!form.formState.errors.yearsInBusiness}
              helperText={form.formState.errors.yearsInBusiness?.message}
              placeholder={l.years}
              onValueChange={(val) => {
                if (val) form.trigger('yearsInBusiness');
              }}
              onBlur={() => {
                analytics.track('Input Changed', {
                  inputName: 'Referral Program - Years in Business',
                  value: form.getValues('yearsInBusiness'),
                });
              }}
            />
          </StyledCompactLabelField>
          <Divider />
          <PmAvatarField form={form} />
        </Stack>
      </Stack>
    </Stack>
  );
};
