import { Box, styled } from '@mui/material';

export const HeaderLogoContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  height: '100%',
  width: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  '&:hover': {
    cursor: 'pointer',
  },
});
