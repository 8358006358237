export enum QueryKey {
  SELLABLE_PROPERTIES = 'marketplace_sellableProperties',
  PROPERTY_IDS = 'marketplace_propertyIds',
  USER_ACTIONS = 'user_actions',
  USERS = 'users',
  OWNER_LEADS = 'owner_leads',
  OWNER_LEADS_REFERRAL_LINKS = 'owner_leads_referral_links',
  STATES = 'states',
  PM_PREFERENCES = 'pm_preferences',
}
