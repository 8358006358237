import { useLocation, useNavigate } from 'react-router-dom';

import { useFeatures } from 'lib';
import {
  MdEdit,
} from 'react-icons/md';
import {
  CollapsibleNavMenu, CollapsibleNavMenuItem,
  CreditCardIcon,
  DashboardIcon,
  Garage,
  InfoTooltip,
  PropertyIcon, PropertySquareMiddleIcon, Report,
  useLabels,
} from 'ui';
import {
  IconButton,
  useTheme,
} from '@mui/material';

import { useListOwnerProperties } from '../../api/properties';
import { useListOwnerReportsIds } from '../../api/reports';

const menuIconSize = 18;
const subMenuIconSize = 16;

export const ProductMenuItems = ({ onNavigate = () => { } }: { onNavigate?: () => void }) => {
  const { data: ownerReports, isLoading: ownerReportsLoading } = useListOwnerReportsIds();
  const { isLoading: isLoadingFeatures, ...features } = useFeatures();

  const l = useLabels();
  const theme = useTheme();
  const navigate = useNavigate();
  const { data: ownerProperties } = useListOwnerProperties();
  const properties = ownerProperties?.properties ?? [];
  const deletedProperties = ownerProperties?.deletedProperties ?? [];
  const totalPropertyCount = properties.length + deletedProperties.length;
  const { pathname } = useLocation();
  const handleNavigate = (path: string) => {
    onNavigate!();
    navigate(path);
  };

  const hasReport = ownerReports ? ownerReports?.length > 0 : false;

  const categoryActive = [
    pathname === '/',
    pathname?.startsWith('/properties'),
    pathname?.startsWith('/onboarding'),
    pathname?.startsWith('/transactions'),
    pathname?.startsWith('/reports'),
    pathname?.startsWith('/proforma'),
  ].includes(true);

  if (ownerReportsLoading || isLoadingFeatures) {
    return null;
  }

  return features.isOwnerDashboardEnabled && (
    <CollapsibleNavMenu
      initialOpen={categoryActive}
      icon={<DashboardIcon height={menuIconSize} width={menuIconSize} />}
      label={l['menu-dashboard']}
      isActive={categoryActive}
    >
      {totalPropertyCount > 1 ? (
        <>
          <CollapsibleNavMenuItem
            icon={<Garage height={subMenuIconSize} width={subMenuIconSize} />}
            text={l['menu-portfolio']}
            onNavigate={() => handleNavigate('/')}
            isActive={pathname === '/'}
          />
          <CollapsibleNavMenu
            initialOpen={pathname?.startsWith('/properties')}
            icon={<PropertySquareMiddleIcon height={menuIconSize} width={menuIconSize} />}
            label={l['menu-properties']}
            itemPaddingLeft={6}
          >
            {properties.map((property) => (
              <CollapsibleNavMenuItem
                key={property.id}
                text={property.displayName}
                onNavigate={() => handleNavigate(`/properties/${property.id}`)}
                isActive={pathname === `/properties/${property.id}`}
                action={(
                  <IconButton
                    aria-label="edit"
                    size="small"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      navigate(`/onboarding/${property.id}`);
                    }}
                  >
                    <MdEdit color={pathname === `/properties/${property.id}` ? theme.palette.primary.contrastText : undefined} />
                  </IconButton>
                )}
              />
            ))}
            {deletedProperties.map((property) => (
              <CollapsibleNavMenuItem
                key={property.id}
                icon={<PropertyIcon size={subMenuIconSize} />}
                text={property.displayName}
                action={(
                  <InfoTooltip
                    arrow
                    isLight
                    isOutlined
                    isSmall
                    title={l.propertyNoLongerMonitored}
                  />
                )}
                isActive={pathname === `/properties/${property.id}`}
                textSx={{ textDecoration: 'line-through' }}
              />
            ))}
          </CollapsibleNavMenu>
        </>
      ) : (
        <CollapsibleNavMenuItem
          icon={<Garage height={subMenuIconSize} width={subMenuIconSize} />}
          text={l['menu-portfolio']}
          onNavigate={() => handleNavigate('/')}
          isActive={pathname === '/'}
        />
      )}
      <CollapsibleNavMenuItem
        icon={<CreditCardIcon height={subMenuIconSize} width={subMenuIconSize} />}
        text={l['menu-transactions']}
        onNavigate={() => handleNavigate('/transactions')}
        isActive={pathname?.startsWith('/transactions')}
      />
      <CollapsibleNavMenuItem
        icon={<Garage height={subMenuIconSize} width={subMenuIconSize} />}
        text={l['menu-update-properties']}
        onNavigate={() => handleNavigate('/onboarding')}
        isActive={pathname?.startsWith('/onboarding')}
      />
      {hasReport && (
        <CollapsibleNavMenuItem
          icon={<Report height={subMenuIconSize} width={subMenuIconSize} />}
          text={l['menu-reports']}
          onNavigate={() => handleNavigate('/reports')}
          isActive={pathname?.startsWith('/reports')}
        />
      )}
    </CollapsibleNavMenu>
  );
};
