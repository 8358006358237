import { pmState, useAnalytics } from 'lib';
import { useLabels } from 'ui';
import { Stack, useMediaQuery, useTheme } from '@mui/material';

import { PredefinedFilterChip } from './styled';
import { PredefinedFilter } from '../../../api/properties/searchable';
import { SingleFilterProps } from '../props';

export const PredefinedFilters = ({ filters, setFilters }: SingleFilterProps) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleClick = (predefinedFilter: PredefinedFilter) => {
    const f = (
      filters.predefinedFilters?.includes(predefinedFilter)
        ? filters.predefinedFilters.filter((c) => c !== predefinedFilter)
        : [...(filters.predefinedFilters ?? []), predefinedFilter]
    );

    analytics.track('Button Clicked', {
      buttonName: 'Predefined Filter',
      filters: f.join(','),
    });

    setFilters({ predefinedFilters: f });
  };

  return (
    <Stack justifyContent="center" alignItems="center" gap={isMobile ? 2 : 4} p={3} flexGrow={1}>
      <PredefinedFilterChip
        className={filters.predefinedFilters?.includes('1%_rule') ? 'active' : ''}
        onClick={() => handleClick('1%_rule')}
        label={l.onePercentRule}
      />
      <PredefinedFilterChip
        className={filters.predefinedFilters?.includes('managed_by_pm') ? 'active' : ''}
        onClick={() => handleClick('managed_by_pm')}
        label={`${l.managedBy} ${pmState.value?.name ?? l.PM}`}
      />
    </Stack>
  );
};
