import { useState } from 'react';

import { MdCheckCircleOutline, MdContentCopy, MdOutlineMailOutline } from 'react-icons/md';
import { hexToRGBA, LightTooltip, useLabels } from 'ui';
import {
  Button, Stack, Typography, useTheme,
} from '@mui/material';

export const CopyEmail = ({
  email,
  color = 'secondary',
}: {
  email: string,
  color?: 'primary' | 'secondary',
}) => {
  const theme = useTheme();
  const [copied, setCopied] = useState(false);
  const l = useLabels();

  const handleCopyToClipboard = async () => {
    await navigator.clipboard.writeText(email);

    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <LightTooltip
      arrow
      title={(
        <Stack p={1}>
          <Button
            size="small"
            variant="outlined"
            onClick={handleCopyToClipboard}
            startIcon={copied ? <MdCheckCircleOutline /> : <MdContentCopy />}
          >
            {copied ? l.copied : l.copyToClipboard}
          </Button>
        </Stack>
      )}
      placement="top"
    >
      <Stack direction="row" alignItems="center" gap={1} width="fit-content">
        <MdOutlineMailOutline size={20} color={hexToRGBA(theme.palette.primary.main, 0.8)} />
        <Typography variant="body2" color={color}>
          {email}
        </Typography>
      </Stack>
    </LightTooltip>
  );
};
