import {
  axiosWithPayloadContext,
  config,
  createHeaders,
  filterNulls,
  OwnerRiskMitigation,
  OwnerRiskRecommendation,
  OwnerRiskSeverity,
  OwnerRiskStatus,
  queryGraphQL,
  ResolvedReason,
  useAuth,
} from 'lib';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';

import { convertBasicOwnerRisk, convertExpandedOwnerRisk } from './converters';
import { QueryKey } from '../../types/enums';
import { getOwnerRiskQuery, getOwnerRisksByPmQuery } from '../graphql/queries';
import {
  BasicOwnerRisk, ExpandedOwnerRisk, GetOwnerRiskQuery, ListOwnerRisksByPmQuery,
} from '../properties/types';

export const useGetOwnerRisksByPM = () => {
  const { getAccessTokenSilently, user } = useAuth();

  const pm = user?.pm;

  const query = useInfiniteQuery({
    queryKey: [QueryKey.OWNER_RISKS_BY_PM],
    queryFn: async ({ pageParam: nextToken }): Promise<{
      ownerRisks: BasicOwnerRisk[];
      nextToken: string | null | undefined;
    }> => {
      const token = await getAccessTokenSilently();

      const res = await queryGraphQL({
        query: getOwnerRisksByPmQuery,
        variables: { nextToken, pm },
        authToken: token,
      }) as GraphQLResult<ListOwnerRisksByPmQuery>;

      if (!res.data?.listOwnerRiskByPM?.items?.length) {
        return { ownerRisks: [], nextToken: null };
      }

      const ownerRisks = filterNulls(res.data.listOwnerRiskByPM.items).map((ownerRisk) => convertBasicOwnerRisk(ownerRisk));

      return {
        ownerRisks,
        nextToken: res.data.listOwnerRiskByPM.nextToken,
      };
    },
    getNextPageParam: (lastPage) => lastPage.nextToken,
    onSuccess: (data) => {
      if (data.pages[data.pages.length - 1]?.nextToken) {
        query.fetchNextPage();
      }
    },
  });

  return query;
};

export const useGetOwnerRisk = (id: string, enabled?: boolean) => {
  const { getAccessTokenSilently } = useAuth();

  const query = useQuery({
    enabled,
    queryKey: [QueryKey.OWNER_RISK, id],
    queryFn: async (): Promise<ExpandedOwnerRisk | null> => {
      const token = await getAccessTokenSilently();

      const res = await queryGraphQL({
        query: getOwnerRiskQuery,
        variables: { id },
        authToken: token,
      }) as GraphQLResult<GetOwnerRiskQuery>;

      if (!res.data?.getOwnerRisk) {
        return null;
      }

      return convertExpandedOwnerRisk(res.data.getOwnerRisk);
    },
  });

  return query;
};

export const useMutateOwnerRiskRecommendation = () => {
  const { getAccessTokenSilently } = useAuth();

  return useMutation(async ({ ownerRiskID, regenerate }: { ownerRiskID: string, regenerate: boolean }) => {
    const token = await getAccessTokenSilently();

    await axiosWithPayloadContext({
      url: `${config.apiBaseURL}/risk-recommendation`,
      method: 'POST',
      headers: createHeaders(token),
      data: {
        ownerRiskID,
        regenerate,
      },
    });
  });
};

export const useUpdateRecommendationMitigations = () => {
  const { getAccessTokenSilently } = useAuth();

  return useMutation(async ({
    id,
    userSelectedMitigations,
    emailHTML,
  }: {
    id: string,
    userSelectedMitigations: OwnerRiskMitigation[],
    emailHTML: string
  }) => {
    const token = await getAccessTokenSilently();

    await axiosWithPayloadContext({
      url: `${config.apiBaseURL}/risk-recommendation`,
      method: 'PATCH',
      headers: createHeaders(token),
      data: {
        recommendationID: id,
        userSelectedMitigations,
        emailHTML,
      },
    });
  });
};

export const useDeleteRecommendation = () => {
  const { getAccessTokenSilently } = useAuth();

  return useMutation(async ({
    id,
  }: {
    id: string,
  }) => {
    const token = await getAccessTokenSilently();

    await axiosWithPayloadContext({
      url: `${config.apiBaseURL}/risk-recommendation`,
      method: 'DELETE',
      headers: createHeaders(token),
      data: {
        recommendationID: id,
      },
    });
  });
};

export const useUpdateOwnerRisk = () => {
  const { getAccessTokenSilently } = useAuth();

  return useMutation(async ({
    ownerRiskID,
    ...data
  }: {
    ownerRiskID: string,
    severity?: OwnerRiskSeverity,
    status?: OwnerRiskStatus,
    resolvedReason?: ResolvedReason,
    resolutionExplanation?: string,
  }) => {
    const token = await getAccessTokenSilently();

    await axiosWithPayloadContext({
      url: `${config.apiBaseURL}/owner-risk/${ownerRiskID}`,
      method: 'PATCH',
      headers: createHeaders(token),
      data,
    });
  });
};

export const useGenerateEmail = () => {
  const { getAccessTokenSilently } = useAuth();

  return useMutation(async ({
    recommendationID,
    regenerate = false,
  }: {
    recommendationID: string;
    regenerate?: boolean;
  }) => {
    const token = await getAccessTokenSilently();

    const res = await axiosWithPayloadContext<OwnerRiskRecommendation>({
      url: `${config.apiBaseURL}/risk-recommendation/generate-email`,
      method: 'POST',
      headers: createHeaders(token),
      data: {
        recommendationID,
        regenerate,
      },
    });

    return res.data;
  });
};
