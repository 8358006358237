import { useEffect, useRef, useState } from 'react';

import { OwnerRiskStatus, ResolvedReason } from 'lib';
import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  BoldTypography, InvertedButton, Spinner, useLabels,
} from 'ui';
import {
  Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle,
  IconButton,
  MenuItem,
  Select, Stack, TextField, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { useUpdateOwnerRisk } from '../../../../api/owner-risk';
import { QueryKey } from '../../../../types/enums';

export const ResolveDialog = ({
  ownerRiskID = undefined,
  open,
  onClose,
}: {
  ownerRiskID?: string,
  open: boolean,
  onClose: () => void,
}) => {
  const l = useLabels();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const queryClient = useQueryClient();

  const [resolution, setResolution] = useState<ResolvedReason | '' >('');
  const [resolutionOther, setResolutionOther] = useState<string>('');
  const [resolutionExplanationBlurred, setResolutionExplanationBlurred] = useState(false);
  const { mutateAsync: updateOwnerRisk, isLoading: isMutatingOwnerRisk } = useUpdateOwnerRisk();
  const resolutionExplanationRef = useRef<HTMLTextAreaElement>(null);

  const hasValidResolution = resolution && (resolution !== ResolvedReason.OTHER || !!resolutionOther.trim());

  const handleSave = async () => {
    if (!hasValidResolution) return;

    try {
      await updateOwnerRisk({
        ownerRiskID: ownerRiskID!,
        status: OwnerRiskStatus.RESOLVED,
        resolvedReason: resolution,
        resolutionExplanation: resolutionOther,
      });
      queryClient.invalidateQueries([QueryKey.OWNER_RISKS_BY_PM]);
    } catch (e) {
      console.error(e);

      toast.error(l['error.unknownError']);
    }

    onClose();
  };

  const handleClose = (e: any, reason: 'backdropClick' | 'escapeKeyDown' | 'buttonClick') => {
    if (reason === 'backdropClick') return;

    onClose();
  };

  useEffect(() => {
    if (!open) {
      setResolution('');
      setResolutionOther('');
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} fullScreen={isMobile} fullWidth maxWidth="sm">
      <DialogTitle sx={{ borderBottom: `1px solid ${theme.palette.divider}`, py: 2 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <BoldTypography variant="h6">
            {l['retention.resolve']}
          </BoldTypography>
          <IconButton onClick={() => handleClose(null, 'buttonClick')}>
            <MdClose />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ pb: 3 }}>
        <Stack mt={3} gap={4}>
          <Typography variant="subtitle1">
            {l['retention.resolve.question']}
          </Typography>
          <Select
            value={resolution || ''}
            onChange={(e) => {
              setResolution(e.target.value as ResolvedReason);
              if (e.target.value === ResolvedReason.OTHER) {
                // this is shitty but necessary for the focus to work since the Collapse only mounts the input after 300ms
                setTimeout(() => resolutionExplanationRef.current?.focus(), 310);
              }
            }}
            displayEmpty
            defaultValue=""
          >
            <MenuItem value="" disabled>
              {l['retention.resolve.reason.selectReason']}
            </MenuItem>
            <MenuItem value={ResolvedReason.OWNER_WANTS_TO_STAY}>
              {l['retention.resolve.reason.ownerWantsToStay']}
            </MenuItem>
            <MenuItem value={ResolvedReason.OWNER_WANTS_TO_SELL}>
              {l['retention.resolve.reason.ownerWantsToSell']}
            </MenuItem>
            <MenuItem value={ResolvedReason.OWNER_WANTS_TO_LEAVE}>
              {l['retention.resolve.reason.ownerWantsToLeave']}
            </MenuItem>
            <MenuItem value={ResolvedReason.OTHER}>
              {l['retention.resolve.reason.other']}
            </MenuItem>
          </Select>
          <Collapse in={resolution === ResolvedReason.OTHER}>
            <TextField
              autoComplete="off"
              inputProps={{
                ref: resolutionExplanationRef,
              }}
              onBlur={() => setResolutionExplanationBlurred(true)}
              value={resolutionOther}
              onChange={(e) => {
                setResolutionOther(e.target.value);
              }}
              label={l['retention.resolve.addTheReason']}
              error={!resolutionOther.trim() && resolutionExplanationBlurred}
              helperText={!resolutionOther.trim() && resolutionExplanationBlurred && l.errorFieldIsRequired}
              fullWidth
            />
          </Collapse>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ borderTop: `1px solid ${theme.palette.divider}`, py: 3 }}>
        <Stack direction="row" justifyContent="space-between" width="100%" pt={3}>
          <Button variant="text" onClick={onClose}>
            {l.cancel}
          </Button>
          <InvertedButton
            disabled={!hasValidResolution || isMutatingOwnerRisk}
            onClick={handleSave}
          >
            {isMutatingOwnerRisk ? <Spinner size={20} /> : l['retention.resolve']}
          </InvertedButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
