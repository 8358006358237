/* eslint-disable max-len */
import { Features, OwnerReferralsIncentive } from 'lib';

import { CrimeLevel } from './types';

export const getReferAndEarnSubtitle = (pmName: string, incentive: OwnerReferralsIncentive) => {
  const ownerReferralIncentiveToNumberOfMonths = {
    [OwnerReferralsIncentive.NO_INCENTIVE]: 0,
    [OwnerReferralsIncentive.ZERO_MGMT_FEE_ONE_MONTH]: 1,
    [OwnerReferralsIncentive.ZERO_MGMT_FEE_TWO_MONTHS]: 2,
    [OwnerReferralsIncentive.ZERO_MGMT_FEE_THREE_MONTHS]: 3,
  };

  const baseText = `Introduce friends to ${pmName}`;
  if (incentive === OwnerReferralsIncentive.NO_INCENTIVE) {
    return baseText;
  }

  const monthOrMonths = incentive === OwnerReferralsIncentive.ZERO_MGMT_FEE_ONE_MONTH ? 'month' : 'months';

  return `${baseText} and earn ${ownerReferralIncentiveToNumberOfMonths[incentive].toString()
  } ${monthOrMonths} of management fee`;
};

export const getCraneName = (features: Omit<Features, 'isLoading'>) => {
  if (features.isCraneAlternativeTextEnabled) return 'NARPM';

  return 'Crane';
};

export const getCrimeTextCounty = (level: CrimeLevel): string => {
  switch (level) {
    case CrimeLevel.LOW:
      return 'This block is in the low-crime range and is considered one of the safest in the area.';
    case CrimeLevel.MEDIUM:
      return 'This area falls within the average-crime range locally.';
    case CrimeLevel.HIGH:
      return 'This block is in the high-crime range and is considered one of the riskiest locally.';
    default:
      return '';
  }
};

export const getCrimeTextNational = (level: CrimeLevel): string => {
  switch (level) {
    case CrimeLevel.LOW:
      return 'This block is in the low-crime range and is considered one of the safest nationwide.';
    case CrimeLevel.MEDIUM:
      return 'This area falls within the average-crime range in the country.';
    case CrimeLevel.HIGH:
      return 'This block is in the high-crime range and is considered one of the riskiest nationwide.';
    default:
      return '';
  }
};
