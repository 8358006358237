import { Activity, SuggestedPartner } from 'lib';
import { FireIcon, GreyFireIcon } from 'ui';
import { Stack } from '@mui/material';

export const ActivityLevel = ({ activity }: { activity: SuggestedPartner['activity'] }) => (
  <Stack direction="row" alignItems="center" gap={1}>
    {activity ? (
      <>
        <FireIcon />
        {[Activity.MEDIUM, Activity.HIGH].includes(activity) ? <FireIcon /> : <GreyFireIcon />}
        {activity === Activity.HIGH ? <FireIcon /> : <GreyFireIcon />}
      </>
    ) : (
      <>
        <GreyFireIcon />
        <GreyFireIcon />
        <GreyFireIcon />
      </>
    )}
  </Stack>
);
