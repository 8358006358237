import {
  lazy, Suspense, useCallback, useEffect, useState,
} from 'react';
import {
  BrowserRouter, Route, Routes,
  useNavigate,
} from 'react-router-dom';

import { Amplify } from 'aws-amplify';
import { ConfigCatProvider, createConsoleLogger } from 'configcat-react';
import { App as Dashboard } from 'dashboard';
import {
  AnalyticsConsumer, AnalyticsProvider, config,
  useAppSettings,
  useAuth,
  useFeatures,
  useListStates,
  useLoadAndCacheFeatures,
  useLoadCounties,
  useTrackHistory,
} from 'lib';
import { App as Marketplace } from 'marketplace';
import { App as PMDashboard, RedirectsFromOldPMDashboard } from 'pm-dashboard';
import { ToastContainer } from 'react-toastify';
import { App as ReferralPartners } from 'referral-partners';
import {
  ConfettiProvider,
  FallbackSpinner,
  SettingsConsumer, SettingsProvider, ThemeComponent, UserLayout,
} from 'ui';
import { Theme, useMediaQuery } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { AppSettingsMonitor } from './components/app-settings/AppSettingsMonitor';
import { AuthProvider } from './components/auth/AuthProvider';
import { MobileLayout } from './components/layouts/mobile';
import { NavigationMenu } from './components/layouts/navigation/NavigationMenu';
import { ScrollToTop } from './components/scroll-to-top/ScrollToTop';
import { usePostLogin } from './hooks/usePostLogin';
import { analytics } from './lib/3rd-parties/analytics';
import { redirectToRelativeHomepage } from './lib/redirect';
import { OwnerBenefits } from './views/owner-benefits';
import { Settings } from './views/settings';

import 'react-toastify/dist/ReactToastify.css';

// eslint-disable-next-line import/extensions
const ReactQueryDevtoolsProduction = lazy(() => import('@tanstack/react-query-devtools/build/lib/index.prod.js').then((d) => ({
  default: d.ReactQueryDevtools,
})));

const queryClient = new QueryClient();

Amplify.configure({
  aws_appsync_graphqlEndpoint: config.gqlEndpoint,
  aws_appsync_region: config.awsRegion,
  aws_appsync_authenticationType: config.appSyncAuthType,
  aws_appsync_apiKey: config.appSyncApiKey,
});

export const AppRoutes = () => {
  const navigate = useNavigate();
  const { getIdTokenClaims, user } = useAuth();
  const showMobileNav = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const { data: appSettings, isLoading: loadingAppSettings } = useAppSettings();
  const { isLoading: isLoadingFeatures, ...features } = useFeatures();

  usePostLogin();
  useTrackHistory();
  useLoadCounties();
  useListStates();

  const onClickLogo = useCallback(() => {
    getIdTokenClaims().then((claims) => {
      redirectToRelativeHomepage(navigate, claims, user?.pm ?? '', features);
    });
  }, [features, getIdTokenClaims, user?.pm]);

  if (isLoadingFeatures || loadingAppSettings) {
    return <FallbackSpinner />;
  }

  return (
    <Routes>
      <Route
        element={showMobileNav ? (
          <MobileLayout />
        ) : (
          <UserLayout
            headerLogoProps={{ logo: appSettings?.logo, logoLoading: loadingAppSettings, onClickLogo }}
            navigationMenu={<NavigationMenu />}
          />
        )}
      >
        {(features.isPmAppEnabled || features.isPmLeadsViewEnabled) && (
          <>
            <Route path="pm/*" element={<PMDashboard />} />
            <Route path="/admin/*" element={<RedirectsFromOldPMDashboard />} />
          </>
        )}
        {features.isMarketplaceEnabled && <Route path="/marketplace/*" element={<Marketplace />} />}
        {features.isReferralPartnersAppEnabled && <Route path="/referral-partners/*" element={<ReferralPartners />} />}
        {features.isObpEnabled && <Route path="/owner-benefits" element={<OwnerBenefits />} />}
        {features.isSettingsViewEnabled && <Route path="/settings" element={<Settings />} />}
        {features.isOwnerDashboardEnabled && <Route path="/*" element={<Dashboard />} />}
        <Route
          path="*"
          element={<NavigateToHomepage />}
        />
      </Route>
    </Routes>
  );
};

const NavigateToHomepage = () => {
  const navigate = useNavigate();
  const { getIdTokenClaims, user } = useAuth();
  const { isLoading: isLoadingFeatures, ...features } = useFeatures();

  useEffect(() => {
    if (isLoadingFeatures || !user) return;

    getIdTokenClaims().then((claims) => {
      redirectToRelativeHomepage(navigate, claims, user?.pm ?? '', features);
    });
  }, [user, isLoadingFeatures, getIdTokenClaims, navigate, features]);

  return null;
};

const AppWithFeatures = () => {
  useLoadAndCacheFeatures();

  return <AppRoutes />;
};

export const App = () => {
  const logger = createConsoleLogger(config.configCatLogLevel);
  const [showDevtools, setShowDevtools] = useState<boolean>(false);

  useEffect(() => {
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  return (
    <BrowserRouter>
      <SettingsProvider>
        <SettingsConsumer>
          {({ settings }) => (
            <AnalyticsProvider analyticsObj={analytics}>
              <AnalyticsConsumer>
                {() => (
                  <QueryClientProvider client={queryClient}>
                    <ThemeComponent settings={settings}>
                      <AuthProvider>
                        <ConfigCatProvider sdkKey={config.configCatApiKey} options={{ logger }}>
                          <ConfettiProvider>
                            <ToastContainer position="top-center" pauseOnHover />
                            <ScrollToTop />
                            <AppSettingsMonitor />
                            <AppWithFeatures />
                          </ConfettiProvider>
                          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
                          {showDevtools && (
                            <Suspense fallback={null}>
                              <ReactQueryDevtoolsProduction />
                            </Suspense>
                          )}
                        </ConfigCatProvider>
                      </AuthProvider>
                    </ThemeComponent>
                  </QueryClientProvider>
                )}
              </AnalyticsConsumer>
            </AnalyticsProvider>
          )}
        </SettingsConsumer>
      </SettingsProvider>
    </BrowserRouter>
  );
};
