/* eslint-disable max-len */
export const DoubleCheckIcon = ({ size = 24, color = undefined }: { size?: number, color?: string }) => {
  const gClipPath = `clip-path-${Math.random()}`;

  const ratio = 21 / 20;

  return (
    <svg width={size * ratio} height={size} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath={`url(#${gClipPath})`}>
        <path
          d="M15.2599 5.8332L14.0849 4.6582L8.80156 9.94154L9.97656 11.1165L15.2599 5.8332ZM18.7932 4.6582L9.97656 13.4749L6.49323 9.99987L5.31823 11.1749L9.97656 15.8332L19.9766 5.8332L18.7932 4.6582ZM0.601562 11.1749L5.2599 15.8332L6.4349 14.6582L1.7849 9.99987L0.601562 11.1749Z"
          fill={color ?? 'currentColor'}
        />
      </g>
      <defs>
        <clipPath id={gClipPath}>
          <rect width="20" height="20" fill="white" transform="translate(0.257812)" />
        </clipPath>
      </defs>
    </svg>
  );
};
