import { useSearchParams } from 'react-router-dom';

import { GrowthPolicy, useFeatures } from 'lib';
import {
  FallbackSpinner, FullScreenBlur, PartnersIcon, useEnableScroll, useLabels,
} from 'ui';
import { Alert, Button, useTheme } from '@mui/material';
import { signal } from '@preact/signals-react';

import { Header, SortOption } from './Header';
import { SuggestedPartners } from './SuggestedPartners';
import { SuggestedPartner } from '../../api/suggested-partners/types';
import { ConfirmPMPartner } from '../../components/add-partner/ConfirmPMPartner';
import { growthPolicyDialogState } from '../../templates/growth-policy/state';

const searchSignal = signal('');
const selectedStatesSignal = signal<string[]>([]);
const sortOptionSignal = signal<SortOption>(SortOption.A_TO_Z);

export const PMsHeader = ({ suggestedPartners }: { suggestedPartners: SuggestedPartner[] }) => {
  const { isLoading, ...features } = useFeatures();

  return (
    <Header
      showFilterButton
      enabled={!isLoading && features.isGrowthPmPartnersEnabled}
      suggestedPartners={suggestedPartners}
      search={searchSignal.value}
      setSearch={(search) => { searchSignal.value = search; }}
      selectedStates={selectedStatesSignal.value}
      setSelectedStates={(selectedStates) => { selectedStatesSignal.value = selectedStates; }}
      sortOption={sortOptionSignal.value}
      setSortOption={(sortOption) => { sortOptionSignal.value = sortOption; }}
      sortOptions={[
        SortOption.YEARS_OF_EXPERIENCE,
        SortOption.A_TO_Z,
        SortOption.Z_TO_A,
        SortOption.DOOR_COUNT,
      ]}
    />
  );
};

export const PropertyManagers = ({
  suggestedPartners,
  growthPolicy,
  blur = false,
}: {
  suggestedPartners: SuggestedPartner[],
  growthPolicy: GrowthPolicy | null,
  blur?: boolean,
}) => {
  const l = useLabels();
  const { isLoading, ...features } = useFeatures();
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  useEnableScroll(!blur);

  if (isLoading) return <FallbackSpinner />;

  const removeSuggestedPartnerId = () => {
    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.delete('suggested_partner_id');
    setSearchParams(updatedParams);
  };

  const suggestedPartnerId = searchParams.get('suggested_partner_id');

  return (
    <>
      <FullScreenBlur
        shown={blur}
      >
        <Alert
          variant="filled"
          severity="error"
          icon={<PartnersIcon height={18} width={18} />}
          sx={{ color: theme.palette.primary.contrastText, alignItems: 'center' }}
          action={(
            <Button
              variant="contained"
              size="small"
              color="error"
              onClick={() => growthPolicyDialogState.value.openDialog()}
            >
              {l.setup}
            </Button>
          )}
        >
          {l['partners.setupGrowthPolicy']}
        </Alert>
      </FullScreenBlur>
      <ConfirmPMPartner
        suggestedPartners={suggestedPartners}
        suggestedPartnerId={suggestedPartnerId ?? ''}
        onClose={() => { removeSuggestedPartnerId(); }}
      />
      {' '}
      <SuggestedPartners
        disabledTitle={l['partners.pms.disabled.title']}
        disabledSubtitle={l['partners.pms.disabled.subtitle']}
        suggestedPartners={suggestedPartners}
        growthPolicy={growthPolicy}
        selectedStates={selectedStatesSignal.value}
        initialSearch={searchSignal.value}
        sortOption={sortOptionSignal.value}
        enabled={features.isGrowthPmPartnersEnabled}
      />
    </>

  );
};
