import { calculateMortgage, objectEntries, sum } from 'lib';

import { PORTFOLIO } from './state';
import { Loan, PropertyProformaFormData } from './types';

export const calculateAnnualGrowth = (year1: number, year2: number): number => (year1 ? ((year2 - year1) / year1) * 100 : 0);

export const calculateMonthlyMortgagePayment = (data: PropertyProformaFormData, propertyID: string) => {
  if (propertyID !== PORTFOLIO) {
    if (!data[propertyID] || !data[propertyID].loan) return 0;

    const loan = data[propertyID].loan!;

    return calculateMortgage({
      loanAmount: loan.amount,
      interestRate: loan.interestRate,
      loanTerm: loan.term === 'other' ? loan.termOther : loan.term,
    });
  }

  const calculatedMortgage = sum(objectEntries(data).map(([key, formData]) => (
    key !== 'portfolio' && formData.loan ? calculateMortgage({
      loanAmount: formData.loan.amount,
      interestRate: formData.loan.interestRate,
      loanTerm: formData.loan.term === 'other' ? formData.loan.termOther : formData.loan.term,
    }) : 0
  )));

  return calculatedMortgage;
};

export const calculateLoanBalance = (loan: Loan, year: number): number => {
  if (!loan?.amount || !loan?.interestRate || !loan?.term) {
    return 0;
  }

  const loanTerm = loan.term === 'other' ? loan.termOther : loan.term;
  const monthlyRate = loan.interestRate / 12 / 100;
  const loanTermInMonths = loanTerm * 12;
  const totalPayments = Math.min(year * 12, loanTermInMonths);

  const monthlyPayment = calculateMortgage({
    loanAmount: loan.amount,
    interestRate: loan.interestRate,
    loanTerm,
  });

  let remainingBalance = loan.amount;

  Array.from({ length: totalPayments }).forEach(() => {
    const interestForMonth = remainingBalance * monthlyRate;
    const principalForMonth = monthlyPayment - interestForMonth;
    remainingBalance -= principalForMonth;
  });

  return Math.max(remainingBalance, 0);
};

export const calculateProjections = ({
  value, years, multiplier,
}: {
  value: number,
  years: number[],
  multiplier: number,
}) => {
  const amounts: number[] = [];

  years.forEach((year, i) => {
    if (year === 1) {
      amounts.push(value);
      return;
    }

    const prevYear = amounts[i - 1];
    const prevYearNumber = years[i - 1];
    const isConsecutiveYear = prevYearNumber && prevYearNumber === year - 1;

    if (isConsecutiveYear) {
      amounts.push(prevYear * multiplier + prevYear);
    } else {
      let amount = prevYear * multiplier + prevYear;
      let nextYear = prevYearNumber + 1;

      while (nextYear < year) {
        amount += amount * multiplier;

        nextYear += 1;
      }

      amounts.push(amount);
    }
  });

  return amounts;
};
