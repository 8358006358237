import { ApexOptions } from 'apexcharts';
import Apexcharts from 'react-apexcharts';

import { formatNumberToCurrency } from '../lib/formatFuncs';

export const AreaChart = ({
  series,
  xLabels,
  height = undefined,
  width = '100%',
  max = undefined,
  formatXLabel = undefined,
}: {
  series: ApexOptions['series'],
  xLabels: number[],
  height?: number | string,
  width?: number | string,
  max?: number,
  formatXLabel?: (label: string | number | undefined, opts?: { inTooltip?: boolean }) => string,
}) => {
  const options: ApexOptions = {
    chart: {
      type: 'area',
      toolbar: { show: false },
      zoom: { enabled: false },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: 'straight',
    },
    legend: {
      fontSize: '14px',
    },
    xaxis: {
      type: 'numeric',
      categories: xLabels,
      tickPlacement: 'on',
      labels: {
        rotate: 0,
        formatter: formatXLabel ? (v) => formatXLabel(v) : undefined,
      },
      tooltip: { enabled: false },
    },
    yaxis: {
      max,
      min: 0,
      forceNiceScale: true,
      labels: {
        formatter: (val) => formatNumberToCurrency(val, 2, { notation: 'compact', compactDisplay: 'short' }),
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      x: {
        formatter: (val, opts) => (
          formatXLabel ? formatXLabel(xLabels[opts.dataPointIndex].toString(), { inTooltip: true })
            : (xLabels[opts.dataPointIndex] || val).toString()
        ),
      },
      y: {
        formatter: (val) => formatNumberToCurrency(val, 0),
      },
    },
  };

  return <Apexcharts type="area" options={options} series={series} height={height} width={width} />;
};
