import { useEffect, useState } from 'react';

import {
  BadOwnerPreferredMitigation, GoodOwnerPreferredMitigation, MaxFinancialIncentive,
  RetentionPolicy as APIRetentionPolicy, useAnalytics, useHideChat,
  Willingness, WritingStyle,
} from 'lib';
import {
  Controller, useForm, UseFormReturn,
} from 'react-hook-form';
import { MdClose } from 'react-icons/md';
import {
  BoldTypography, capitalize, InfoTooltip, InvertedButton, LightButton, RetentionPolicyIcon, useLabels,
} from 'ui';
import {
  Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent,
  DialogTitle, Divider, IconButton, MenuItem, Select, Skeleton, Stack, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { RetentionPolicyData, useGetRetentionPolicy, useMutateRetentionPolicy } from '../../../api/retention-policy';
import { QueryKey } from '../../../types/enums';
import { retentionPolicyDialogState } from '../state';

const createRetentionPolicyData = (retentionPolicy: APIRetentionPolicy | null): RetentionPolicyData => ({
  maxFinancialIncentive: retentionPolicy?.maxFinancialIncentive ?? MaxFinancialIncentive.ZERO_MGMT_FEE_ONE_MONTH,
  giveIncentiveWillingness: retentionPolicy?.giveIncentiveWillingness ?? Willingness.WILLINGNESS_LEVEL_3,
  meetWillingness: retentionPolicy?.meetWillingness ?? Willingness.WILLINGNESS_LEVEL_3,
  badOwnerPreferredMitigation: retentionPolicy?.badOwnerPreferredMitigation ?? BadOwnerPreferredMitigation.NO_MITIGATION,
  goodOwnerPreferredMitigation: (
    retentionPolicy?.goodOwnerPreferredMitigation ?? GoodOwnerPreferredMitigation.RECOMMEND_TO_SELL_PROPERTY
  ),
  writingStyles: retentionPolicy?.writingStyles ?? [WritingStyle.PROFESSIONAL, WritingStyle.ANALYTICAL],
});

const Question = <FieldName extends keyof RetentionPolicyData>({
  fieldName,
  form,
  question,
  options,
  multiple = false,
}: {
  fieldName: FieldName,
  form: UseFormReturn<RetentionPolicyData>,
  question: string,
  options: {
    label: string,
    value: (
      FieldName extends 'writingStyles'
        ? RetentionPolicyData['writingStyles'][number]
        : RetentionPolicyData[FieldName]
    ),
    capitalize?: boolean
  }[],
  multiple?: boolean,
}) => {
  const analytics = useAnalytics();

  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      justifyContent={{ xs: 'flex-start', md: 'space-between' }}
      alignItems={{ xs: 'flex-start', md: 'center' }}
      width="100%"
      p={4}
      gap={{ xs: 2, md: 0 }}
      flexWrap="wrap"
    >
      <Typography variant="body1" sx={{ width: { xs: undefined, md: '50%' } }}>{question}</Typography>
      <Controller
        name={fieldName}
        control={form.control}
        render={({ field: { onChange, value, ...field } }) => (
          <Select
            size="small"
            data-hj-allow
            multiple={multiple}
            {...field}
            sx={{ width: 400 }}
            onChange={(e, ...args) => {
              onChange?.(e, ...args);

              analytics.track('Selector Changed', {
                selectorName: fieldName,
                value: Array.isArray(e.target.value) ? e.target.value.join(',') : e.target.value,
              });
            }}
            value={value as string}
          >
            {options.map((option) => (
              <MenuItem
                value={option.value}
                key={`${option.value}`}
                sx={{ textTransform: option.capitalize ? 'capitalize' : undefined }}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </Stack>
  );
};

const RetentionPolicyForm = ({
  form,
}: {
  form: UseFormReturn<RetentionPolicyData>,
}) => {
  const l = useLabels();

  const maxFinancialIncentiveOptions = [
    {
      label: l['retention.setupRetentionPolicy.maximumIncentiveQuestion.oneMonth'],
      value: MaxFinancialIncentive.ZERO_MGMT_FEE_ONE_MONTH,
    },
    {
      label: l['retention.setupRetentionPolicy.maximumIncentiveQuestion.twoMonths'],
      value: MaxFinancialIncentive.ZERO_MGMT_FEE_TWO_MONTHS,
    },
    {
      label: l['retention.setupRetentionPolicy.maximumIncentiveQuestion.threeMonths'],
      value: MaxFinancialIncentive.ZERO_MGMT_FEE_THREE_MONTHS,
    },
    {
      label: l['retention.setupRetentionPolicy.maximumIncentiveQuestion.noIncentive'],
      value: MaxFinancialIncentive.NO_INCENTIVE,
    },
  ];

  const willingnessOptions = [
    {
      label: l['retention.setupRetentionPolicy.willingnessOptions.anyHomeowner'],
      value: Willingness.WILLINGNESS_LEVEL_5,
    },
    {
      label: l['retention.setupRetentionPolicy.willingnessOptions.onlyHomeownersWithHighPriority'],
      value: Willingness.WILLINGNESS_LEVEL_4,
    },
    {
      label: l['retention.setupRetentionPolicy.willingnessOptions.onlyHomeownersWithMediumPriorityAndAbove'],
      value: Willingness.WILLINGNESS_LEVEL_3,
    },
    {
      label: l['retention.setupRetentionPolicy.willingnessOptions.onlyHomeownersWithLowPriorityAndAbove'],
      value: Willingness.WILLINGNESS_LEVEL_2,
    },
    {
      label: l['retention.setupRetentionPolicy.willingnessOptions.none'],
      value: Willingness.WILLINGNESS_LEVEL_1,
    },
  ];

  const badOwnerPreferredMitigationOptions = [
    {
      label: l['retention.setupRetentionPolicy.badOwnerMitigationQuestion.fireOwner'],
      value: BadOwnerPreferredMitigation.FIRE_OWNER,
    },
    {
      label: l['retention.setupRetentionPolicy.badOwnerMitigationQuestion.refer'],
      value: BadOwnerPreferredMitigation.REFER_OWNER,
    },
    {
      label: l['retention.setupRetentionPolicy.badOwnerMitigationQuestion.sell'],
      value: BadOwnerPreferredMitigation.RECOMMEND_TO_SELL_PROPERTY,
    },
    {
      label: l['retention.setupRetentionPolicy.badOwnerMitigationQuestion.increaseMgmtFee'],
      value: BadOwnerPreferredMitigation.INCREASE_MGMT_FEE,
    },
    {
      label: l['retention.setupRetentionPolicy.badOwnerMitigationQuestion.noMitigation'],
      value: BadOwnerPreferredMitigation.NO_MITIGATION,
    },
  ];

  const goodOwnerPreferredMitigationOptions = [
    {
      label: l['retention.setupRetentionPolicy.goodOwnerMitigationQuestion.recommendToSell'],
      value: GoodOwnerPreferredMitigation.RECOMMEND_TO_SELL_PROPERTY,
    },
    {
      label: l['retention.setupRetentionPolicy.goodOwnerMitigationQuestion.1031'],
      value: GoodOwnerPreferredMitigation.RECOMMEND_1031,
    },
    {
      label: l['retention.setupRetentionPolicy.goodOwnerMitigationQuestion.fix'],
      value: GoodOwnerPreferredMitigation.FIX_PROPERTY_ISSUES,
    },
    {
      label: l['retention.setupRetentionPolicy.goodOwnerMitigationQuestion.offerIncentives'],
      value: GoodOwnerPreferredMitigation.OFFER_INCENTIVES,
    },
    {
      label: l['retention.setupRetentionPolicy.goodOwnerMitigationQuestion.noMitigation'],
      value: GoodOwnerPreferredMitigation.NO_MITIGATION,
    },
  ];

  const writingStylesOptions = [
    { label: capitalize(WritingStyle.PROFESSIONAL), value: WritingStyle.PROFESSIONAL, titleCase: true },
    { label: capitalize(WritingStyle.EDUCATIONAL), value: WritingStyle.EDUCATIONAL, titleCase: true },
    { label: capitalize(WritingStyle.INFORMAL), value: WritingStyle.INFORMAL, titleCase: true },
    { label: capitalize(WritingStyle.HUMOROUS), value: WritingStyle.HUMOROUS, titleCase: true },
    { label: capitalize(WritingStyle.ANALYTICAL), value: WritingStyle.ANALYTICAL, titleCase: true },
    { label: capitalize(WritingStyle.NARRATIVE), value: WritingStyle.NARRATIVE, titleCase: true },
    { label: capitalize(WritingStyle.FRIENDLY), value: WritingStyle.FRIENDLY, titleCase: true },
    { label: capitalize(WritingStyle.TECHNICAL), value: WritingStyle.TECHNICAL, titleCase: true },
  ];

  return (
    <>
      <Question
        fieldName="maxFinancialIncentive"
        form={form}
        question={l['retention.setupRetentionPolicy.maximumIncentiveQuestion']}
        options={maxFinancialIncentiveOptions}
      />
      <Divider sx={{ my: 0 }} />
      <Question
        fieldName="giveIncentiveWillingness"
        form={form}
        question={l['retention.setupRetentionPolicy.financialIncentiveQuestion']}
        options={willingnessOptions}
      />
      <Divider sx={{ my: 0 }} />
      <Question
        fieldName="meetWillingness"
        form={form}
        question={l['retention.setupRetentionPolicy.meetWillingnessQuestion']}
        options={willingnessOptions}
      />
      <Divider sx={{ my: 0 }} />
      <Question
        fieldName="badOwnerPreferredMitigation"
        form={form}
        question={l['retention.setupRetentionPolicy.badOwnerMitigationQuestion']}
        options={badOwnerPreferredMitigationOptions}
      />
      <Divider sx={{ my: 0 }} />
      <Question
        fieldName="goodOwnerPreferredMitigation"
        form={form}
        question={l['retention.setupRetentionPolicy.goodOwnerMitigationQuestion']}
        options={goodOwnerPreferredMitigationOptions}
      />
      <Divider sx={{ my: 0 }} />
      <Question
        fieldName="writingStyles"
        form={form}
        question={l['retention.setupRetentionPolicy.writingStylesQuestion']}
        options={writingStylesOptions}
        multiple
      />
    </>
  );
};

const RetentionPolicyDialog = ({
  open,
  onClose,
  retentionPolicy = null,
}: {
  open: boolean,
  onClose: () => void,
  retentionPolicy?: APIRetentionPolicy | null,
}) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const queryClient = useQueryClient();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { mutateAsync: saveRetentionPolicy, isLoading: isSavingRetentionPolicy } = useMutateRetentionPolicy();

  useHideChat(open && isMobile);

  const form = useForm<RetentionPolicyData>({
    defaultValues: createRetentionPolicyData(retentionPolicy),
  });

  const handleClickSave = async () => {
    analytics.track('Form Submitted', {
      formName: 'Retention Policy',
      previousRetentionPolicy: retentionPolicy?.id,
    });

    await saveRetentionPolicy({
      retentionPolicy: form.getValues(),
      currentID: retentionPolicy?.id,
    });
    await queryClient.invalidateQueries([QueryKey.RETENTION_POLICY]);

    onClose();
  };

  const handleClickReset = () => {
    analytics.track('Button Clicked', {
      buttonName: 'Reset Retention Policy Form',
    });

    form.reset(createRetentionPolicyData(null));
  };

  const handleClose = (e: any, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick') return;

    onClose();
  };

  useEffect(() => {
    if (!open) {
      // to avoid the text flashing at the same time as the popup is closing
      setTimeout(() => form.reset(createRetentionPolicyData(retentionPolicy)), 150);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={isMobile}
      fullWidth
      maxWidth="lg"
      disableEscapeKeyDown
      disableScrollLock
    >
      <DialogTitle sx={{ borderBottom: `1px solid ${theme.palette.divider}`, py: 2 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <BoldTypography variant="h6">
            {l['retention.policy']}
          </BoldTypography>
          <IconButton onClick={onClose}>
            <MdClose />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ pb: 3 }}>
        <Stack mt={3} borderRadius="10px" border={`1px solid ${theme.palette.divider}`}>
          <RetentionPolicyForm form={form} />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ borderTop: `1px solid ${theme.palette.divider}`, py: 3 }}>
        <Stack direction="row" justifyContent="space-between" width="100%" pt={3}>
          <Button
            variant="text"
            onClick={() => handleClickReset()}
          >
            {l['retention.setupRetentionPolicy.resetToDefault']}
          </Button>
          <InvertedButton
            disabled={isSavingRetentionPolicy}
            onClick={handleClickSave}
          >
            {l.save}
          </InvertedButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export const RetentionPolicy = () => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();

  const [open, setOpen] = useState(false);
  const { data: retentionPolicy, isLoading, isError } = useGetRetentionPolicy();

  const buttonText = retentionPolicy ? l['retention.edit'] : l['retention.setup'];

  const handleClickSetup = () => {
    analytics.track('Button Clicked', {
      buttonName: `${buttonText} - Retention Policy Popup`,
    });

    setOpen(true);
  };

  useEffect(() => {
    if (!retentionPolicy && !isLoading && !isError) {
      setOpen(true);
    }
  }, [retentionPolicy, isLoading, isError]);

  useEffect(() => {
    retentionPolicyDialogState.value = {
      open,
      openDialog: () => setOpen(true),
    };
  }, [open, setOpen]);

  return (
    <>
      <Card sx={{ height: '100%', background: theme.palette.primary.main }}>
        <CardContent sx={{ height: '100%' }}>
          <Stack height="100%" gap={3} pt={1}>
            <RetentionPolicyIcon color={theme.palette.primary.contrastText} />
            <Stack direction="row" justifyContent="space-between">
              <BoldTypography variant="h6" color="primary.contrastText">
                {l['retention.policy']}
              </BoldTypography>
              <InfoTooltip
                color={theme.palette.grey[400]}
                title={(
                  <Typography variant="body2" sx={{ p: 1 }}>
                    {l['retention.tooltip.setupRetention']}
                  </Typography>
                )}
                isOutlined
                arrow
                isLight
                track={(value) => {
                  analytics.track('Tooltip Toggled', {
                    value,
                    tooltipName: 'Retention Policy Tooltip',
                  });
                }}
              />
            </Stack>
            <Box mt="auto">
              <LightButton disabled={isError || isLoading} onClick={() => handleClickSetup()}>
                {isLoading && <Skeleton variant="text" width={80} />}
                {!isLoading && buttonText}
              </LightButton>
            </Box>
          </Stack>
        </CardContent>
      </Card>
      {!isLoading && open
          && <RetentionPolicyDialog open={open} onClose={() => setOpen(false)} retentionPolicy={retentionPolicy} />}
    </>
  );
};
