import { MouseEvent, useState } from 'react';

import {
  getOwnerFromID,
  OwnerRiskStatus, useAnalytics,
  useImpersonate,
} from 'lib';
import { IoMdCheckmark } from 'react-icons/io';
import {
  MdKeyboardArrowDown,
} from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  InvertedButton,
  RobotHead, Spinner, useLabels,
} from 'ui';
import {
  ButtonGroup,
  darken,
  Menu, MenuItem,
  Stack, useTheme,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { useDeleteRecommendation, useUpdateOwnerRisk } from '../../../../api/owner-risk';
import { BasicOwnerRisk } from '../../../../api/properties/types';
import { QueryKey } from '../../../../types/enums';

export const OwnerActions = ({
  ownerRisk,
  onResolveClick,
  onActClick,
  forceActButtonLoading,
}: {
  ownerRisk: BasicOwnerRisk,
  onResolveClick: (ownerRisk: BasicOwnerRisk) => void,
  onActClick: (ownerRisk: BasicOwnerRisk) => void,
  forceActButtonLoading: boolean,
}) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isInvalidatingQueries, setIsInvalidatingQueries] = useState(false);
  const open = Boolean(anchorEl);
  const { mutateAsync: impersonateOwner, isLoading: isLoadingImpersonation } = useImpersonate();
  const { mutateAsync: updateOwnerRisk, isLoading: isUpdatingOwnerRisk } = useUpdateOwnerRisk();
  const { mutateAsync: deleteRecommendation, isLoading: isDeletingRecommendation } = useDeleteRecommendation();

  const loadingMoveToNew = isInvalidatingQueries || isUpdatingOwnerRisk || isDeletingRecommendation;

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickResolve = (e: MouseEvent) => {
    e.stopPropagation();

    analytics.track('Button Clicked', {
      buttonName: 'Resolve Owner',
      ownerID: ownerRisk.ownerID,
      status: ownerRisk.status,
    });

    onResolveClick(ownerRisk);
    handleCloseMenu();
  };

  const handleClickAct = async (e: MouseEvent) => {
    e.stopPropagation();

    analytics.track('Button Clicked', {
      buttonName: 'Act on Owner',
      ownerID: ownerRisk.ownerID,
      status: ownerRisk.status,
      generatingRiskRecommendation: !ownerRisk.recommendation,
    });

    onActClick(ownerRisk);
    handleCloseMenu();
  };

  const handleClickOpenMenu = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    analytics.track('Button Clicked', {
      buttonName: 'Owner Row - Open Menu',
      ownerID: ownerRisk.ownerID,
      status: ownerRisk.status,
    });

    setAnchorEl(e.currentTarget);
  };

  const handleClickImpersonate = async (e: MouseEvent, actionName: string) => {
    e.stopPropagation();
    const owner = getOwnerFromID(ownerRisk!.ownerID);

    analytics.track('User Impersonated', {
      actionName,
      userEmail: owner.email,
      status: ownerRisk.status,
    });

    const res = await impersonateOwner(getOwnerFromID(ownerRisk!.ownerID).email);
    window.open(`/?impersonation-token=${res.data.token}`, '_blank');
  };

  const handleClickMoveToNew = async (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    analytics.track('Button Clicked', {
      buttonName: 'Owner Risk Move to In Progress',
      ownerID: ownerRisk.ownerID,
      status: ownerRisk.status,
    });

    setIsInvalidatingQueries(true);

    try {
      if (ownerRisk.recommendation?.id) {
        await deleteRecommendation({ id: ownerRisk.recommendation.id });
      }

      await updateOwnerRisk({
        ownerRiskID: ownerRisk.id,
        status: OwnerRiskStatus.NEW,
      });

      await Promise.all([
        queryClient.invalidateQueries([QueryKey.OWNER_RISK, ownerRisk.id]),
        queryClient.invalidateQueries([QueryKey.OWNER_RISKS_BY_PM]),
      ]);

      handleCloseMenu();
      toast.success(l['retention.ownerRisk.movedToNeedsAttention']);
    } catch (err) {
      console.error(err);

      toast.error(l['error.unknownError']);
    }

    setIsInvalidatingQueries(false);
  };

  const spinner = (
    <>
      &nbsp;
      <Spinner size={20} />
    </>
  );

  const actButton = (
    <InvertedButton
      variant="contained"
      size="small"
      startIcon={forceActButtonLoading ? null : <RobotHead />}
      onClick={handleClickAct}
      disabled={forceActButtonLoading}
    >
      {forceActButtonLoading ? spinner : null}
      {!forceActButtonLoading && (
        ownerRisk.recommendation ? l['retention.view'] : l['retention.action']
      )}
    </InvertedButton>
  );

  if (ownerRisk.isNoRisk) {
    return (
      <Stack direction="row" alignItems="center" gap={2} ml="auto">
        <InvertedButton
          onClick={(e) => handleClickImpersonate(e, l['retention.viewOwner'])}
          variant="contained"
          size="small"
        >
          {l['retention.viewOwner']}
        </InvertedButton>
      </Stack>
    );
  }

  return (
    <>
      <Stack direction="row" alignItems="center" gap={2} ml="auto">
        {[OwnerRiskStatus.NEW, OwnerRiskStatus.IN_PROGRESS].includes(ownerRisk.status) ? (
          <ButtonGroup>
            {actButton}
            {ownerRisk.status === OwnerRiskStatus.IN_PROGRESS && (
              <InvertedButton
                variant="contained"
                size="small"
                startIcon={<IoMdCheckmark />}
                onClick={handleClickResolve}
                sx={{
                  borderLeft: `1px solid ${darken(theme.palette.primary.dark, 0.1)} !important`,
                }}
              >
                {l['retention.resolve']}
              </InvertedButton>
            )}
            <InvertedButton
              onClick={(e) => handleClickOpenMenu(e)}
              variant="contained"
              size="small"
              sx={{
                p: '0 !important',
                borderLeft: `1px solid ${darken(theme.palette.primary.dark, 0.1)} !important`,
              }}
            >
              <MdKeyboardArrowDown size={24} />
            </InvertedButton>
          </ButtonGroup>
        ) : actButton}
      </Stack>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
      >
        {ownerRisk.status !== OwnerRiskStatus.IN_PROGRESS && (
          <MenuItem onClick={handleClickResolve}>
            {l['retention.resolve']}
          </MenuItem>
        )}
        {ownerRisk.status === OwnerRiskStatus.IN_PROGRESS && (
          <MenuItem onClick={handleClickMoveToNew} disabled={loadingMoveToNew}>
            <Stack position="relative" width="100%" minWidth={100}>
              {loadingMoveToNew ? spinner : l['retention.moveToAttentionNeeded']}
            </Stack>
          </MenuItem>
        )}
        {[OwnerRiskStatus.NEW, OwnerRiskStatus.IN_PROGRESS].includes(ownerRisk.status) && (
          <MenuItem onClick={(e) => handleClickImpersonate(e, l.viewOwnerDashboard)} disabled={isLoadingImpersonation}>
            <Stack position="relative" width="100%">
              {isLoadingImpersonation ? spinner : l.viewOwnerDashboard}
            </Stack>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
