import { ApexOptions } from 'apexcharts';
import Apexcharts from 'react-apexcharts';

import { formatNumberToCurrency } from '../lib/formatFuncs';

export const BarChart = ({
  series,
  labels,
  height = undefined,
  width = '100%',
  formatXLabel = undefined,
  max = undefined,
  stroke = undefined,
}: {
  series: ApexAxisChartSeries,
  labels: string[],
  height?: number | string,
  width?: number | string,
  max?: number,
  stroke?: ApexStroke,
  formatXLabel?: (label: string | undefined) => string,
}) => {
  const options: ApexOptions = {
    chart: {
      zoom: { enabled: false },
      toolbar: { show: false },
      type: 'bar',
      stacked: true,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      fontSize: '14px',
    },
    xaxis: {
      categories: labels,
      offsetX: -2,
      labels: {
        rotate: 0,
        formatter: formatXLabel,
      },
    },
    stroke,
    yaxis: {
      max,
      min: 0,
      tickAmount: 10,
      forceNiceScale: true,
      labels: {
        formatter: (val) => formatNumberToCurrency(val, 2, { notation: 'compact', compactDisplay: 'short' }),
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      x: {
        formatter: (val, opts) => labels[opts.dataPointIndex] || val.toString(),
      },
      y: {
        formatter: (val) => formatNumberToCurrency(val, 0),
      },
    },
    markers: {
      size: 3,
      colors: series?.map((s) => s.color ?? '#fff') ?? [],
      strokeWidth: 1,
    },
  };

  return <Apexcharts type="bar" options={options} series={series} height={height} width={width} />;
};
