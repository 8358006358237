import { useEffect, useState } from 'react';

import {
  PartnerType, useHideChat,
} from 'lib';
import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  BoldTypography, InvertedButton, Spinner, useConfetti, useLabels,
} from 'ui';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton,
  Stack, Typography, useMediaQuery,
  useTheme,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import {
  useCreatePartner,
} from '../../api/partners';
import { SuggestedPartner } from '../../api/suggested-partners/types';
import { QueryKey } from '../../types/enums';

export const ConfirmPMPartner = ({
  onClose,
  suggestedPartners,
  suggestedPartnerId,
}: {
  onClose: () => void,
  suggestedPartners: SuggestedPartner[],
  suggestedPartnerId: string,
}) => {
  const l = useLabels();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const queryClient = useQueryClient();
  const confetti = useConfetti();
  const [open, setOpen] = useState(false);

  const { mutateAsync: createPartner, isLoading: isCreatingPartner } = useCreatePartner();
  const [invalidatingQueries, setInvalidatingQueries] = useState(false);

  const isLoading = isCreatingPartner || invalidatingQueries;

  const suggestedPartner = suggestedPartners.find((p) => p.id === suggestedPartnerId);

  useHideChat(isMobile && open);

  useEffect(() => {
    setOpen(!!suggestedPartner);
  }, [suggestedPartner]);

  const onCloseDialog = () => {
    onClose();
  };

  const addPartner = async () => {
    try {
      await createPartner({
        email: suggestedPartner?.email ?? '',
        firstName: suggestedPartner?.firstName ?? '',
        lastName: suggestedPartner?.lastName ?? '',
        partnerType: PartnerType.PM,
        referralAmount: 0,
        suggestedPartnerId,
      });
      setInvalidatingQueries(true);
      await queryClient.invalidateQueries([QueryKey.PARTNERS]);
      setInvalidatingQueries(false);
      toast.success(l['pm-dashboard.network.messageSuccessInviteNewPartner']);
      confetti.trigger();
      onCloseDialog();
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
      onCloseDialog();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onCloseDialog}
      fullScreen={isMobile}
      fullWidth
      maxWidth="xs"
      disableEscapeKeyDown
    >
      <DialogTitle sx={{ borderBottom: `1px solid ${theme.palette.divider}`, py: 2 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <BoldTypography variant="h6">
            Confirm Connection with
            {` ${suggestedPartner?.officeName}`}
          </BoldTypography>
          <IconButton onClick={onCloseDialog}>
            <MdClose />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ p: `${theme.spacing(4)} !important` }}>
        <Typography>
          Are you sure you want to add
          {` ${suggestedPartner?.firstName} ${suggestedPartner?.lastName}`}
          to your network? This will allow you to exchange leads and referrals directly, expanding opportunities for growth.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ borderTop: `1px solid ${theme.palette.divider}`, py: 3 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%" pt={3} gap={2}>
          <Button
            size="small"
            variant="text"
            onClick={onCloseDialog}
          >
            {l.cancel}
          </Button>
          <InvertedButton
            size="small"
            onClick={() => addPartner()}
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                &nbsp;
                <Spinner size={20} />
              </>
            ) : l.confirm}
          </InvertedButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
