import {
  ReactNode, useCallback, useEffect, useState,
} from 'react';

import { ApexOptions } from 'apexcharts';
import dayjs from 'dayjs';
import { useAnalytics } from 'lib';
import Apexcharts from 'react-apexcharts';
import { BoldTypography, InfoTooltip } from 'ui';
import {
  Card, CardContent, Stack, Typography,
} from '@mui/material';

const TrendChart = ({
  series, unixLabels, addWidth = 24,
}: {
  series: ApexOptions['series'],
  unixLabels: number[],
  addWidth?: number,
}) => {
  const [chartEl, setChartEl] = useState<HTMLDivElement | null>(null);
  const [parentWidth, setParentWidth] = useState(0);
  const chartRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setChartEl(node);
      setParentWidth(node.clientWidth);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (chartEl) {
        setParentWidth(chartEl.clientWidth);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [chartEl]);

  return (
    <Stack ref={chartRef}>
      <Apexcharts
        options={{
          chart: {
            toolbar: { show: false },
            offsetX: -16,
            offsetY: -16,
            redrawOnParentResize: true,
            redrawOnWindowResize: true,
            zoom: {
              enabled: false,
            },
          },
          grid: {
            strokeDashArray: 15,
            yaxis: {
              lines: { show: false },
            },
            xaxis: {
              lines: {
                show: true,
              },
            },
            padding: {
              left: 8,
            },
          },
          xaxis: {
            type: 'numeric',
            tickPlacement: 'on',
            overwriteCategories: unixLabels,
            categories: unixLabels,
            tooltip: { enabled: false },
            axisTicks: { show: false },
            labels: {
              formatter: (v) => dayjs.unix(v as any).format('MMM'),
            },
          },
        }}
        series={series}
        type="line"
        width={parentWidth + addWidth}
        height={170}
      />
    </Stack>
  );
};

export const TrendChartCard = ({
  title, subtitle = undefined, series, unixLabels, tooltip = undefined,
}: {
  title: string,
  subtitle?: ReactNode,
  series: ApexOptions['series'],
  unixLabels: number[],
  tooltip?: string,
}) => {
  const analytics = useAnalytics();

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Stack gap={3}>
          <Stack gap={1}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" gap={3}>
              <BoldTypography variant="h6">
                {title}
              </BoldTypography>
              {tooltip && (
                <InfoTooltip
                  title={(
                    <Typography variant="body2" sx={{ p: 1 }}>
                      {tooltip}
                    </Typography>
                  )}
                  arrow
                  isLight
                  isOutlined
                  track={(value) => {
                    analytics.track('Tooltip Toggled', {
                      value,
                      tooltipName: title,
                    });
                  }}
                />
              )}
            </Stack>
            {subtitle}
          </Stack>
          <TrendChart series={series} unixLabels={unixLabels} />
        </Stack>
      </CardContent>
    </Card>
  );
};
