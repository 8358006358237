import {
  useMediaQuery,
  useTheme,
} from '@mui/material';

export const PrivacyPolicy = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  /* eslint-disable */
  return (
    <div style={{ width: isMobile ? '90%' : '600px', margin: 'auto', paddingTop: '10px', paddingBottom: '100px' }}>
      <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Privacy Policy</div>

      <p>Welcome to Portico Homes, a mobile and web-based sales enablement platform for property managers and property owners that enables property owners to track and manage their property portfolios in an investor dashboard powered by artificial intelligence (the &ldquo;Platform&rdquo;). This Privacy Policy (the &ldquo;Policy&rdquo;) explains our privacy practices for the services we offer through the Platform. It describes how we collect personal information, use it and share it and the rights and options available to you with respect to your information.&nbsp;</p>

      <p>This policy is integrated into our Terms of Service, <a href='/terms'>Terms of Service</a> (the &quot;Terms of Service&quot;) and constitutes an integral part of the Terms of Service.&nbsp;</p>
      <p>&nbsp;</p>
      <b>INFORMATION WE COLLECT</b>

      <p><b>Registration Information:</b> If you register to the Platform, we will collect:</p>
      <ul>
        <li>
          <p>Your username and password on the Platform</p>
        </li>
        <li>
          <p>Your contact information, including your name, phone number and email address.&nbsp;</p>
        </li>
        <li>
          <p>Any information you provide in the course of registration about your real estate properties and related financial information.&nbsp;</p>
        </li>
      </ul>

      <p><b>User Content:</b> We will collect any information you add to or generate on the Platform, such as information relating to your real estate properties and related financial information.&nbsp;</p>

      <p><b>Analytics Data:</b>  We may use third party analytics tools to collect information about your use of the Platform and create statistics from such information. For example, we may record the frequency and scope of your use of the Platform, the duration of your sessions, and the interactions you make with the Platform&rsquo;s interface.&nbsp;</p>

      <p><b>Device Information:</b>  We may collect information about your personal computer or mobile device that you use to access the Platform, including its model, operating system, unique device identifiers, mobile network information and the Internet Protocol (IP) address through which you accessed the Platform.</p>
      <p>&nbsp;</p>
      <p><b>Information we receive from third parties: </b> In addition to the information you provide us directly, we receive:&nbsp;</p>
      <ul>
        <li>
          <p>Information about you and real estate properties you own from the property manager in connection with you are using the Platform (the &ldquo;Property Manager&rdquo;), such as your name, email address, the location of your properties and other features of such properties.&nbsp;</p>
        </li>
        <li>
          <p>Information about you and real estate properties you own from publicly available sources and records, such as property tax information, mortgage information and property listing status. &nbsp; .&nbsp;</p>
        </li>
        <li>
          <p>Information you provide us through third-party products which you choose to integrate or interoperate with the Platform. &nbsp;</p>
        </li>
      </ul>

      <b>CHILDREN&apos;S PRIVACY</b>

      <p>The Platform is prohibited for children under the age of 18. Furthermore, we do not knowingly or intentionally collect information from children who are under 18 years of age. As stated in the Terms of Service, you must be at least 18 years old to use the Platform</p>
      <b>USE OF COLLECTED INFORMATION</b>

      <p>We may use the information we collect for the following purposes:</p>
      <ul>
        <li>
          <p>Operating the Platform and providing its features and functionalities;</p>
        </li>
        <li>
          <p>Providing the Platform and related services to the Property Manager;&nbsp;</p>
        </li>
        <li>
          <p>Billing the Property Manager for the fees that apply to your use of the Platform, if any;</p>
        </li>
        <li>
          <p>Improving and customizing the Platform and developing new services;</p>
        </li>
        <li>
          <p>Collecting statistical and aggregated information about users&rsquo; experience within the Platform;</p>
        </li>
        <li>
          <p>Providing you with support, handling complaints and contacting you when we believe it is necessary;</p>
        </li>
        <li>
          <p>Enforcing the Terms of Service and this Policy and preventing misuse of the Platform;</p>
        </li>
        <li>
          <p>Complying with any applicable law and assisting law enforcement agencies and competent authorities, if we believe it is necessary or justified; and&nbsp;</p>
        </li>
        <li>
          <p>Taking any action in any case of dispute involving you or the Property Manager, with respect or in relation to the Platform.</p>
        </li>
      </ul>

      <b>COMMUNICATIONS</b>

      <p>The email address you provide as part of the registration process may be used to send you information and updates pertaining to the Platform, in addition to occasional news updates, related product or Platform information, etc. If at any time you would like to unsubscribe from receiving certain types of emails, an unsubscribe option shall be provided at the bottom of each email.</p>

      <p>Please note that use of the Platform is subject to having a valid email address, which will be used for password recovery and for sending updates about changes to this Privacy Policy. Unsubscribing from our email lists if present will not block these two types of messages which will continue to be available until your account is cancelled.</p>
      <b>SHARING INFORMATION COLLECTED</b>

      <p>We may share the information outlined in this Policy, with others, in any of the following instances:&nbsp;</p>
      <ul>
        <li>
          <p>With the Property Manager in connection with its use of the Platform;</p>
        </li>
        <li>
          <p>With our contractors and service providers, for data analysis and processing, bug reporting and handling, and in order to operate, maintain and enhance the Platform;</p>
        </li>
        <li>
          <p>If you have breached the Terms of Service, abused your rights to use the Platform, or violated any applicable law. Your personally identifiable information may be shared with competent authorities and with any third party, if we believe it is necessary or justified;</p>
        </li>
        <li>
          <p>If the operation of the Platform is organized within a different framework, or through another legal structure or entity (such as due to a merger or acquisition);</p>
        </li>
        <li>
          <p>We may share personally identifiable information with our corporate group entities, but their use of such information must comply with the Policy.</p>
        </li>
      </ul>

      <b>COOKIES</b>

      <p>Cookies are small files that a site or its service provider transfer to the hard drive of a computer through a web browser (if the computer settings allow) that enable the site&apos;s or service provider&apos;s systems to recognize the browser and capture and remember certain information. We may use cookies to understand and save your preferences for future visits in order to streamline the Platform processes and to compile aggregate data about your interaction with and usage of the Platform so that we can offer an enhanced experience and improved tools in the future, including the ability to improve Website navigation and Platform usability.</p>
      <b>AGGREGATED INFORMATION</b>

      <p>We may use the information we collect, as outlined above, to compile anonymized or aggregated information. We may share, sell or otherwise communicate and make available such anonymized or aggregated information to any other third party, at our sole discretion. However, we will not knowingly or intentionally share information that can be reasonably used to reveal your identity or the identity of anyone tracked through the Platform, without consent.&nbsp;</p>
      <b>INFORMATION SECURITY</b>

      <p>We work hard to protect you from unauthorized access to or alteration, disclosure or destruction of your personal information. As with all technology companies, although we take steps to secure your information, we do not promise, and you should not expect, that your personal information will always remain secure. We implement measures to reduce the risks of damage, loss of information and unauthorized access or use of information. These measures do not provide absolute information security.&nbsp;</p>

      <p>Therefore, it is not guaranteed and you cannot expect that the Platform will be immune from information security risks.</p>

      <p>We may suspend your use of all or part of the services without notice if we suspect or detect any breach of security. If you believe that your account or information is no longer secure, please notify us immediately.</p>

      <b>DATA RETENTION&nbsp;</b>

      <p>We retain personally identifiable information for as long as we deem necessary for the purposes set forth above. We may delete information from our systems, without notice to you, once we deem it is no longer necessary for the purposes set forth above.&nbsp;</p>
      <b>CHANGES TO THIS PRIVACY POLICY</b>

      <p>From time to time, we may change this Policy. If we do so, we will make efforts to proactively notify you of such changes. In any event, the latest version of the Privacy Policy will always be accessible at [provide link to the privacy policy page on the website].</p>

      <b>CONTACT US</b>

      <p>At any time, you may contact us with any question, request, comment or complaint that you may have with respect to the Platform or this Policy, at support@blankethomes.com.&nbsp;</p>

      <p>Last Updated: 11/12/22</p>

    </div>
  );
};
