import { MouseEvent } from 'react';

import { InsightType, useAnalytics } from 'lib';
import { formatDecimalToPercentage, hexToRGBA, useLabels } from 'ui';
import {
  Box, Stack, Typography,
  useTheme,
} from '@mui/material';

import { BasicOwnerRisk } from '../../../../api/properties/types';

export const RiskScore = ({ ownerRisk, expandRow }: { ownerRisk: BasicOwnerRisk, expandRow: () => void }) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();

  const riskLevel = Math.round((ownerRisk.risk ?? 0) / 10);
  const negativeInsightCount = ownerRisk.insights?.filter((i) => i.insightType === InsightType.NEGATIVE)?.length ?? 0;

  const colors = Array.from({ length: 9 }).map((_, i) => hexToRGBA(theme.palette.error.main, (i + 1) / 10));

  const handleClickIssues = (e: MouseEvent) => {
    e.stopPropagation();

    analytics.track('Button Clicked', {
      buttonName: 'Owner Risk Issues',
      ownerID: ownerRisk.ownerID,
      ownerRiskID: ownerRisk.id,
      numOfIssues: negativeInsightCount,
    });

    expandRow();
  };

  return (
    <Stack gap={1}>
      <Stack direction="row" alignItems="center" gap={0.5}>
        {colors.map((color, i) => (
          <Box
            // eslint-disable-next-line react/no-array-index-key
            key={`${ownerRisk.id}-${i}`}
            sx={{
              width: 13,
              height: 16,
              backgroundColor: i + 1 <= riskLevel ? color : theme.palette.grey.A100,
            }}
          />
        ))}
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="body1">
          {formatDecimalToPercentage((ownerRisk.risk ?? 0) / 100, 2)}
        </Typography>
        <Typography
          variant="body1"
          color="primary.dark"
          sx={{ '&:hover': { cursor: 'pointer', textDecoration: 'underline' } }}
          onClick={handleClickIssues}
        >
          {negativeInsightCount}
          {' '}
          {negativeInsightCount === 1 ? l['retention.issue'] : l['retention.issues']}
        </Typography>
      </Stack>
    </Stack>
  );
};
