import { useAnalytics } from 'lib';
import {
  BoldTypography, ControlledFormattedNumberField, formatNumberToCurrency, SemiBoldTypography, useLabels,
} from 'ui';
import { Stack } from '@mui/material';

import { DetailRow } from './Row';
import { editModeSignal, useGetPropertyIdParam } from '../state';
import { RowProps } from '../types';
import { useProjections } from '../useProjections';

export const AskingPrice = ({ form }: RowProps) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const editMode = editModeSignal.value;
  const propertyID = useGetPropertyIdParam();
  const projections = useProjections(form);
  const askingPrice = form.watch(`${propertyID}.askingPrice`);

  return (
    <DetailRow
      rowID="asking-price"
      bottomBorder={false}
      columns={[
        {
          id: 'asking-price',
          content: (
            <SemiBoldTypography variant="body1">
              {l.priceOrValue}
            </SemiBoldTypography>
          ),
        },
        ...projections.years.map((year, i) => ({
          id: `asking-price-${year}`,
          content: editMode && year === 1 ? (
            <Stack>
              <ControlledFormattedNumberField
                control={form.control}
                name={`${propertyID}.askingPrice`}
                label={l.askingPrice}
                prefix="$"
                size="small"
                useExternalValue
                externalValue={askingPrice || 0}
                onBlur={() => {
                  analytics.track('Input Changed', {
                    value: askingPrice,
                    inputName: 'Property Proforma - Asking Price',
                  });
                }}
              />
            </Stack>
          ) : (
            <BoldTypography variant="body1">
              {formatNumberToCurrency(projections.askingPrices[i], 0)}
            </BoldTypography>
          ),
        })),
      ]}
    />
  );
};
