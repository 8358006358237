import {
  ProspectLeadsWorkflowStatus,
} from 'lib';

import { ProspectLeadsKanban } from './kanban/ProspectLeadsKanban';
import { ProspectLeadsList } from './list/ProspectLeadsList';

const orderedStatuses = [
  ProspectLeadsWorkflowStatus.NEW,
  ProspectLeadsWorkflowStatus.IN_PROGRESS,
  ProspectLeadsWorkflowStatus.WON,
  ProspectLeadsWorkflowStatus.LOST,
];

export const ProspectLeads = ({ isKanbanViewEnabled }: { isKanbanViewEnabled: boolean }) => (
  isKanbanViewEnabled
    ? <ProspectLeadsKanban orderedStatuses={orderedStatuses} />
    : <ProspectLeadsList orderedStatuses={orderedStatuses} />
);
