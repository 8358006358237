import { useEffect } from 'react';

import { signal } from '@preact/signals-react';

type CountyData = {
  isLoading: boolean,
  counties: { state: string, county: string }[],
};

const countyData = signal<CountyData>({ isLoading: true, counties: [] });

export const useGetCounties = () => countyData.value;

export const useLoadCounties = () => {
  useEffect(() => {
    if (countyData.value.counties.length > 0) {
      return;
    }

    const loadCounties = async () => {
      const res = (await import('../data/counties.json')).default;

      const counties = res.sort((a, b) => {
        if (a.state < b.state) return -1;
        if (a.state > b.state) return 1;

        return a.county.toLowerCase().localeCompare(b.county.toLowerCase());
      });

      countyData.value = { isLoading: false, counties };
    };

    loadCounties();
  }, []);
};
