import { NavigateFunction } from 'react-router-dom';

import {
  checkIsFirstLogin, Claims, Features,
} from 'lib';

const isFirstLogin = (claims: Claims | undefined, pm: string): boolean => !!claims?.isFirstLogin
 && !checkIsFirstLogin(pm);

export const redirectToRelativeHomepage = (
  navigate: NavigateFunction,
  claims: Claims,
  pm: string,
  features: Omit<Features, 'isLoading'>,
) => {
  if (features.isPmAppEnabled) {
    return navigate('/pm');
  }

  if (features.isPmLeadsViewEnabled) {
    return navigate('/pm/leads');
  }

  if (features.isOwnerDashboardEnabled) {
    if (isFirstLogin(claims, pm)) {
      const existingFirstLoginDetected = localStorage.getItem('firstLoginDetected');
      if (existingFirstLoginDetected) {
        localStorage.setItem('firstLoginDetected', `${existingFirstLoginDetected},${pm}`);
      } else {
        localStorage.setItem('firstLoginDetected', pm);
      }

      return navigate('/onboarding', { state: { firstLogin: true } });
    }

    return undefined;
  }

  if (features.isReferralPartnersAppEnabled) {
    return navigate('/referral-partners');
  }

  if (features.isMarketplaceEnabled) {
    return navigate('/marketplace');
  }

  if (features.isBillingEnabled) {
    return navigate('/settings');
  }

  return null;
};
