import { PMPreferences } from './types';
import { parseManagementFee } from '../../lib/parse';
import { MarketplacePMPreferences } from '../API';

export const convertPMPreferences = (pmPreferences: MarketplacePMPreferences | null): PMPreferences => {
  const managementFee = parseManagementFee(pmPreferences?.managementFee);

  return {
    managementFee: managementFee.isDollars ? managementFee.value * 12 : managementFee.value,
    isManagementFeeDollars: managementFee.isDollars,
    leadsThirdPartyIntegrationRequested: pmPreferences?.leadsThirdPartyIntegrationRequested ?? false,
  };
};
