import { WorkPropertyType } from 'lib';
import { BoldTypography, InfoTooltip, useLabels } from 'ui';
import {
  Stack, Typography, useTheme,
} from '@mui/material';

export const ManagedPropertyTypes = ({
  id,
  propertyTypes,
  isBold = false,
  colorSecondary = false,
  variant = 'body1',
}: {
  id: string,
  propertyTypes: WorkPropertyType[],
  isBold?: boolean,
  colorSecondary?: boolean,
  variant?: 'body1' | 'body2'
}) => {
  const l = useLabels();
  const theme = useTheme();

  const TypographyComponent = isBold ? BoldTypography : Typography;

  const propertyTypeToLabel: Record<WorkPropertyType, string> = {
    [WorkPropertyType.HOUSE]: l['partner.propertyType.house'],
    [WorkPropertyType.CONDO]: l['partner.propertyType.condo'],
    [WorkPropertyType.LOT]: l['partner.propertyType.lot'],
    [WorkPropertyType.MANUFACTURED]: l['partner.propertyType.manufactured'],
    [WorkPropertyType.COMMERCIAL]: l['partner.propertyType.commercial'],
    [WorkPropertyType.SECTION_8]: l['partner.propertyType.section8'],
    [WorkPropertyType.SINGLE_FAMILY]: l['partner.propertyType.singleFamily'],
    [WorkPropertyType.TOWNHOUSE]: l['partner.propertyType.townHome'],
    [WorkPropertyType.SMALL_MULTI_FAMILY]: l['partner.propertyType.smallMf'],
    [WorkPropertyType.MULTI_FAMILY]: l['partner.propertyType.multiFamily'],
    [WorkPropertyType.ALL]: l.all,
    [WorkPropertyType.OTHER]: l.other,
  };

  if (propertyTypes.length === 0) {
    return (
      <TypographyComponent variant={variant} color={colorSecondary ? 'secondary' : undefined}>
        {l.na}
      </TypographyComponent>
    );
  }

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        flexWrap="nowrap"
        maxWidth="200px"
        sx={{ overflow: 'hidden' }}
      >
        <TypographyComponent
          variant={variant}
          sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
          color={colorSecondary ? 'secondary' : undefined}
        >
          {propertyTypes.map((type) => propertyTypeToLabel[type]).join(', ')}
        </TypographyComponent>
        {propertyTypes.length > 1 && (
          <TypographyComponent variant={variant} color={colorSecondary ? 'secondary' : undefined}>
            {` (${propertyTypes.length})`}
          </TypographyComponent>
        )}
      </Stack>
      {propertyTypes.length > 1 && (
        <InfoTooltip
          arrow
          isSmall
          isLight
          isOutlined
          title={(
            <Stack p={1} gap={2}>
              <TypographyComponent variant="body1">
                {l['partners.card.propertyTypes']}
              </TypographyComponent>
              <ul style={{ margin: 0, paddingLeft: theme.spacing(3) }}>
                {propertyTypes.map((type) => (
                  <li key={`${id}::${type}`}>
                    <Typography variant="body2">{propertyTypeToLabel[type]}</Typography>
                  </li>
                ))}
              </ul>
            </Stack>
          )}
        />
      )}
    </Stack>
  );
};
