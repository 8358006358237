import { Navigate, Route, Routes } from 'react-router-dom';

import { useFeatures } from 'lib';

import { PartnerDashboard } from './views/dashboard';

export const App = () => {
  const { isLoading: isLoadingFeatures, ...features } = useFeatures();

  if (isLoadingFeatures) return null;

  return (
    <Routes>
      {features.isReferralPartnersAppEnabled && <Route path="/" element={<PartnerDashboard />} />}
      <Route
        path="*"
        element={
          <Navigate to="/" />
        }
      />
    </Routes>
  );
};
