import { ReactNode } from 'react';

import {
  BuyBoxLeadsWorkflowStatus, BuyerLeadsWorkflowStatus,
  OwnerLeadsWorkflowStatus, ProspectLeadsWorkflowStatus, SellerLeadsWorkflowStatus,
} from 'lib';
import {
  PersonCircleBottomRight,
  PersonCircleTopLeft, PersonSquareBottomRight, PersonSquareTopLeft, useLabels,
} from 'ui';
import { alpha, useTheme } from '@mui/material';

export type StatusConfig = {
  color: string,
  displayValue: string,
  icon: ReactNode,
  smallIcon: ReactNode,
  tooltip?: ReactNode,
};

const iconSize = 40;
const smallIconSize = 32;
const yellowBaseColor = '#ffe000';

export const useBuyerLeadStatuses = (): {
  getStatus: (status: BuyerLeadsWorkflowStatus) => StatusConfig,
  statuses: Record<BuyerLeadsWorkflowStatus, StatusConfig>
} => {
  const l = useLabels();
  const theme = useTheme();

  const statuses: Record<BuyerLeadsWorkflowStatus, StatusConfig> = {
    [BuyerLeadsWorkflowStatus.new]: {
      color: theme.palette.info.main,
      displayValue: l['leads.new'],
      icon: <PersonSquareTopLeft size={iconSize} color={theme.palette.info.main} />,
      smallIcon: <PersonSquareTopLeft size={smallIconSize} color={theme.palette.info.main} />,
    },
    [BuyerLeadsWorkflowStatus.attemptedContact]: {
      color: alpha(yellowBaseColor, 0.25),
      displayValue: l['pm-dashboard.leads.attemptedContact'],
      icon: <PersonSquareBottomRight size={iconSize} color={alpha(yellowBaseColor, 0.25)} />,
      smallIcon: <PersonSquareBottomRight size={smallIconSize} color={alpha(yellowBaseColor, 0.25)} />,
    },
    [BuyerLeadsWorkflowStatus.spokeWithLead]: {
      color: alpha(yellowBaseColor, 0.5),
      displayValue: l['pm-dashboard.leads.spokeWithLead'],
      icon: <PersonSquareBottomRight size={iconSize} color={alpha(yellowBaseColor, 0.5)} />,
      smallIcon: <PersonSquareBottomRight size={smallIconSize} color={alpha(yellowBaseColor, 0.5)} />,
    },
    [BuyerLeadsWorkflowStatus.appointmentSet]: {
      color: alpha(yellowBaseColor, 0.75),
      displayValue: l['pm-dashboard.leads.appointmentSet'],
      icon: <PersonSquareBottomRight size={iconSize} color={alpha(yellowBaseColor, 0.75)} />,
      smallIcon: <PersonSquareBottomRight size={smallIconSize} color={alpha(yellowBaseColor, 0.75)} />,
    },
    [BuyerLeadsWorkflowStatus.metWithLead]: {
      color: alpha(yellowBaseColor, 1),
      displayValue: l['pm-dashboard.leads.metWithLead'],
      icon: <PersonSquareBottomRight size={iconSize} color={alpha(yellowBaseColor, 1)} />,
      smallIcon: <PersonSquareBottomRight size={smallIconSize} color={alpha(yellowBaseColor, 1)} />,
    },
    [BuyerLeadsWorkflowStatus.offerSubmitted]: {
      color: '#9900ff',
      displayValue: l['pm-dashboard.leads.offerSubmitted'],
      icon: <PersonCircleTopLeft size={iconSize} color="#9900ff" />,
      smallIcon: <PersonCircleTopLeft size={smallIconSize} color="#9900ff" />,
    },
    [BuyerLeadsWorkflowStatus.underContract]: {
      color: '#ff00ff',
      displayValue: l['pm-dashboard.leads.underContract'],
      icon: <PersonCircleBottomRight size={iconSize} color="#ff00ff" />,
      smallIcon: <PersonCircleBottomRight size={smallIconSize} color="#ff00ff" />,
    },
    [BuyerLeadsWorkflowStatus.sold]: {
      color: theme.palette.success.main,
      displayValue: l['pm-dashboard.leads.sold'],
      icon: <PersonCircleTopLeft size={iconSize} color={theme.palette.success.main} />,
      smallIcon: <PersonCircleTopLeft size={smallIconSize} color={theme.palette.success.main} />,
    },
    [BuyerLeadsWorkflowStatus.rejected]: {
      color: theme.palette.error.main,
      displayValue: l['pm-dashboard.leads.rejected'],
      icon: <PersonSquareBottomRight size={iconSize} color={theme.palette.error.main} />,
      smallIcon: <PersonSquareBottomRight size={smallIconSize} color={theme.palette.error.main} />,
    },
  };

  return {
    statuses,
    getStatus: (status: BuyerLeadsWorkflowStatus) => statuses[status],
  };
};

export const useSellerLeadStatuses = (): {
  getStatus: (status: SellerLeadsWorkflowStatus) => StatusConfig,
  statuses: Record<SellerLeadsWorkflowStatus, StatusConfig>
} => {
  const l = useLabels();
  const theme = useTheme();

  const statuses: Record<SellerLeadsWorkflowStatus, StatusConfig> = {
    [SellerLeadsWorkflowStatus.new]: {
      color: theme.palette.info.main,
      displayValue: l['leads.new'],
      icon: <PersonSquareTopLeft size={iconSize} color={theme.palette.info.main} />,
      smallIcon: <PersonSquareTopLeft size={smallIconSize} color={theme.palette.info.main} />,
    },
    [SellerLeadsWorkflowStatus.attemptedContact]: {
      color: alpha(yellowBaseColor, 0.25),
      displayValue: l['pm-dashboard.leads.attemptedContact'],
      icon: <PersonSquareBottomRight size={iconSize} color={alpha(yellowBaseColor, 0.25)} />,
      smallIcon: <PersonSquareBottomRight size={smallIconSize} color={alpha(yellowBaseColor, 0.25)} />,
    },
    [SellerLeadsWorkflowStatus.spokeWithLead]: {
      color: alpha(yellowBaseColor, 0.5),
      displayValue: l['pm-dashboard.leads.spokeWithLead'],
      icon: <PersonSquareBottomRight size={iconSize} color={alpha(yellowBaseColor, 0.5)} />,
      smallIcon: <PersonSquareBottomRight size={smallIconSize} color={alpha(yellowBaseColor, 0.5)} />,
    },
    [SellerLeadsWorkflowStatus.appointmentSet]: {
      color: alpha(yellowBaseColor, 0.75),
      displayValue: l['pm-dashboard.leads.appointmentSet'],
      icon: <PersonSquareBottomRight size={iconSize} color={alpha(yellowBaseColor, 0.75)} />,
      smallIcon: <PersonSquareBottomRight size={smallIconSize} color={alpha(yellowBaseColor, 0.75)} />,
    },
    [SellerLeadsWorkflowStatus.metWithLead]: {
      color: alpha(yellowBaseColor, 1),
      displayValue: l['pm-dashboard.leads.metWithLead'],
      icon: <PersonSquareBottomRight size={iconSize} color={alpha(yellowBaseColor, 1)} />,
      smallIcon: <PersonSquareBottomRight size={smallIconSize} color={alpha(yellowBaseColor, 1)} />,
    },
    [SellerLeadsWorkflowStatus.activeListing]: {
      color: '#770077',
      displayValue: l['pm-dashboard.leads.activeListing'],
      icon: <PersonSquareBottomRight size={iconSize} color="#770077" />,
      smallIcon: <PersonSquareBottomRight size={smallIconSize} color="#770077" />,
    },
    [SellerLeadsWorkflowStatus.offerAccepted]: {
      color: '#9900ff',
      displayValue: l['pm-dashboard.leads.offerAccepted'],
      icon: <PersonCircleTopLeft size={iconSize} color="#9900ff" />,
      smallIcon: <PersonCircleTopLeft size={smallIconSize} color="#9900ff" />,
    },
    [SellerLeadsWorkflowStatus.underContract]: {
      color: '#ff00ff',
      displayValue: l['pm-dashboard.leads.underContract'],
      icon: <PersonCircleBottomRight size={iconSize} color="#ff00ff" />,
      smallIcon: <PersonCircleBottomRight size={smallIconSize} color="#ff00ff" />,
    },
    [SellerLeadsWorkflowStatus.sold]: {
      color: theme.palette.success.main,
      displayValue: l['pm-dashboard.leads.sold'],
      icon: <PersonSquareTopLeft size={iconSize} color={theme.palette.success.main} />,
      smallIcon: <PersonSquareTopLeft size={smallIconSize} color={theme.palette.success.main} />,
    },
    [SellerLeadsWorkflowStatus.rejected]: {
      color: theme.palette.error.main,
      displayValue: l['pm-dashboard.leads.rejected'],
      icon: <PersonSquareBottomRight size={iconSize} color={theme.palette.error.main} />,
      smallIcon: <PersonSquareBottomRight size={smallIconSize} color={theme.palette.error.main} />,
    },
  };

  return {
    statuses,
    getStatus: (status: SellerLeadsWorkflowStatus) => statuses[status],
  };
};

export const useBuyBoxLeadStatuses = (): {
  getStatus: (status: BuyBoxLeadsWorkflowStatus) => StatusConfig,
  statuses: Record<BuyBoxLeadsWorkflowStatus, StatusConfig>
} => {
  const l = useLabels();
  const theme = useTheme();

  const statuses: Record<BuyBoxLeadsWorkflowStatus, StatusConfig> = {
    [BuyBoxLeadsWorkflowStatus.new]: {
      color: theme.palette.info.main,
      displayValue: l['leads.new'],
      icon: <PersonSquareTopLeft size={iconSize} color={theme.palette.info.main} />,
      smallIcon: <PersonSquareTopLeft size={smallIconSize} color={theme.palette.info.main} />,
    },
    [BuyBoxLeadsWorkflowStatus.attemptedContact]: {
      color: alpha(yellowBaseColor, 1),
      displayValue: l['pm-dashboard.leads.attemptedContact'],
      icon: <PersonSquareBottomRight size={iconSize} color={alpha(yellowBaseColor, 1)} />,
      smallIcon: <PersonSquareBottomRight size={smallIconSize} color={alpha(yellowBaseColor, 1)} />,
    },
    [BuyBoxLeadsWorkflowStatus.spokeWithLead]: {
      color: '#770077',
      displayValue: l['pm-dashboard.leads.spokeWithLead'],
      icon: <PersonSquareBottomRight size={iconSize} color="#770077" />,
      smallIcon: <PersonSquareBottomRight size={smallIconSize} color="#770077" />,
    },
    [BuyBoxLeadsWorkflowStatus.appointmentSet]: {
      color: '#9900ff',
      displayValue: l['pm-dashboard.leads.appointmentSet'],
      icon: <PersonCircleTopLeft size={iconSize} color="#9900ff" />,
      smallIcon: <PersonCircleTopLeft size={smallIconSize} color="#9900ff" />,
    },
    [BuyBoxLeadsWorkflowStatus.metWithLead]: {
      color: '#ff00ff',
      displayValue: l['pm-dashboard.leads.metWithLead'],
      icon: <PersonSquareBottomRight size={iconSize} color="#ff00ff" />,
      smallIcon: <PersonSquareBottomRight size={smallIconSize} color="#ff00ff" />,
    },
    [BuyBoxLeadsWorkflowStatus.propertyChosen]: {
      color: theme.palette.success.main,
      displayValue: l['pm-dashboard.leads.propertyChosen'],
      icon: <PersonSquareTopLeft size={iconSize} color={theme.palette.success.main} />,
      smallIcon: <PersonSquareTopLeft size={smallIconSize} color={theme.palette.success.main} />,
    },
  };

  return {
    statuses,
    getStatus: (status: BuyBoxLeadsWorkflowStatus) => statuses[status],
  };
};

export const useOwnerLeadStatuses = (): {
  getStatus: (status: OwnerLeadsWorkflowStatus) => StatusConfig,
  statuses: Record<OwnerLeadsWorkflowStatus, StatusConfig>
} => {
  const l = useLabels();
  const theme = useTheme();

  const statuses: Record<OwnerLeadsWorkflowStatus, StatusConfig> = {
    [OwnerLeadsWorkflowStatus.NEW]: {
      color: theme.palette.primary.dark,
      displayValue: l['leads.new'],
      tooltip: l['pm-dashboard.leads.new.tooltip'],
      icon: <PersonSquareTopLeft size={iconSize} color={theme.palette.primary.dark} />,
      smallIcon: <PersonSquareTopLeft size={smallIconSize} color={theme.palette.primary.dark} />,
    },
    [OwnerLeadsWorkflowStatus.IN_PROGRESS]: {
      color: alpha(yellowBaseColor, 1),
      displayValue: l['pm-dashboard.leads.inProgress'],
      tooltip: l['pm-dashboard.leads.inProgress.tooltip'],
      icon: <PersonSquareBottomRight size={iconSize} color={alpha(yellowBaseColor, 1)} />,
      smallIcon: <PersonSquareBottomRight size={smallIconSize} color={alpha(yellowBaseColor, 1)} />,
    },
    [OwnerLeadsWorkflowStatus.WON]: {
      color: theme.palette.success.main,
      displayValue: l['pm-dashboard.leads.won'],
      tooltip: l['pm-dashboard.leads.won.tooltip'],
      icon: <PersonCircleTopLeft size={iconSize} color={theme.palette.success.main} />,
      smallIcon: <PersonCircleTopLeft size={smallIconSize} color={theme.palette.success.main} />,
    },
    [OwnerLeadsWorkflowStatus.LOST]: {
      color: theme.palette.error.main,
      displayValue: l['pm-dashboard.leads.lost'],
      tooltip: l['pm-dashboard.leads.lost.tooltip'],
      icon: <PersonSquareBottomRight size={iconSize} color={theme.palette.error.main} />,
      smallIcon: <PersonSquareBottomRight size={smallIconSize} color={theme.palette.error.main} />,
    },
  };

  return {
    statuses,
    getStatus: (status: OwnerLeadsWorkflowStatus) => statuses[status],
  };
};

export const useProspectLeadStatuses = (): {
  getStatus: (status: ProspectLeadsWorkflowStatus) => StatusConfig,
  statuses: Record<ProspectLeadsWorkflowStatus, StatusConfig>
} => {
  const l = useLabels();
  const theme = useTheme();

  const statuses: Record<ProspectLeadsWorkflowStatus, StatusConfig> = {
    [ProspectLeadsWorkflowStatus.NEW]: {
      color: theme.palette.primary.dark,
      displayValue: l['leads.new'],
      icon: <PersonSquareTopLeft size={iconSize} color={theme.palette.primary.dark} />,
      smallIcon: <PersonSquareTopLeft size={smallIconSize} color={theme.palette.primary.dark} />,
    },
    [ProspectLeadsWorkflowStatus.IN_PROGRESS]: {
      color: alpha(yellowBaseColor, 1),
      displayValue: l['pm-dashboard.leads.inProgress'],
      icon: <PersonSquareBottomRight size={iconSize} color={alpha(yellowBaseColor, 1)} />,
      smallIcon: <PersonSquareBottomRight size={smallIconSize} color={alpha(yellowBaseColor, 1)} />,
    },
    [ProspectLeadsWorkflowStatus.WON]: {
      color: theme.palette.success.main,
      displayValue: l['pm-dashboard.leads.won'],
      icon: <PersonCircleTopLeft size={iconSize} color={theme.palette.success.main} />,
      smallIcon: <PersonCircleTopLeft size={smallIconSize} color={theme.palette.success.main} />,
    },
    [ProspectLeadsWorkflowStatus.LOST]: {
      color: theme.palette.error.main,
      displayValue: l['pm-dashboard.leads.lost'],
      icon: <PersonSquareBottomRight size={iconSize} color={theme.palette.error.main} />,
      smallIcon: <PersonSquareBottomRight size={smallIconSize} color={theme.palette.error.main} />,
    },
  };

  return {
    statuses,
    getStatus: (status: ProspectLeadsWorkflowStatus) => statuses[status],
  };
};
