import { useMemo } from 'react';

import { ApexOptions } from 'apexcharts';
import { OwnerRiskStatus, useAnalytics } from 'lib';
import {
  BoldTypography, DonutChart, InfoTooltip, MissingDataIcon, SpinnerWithLogo, useLabels,
} from 'ui';
import {
  Box,
  Card, CardContent, Stack, Typography,
  useTheme,
} from '@mui/material';

import { useGetOwnerRisksByPM } from '../../../api/owner-risk';
import { RetainTab } from '../state';

type StatusConfig = {
  label: string,
  color: string,
  count: number,
};

export const BlanketScore = () => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const { data, isLoading, isError } = useGetOwnerRisksByPM();

  const ownerRiskCounts: Record<RetainTab, number> = useMemo(() => {
    const allOwnerRisks = data?.pages.flatMap((page) => page.ownerRisks) ?? [];
    const noRisk = allOwnerRisks.filter((ownerRisk) => ownerRisk.isNoRisk);
    const risks = allOwnerRisks.filter((ownerRisk) => !ownerRisk.isNoRisk);

    return {
      new: risks.filter((ownerRisk) => ownerRisk.status === OwnerRiskStatus.NEW).length,
      inProgress: risks.filter((ownerRisk) => ownerRisk.status === OwnerRiskStatus.IN_PROGRESS).length,
      resolved: risks.filter((ownerRisk) => ownerRisk.status === OwnerRiskStatus.RESOLVED).length,
      noRisk: noRisk.length,
    };
  }, [data]);

  const statusToConfig: Record<OwnerRiskStatus, StatusConfig> = {
    [OwnerRiskStatus.NEW]: {
      label: l['retention.tab.new'], color: theme.palette.error.main, count: ownerRiskCounts.new,
    },
    [OwnerRiskStatus.IN_PROGRESS]: {
      label: l['retention.tab.inProgress'], color: theme.palette.warning.main, count: ownerRiskCounts.inProgress,
    },
    [OwnerRiskStatus.RESOLVED]: {
      label: l['retention.tab.resolved'], color: theme.palette.success.main, count: ownerRiskCounts.resolved,
    },
  };

  const labels = [
    statusToConfig[OwnerRiskStatus.NEW].label,
    statusToConfig[OwnerRiskStatus.IN_PROGRESS].label,
    statusToConfig[OwnerRiskStatus.RESOLVED].label,
  ];

  const colors = [
    statusToConfig[OwnerRiskStatus.NEW].color,
    statusToConfig[OwnerRiskStatus.IN_PROGRESS].color,
    statusToConfig[OwnerRiskStatus.RESOLVED].color,
  ];

  const series: ApexOptions['series'] = [ownerRiskCounts.new, ownerRiskCounts.inProgress, ownerRiskCounts.resolved];

  return (
    <Card sx={{ height: '100%' }} className={isLoading ? '' : 'retain-blanket-score'}>
      <CardContent sx={{
        height: '100%', display: 'flex', flexDirection: 'column',
      }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <BoldTypography variant="h6">
            {l['retention.blanketScore']}
          </BoldTypography>
          <InfoTooltip
            arrow
            isLight
            title={(
              <Typography variant="body2" sx={{ p: 1 }}>
                {l['retention.tooltip.blanketScore']}
              </Typography>
            )}
            isOutlined
            track={(value) => {
              analytics.track('Tooltip Toggled', {
                value,
                tooltipName: 'Blanket Score',
              });
            }}
          />
        </Stack>
        <Stack flexGrow={1} justifyContent="center">
          {isError && (
            <Stack alignItems="center" justifyContent="center" flexGrow={1}>
              <MissingDataIcon iconProps={{ size: 36 }} boxProps={{ sx: { borderRadius: '100%' } }} />
            </Stack>
          )}
          {isLoading ? (
            <Stack alignItems="center" justifyContent="center" flexGrow={1}>
              <SpinnerWithLogo size={48} />
            </Stack>
          ) : !isError && (
            <Stack direction="row" alignItems="center" justifyContent="space-between" flexWrap="wrap">
              <DonutChart labels={labels} colors={colors} series={series} scale={0.9}>
                <Stack gap={1}>
                  {Object.entries(statusToConfig).map(([status, config]) => (
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap={3}
                      key={`blanket-score-label-${status}`}
                      sx={{
                        textWrap: 'nowrap',
                      }}
                    >
                      <Box
                        height={10}
                        width={10}
                        borderRadius={100}
                        sx={{
                          background: config.color,
                        }}
                      />
                      {config.label}
                      {' '}
                      (
                      {statusToConfig[status as OwnerRiskStatus].count}
                      )
                    </Stack>
                  ))}
                </Stack>
              </DonutChart>
            </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};
