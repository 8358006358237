import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { config, useAppSettings } from 'lib';
import { MdErrorOutline } from 'react-icons/md';
import {
  loginVideoPosterURL, loginVideoURL, useLabels,
} from 'ui';
import { Descope } from '@descope/react-sdk';
import {
  Alert,
  Box, Container, Stack, Typography,
} from '@mui/material';

export const DescopeLogin = () => {
  const l = useLabels();
  const { data: appSettings } = useAppSettings();
  const [urlParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const register = urlParams.get('register') ?? 'false';

  const errorTransformer = useCallback((error: { text: string; type: string }) => {
    setErrorMessage({
      SAMLStartFailed: 'Failed to start SAML flow',
      PasswordSignInFailed: l.wrongEmailOrPassword,
    }[error.type] ?? error.text);

    return '';
  }, [setErrorMessage]);

  const renderRightPaneText = () => {
    if (register === 'true') {
      return (
        <>
          <Typography variant="h5" textAlign="center">
            Access Your
            {` ${appSettings?.pmName} `}
            Investor Marketplace!
          </Typography>
          <Typography variant="subtitle2" textAlign="center">
            This is where you can find off-market investment properties for sale.
            Create your account to get access to unique investment opportunities!
          </Typography>
        </>
      );
    }
    return (
      <>
        <Typography variant="h5" textAlign="center">
          Welcome to Your
          {` ${appSettings?.pmName} `}
          Platform
        </Typography>
        <Typography variant="body1" textAlign="center">
          The place for all your real-time asset insights and opportunities for growth
        </Typography>
      </>
    );
  };

  useEffect(() => {
    document.querySelector('html')?.classList.add('full-height');

    return () => {
      document.querySelector('html')?.classList.remove('full-height');
    };
  }, []);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        overflow: 'scroll',
        px: [0, 0, 3],
      }}
    >
      <Stack
        component={Container}
        direction={['column', 'column', 'row']}
        sx={{
          width: '100%',
          border: ['none', 'none', '2px solid #D8D8D8'],
          borderRadius: [undefined, undefined, '6px'],
          px: '0 !important',
          height: ['100%', '100%', 'auto'],
        }}
      >
        <Stack sx={{
          p: 5,
          maxWidth: ['100%', '100%', '50%'],
          background: 'white',
          flexGrow: 1,
          justifyContent: 'center',
          borderTopLeftRadius: [undefined, undefined, '6px'],
          borderBottomLeftRadius: [undefined, undefined, '6px'],
        }}
        >
          <Box
            component="img"
            src={appSettings?.logo || 'https://url4904.blankethomes.com/pms/portico/logo.png'}
            sx={{ width: '110px' }}
          />
          <Stack>
            <Box px={[0, 0, 10, 20]}>
              {errorMessage && (
                <Alert
                  color="error"
                  icon={<MdErrorOutline />}
                  sx={{
                    mx: 4.5, mt: 4, mb: 3, display: 'flex', alignItems: 'center',
                  }}
                >
                  {errorMessage}
                </Alert>
              )}
              <Descope
                flowId={config.descopeFlowId}
                debug={config.descopeDebugMode}
                tenant={appSettings!.tenant!}
                errorTransformer={errorTransformer}
                form={{
                  email: urlParams.get('email') ?? '',
                  tenantName: appSettings!.pmName,
                  userSelectedTenant: appSettings!.tenant!,
                  register,
                }}
              />
            </Box>
          </Stack>
        </Stack>
        <Box sx={{
          background: '#EFF0F8 !important',
          display: ['none', 'none', 'flex'],
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          maxWidth: '50%',
          width: ['100%', '100%', '50%'],
          borderTopRightRadius: [undefined, undefined, '6px'],
          borderBottomRightRadius: [undefined, undefined, '6px'],
          px: 10,
          py: 5,
        }}
        >
          <Stack alignItems="center" justifyContent="center" gap={3}>
            <video
              width="100%"
              height="100%"
              autoPlay
              loop
              playsInline
              muted
              poster={loginVideoPosterURL}
            >
              <source src={loginVideoURL} type="video/mp4" />
            </video>
            {renderRightPaneText()}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};
