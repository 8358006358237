import { useAnalytics } from 'lib';
import { useLabels } from 'ui';
import { Stack, useMediaQuery, useTheme } from '@mui/material';

import { PredefinedFilterChip } from './styled';
import { Category } from '../../../api/properties/searchable';
import { SingleFilterProps } from '../props';

export const CategoryFilter = ({ filters, setFilters }: SingleFilterProps) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleClick = (category: Category) => {
    const categories = (
      filters.categories?.includes(category)
        ? filters.categories.filter((c) => c !== category)
        : [...(filters.categories ?? []), category]
    );

    analytics.track('Button Clicked', {
      buttonName: 'Category Filter',
      categories: categories.join(','),
    });

    setFilters({ categories });
  };

  return (
    <Stack justifyContent="center" alignItems="center" gap={isMobile ? 2 : 4} p={3} flexGrow={1}>
      <PredefinedFilterChip
        className={filters.categories?.includes('internal') ? 'active' : ''}
        onClick={() => handleClick('internal')}
        label={l.exclusive}
      />
      <PredefinedFilterChip
        className={filters.categories?.includes('new_construction') ? 'active' : ''}
        onClick={() => handleClick('new_construction')}
        label={l.newConstruction}
      />
      <PredefinedFilterChip
        className={filters.categories?.includes('turnkey') ? 'active' : ''}
        onClick={() => handleClick('turnkey')}
        label={l.turnkey}
      />
      <PredefinedFilterChip
        className={filters.categories?.includes('wholesale') ? 'active' : ''}
        onClick={() => handleClick('wholesale')}
        label={l.wholesale}
      />
    </Stack>
  );
};
