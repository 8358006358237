import { calculateGrossYield } from 'lib';
import { formatDecimalToPercentage, useLabels } from 'ui';

import { CalculatedRow } from './Row';
import { RowProps } from '../types';
import { useProjections } from '../useProjections';

export const GrossYield = ({ form }: RowProps) => {
  const l = useLabels();

  const projections = useProjections(form);

  const getGrossYield = (i: number) => calculateGrossYield(
    projections.expectedRent[i] / 12,
    projections.askingPrices[i],
  );

  return (
    <CalculatedRow
      label={l.grossYield}
      values={projections.years.map((_, i) => formatDecimalToPercentage(getGrossYield(i) || 0, 2))}
      isInnerRow
    />
  );
};
