import { ReactNode } from 'react';

import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import {
  BoldTypography, formatDecimalToPercentage, formatNumber, useLabels,
} from 'ui';
import {
  Card, CardContent, Grid, lighten, Stack, Typography, useTheme,
} from '@mui/material';

import { PMMetrics } from '../../../api/pm-metrics/types';

const TotalUsersMetric = ({
  value,
  label,
  trendValue = undefined,
}: {
  value: ReactNode,
  label: string,
  trendValue?: number,
}) => {
  const theme = useTheme();

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <Stack sx={{ background: lighten(theme.palette.primary.main, 0.2), borderRadius: '6px', minWidth: 60 }} p={2}>
        <Typography variant="body1" color="primary.contrastText" sx={{ textAlign: 'center' }}>
          {value}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" gap={2} flexWrap="wrap">
        <Typography variant="caption" color="primary.contrastText" sx={{ textWrap: 'nowrap' }}>
          {label}
        </Typography>
        {!!trendValue && (
          <Stack direction="row" alignItems="center" gap={0}>
            <Typography variant="subtitle2" color={trendValue > 0 ? 'success.main' : 'error.main'}>
              {trendValue > 0 ? '+' : '-'}
              {formatDecimalToPercentage(Math.abs(trendValue), 0)}
            </Typography>
            {trendValue > 0
              ? <MdKeyboardArrowUp color={theme.palette.success.main} />
              : <MdKeyboardArrowDown color={theme.palette.error.main} />}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export const TotalUsers = ({ metrics }: { metrics: PMMetrics }) => {
  const l = useLabels();
  const theme = useTheme();

  const totalActivated = metrics.usersMetrics?.activatedUsers ?? 0;
  const totalActivatedLastMonth = metrics.usersMetrics?.activatedUsersLastMonth ?? 0;
  const totalActivatedTrend = ((totalActivated - totalActivatedLastMonth) / totalActivatedLastMonth);
  const totalInvited = metrics.usersMetrics?.invitedUsers ?? 0;
  const totalInvitedLastMonth = metrics.usersMetrics?.invitedUsersLastMonth ?? 0;
  const totalInvitedTrend = ((totalInvited - totalInvitedLastMonth) / totalInvitedLastMonth);
  const currentConversionRate = Math.round((totalActivated / totalInvited) * 100) / 100;

  const lastMonthConversionRate = totalActivatedLastMonth / totalInvitedLastMonth;
  const currentConversionRateTrend = ((currentConversionRate - lastMonthConversionRate) / lastMonthConversionRate);

  return (
    <Card sx={{ height: '100%', background: theme.palette.primary.main }}>
      <CardContent sx={{ height: '100%' }}>
        <Stack height="100%" justifyContent="space-between" gap={5}>
          <BoldTypography variant="h6" color="primary.contrastText">
            {l['blanketOverview.totalUsers']}
          </BoldTypography>
          <Grid container rowSpacing={6} columnSpacing={3}>
            <Grid item xs={6}>
              <TotalUsersMetric
                value={formatNumber(totalInvited, 0)}
                trendValue={totalInvitedLastMonth ? totalInvitedTrend : undefined}
                label={l['blanketOverview.invited']}
              />
            </Grid>
            <Grid item xs={6}>
              <TotalUsersMetric
                value={totalActivated}
                trendValue={totalActivatedLastMonth ? totalActivatedTrend : undefined}
                label={l['blanketOverview.activated']}
              />
            </Grid>
            <Grid item xs={12}>
              <TotalUsersMetric
                value={formatDecimalToPercentage(currentConversionRate || 0, 2)}
                trendValue={lastMonthConversionRate ? currentConversionRateTrend : undefined}
                label={l['blanketOverview.conversionRate']}
              />
            </Grid>
          </Grid>
        </Stack>
      </CardContent>
    </Card>
  );
};
