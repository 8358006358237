import {
  OwnerReferralsIncentive, useFeatures,
} from 'lib';

import { useGetB2CIncentive } from '../api/growthPolicies';

export const useGetB2CReferralProgram = () => {
  const { isLoading: isLoadingFeatures, ...features } = useFeatures();

  const shouldFetchB2CIncentive = !isLoadingFeatures && features.isB2CReferralProgramEnabled;

  const { data: b2cIncentiveData, isLoading: isLoadingB2CIncentive } = useGetB2CIncentive(shouldFetchB2CIncentive);

  const shouldDisplayReferralProgram = (
    (features.isB2CReferralProgramEnabled && b2cIncentiveData?.enableOwnerReferralProgram) || features.isFreemium
  );
  const isLoading = (shouldFetchB2CIncentive && isLoadingB2CIncentive) || isLoadingFeatures;
  const b2cReferralIncentive = b2cIncentiveData?.ownerReferralsIncentive ?? OwnerReferralsIncentive.NO_INCENTIVE;

  return {
    shouldDisplayReferralProgram,
    isLoading,
    b2cReferralIncentive,
  };
};
