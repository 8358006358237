import {
  GetPMMetricsQuery, PMMetrics as ApiPMMetrics,
  queryGraphQL, useAuth,
} from 'lib';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useQuery } from '@tanstack/react-query';

import { convertPmMetrics } from './converters';
import { PMMetrics } from './types';
import { QueryKey } from '../../types/enums';
import { getPmMetrics } from '../graphql/queries';

export const useGetPmMetrics = () => {
  const { user, getAccessTokenSilently } = useAuth();

  const pm = user?.pm;

  return useQuery([QueryKey.PM_METRICS], async (): Promise<PMMetrics> => {
    const token = await getAccessTokenSilently();

    try {
      const res = await queryGraphQL({
        query: getPmMetrics,
        variables: { pm },
        authToken: token,
      }) as GraphQLResult<GetPMMetricsQuery>;

      return convertPmMetrics(res.data?.getPMMetrics as ApiPMMetrics);
    } catch (e) {
      console.error(e);

      return convertPmMetrics(undefined);
    }
  });
};
