import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  config, Question, shouldSkipQuestion, useAuth,
} from 'lib';
import Carousel from 'react-multi-carousel';
import { Spacer, useLabels } from 'ui';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { DialogOPBQuestionnaire, ObpFormCategory } from './questionnaire';
import { Vendor } from './Vendor';
import { categories } from './vendorData';

import './carousel.css';

export const OwnerBenefits = () => {
  const obpFormCategoryMap: { [key: string]: ObpFormCategory } = {
    Insurance: {
      categoryName: 'Insurance',
      formId: config.jotFormInsuranceObpFormId,
      openCalendly: () => false,
    },
    '1031 Exchange': {
      categoryName: '1031 Exchange',
      formId: config.jotForm1031ExchangeObpFormId,
      openCalendly: () => false,
    },
    Inspection: {
      categoryName: 'Inspection',
      formId: config.jotFormInspectionObpFormId,
      openCalendly: () => false,
    },
    Financing: {
      categoryName: 'Financing',
      formId: config.jotFormFinancingObpFormId,
      openCalendly: (questions: Question[]) => {
        const questionsForm = (questions ?? []).reduce((acc, q) => ({
          ...acc,
          [q.id]: q,
        }), {} as { [key: string]: Question });
        return questions.filter((q) => (q.id === config.jotFormFinancingObpAgentFacilitateHelpQuestion && q.answer === 'Yes'
          && !shouldSkipQuestion(config.jotFormFinancingObpAgentFacilitateHelpQuestion, questionsForm))
          || (q.id === config.jotFormFinancingObpAgentHelpQuestion && q.answer === 'Yes'
            && !shouldSkipQuestion(config.jotFormFinancingObpAgentHelpQuestion, questionsForm))).length > 0;
      },
    },
  };

  const [obpFormCategorySelect, setObpFormCategorySelect] = useState<ObpFormCategory | undefined>(obpFormCategoryMap.Insurance);

  const { user } = useAuth();

  const theme = useTheme();
  const isLowerThanLg = useMediaQuery(theme.breakpoints.down('lg'));
  const l = useLabels();
  const [questionnaireDialogOpen, setQuestionnaireDialogOpen] = useState<boolean>(false);
  const [obpUrl, setObpUrl] = useState<string>('');

  const ctaUrl = (url: string, vendorName: string, vendorCategory: string) => {
    const params = {
      user_id: user?.id ?? '',
      vendorName,
      vendorCategory,
    };

    return `${url}?b=${btoa(JSON.stringify(params))}`;
  };

  const openQuestions = async (url: string, category: string) => {
    if (['Cost Segregation', 'Home Warranty'].includes(category)) {
      window.open(url, '_blank');
      return;
    }

    setObpFormCategorySelect(obpFormCategoryMap[category]);
    setObpUrl(url);
    setQuestionnaireDialogOpen(true);
  };

  const [searchParams] = useSearchParams();
  const categoryParam = searchParams.get('category');
  const vendorParam = searchParams.get('vendor');

  useEffect(() => {
    const selectedCategory = categories.find((c) => c.name === categoryParam);
    const selectedVendor = selectedCategory?.vendors.find((v) => v.name === vendorParam);

    if (selectedCategory && selectedVendor) {
      const url = ctaUrl(selectedVendor?.cta, selectedVendor.name, selectedCategory.name);
      openQuestions(url, selectedCategory.name);
    } else if (selectedCategory) {
      window.location.href += `#${selectedCategory.name}`;
    }
  }, [categoryParam, vendorParam]);

  const getCarouselResponsiveConfig = (count: number) => ({
    xs: {
      breakpoint: { max: 597, min: 0 },
      items: 1,
      partialVisibilityGutter: count === 1 ? 0 : 100,
    },
    s: {
      breakpoint: { max: 875, min: 598 },
      items: 2,
      partialVisibilityGutter: 25,
    },
    s2: {
      breakpoint: { max: 4000, min: 876 },
      items: 3,
    },
  });

  return (
    <>
      <DialogOPBQuestionnaire
        open={questionnaireDialogOpen}
        closeDialog={() => {
          setQuestionnaireDialogOpen(false);
          window.open(obpUrl, '_blank');
        }}
        cancelDialog={() => {
          setQuestionnaireDialogOpen(false);
        }}
        obpFormCategory={obpFormCategorySelect}
      />
      <Stack px={3} pt={{ xs: 3, lg: 0 }}>
        <Card>
          <CardContent sx={{ px: 3 }}>
            <Stack direction="row" spacing={2}>
              <Stack>
                <Box
                  component="img"
                  src="https://assets.blankethomes.com/gi/dollar.webp"
                  sx={{
                    width: '100%',
                    height: '100%',
                    maxHeight: '56px',
                    maxWidth: '56px',
                  }}
                />
              </Stack>
              <Stack direction="column" pt={{ xs: 3, lg: 0 }}>
                <Stack>
                  <Typography variant="h6">
                    {l['obp.discount-title']}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="body1">
                    {l['obp.discount-description']}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
        <Spacer spacing={3} />
        {categories.map((c) => (
          <Stack id={c.name} key={c.name} pb={3}>
            <Stack>
              <Stack>
                <Stack>
                  <Typography fontWeight="bold" variant="body1">
                    {c.name}
                  </Typography>
                </Stack>
                <Stack pb={3}>
                  <Typography variant="body1">
                    {c.description}
                  </Typography>
                </Stack>
                {isLowerThanLg ? (
                  <Carousel
                    containerClass="carousel-container-vendor"
                    partialVisible
                    arrows={false}
                    responsive={getCarouselResponsiveConfig(c.vendors.length)}
                  >
                    {c.vendors.map((v) => (
                      <Vendor
                        onClick={(url: string) => {
                          openQuestions(ctaUrl(url, v.name, c.name), c.name);
                        }}
                        key={v.name}
                        vendorData={{ ...v, category: c.name }}
                      />
                    ))}
                  </Carousel>
                ) : (
                  <Grid container spacing={3}>
                    {c.vendors.map((v) => (
                      <Vendor
                        onClick={(url: string) => {
                          openQuestions(ctaUrl(url, v.name, c.name), c.name);
                        }}
                        key={v.name}
                        vendorData={{ ...v, category: c.name }}
                      />
                    ))}
                  </Grid>
                )}
              </Stack>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </>

  );
};
