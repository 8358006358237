import { MouseEvent, useState } from 'react';

import { useAnalytics } from 'lib';
import { IoIosLink } from 'react-icons/io';
import {
  MdCheck,
} from 'react-icons/md';
import {
  Button, ButtonProps,
} from '@mui/material';

import { InvertedButton } from './styled';

export const ShareLinkButton = ({
  copyURL,
  buttonText,
  onCopy,
  ButtonComponent = Button,
  ...buttonProps
}: {
  copyURL: string,
  buttonText: string,
  ButtonComponent?: typeof Button | typeof InvertedButton
  onCopy: () => void,
} & ButtonProps) => {
  const analytics = useAnalytics();
  const [copied, setCopied] = useState(false);

  const handleClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    analytics.track('Button Clicked', {
      buttonName: 'Share Link',
      url: copyURL,
    });

    await navigator.clipboard.writeText(copyURL);

    onCopy();

    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  return (
    <ButtonComponent
      variant="outlined"
      size="small"
      startIcon={copied ? <MdCheck /> : <IoIosLink />}
      onClick={handleClick}
      {...buttonProps}
    >
      {buttonText}
    </ButtonComponent>
  );
};
