import { useMemo } from 'react';

import dayjs from 'dayjs';
import { ActiveUsersByMonth, sum } from 'lib';
import { useLabels } from 'ui';
import { useTheme } from '@mui/material';

import { BlurredComponent } from './BlurredComponent';
import { sortTrend } from './trend';
import { PMMetrics } from '../../../api/pm-metrics/types';
import { TrendChartCard } from '../TrendChart';

export const MonthlyActiveUsers = ({ metrics }: { metrics: PMMetrics }) => {
  const l = useLabels();
  const theme = useTheme();
  const datapointNum = 4;
  const active = useMemo(() => {
    const dp: Omit<ActiveUsersByMonth, '__typename'>[] = (
      (metrics.usersMetrics?.activeUsersByMonth ?? []).sort(sortTrend).slice(0, datapointNum)
    );

    while (dp.length > 0 && dp.length < datapointNum) {
      const first = dp[0];

      dp.unshift({
        year: first.month === 1 ? first.year - 1 : first.year,
        month: first.month === 1 ? 12 : first.month - 1,
        activeUsers: 0,
      });
    }

    return dp;
  }, []);
  const series = active.map((a) => a.activeUsers);
  // dayjs month is zero indexed
  const labels = active.map((a) => dayjs().month(a.month - 1).year(a.year).unix());

  return (
    <BlurredComponent
      isBlurred={sum(series) === 0}
      title={l['blanketOverview.chart.noData.title']}
      subtitle={l['blanketOverview.chart.noData.subtitle']}
    >
      <TrendChartCard
        title={l['blanketOverview.monthlyActiveUsers']}
        tooltip={l['blanketOverview.monthlyActiveUsers.tooltip']}
        series={[{
          name: l['blanketOverview.monthlyActiveUsers'],
          color: theme.palette.success.main,
          data: series,
        }]}
        unixLabels={labels}
      />
    </BlurredComponent>
  );
};
