import { lazy, Suspense } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useFeatures } from 'lib';
import {
  MenuItem,
  PriceTagIcon,
  Store,
  useLabels,
} from 'ui';

const MarketplaceIcon = lazy(async () => ({
  default: (await (import('ui'))).Marketplace,
}));

const menuIconSize = 22;

export const ProductMenuItems = ({ onNavigate = () => { } }: { onNavigate?: () => void }) => {
  const l = useLabels();
  const { isLoading: isLoadingFeatures, ...features } = useFeatures();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const handleNavigate = (path: string) => {
    onNavigate();
    navigate(path);
  };

  if (isLoadingFeatures) return null;

  return features.isMarketplaceEnabled && (
    <>
      <MenuItem
        label={l['listings.menu.marketplace']}
        isActive={pathname === '/marketplace'}
        onClick={() => handleNavigate('/marketplace')}
        icon={(
          <Suspense fallback={<Store />}>
            <MarketplaceIcon size={20} invert={pathname === '/marketplace'} hoverable />
          </Suspense>
        )}
      />
      {features.isMarketplaceLeadsViewEnabled && (
        <MenuItem
          label={l['listings.activitiesTitle']}
          isActive={pathname === '/marketplace/activities'}
          onClick={() => handleNavigate('/marketplace/activities')}
          icon={<PriceTagIcon size={menuIconSize} />}
        />
      )}
    </>
  );
};
