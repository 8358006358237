import {
  BoldTypography,
  InfoTooltip,
  useLabels,
} from 'ui';
import { Stack, Typography } from '@mui/material';

import { DetailRow } from './Row';
import { RowProps } from '../types';
import { useProjections } from '../useProjections';

export const Income = ({ form }: RowProps) => {
  const l = useLabels();
  const { years } = useProjections(form);

  return (
    <DetailRow
      rowID="income"
      bottomBorder={false}
      columns={[
        {
          id: 'income',
          content: (
            <Stack direction="row" gap={3}>
              <BoldTypography variant="body1">
                {l.income}
              </BoldTypography>
              <InfoTooltip
                title={(
                  <Typography variant="body2">
                    {l['tooltip.proforma.income']}
                  </Typography>
                )}
                isLight
                isOutlined
              />
            </Stack>
          ),
        },
        ...years.map(() => ({})),
      ]}
    />
  );
};
