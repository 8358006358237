import { useAnalytics } from 'lib';
import {
  BoldTypography, ControlledFormattedNumberField, formatNumberToCurrency,
  SemiBoldTypography,
} from 'ui';

import { DetailRow } from './Row';
import { editModeSignal, useGetPropertyIdParam } from '../state';
import {
  Form, PropertyProformaData, RowProps,
} from '../types';
import { useProjections } from '../useProjections';

type ExpenseProps = {
  form: Form,
  expenseName: keyof PropertyProformaData['expenses']
  value: number
  label: string
  showAsNegative?: boolean
};

export const Expense = ({
  form, value, label, expenseName, showAsNegative = false,
}: ExpenseProps) => {
  const analytics = useAnalytics();
  const propertyID = useGetPropertyIdParam();
  const metrics = form.watch(`${propertyID}.metrics`);
  const rent = form.watch(`${propertyID}.income.rent`);
  const editMode = editModeSignal.value;

  const { control } = form;

  if (editMode) {
    return (
      <ControlledFormattedNumberField
        control={control}
        name={`${propertyID}.expenses.${expenseName}`}
        label={label}
        prefix="$"
        size="small"
        decimalScale={0}
        useExternalValue
        externalValue={value || 0}
        onBlur={() => {
          if (expenseName in metrics) {
            const metricPercentage = (value / rent) * 100;

            form.setValue(`${propertyID}.metrics.${expenseName}`, metricPercentage);
          }

          analytics.track('Input Changed', {
            value,
            inputName: `Property Proforma Expense - ${label}`,
          });
        }}
      />
    );
  }

  return (
    <BoldTypography variant="body1">
      {showAsNegative && value !== 0 && '-'}
      {formatNumberToCurrency(value, 0)}
    </BoldTypography>
  );
};

export const ExpenseRow = ({
  form,
  label,
  expenseKey,
}: RowProps & {
  label: string,
  expenseKey: 'tax' | 'insurance' | 'hoa' | 'managementFee' | 'maintenance' | 'capEx'
}) => {
  const propertyID = useGetPropertyIdParam();
  const projections = useProjections(form);
  const projectionData = projections[expenseKey];
  const valuePath = `${propertyID}.expenses.${expenseKey}` as const;
  const value = form.watch(valuePath);

  return (
    <DetailRow
      rowID={expenseKey}
      columns={[
        {
          id: expenseKey,
          content: (
            <SemiBoldTypography variant="body1" sx={{ pl: 8 }}>
              {label}
            </SemiBoldTypography>
          ),
        },
        ...projections.years.map((year, i) => ({
          id: `${expenseKey}-year-${year}`,
          content: year === 1 ? (
            <Expense
              form={form}
              expenseName={expenseKey}
              value={value}
              label={label}
            />
          ) : (
            <BoldTypography variant="body1">
              {formatNumberToCurrency(projectionData[i], 0)}
            </BoldTypography>
          ),
        })),
      ]}
    />
  );
};
