import {
  ListMarketplacePropertiesQuery,
  ListOffersQuery,
  MarketplaceProperty,
  Offer,
  queryGraphQL, useAuth,
} from 'lib';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useInfiniteQuery } from '@tanstack/react-query';

import { convertActivities, convertOffer, convertProperty } from './converters';
import { Activity } from '../../types/activity';
import { QueryKey } from '../../types/enums';
import {
  listMarketplacePropertiesForActivities,
  listOffersForActivities,
} from '../graphql/queries';

export const useListMarketplacePropertyActivities = () => {
  const { user, getAccessTokenSilently } = useAuth();

  const query = useInfiniteQuery({
    enabled: !!user?.id,
    queryKey: [QueryKey.MARKETPLACE_PROPERTIES_FOR_ACTIVITIES],
    queryFn: async ({ pageParam: nextToken = '' }): Promise<{
      nextToken: string | null | undefined;
      activities: Activity[];
    }> => {
      const token = await getAccessTokenSilently();

      const res = await queryGraphQL({
        query: listMarketplacePropertiesForActivities,
        authToken: token,
        variables: { owner: user?.id, nextToken: nextToken || undefined },
      }) as GraphQLResult<ListMarketplacePropertiesQuery>;

      if (!res.data?.listMarketplaceProperties) {
        return { nextToken: null, activities: [] };
      }

      const filteredProperties = res.data.listMarketplaceProperties.items.filter(Boolean) as MarketplaceProperty[];

      const listings = filteredProperties.map(convertProperty);

      return {
        nextToken: res.data.listMarketplaceProperties?.nextToken,
        activities: convertActivities(listings),
      };
    },
    getNextPageParam: (lastPage) => lastPage.nextToken,
    onSuccess: (data) => {
      if (data.pages[data.pages.length - 1]?.nextToken) {
        query.fetchNextPage();
      }
    },
  });

  return query;
};

export const useListOfferActivities = () => {
  const { user, getAccessTokenSilently } = useAuth();

  const query = useInfiniteQuery({
    enabled: !!user?.id,
    queryKey: [QueryKey.OFFERS_FOR_ACTIVITIES],
    queryFn: async ({ pageParam: nextToken = '' }): Promise<{
      nextToken: string | null | undefined;
      activities: Activity[];
    }> => {
      const token = await getAccessTokenSilently();

      const res = await queryGraphQL({
        query: listOffersForActivities,
        authToken: token,
        variables: { owner: user?.id, nextToken: nextToken || undefined },
      }) as GraphQLResult<ListOffersQuery>;

      if (!res.data?.listOffers) {
        return { nextToken: null, activities: [] };
      }

      const filteredOffers = res.data.listOffers.items.filter(Boolean) as Offer[];

      const listings = filteredOffers.map(convertOffer);

      return {
        nextToken: res.data.listOffers?.nextToken,
        activities: convertActivities(listings),
      };
    },
    getNextPageParam: (lastPage) => lastPage.nextToken,
    onSuccess: (data) => {
      if (data.pages[data.pages.length - 1]?.nextToken) {
        query.fetchNextPage();
      }
    },
  });

  return query;
};
