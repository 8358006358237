import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppSettings, useAuth, useFeatures } from 'lib';
import { HeaderLogoContainer, PorticoLogo } from 'ui';
import { Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Hamburger } from './Hamburger';
import { MobileMenu } from './MobileMenu';
import { redirectToRelativeHomepage } from '../../../lib/redirect';

export const MobileHeader = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const features = useFeatures();
  const { getIdTokenClaims, user } = useAuth();
  const { data: appSettings, isLoading } = useAppSettings();
  const [menuOpen, setMenuOpen] = useState(false);

  const onClickLogo = useCallback(() => {
    getIdTokenClaims().then((claims) => {
      redirectToRelativeHomepage(navigate, claims, user?.pm ?? '', features);
    });
  }, [features, getIdTokenClaims, user?.pm]);

  if (!appSettings || isLoading) return null;

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        component="header"
        sx={{
          backgroundColor: theme.palette.background.paper,
          height: '60px important',
          p: 4,
          zIndex: 1200,
          position: 'relative',
        }}
      >
        {appSettings.logo ? (
          <HeaderLogoContainer onClick={onClickLogo} style={{ backgroundImage: `url(${appSettings.logo})` }} />
        ) : (
          <HeaderLogoContainer onClick={onClickLogo}>
            <PorticoLogo height="24" title="Portico" />
          </HeaderLogoContainer>
        )}
        <Hamburger open={menuOpen} onClick={() => setMenuOpen(true)} />
      </Stack>
      <MobileMenu open={menuOpen} onClose={() => setMenuOpen(false)} />
    </>
  );
};
