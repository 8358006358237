import {
  useEffect,
  useState,
} from 'react';

import {
  Question, shouldSkipQuestion, SubmissionRequest, useAnalytics, useAuth, useCreateSubmission, useGetQuestions,
} from 'lib';
import { toast } from 'react-toastify';
import {
  Calendly, Progress, QuestionControlRadio, QuestionNumber,
  QuestionText, SuccessDialog, useLabels,
} from 'ui';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Fade,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { FooterActions } from './styled';

export type ObpFormCategory = {
  formId: string,
  categoryName: string,
  openCalendly: (questions: Question[]) => boolean
};

export const DialogOPBQuestionnaire = ({
  open, closeDialog, cancelDialog,
  obpFormCategory,
}: {
  open: boolean,
  closeDialog: () => void,
  cancelDialog: () => void,
  obpFormCategory: ObpFormCategory | undefined,
}) => {
  const { user } = useAuth();

  const {
    data: questions,
  } = useGetQuestions({
    formID: obpFormCategory?.formId ?? '',
    ownerEmail: user?.email ?? '',
    enabled: true,
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [questionSlide, setQuestionSlide] = useState<number>(0);
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [displayView, setDisplayView] = useState<'questions' | 'sell' | 'calendly'>('questions');

  const [skippedQuestions, setSkippedQuestions] = useState<string[]>([]);

  const createSubmission = useCreateSubmission(obpFormCategory?.formId ?? '');
  const l = useLabels();
  const analytics = useAnalytics();

  const [questionsForm, setQuestionsForm] = useState<{ [key: string]: Question }>((questions ?? []).reduce((acc, q) => ({
    ...acc,
    [q.id]: q,
  }), {} as { [key: string]: Question }));

  useEffect(() => {
    setQuestionsForm((questions ?? []).reduce((acc, q) => ({
      ...acc,
      [q.id]: q,
    }), {} as { [key: string]: Question }));
  }, [questions]);

  const orderFormValues = Object.values(questionsForm)
    .sort((a, b) => Number(a.order) - Number(b.order));

  const orderFormKeys = Object.keys(questionsForm)
    .sort((a, b) => Number(questionsForm[a].order)
      - Number(questionsForm[b].order));

  const closeQuestionnaireDialog = () => {
    setDisplayView('questions');
    setQuestionSlide(0);
    setSuccessPopupOpen(true);
    closeDialog();
    setSkippedQuestions([]);
  };

  const onFormSubmitted = () => {
    if (obpFormCategory?.openCalendly(orderFormValues)) {
      setDisplayView('calendly');
      return;
    }
    closeQuestionnaireDialog();
  };

  const submitForm = async () => {
    const requestData = Object.keys(questionsForm).map((key): SubmissionRequest => ({
      id: key,
      answer: questionsForm[key].answer,
    }));

    analytics.track('Form Submitted', {
      formName: obpFormCategory?.categoryName ?? '',
      ...requestData.reduce((acc, q) => ({
        ...acc,
        [q.id]: q.answer,
      }), {}),
    });

    onFormSubmitted();

    try {
      await createSubmission.mutateAsync(requestData);
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
    }
  };

  const handleLastQuestionIsNext = () => {
    const question = orderFormValues[(questions ?? []).length - 1];
    const skip = shouldSkipQuestion(question.id, questionsForm);
    if (skip) {
      submitForm();
    }
  };

  const handleNext = (idx: number) => () => {
    if (!questions || questions.length === 0) {
      return;
    }

    let nextQuestionIndex = idx;

    let skip = nextQuestionIndex !== (questions!.length - 1);

    const skippedQuestionsIds = skippedQuestions;

    while (skip) {
      nextQuestionIndex += 1;
      if (nextQuestionIndex === (questions!.length - 1)) {
        skip = false;
        break;
      }
      const question = orderFormValues[nextQuestionIndex];
      skip = shouldSkipQuestion(question.id, questionsForm, skippedQuestionsIds);
      if (skip) {
        skippedQuestionsIds.push(question.id);
      }
    }

    setSkippedQuestions(skippedQuestionsIds);
    setQuestionSlide(nextQuestionIndex);

    if (nextQuestionIndex === ((questions ?? []).length - 1)) {
      handleLastQuestionIsNext();
    }
  };

  const updateAnswer = (selectedId: string, answer: string) => {
    setQuestionsForm((currentQuestions) => ({
      ...currentQuestions,
      [selectedId]: {
        ...currentQuestions[selectedId],
        answer,
      },
    }));
  };

  return (
    <>
      <Dialog
        fullScreen={isMobile}
        open={open}
        maxWidth="sm"
        sx={{ zIndex: 1600 }}
      >
        <DialogContent sx={{ p: 0 }}>

          {displayView === 'questions' && (
            <Box
              component="form"
              height={isMobile ? '100%' : undefined}
            >
              {(
                questions && questions.length > 1) ? (
                  <Progress
                    title={obpFormCategory?.categoryName ?? ''}
                    show
                    cancel={() => {
                      setQuestionSlide(0);
                      setSkippedQuestions([]);
                      cancelDialog();
                      analytics.track('Button Clicked', {
                        buttonName: `${obpFormCategory?.categoryName} - Cancel`,
                      });
                    }}
                    amount={questionSlide + 1}
                    total={[...Object.keys(questionsForm)].length}
                  />
                ) : null}
              <Box
                position="relative"
                flexGrow="1"
                minHeight={isMobile ? '100%' : '400px'}
                width={isMobile ? undefined : theme.breakpoints.values.sm}
              >
                {orderFormKeys
                  .map((id, idx) => (
                    <Fade
                      key={`${id}-slide`}
                      in={idx === questionSlide}
                      timeout={600}
                    >
                      <Box position="absolute" top="0" left="0" height="100%" width="100%">
                        {questionsForm[id].type === 'control_radio' && (
                          <QuestionControlRadio
                            onChange={updateAnswer}
                            id={id}
                            question={questionsForm[id]}
                          />
                        )}
                        {questionsForm[id].type === 'control_textbox' && (
                          <QuestionText
                            onChange={updateAnswer}
                            id={id}
                            question={questionsForm[id]}
                          />
                        )}
                        {questionsForm[id].type === 'control_number' && (
                          <QuestionNumber
                            onChange={updateAnswer}
                            id={id}
                            question={questionsForm[id]}
                          />
                        )}
                      </Box>
                    </Fade>
                  ))}
              </Box>
              <FooterActions className="sticky">
                <Button
                  type="button"
                  variant="text"
                  onClick={() => {
                    setQuestionSlide(0);
                    setSkippedQuestions([]);
                    cancelDialog();
                  }}
                  sx={{
                    borderColor: theme.palette.grey[500],
                    color: theme.palette.text.primary,
                  }}
                >
                  {l.cancel}
                </Button>
                <Button
                  variant="contained"
                  type="button"
                  data-testid="click-next-buy-box-button"
                  sx={{
                    borderColor: theme.palette.grey[500],
                    display: (questionSlide !== (questions ?? []).length - 1) ? 'block' : 'none',
                  }}
                  onClick={handleNext(questionSlide)}
                >
                  {l.next}
                </Button>
                <Button
                  variant="contained"
                  type="button"
                  sx={{
                    borderColor: theme.palette.grey[500],
                    display: (questionSlide === (questions ?? []).length - 1) ? 'block' : 'none',
                  }}
                  onClick={submitForm}
                >
                  {l.submit}
                </Button>

              </FooterActions>
            </Box>
          )}

          {displayView === 'calendly' && (
            <Calendly
              title={obpFormCategory?.categoryName ?? ''}
              onClose={closeQuestionnaireDialog}
            />
          )}

        </DialogContent>
      </Dialog>

      <SuccessDialog
        open={successPopupOpen}
        title={l.excitedSuccess}
        description={l['obp.successFormMessage']}
        btnText={l.gotIt}
        closeDialog={() => setSuccessPopupOpen(false)}
      />

    </>
  );
};
