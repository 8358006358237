import { useState } from 'react';

import dayjs from 'dayjs';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { MdClose } from 'react-icons/md';
import {
  Box,
  DialogContent,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import {
  Spinner,
} from '../spinner';

export const Calendly = (
  { onClose, title }:
  { onClose: () => void, title: string },
) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [calendlyLoading, setCalendlyLoading] = useState<boolean>(true);

  useCalendlyEventListener({
    onEventTypeViewed: () => { setCalendlyLoading(false); },
    onEventScheduled: () => onClose(),
  });

  return (
    <DialogContent sx={{ p: 0 }}>
      <Box
        position="relative"
        flexGrow="1"
        minHeight={isMobile ? '100%' : '400px'}
        width={isMobile ? undefined : theme.breakpoints.values.sm}
      >
        <Stack position="sticky" top="0" sx={{ background: theme.palette.background.paper, zIndex: 1600 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ p: 3, backgroundColor: theme.palette.common.white }}
          >
            <Typography variant="h5">
              {title}
            </Typography>
            <IconButton onClick={() => {
              onClose();
            }}
            >
              <MdClose />
            </IconButton>
          </Stack>
        </Stack>

        {calendlyLoading && (
          <Spinner size={48} />
        )}

        <InlineWidget
          pageSettings={{
            hideEventTypeDetails: true,
            hideGdprBanner: true,
            hideLandingPageDetails: true,
          }}
          styles={{
            width: isMobile ? undefined : theme.breakpoints.values.sm,
            height: isMobile ? '700px' : '500px',
          }}
          url={`https://calendly.com/jeff-grice/blanket-strategy-session?month=${dayjs().format('MM-YYYY')}`}
        />
      </Box>
    </DialogContent>
  );
};
