import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Question, SellableProperty, useAnalytics, useCreateExternalMarketplaceProperty,
  useCreateMarketplaceProperty,
} from 'lib';
import { toast } from 'react-toastify';
import {
  QuestionControlRadio,
  SellProperty,
  SellSelector,
  useLabels,
} from 'ui';
import {
  Box,
  Button,
  Dialog, DialogContent, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import Stack from '@mui/material/Stack';

import { FooterActions } from './styled';

export const DialogCashOut = ({
  open, closeDialog, propertyIDs, sellableProperties,
}:
{ open: boolean, closeDialog: () => void, propertyIDs: string[], sellableProperties: SellableProperty[] }) => {
  const l = useLabels();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [sellProperty, setSellProperty] = useState<SellProperty>();
  const analytics = useAnalytics();
  const createMarketplacePropertyMutation = useCreateMarketplaceProperty();
  const createExternalListingMutation = useCreateExternalMarketplaceProperty();

  const navigate = useNavigate();
  const [questionAnswer, setQuestionAnswer] = useState('');
  const [viewState, setViewState] = useState('view-question');

  const handleSaveSellProperty = async () => {
    const originalPrice = sellableProperties.find((property) => property.id === sellProperty?.id)?.prices?.priceMean || '';

    analytics.track('Form Submitted', {
      formName: 'Cash out',
      propertyId: sellProperty?.id,
      price: sellProperty?.price,
      originalPrice,
      idkPrice: sellProperty?.hasPriceInMind,
    });

    if (!sellProperty?.address && !sellProperty?.isOtherOptionSelected) return;
    try {
      if (sellProperty.isOtherOptionSelected) {
        await createExternalListingMutation.mutateAsync({
          fullAddress: sellProperty.externalPropertyAddress,
          askingPrice: !sellProperty.price || !sellProperty.hasPriceInMind ? 0 : sellProperty.price,
        });
      } else if (sellProperty.address) {
        await createMarketplacePropertyMutation.mutateAsync({
          propertyId: sellProperty.id,
          address: sellProperty.address,
          askingPrice: !sellProperty.price || !sellProperty.hasPriceInMind ? 0 : sellProperty.price,
          category: 'unknown',
        });
      }
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
    }
  };

  useEffect(() => () => {
    setViewState('view-question');
  }, []);

  const sell = l['cashOut.answer.sell'];
  const refinanceCashOut = l['cashOut.answer.refinanceCashOut'];
  const exchange1031 = l['cashOut.answer.exchange1031'];

  const question: Question = {
    id: '1',
    name: l['cashOut.question.name'],
    description: '',
    answer: '',
    options: [sell, refinanceCashOut, exchange1031],
    type: 'control_radio',
    order: '1',
    conditions: [],
  };

  const updateAnalytics = () => {
    analytics.track('Question Answered', {
      formName: 'cash out',
      questionText: question.name,
      questionID: question.id,
      answer: questionAnswer,
    });
  };

  const save = () => {
    if (viewState === 'view-sell-selector') {
      handleSaveSellProperty();
      setViewState('view-question');
      closeDialog();
      return;
    }

    if (viewState === 'view-refinance') {
      navigate('/owner-benefits?category=Financing');
      closeDialog();
      return;
    }

    if (viewState === 'view-1031-exchange') {
      navigate('/owner-benefits?category=1031 Exchange&vendor=Plenti');
      closeDialog();
      return;
    }

    if (questionAnswer === sell) {
      updateAnalytics();
      setViewState('view-sell-selector');
    }

    if (questionAnswer === refinanceCashOut) {
      updateAnalytics();
      setViewState('view-refinance');
    }

    if (questionAnswer === exchange1031) {
      navigate('/owner-benefits?category=1031 Exchange&vendor=Plenti');
      closeDialog();
    }
  };

  return (
    <Dialog
      fullScreen={isMobile}
      open={open}
      maxWidth="sm"
      sx={{ zIndex: 1600 }}
    >
      <DialogContent sx={{ p: 0, height: '100%' }}>
        <Box
          position="relative"
          flexGrow="1"
          height="100%"
          width={isMobile ? undefined : theme.breakpoints.values.sm}
        >
          <Box height="100%" width="100%">
            {viewState === 'view-question' && (
              <QuestionControlRadio
                onChange={(id: string, answer: string) => setQuestionAnswer(answer)}
                id={question.id}
                question={question}
              />
            )}
            {viewState === 'view-sell-selector' && (
              <SellSelector
                onSellPropertyChanged={(sp: SellProperty) => setSellProperty(sp)}
                sellableProperties={sellableProperties.filter((sp) => !propertyIDs.includes(sp.id))}
              />
            )}
            {viewState === 'view-refinance' && (
              <Stack
                height="100%"
                width="100%"
                justifyContent="center"
                alignItems="center"
                gap={2}
                p={20}
              >
                <Typography variant="h5">
                  {l['cashOut.answer.chooseYourLender']}
                </Typography>
                <Typography variant="body2" sx={{ textAlign: 'center' }}>
                  {l['cashOut.answer.chooseYourLender.description']}
                </Typography>
              </Stack>
            )}
            {viewState === 'view-1031-exchange' && (
              <Box
                height="100%"
                width="100%"
                sx={{
                  justifyContent: 'center',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h5">
                  {l['cashOut.answer.pickYourExchange1031']}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <FooterActions className="sticky">
          <Button
            type="button"
            variant="text"
            sx={{
              borderColor: theme.palette.grey[500],
              color: theme.palette.text.primary,
            }}
            onClick={() => {
              closeDialog();
              setViewState('view-question');
            }}
          >
            {l.cancel}
          </Button>
          <Button
            variant="contained"
            type="button"
            data-testid="click-save-cash-out-button"
            onClick={save}
            sx={{
              borderColor: theme.palette.grey[500],
            }}
          >
            {viewState === 'view-sell-selector' ? l.save : l.next}
          </Button>
        </FooterActions>
      </DialogContent>
    </Dialog>
  );
};
