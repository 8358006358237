import {
  useMediaQuery,
  useTheme,
} from '@mui/material';

export const TermsAndConditions = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  /* eslint-disable */
  return (
    <div style={{
      width: isMobile ? '90%' : '600px', margin: 'auto', paddingTop: '10px', paddingBottom: '100px',
    }}
    >
      <p><strong>Terms of Service</strong></p>
      <p>Welcome to the Blanket Platform, a mobile and web-based owner platform for real estate property owners and buyers (the <strong>“Platform”</strong>). The Platform is owned and operated by Blanket Partners Inc. (the “<strong>Company</strong>”, &quot;<strong>we</strong>&quot; and &quot;<strong>our</strong>&quot;). </p>
      <p>Please read the following terms and conditions (the &quot;<strong>Terms</strong>&quot;). The Terms constitute a binding agreement between you and the Company. By accessing the Platform or by using it in any other manner, you indicate your acceptance of these Terms. If you do not agree to these Terms of Service, you may not access the Platform or use it in any manner. </p>
      <p>If you are an individual entering into these Terms on behalf of a legal entity, you hereby represent and warrant that you are authorized to enter into these Terms on behalf of such entity and bind such entity to these Terms. </p>
      <p>THESE TERMS REQUIRE BINDING ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS, AND INCLUDE A CLASS ACTION WAIVER. THESE TERMS LIMIT THE REMEDIES THAT MAY BE AVAILABLE TO YOU IN THE EVENT OF A DISPUTE.</p>
      <ol>
      <li><strong>About the Platform</strong></li>
      </ol>
      <p>You may use the Platform as either an “<strong>Owner</strong>” or a “<strong>Buyer</strong>”. </p>
      <p>As an Owner, you can (i) offer real estate properties you own (“<strong>Properties</strong>”) for sale on the Platform, (ii) track and manage sales transactions with Buyers related to your Properties (“<strong>Sales Transactions</strong>”), and (iii) track and manage your Properties and related income, expenses and other financial information (“<strong>Financial Data</strong>”). </p>
      <p>As a Buyer, you may (i) submit offers for the purchase of Properties offered by Owners on the Platform (“<strong>Offers</strong>”), and (ii) track and manage any related Sales Transactions with Owners.   </p>
      <ol start={2}>
      <li><strong>Account Registration</strong></li>
      </ol>
      <p>Use of the Platform requires you to register and open an account on the Platform as either an Owner or a Buyer (<strong>“User Account”</strong>). When opening an account with us, you will be required to provide us with information such as your full name and email address. Depending on your User Account type, different features and services will be available to you on the Platform.</p>
      <p>You must submit only true, accurate and complete details. Bear in mind that false, incorrect or outdated information may prevent you from registering to the Platform and impair our ability to provide you with our Platform and to contact you when necessary.</p>
      <p>You are fully accountable for any outcome that may result from your failure to provide true, accurate and complete details when you sign-up to the Platform. You are solely responsible and liable for all activities performed with or through your User Account.</p>
      <p>We reserve the right to request additional information to verify your identity, during the registration process, throughout your use of the Platform, or when you submit requests related to your User Account on the Platform. If you fail to provide us the requested information, we reserve the right to suspend or terminate your User Account, pursuant to these Terms.</p>
      <ol start={3}>
      <li><strong>Use of the Platform as an Owner</strong></li>
      </ol>
      <p>If you register to the Platform as an Owner, the following terms and conditions will apply:</p>
      <p>Listing Properties</p>
      <p>You are solely responsible for the Properties you offer for sale and list through the Platform. You may only list Properties (i) that you solely own and have title to, and (ii) for the sole purpose of selling such Properties for your own account or on behalf of the company or other legal entity on behalf of which you are using the Platform.  </p>
      <p>To accept your Property listing, we may require that you provide us with certain additional information, materials and documentation. You may remove a listing of a Property from the Platform by contacting us or using the removal options available on the Platform. </p>
      <p>You hereby represent and warrant that (i) you solely own and have title to any Property you list on the Platform; (ii) you have all rights, licenses, consents, permissions, powers and/or authorities necessary to offer for sale and sell any Properties you list on the Platform; (iii) your offering for sale and/or sale of any Property through the Platform in accordance with the terms hereof does not and will not conflict with or result in any breach of any instrument or agreement to which you are a party; (iv) you are offering for sale and selling any Property you list on the Platform solely for your own account or on behalf of the company or other legal entity on behalf of which you are using the Platform; (v) all content, materials and information you submit or otherwise provide Company regarding any Properties you list on the Platform (“<strong>Listing Information</strong>”) are truthful and accurate and you will maintain the accuracy of such Listing Information; and (vi) all Listing Information (a) complies with all applicable laws and regulations; and (b) does not infringe, misappropriate or violate a third party’s intellectual property rights, rights of publicity or privacy or other third-party rights.   </p>
      <p>Company may accept or reject any particular Property listing in its sole discretion. Any Property listing and/or Listing Information that Company, at its sole discretion, deems inappropriate, offensive, disruptive, or illegal are prohibited on the Platform and may be removed or delisted without notice. If you offer any Property in violation of these Terms, we reserve the right to take corrective actions, as appropriate, including but not limited to removing the Property from the Platform, deleting your Listing Information and/or deleting your User Account from the Platform. </p>
      <p>Sales Transactions</p>
      <p>In the event that you accept an Offer from a Buyer, the sale and purchase of the applicable Property shall be subject to the entering into of a separate purchase agreement between you and the applicable Buyer, upon such terms mutually agreed upon between you and the Buyer in each party’s sole and independent discretion (“<strong>Purchase Agreement</strong>”). </p>
      <p>The Platform may provide you with a sample purchase agreement for the applicable Sales Transaction (“<strong>Sample Agreement</strong>”). </p>
      <p>Owner Output</p>
      <p>Based on your Properties and Financial Data, the Platform may also provide you with evaluations, valuations, analyses and recommendations with regards to your Properties.</p>
      <ol start={4}>
      <li><strong>Use of the Platform as a Buyer</strong></li>
      </ol>
      <p>If you register to the Platform as a Buyer, the following terms and conditions will apply:</p>
      <p>Submitting an Offer</p>
      <p>You are solely responsible for any Offers you submit through the Platform. You may only submit an offer for the sole purpose of purchasing the applicable Property for your own account or on behalf of the company or other legal entity on behalf of which you are using the Platform. </p>
      <p>To submit an Offer, we may also require that you provide us with certain additional information, materials and documentation. </p>
      <p>You hereby represent and warrant that (i) you have all rights, licenses, consents, permissions, powers and/or authorities necessary to submit an Offer and/or enter into and perform any Sales Transaction through the Platform; (ii) your submission of an Offer and/or entering  into and performance of any Sales Transaction through the Platform in accordance with the terms hereof does not and will not conflict with or result in any breach of any instrument or agreement to which you are a party; (iii) all Offers you submit have been submitted for the sole purpose of purchasing the applicable Property for your own account or on behalf of the company or other legal entity on behalf of which you are using the Platform; (iv) all content, materials and information you submit or otherwise provide Company regarding any Offers you submit and/or Sales Transaction (“<strong>Buyer Information</strong>”) are truthful and accurate and you will maintain the accuracy of such Buyer Information; and (v) all Buyer Information (a) complies with all applicable laws and regulations; and (b) does not infringe, misappropriate or violate a third party’s intellectual property rights, rights of publicity or privacy or other third-party rights.</p>
      <p>Sales Transactions</p>
      <p>In the event that an Owner accepts your Offer to purchase a Property on the Platform, the sale and purchase of the applicable Property shall be subject to the entering into of a separate purchase agreement between you and the applicable Owner, upon such terms mutually agreed upon between you and the Owner in each party’s sole and independent discretion (“<strong>Purchase Agreement</strong>”). </p>
      <p>The Platform may provide you with a sample purchase agreement for the applicable Sales Transaction (“<strong>Sample Agreement</strong>”). </p>
      <ol start={5}>
      <li><strong>Third Party Services</strong></li>
      </ol>
      <p>The Platform may integrate or interoperate with, or import or export data to or from, certain third-party products and services (“<strong>Integrations</strong>” and “<strong>Third-Party Products</strong>,” respectively). The Company is not responsible for Third-Party Products, and any use by you of Third-Party Products, and any exchange of data between you and any Third-Party Product is solely between you and the provider of the applicable Third-Party Product. If you opt to use any Integrations, including but not limited to with respect to the exchange of data between the Platform and Third-Party Products, you agree to: (i) assume sole responsibility for and all risk arising from your use of Integrations and the content, functionality, or availability of any Third-Party Products, including waiving and releasing the Company from any claims directly or indirectly related thereto; and (ii) without limitation, defend, indemnify, and hold harmless the Company from any third party claims directly or indirectly arising from or related to your use of any Integrations. THE COMPANY PROVIDES INTEGRATIONS “AS IS” WITHOUT WARRANTY OF ANY KIND AND ONLY AS A CONVENIENCE.</p>
      <ol start={6}>
      <li><strong>Content</strong></li>
      </ol>
      <p>Certain types of data, information, reports, analyses, texts, images and other content may be made available through the Platform (“<strong>Content</strong>”). The Company also allows you to upload and import certain documents, text, data and other materials on or through the Platform, including, without limitation, Listing Information, Buyer Information and Financial Data (“<strong>User Content</strong>”). </p>
      <p>The Company has no obligation to accept, display, or maintain any User Content. The Company reserves the right to remove and permanently delete any User Content uploaded by you, without notice and for any reason. You are and shall remain at all times fully and solely responsible for any User Content that you upload to the Platform. You represent and warrant that any User Content that you upload (i) complies with applicable law; (ii) does not infringe or violate any third-party intellectual property or other rights; and (iii) that you have all necessary rights and authorities to submit such User Content.</p>
      <p>User Content that you provide through the Platform may be made available to the applicable property manager or other entity in connection with you are accessing the Platform (&quot;<strong>Customer</strong>&quot;). By uploading or otherwise submitting any User Content, you grant the Customer a non-exclusive license to access your User Content through the Platform, and to use, reproduce, distribute, display and perform such User Content as permitted through the functionality of the Platform. </p>
      <p>The Company may, at its sole discretion, choose to monitor User Content for inappropriate or illegal behavior, including through automatic means, provided however, that the Company reserves the right to treat User Content as content stored at the direction of users for which the Company will not exercise editorial control except when violations are directly brought to the Company’s attention.</p>
      <p>Content on the Platform comes from a variety of sources. The Company is not responsible for the accuracy, usefulness, safety, appropriateness of, or infringement of any intellectual property rights of or relating to this Content (including but not limited to the User Content).</p>
      <ol start={7}>
      <li><strong>Acceptable Use of the Platform</strong></li>
      </ol>
      <p>Subject to these Terms, you may use the Platform and any Content on the Platform, only for your personal or internal business purposes.</p>
      <p>While using the Platform, you must refrain from:</p>
      <ul>
      <li><p>breaching the Terms or any other applicable rules and instructions that we may convey with respect to the use of the Platform; </p>
      </li>
      <li><p>engaging in any activity that constitutes or encourages conduct that would constitute a criminal offense, give rise to civil liability or otherwise violate any applicable law;</p>
      </li>
      <li><p>interfering with, burdening or disrupting the functionality of the Platform;</p>
      </li>
      <li><p>breaching the security of the Platform or identifying any security vulnerabilities in it;</p>
      </li>
      <li><p>copying, reverse engineering, modifying or creating derivative works of the Platform; </p>
      </li>
      <li><p>circumventing or manipulating the operation or functionality of the Platform, or attempting to enable features or functionalities that are otherwise disabled, inaccessible or undocumented in the Platform;</p>
      </li>
      <li><p>using or launching any automated system, including without limitation robots, crawlers and similar applications to collect and compile content from the Platform; </p>
      </li>
      <li><p>displaying the Platform or any part thereof in an exposed or concealed frame, or linking to elements or portions of the Platform, independently from the manner on which they originally appear or are made available through the Platform;</p>
      </li>
      <li><p>impersonating any person or entity, or making any false statement pertaining to your identity, employment, agency or affiliation with any person or entity; </p>
      </li>
      <li><p>collecting, harvesting, obtaining or processing personal information of or about other users of the Platform;</p>
      </li>
      <li><p>accessing or using the Platform in order to develop or create a similar or competitive Platform;</p>
      </li>
      <li><p>transmitting, submitting or uploading any User Content or other materials to the Platform that: (a) stores, contains or transmits anything that contains any virus, worm, trojan horse, or other harmful or disruptive component; (b) violates the legal rights of others; (c) infringes (or results in the infringement of) the intellectual property rights, moral rights, publicity, privacy, or other rights of any third party; or (d) is (or you reasonably believe or should reasonably believe to be) stolen, illegal, counterfeit, fraudulent, pirated, violent or unauthorized. </p>
      </li>
      <li><p>obtaining or attempting to obtain any materials or information through any means not intentionally made available or provided for through the Platform;</p>
      </li>
      <li><p>violating any applicable laws or regulations.</p>
      </li>
      </ul>
      <p><strong>WE MAY EMPLOY TECHNOLOGICAL MEASURES TO DETECT AND PREVENT FRAUDULENT OR ABUSIVE USE OF THE PLATFORM. WE MAY SUSPEND OR TERMINATE YOUR USER ACCOUNT, OR BLOCK YOUR ACCESS TO AND USE OF THE PLATFORM, WITHOUT PRIOR NOTICE AND AT OUR SOLE DISCRETION, IF WE BELIEVE THAT YOU ARE USING THE PLATFORM FRAUDULENTLY OR ABUSIVELY.</strong></p>
      <ol start={8}>
      <li><strong>User Account Termination</strong></li>
      </ol>
      <p>You may, at any time terminate your User Account by providing us written notice of termination by sending an email to: <a href="mailto:&#115;&#x75;&#x70;&#x70;&#x6f;&#114;&#116;&#x40;&#x62;&#x6c;&#x61;&#110;&#x6b;&#101;&#x74;&#104;&#111;&#109;&#101;&#x73;&#x2e;&#99;&#x6f;&#109;">&#115;&#x75;&#x70;&#x70;&#x6f;&#114;&#116;&#x40;&#x62;&#x6c;&#x61;&#110;&#x6b;&#101;&#x74;&#104;&#111;&#109;&#101;&#x73;&#x2e;&#99;&#x6f;&#109;</a>.    </p>
      <p>You agree that the Company may for any reasonable reason, at its sole discretion and without notice, terminate your access to your User Account  for any reason. Grounds for such termination may include (i) violation of the letter or spirit of these Terms, (ii) fraudulent, harassing or abusive behavior, and (iii) behavior that is harmful to other users or the business interests of the Company. </p>
      <p>Upon termination of these Terms or your User Account, for any reason - </p>
      <ul>
      <li>Your right to use the Platform is terminated and you must immediately cease using the Platform;   </li>
      <li>We reserve the right (but have no obligation) to delete all of your User Content and other information and account data stored on our servers; and  </li>
      <li>We will not be liable to you or any third party for termination of access to the Platform or for deletion of your User Content or other information or account data.</li>
      </ul>
      <p>Any suspension, cancellation or termination of your User Account shall not affect your obligations under these Terms, which by their sense and context are intended to survive such suspension, cancellation, or termination.</p>
      <ol start={9}>
      <li><strong>Links</strong></li>
      </ol>
      <p>The Platform may contain links to ads and other content published by third parties or on other websites provided by third parties. We do not operate or monitor these websites and content. By linking to a certain website or third-party content, we do not endorse, or sponsor such websites or content, or confirm its accuracy, credibility, authenticity, reliability, validity, integrity, or legality. We assume no responsibility or liability for such third-party websites or content, or their availability, or for any transactions or dealings made between you and such third-parties. </p>
      <ol start={10}>
      <li><strong>Privacy</strong></li>
      </ol>
      <p>We respect your privacy. The Platform’s privacy policy is available here <a href="https://portico.blankethomes.com/privacy-policy">https://portico.blankethomes.com/privacy-policy</a> and is hereby incorporated by reference to and forms an integral part of these Terms.  </p>
      <ol start={11}>
      <li><strong>Intellectual Property</strong></li>
      </ol>
      <p>Subject to these Terms, the Company grants you a limited, non-exclusive, non-transferable, revocable license to use and access the Platform for your personal use only. Except as set forth in these Terms and the limited license granted herein, the Company and its licensors own all rights, title and interest in and to the Platform, including patents, copyrights, trademarks, trade names, service marks, trade secrets and other intellectual property rights, and any goodwill associated therewith.</p>
      <p>As between you and the Company, you have all right, title and interest in the User Content you submit. By submitting any User Content, you grant the Company non-exclusive license to use, store, process, analyze, and display in connection with the Platform, all User Content  (i) for purposes of providing the Platform to you and performing the Company’s obligations under these Terms, and (ii) for Company&#39;s internal business purposes, including enhancements to the Platform and to analyze, improve, support and operate the Platform. </p>
      <p>You may provide us feedback regarding the Platform, its operation and its functionality (“<strong>Feedback</strong>”). Any Feedback you provide us will be owned exclusively by us and we may use it for any commercial purpose we deem appropriate. You will not be entitled to any remuneration from us for our use of Feedback that you provide.</p>
      <p>Trademarks featured on the Platform (whether registered or not) are the sole property of the Company and/or its third-party licensors. You may not use them without our prior written consent.</p>
      <ol start={12}>
      <li><strong>Confidentiality</strong></li>
      </ol>
      <p>The Platform consists of our trade secrets, which include practices, processes and designs, not generally known or reasonably ascertainable by others by which we obtain an economic advantage over others. You must maintain in confidence information you are exposed to through your use of the Platform relating to its operation, functionality, features, designs and business practices. You must use reasonable care to maintain the information about the Platform. You must not use or disclose that information about the Platform. </p>
      <ol start={13}>
      <li><strong>Changes and Availability</strong></li>
      </ol>
      <p>From time to time, we may change the Platform&#39;s structure, layout, design or display, as well as the scope and availability of the information and content therein, without prior notice. Changes of this type by their<br />very nature may result in glitches or cause inconvenience of some kind. </p>
      <p>We may, but are not obligated to, maintain the Platform with periodic releases of bug fixes, code updates or upgrades. We will determine, at our discretion, the frequency and scope of such releases and you will have no plea, claim or demand against us or our directors, officers, employees, agents, advisors, consultants, subcontractors and assignees (collectively, our “<strong>Representatives</strong>”), for any of these releases or the lack thereof. </p>
      <p>You grant us your express consent to remotely send and automatically install on your mobile device, without any prior or additional notice, updates, upgrades, code modifications, enhancements, bug fixes, improvements and any other form of code or settings changes in or to the Platform, which, among other things, may change its settings, layout, design or display.</p>
      <p>YOU AGREE AND ACKNOWLEDGE THAT WE DO NOT ASSUME ANY RESPONSIBILITY WITH RESPECT TO, OR IN CONNECTION WITH THE INTRODUCTION OF SUCH CHANGES OR FROM ANY MALFUNCTIONS OR FAILURES THAT MAY RESULT THEREFROM.</p>
      <p>The availability, quality and functionality of the Platform depends on various factors, including software, hardware, communication networks, and the quality of broadband/cellular/WiFi network connectivity, which are provided by third parties, at their responsibility. These factors are not fault-free. </p>
      <p>WE DO NOT WARRANT THAT THE PLATFORM WILL OPERATE WITHOUT DISRUPTION, ERRORS OR INTERRUPTIONS, OR THAT IT WILL BE ACCESSIBLE, OR AVAILABLE AT ALL TIMES OR FREE FROM ERRORS, GLITCHES OR UNAUTHORIZED ACCESS. </p>
      <p>We may, at any time, at our sole discretion, discontinue, terminate or suspend the operation of the Platform, or any part or aspects thereof, temporarily or permanently, without any liability to you. We are not obligated to give any notice prior to such change, discontinuation or suspension.</p>
      <ol start={14}>
      <li><strong>Age Restriction</strong></li>
      </ol>
      <p>If you are under the age of 18, you may not use the Platform in any way. By using, accessing or registering with the Platform, you declare that you are 18 years of age or older. We reserve the right to terminate your access to the Platform, if we find that you are younger than the minimum age specified above. We may request additional information to confirm your age at any time.</p>
      <ol start={15}>
      <li><strong>Changes to the Terms</strong></li>
      </ol>
      <p>From time to time, the Company may change the Terms. Changes will take effect seven (7) days after their initial posting on the Platform, unless the Company amends the Terms to comply with legal requirements. In such cases, the amendments will become effective immediately upon their initial posting, or as required. </p>
      <ol start={16}>
      <li><h1 id="disclaimers"><strong>Disclaimers</strong></h1>
      </li>
      </ol>
      <p>YOU ACKNOWLEDGE AND AGREE THAT THE PLATFORM IS PROVIDED “AS IS”. YOU WILL HAVE NO PLEA, CLAIM OR DEMAND AGAINST THE COMPANY OR ITS REPRESENTATIVES WITH RESPECT TO THE PLATFORM, ITS FEATURES, CONTENT, LIMITATIONS OR COMPATIBILITY WITH YOUR NEEDS. YOUR USE OF THE PLATFORM, THE INFORMATION CONTAINED IN IT, AND ANY SUGGESTIONS OR RECOMMENDATIONS PRODUCED BY THE PLATFORM, IS AT YOUR FULL AND EXCLUSIVE RISK. WE DISCLAIM ALL WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, COMPATIBILITY, SECURITY OR ACCURACY.  </p>
      <p>THE COMPANY DOES NOT WARRANT, AND HEREBY DISCLAIMS ANY WARRANTIES, EITHER EXPRESS OR IMPLIED, WITH RESPECT TO THE ACCURACY, ADEQUACY OR COMPLETENESS OF THE PLATFORM, ANY REPORTS, SUGGESTIONS, RECOMMENDATIONS AND ANY OTHER CONTENT ON THE PLATFORM. </p>
      <p>THE COMPANY MAKES NO WARRANTY THAT ANY EVALUATIONS, VALUATIONS, REPORTS, SUGGESTIONS, RECOMMENDATIONS AND OTHER CONTENT PRODUCED BY THE PLATFORM SHALL BE USEFUL TO YOU. YOU ARE SOLELY RESPONSIBLE FOR ALL ACTS OR OMISSIONS THAT YOU CONDUCT IN CONNECTION WITH YOUR USE OF THE PLATFORM AND CONTENT THEREON, AND FOR ALL CONSEQUENCES RESULTING FROM SUCH ACTIVITIES OR ACTIONS.</p>
      <p>THE COMPANY DOES NOT PROVIDE ANY REAL ESTATE BROKERAGE SERVICES AND DOES NOT ACT AS A REAL ESTATE BROKER IN CONNECTION WITH ANY PROPERTIES LISTED AND OFFERED FOR SALE AND/OR PURCHASED THROUGH THE PLATFORM. THE PLATFORM SHOULD NOT BE TAKEN AS A SUBSTITUTE FOR THE SERVICES OF A LICENSED REAL ESTATE BROKER. YOU ARE SOLELY AND EXCLUSIVELY RESPONSIBLE FOR OBTAINING IN YOUR SOLE AND INDEPENDENT DISCRETION ANY REAL ESTATE BROKERAGE SERVICES YOU REQUIRE IN CONNECTION WITH ANY SALES TRANSACTION. THE COMPANY SHALL HAVE NO RESPONSIBILITY OR LIABILITY FOR ANY REAL ESTATE BROKERAGE SERVICES PROVIDED TO YOU IN CONNECTION WITH ANY PROPERTIES LISTED AND/OR OFFERED FOR SALE TO YOU AND/OR PURCHASED THROUGH THE PLATFORM.</p>
      <p>COMPANY DOES NOT PROVIDE LEGAL ADVICE OR SERVICES. THE PLATFORM AND/OR PROVISION OF ANY SAMPLE AGREEMENT THEREON SHOULD NOT BE TAKEN AS A SUBSTITUTE FOR PROFESSIONAL LEGAL ADVICE. YOU ARE SOLELY AND EXCLUSIVELY RESPONSIBLE FOR OBTAINING IN YOUR SOLE AND INDEPENDENT DISCRETION ANY LEGAL ADVICE AND COUNSEL YOU REQUIRE IN CONNECTION WITH ANY SALES TRANSACTION. ALL USE OF AND/OR RELIANCE ON THE SAMPLE AGREEMENT IS AT YOUR FULL AND EXCLUSIVE RISK.  </p>
      <p>YOU ARE SOLELY AND EXCLUSIVELY RESPONSIBLE FOR ANY PURCHASE AGREEMENT AND/OR SALES TRANSACTION YOU ENTER INTO WITH A BUYER THROUGH THE PLATFORM. ALL PURCHASE AGREEMENTS AND/OR SALES TRANSACTIONS ARE SOLELY BETWEEN YOU AND THE APPLICABLE BUYER. COMPANY IS NOT A PARTY TO THE PURCHASE AGREEMENT BETWEEN YOU AND THE BUYER AND CANNOT ENSURE THAT A BUYER OR OWNER WILL ACTUALLY COMPLETE OR CONSUMMATE A TRANSACTION FOR THE PURCHASE OF ANY PROPERTY OR OTHERWISE PERFORM ITS OBLIGATIONS UNDER THE PURCHASE AGREEMENT. THE RECEIPT OF ANY OFFER FROM A BUYER ON THE PLATFORM DOES NOT CONSTITUTE AN ENDORSEMENT OR GUARANTEE BY COMPANY OF THE APPLICABLE BUYER. COMPANY MAKES NO REPRESENTATIONS AND WARRANTIES OF ANY KIND WITH RESPECT TO ANY BUYER ON THE PLATFORM AND/OR ANY TRANSACTION WITH ANY SUCH BUYER.  </p>
      <p>COMPANY SHALL HAVE NO RESPONSIBILITY OR LIABILITY FOR ANY TRANSACTION THAT MAY OR MAY NOT TAKE PLACE BETWEEN YOU AND ANY BUYER OR OWNER. ANY LEGAL CLAIM RELATED TO THE PURCHASE AGREEMENT AND SALE OF A PROPERTY TO A BUYER MUST BE BROUGHT DIRECTLY AGAINST THE APPLICABLE BUYER. YOU RELEASE COMPANY FROM ANY KIND OF CLAIMS RELATED TO ANY PROPERTIES LISTED AND SOLD THROUGH THE PLATFORM AND/OR PURCHASE AGREEMENT OR ANY TRANSACTION YOU ENTER INTO WITH A BUYER.</p>
      <p> ANY ANALYSIS, EVALUATION OR VALUATION OF YOUR PROPERTIES AND FINANCIAL DATA BY THE PLATFORM IS ONLY MEANT AS A SUGGESTION AND DOES NOT CONSTITUTE ANY PROFESSIONAL INVESTMENT OR FINANCIAL ADVICE OR RECOMMENDATION. THE COMPANY IS NOT A LICENSED OR OTHERWISE AUTHORIZED FINANCIAL, INVESTMENT OR TAX ADVISOR.  </p>
      <p>COMPANY DOES NOT OWN OR INSPECT ANY OF THE PROPERTIES OFFERED BY OWNERS ON THE PLATFORM (OR DIRECT ANY OF THE FOREGOING). COMPANY HAS NO CONTROL OVER THE QUALITY OR SAFETY OF PROPERTIES, THE ACCURACY OF THE PROPERTY LISTINGS, OR THE ABILITY OF OWNERS TO SELL PROPERTY THEY OFFER ON THE PLATFORM. YOU ARE SOLELY LIABLE FOR DETERMINING THE SUITABILITY, SAFETY AND QUALITY OF ANY PROPERTIES YOU PURCHASE THROUGH THE PLATFORM.  THE LISTING OF ANY PROPERTY ON THE PLATFORM DOES NOT CONSTITUTE AN ENDORSEMENT OR GUARANTEE BY COMPANY OF THE APPLICABLE OWNER OR SUCH OWNER’S PROPERTIES. COMPANY MAKES NO REPRESENTATIONS AND WARRANTIES OF ANY KIND WITH RESPECT TO ANY OWNER, THEIR PROPERTIES AND/OR ANY TRANSACTION WITH AN OWNER. </p>
      <ol start={17}>
      <li><h1 id="limitation-of-liability"><strong>Limitation of Liability</strong></h1>
      </li>
      </ol>
      <p>THE COMPANY SHALL NOT BE LIABLE FOR ANY INCIDENTAL, INDIRECT, SPECIAL OR CONSEQUENTIAL DAMAGES FOR BREACH OF ANY EXPRESS OR IMPLIED WARRANTY, BREACH OF CONTRACT, NEGLIGENCE, STRICT LIABILITY OR ANY OTHER LEGAL THEORY RELATED TO THE PLATFORM OR THESE TERMS. SUCH DAMAGES INCLUDE, BUT ARE NOT LIMITED TO, LOSS OF PROFITS, LOSS OF REVENUE, LOSS OF DATA, DOWN TIME AND END USER&#39;S TIME, EVEN IF THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.  </p>
      <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL COMPANY’S AGGREGATE LIABILITY HEREUNDER FOR ANY CAUSE OF ACTION OR THEORY OF LIABILITY EXCEED (I) AN AMOUNT OF US$ 1,000, OR (II) THE AMOUNTS PER END USER PAID BY CUSTOMER TO THE COMPANY DURING THE PRECEDING SIX (6) MONTH PERIOD PRIOR TO THE DATE THE CAUSE OF ACTION AROSE. THESE LIMITATIONS ARE AN ESSENTIAL BASIS OF THE BARGAIN AND SHALL APPLY NOTWITHSTANDING ANY FAILURE OF THE ESSENTIAL PURPOSE OF ANY REMEDY.</p>
      <ol start={18}>
      <li><strong>Indemnification</strong></li>
      </ol>
      <p>To the maximum extent permitted by law, you agree to indemnify, defend and hold harmless, us and our Representatives, at your own expense and immediately after receiving a written notice thereof, from and against any damages, loss, costs and expenses, including attorney’s fees and legal expenses, resulting from any plea, claim, allegation or demand, arising from, or in connection with your use of the Platform or any Content thereon, or from your breach of the Terms. </p>
      <ol start={19}>
      <li><strong>Governing Law and Jurisdiction</strong></li>
      </ol>
      <p>Regardless of your place of residence or where you access or use the Platform from, these Terms and your use of the Platform will be governed by and construed solely in accordance with the laws of the State of New York, excluding any otherwise applicable rules of conflict of laws, which would result in the application of the laws of a jurisdiction other than the State of New York.</p>
      <p>ANY AND ALL DISPUTES, CLAIMS OR CONTROVERSIES BETWEEN YOU AND COMPANY REGARDING THESE TERMS OR THE USE OF THE PLATFORM, WHICH ARE NOT AMICABLY RESOLVED, SHALL BE SETTLED THROUGH BINDING ARBITRATION (RATHER THAN IN COURT) BY TELEPHONE, ONLINE OR BASED SOLELY UPON WRITTEN SUBMISSIONS WITHOUT IN-PERSON APPEARANCE, ADMINISTERED BY THE AMERICAN ARBITRATION ASSOCIATION (AAA), UNDER ITS CONSUMER ARBITRATION RULES (WHICH ARE AVAILABLE AT WWW.ADR.ORG). THE SUBSTANTIVE LAW OF ARBITRATION SHALL BE THE LAWS OF THE STATE OF NEW YORK.</p>
      <p>JUDGMENT ON THE AWARD RENDERED BY THE ARBITRATOR(S) MAY BE ENTERED IN ANY COURT HAVING JURISDICTION THEREOF. HOWEVER, YOU MAY LODGE CLAIMS IN SMALL CLAIMS COURT IF YOUR CLAIM QUALIFIES. THE FEDERAL ARBITRATION ACT AND FEDERAL ARBITRATION LAW APPLY TO THESE TERMS.</p>
      <p>THERE IS NO JUDGE OR JURY IN ARBITRATION, AND COURT REVIEW OF AN ARBITRATION AWARD IS LIMITED. HOWEVER, AN ARBITRATOR CAN AWARD ON AN INDIVIDUAL BASIS THE SAME DAMAGES AND RELIEF AS A COURT (INCLUDING INJUNCTIVE AND DECLARATORY RELIEF OR STATUTORY DAMAGES), AND MUST FOLLOW THESE TERMS AS A COURT WOULD.</p>
      <p>PAYMENT OF FILING, ADMINISTRATION AND ARBITRATOR FEES WILL BE GOVERNED BY THE AAA&#39;S CONSUMER ARBITRATION RULES. </p>
      <p>YOU AND THE COMPANY HEREBY ACKNOWLEDGE, AGREE AND COVENANT THAT ANY DISPUTES SHALL ONLY BE ADJUDICATED IN ARBITRATION ON AN INDIVIDUAL BASIS, AND NOT IN CLASS, COLLECTIVE, CONSOLIDATED OR REPRESENTATIVE PROCEEDINGS.</p>
      <p>YOU MAY OPT-OUT OF THE ABOVE ARBITRATION CLAUSE BY EMAILING US TO <a href="mailto:&#115;&#117;&#112;&#x70;&#111;&#x72;&#x74;&#x40;&#x62;&#x6c;&#x61;&#x6e;&#x6b;&#x65;&#116;&#x68;&#x6f;&#109;&#x65;&#115;&#x2e;&#x63;&#111;&#x6d;">&#115;&#117;&#112;&#x70;&#111;&#x72;&#x74;&#x40;&#x62;&#x6c;&#x61;&#x6e;&#x6b;&#x65;&#116;&#x68;&#x6f;&#109;&#x65;&#115;&#x2e;&#x63;&#111;&#x6d;</a>, WITHIN SEVEN (7) DAYS OF YOU ENTERING INTO THESE TERMS FOR THE FIRST TIME, AN OPT-OUT NOTICE THAT IDENTIFIES YOURSELF AND CLEARLY SETS OUT YOUR CHOICE TO OPT OUT OF DISPUTE RESOLUTION BY ARBITRATION. IN CASE OF SUCH OPT-OUT, ANY AND ALL DISPUTES, CLAIMS OR CONTROVERSIES BETWEEN YOU AND US REGARDING THESE TERMS OR THE USE OF THE PLATFORM, WHICH ARE NOT AMICABLY RESOLVED, SHALL BE SUBJECT TO THE EXCLUSIVE JURISDICTION AND VENUE OF THE STATE AND FEDERAL COURTS IN NEW YORK COUNTY, NEW YORK. </p>
      <p>NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS SECTION, EITHER PARTY MAY ASSERT: (A) AN IMPLEADER CLAIM AGAINST THE OTHER PARTY IN ANY COURT OF COMPETENT JURISDICTION ADJUDICATING A THIRD PARTY CLAIM THAT IS SUBJECT TO THE INDEMNITY CLAUSE IN THESE TERMS; OR (B) A CLAIM ALLEGING INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS (INCLUDING COPYRIGHTS AND TRADE SECRETS), IN ANY COURT HAVING GENERAL OR SPECIFIC JURISDICTION OVER THE DEFENDANT.</p>
      <ol start={20}>
      <li><strong>Miscellaneous</strong></li>
      </ol>
      <p>These Terms constitute the entire agreement between you and us concerning the subject matter herein and supersede all prior and contemporaneous negotiations and oral representations, agreements and statements. </p>
      <p>You may not assign or transfer your rights and obligations under the Terms without our prior written consent. Any attempted or actual assignment by you, without our prior consent, shall be null and void. We may assign these Terms in their entirety, including all right, duties, liabilities and obligations therein, upon notice to you and without obtaining your further specific consent, to a third-party, upon a merger, acquisition, change of control or the sale of all or substantially all of our equity or assets, whereby virtue of such assignment, the assignee assumes in our stead, all right, duties, liabilities and obligations hereunder. </p>
      <p>If any provision of the Terms is held to be illegal, invalid, or unenforceable by a competent court, then the provision shall be performed and enforced to the maximum extent permitted by law to reflect as closely as possible, the original intention of that provision, and the remaining provisions of the Terms shall continue to remain in full force and effect.</p>
      <ol start={21}>
      <li><strong>Contact Us</strong></li>
      </ol>
      <h2 id="you-may-contact-us-with-any-questions-or-comments-at-supportblankethomescom">You may contact us with any questions or comments at: <a href="mailto:&#x73;&#x75;&#x70;&#x70;&#x6f;&#x72;&#x74;&#x40;&#x62;&#108;&#x61;&#x6e;&#x6b;&#x65;&#x74;&#104;&#x6f;&#x6d;&#x65;&#115;&#46;&#99;&#111;&#109;">&#x73;&#x75;&#x70;&#x70;&#x6f;&#x72;&#x74;&#x40;&#x62;&#108;&#x61;&#x6e;&#x6b;&#x65;&#x74;&#104;&#x6f;&#x6d;&#x65;&#115;&#46;&#99;&#111;&#109;</a>.</h2>
      <p>Last Updated: July 2024. </p>
    </div>
  );
};
