import { ReactNode, useState } from 'react';

import {
  APIUser,
  config, dateToUTC, Question, useAnalytics, useCreateSubmission, useGetQuestions,
  useImpersonate,
} from 'lib';
import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  BoldTypography,
  Spinner, useLabels,
} from 'ui';
import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogTitle,
  Fade, IconButton, LinearProgress, linearProgressClasses, List, ListItem, Stack, useMediaQuery,
  useTheme,
} from '@mui/material';
import { signal } from '@preact/signals-react';

import { LongTextQuestion } from './LongTextQuestion';

type PopupStage = 'start_review' | 'end_review' | 'questionnaire';
const acceptedQuestionTypes = ['control_textarea'];
const hiddenQuestionNames = ['start_time', 'end_time', 'time_delta'] as const;
type HiddenQuestionName = typeof hiddenQuestionNames[number];

const currentQuestionIndex = signal<number>(0);

const Questionnaire = ({
  questions,
  answerQuestion,
}: {
  questions: Question[],
  answerQuestion: (question: Question, answer: string) => void,
}) => {
  const theme = useTheme();
  const analytics = useAnalytics();

  const submitQuestion = async (question: Question, answer: string) => {
    analytics.track('Question Answered', {
      answer,
      questionID: question.id,
      questionText: question.name,
      formName: 'Performance Review',
    });

    try {
      await answerQuestion(question, answer);
    } catch (e) {
      console.error(e);
      toast.error('error.unknownError');
    }
  };

  return (
    <>
      <LinearProgress
        variant="determinate"
        value={(100 * (currentQuestionIndex.value + 1)) / questions.length}
        data-testid="performance-review-questionnaire-progress"
        sx={{
          mx: -5,
          backgroundColor: theme.palette.common.white,
          borderRadius: 0,
          [`& .${linearProgressClasses.bar1Determinate}`]: {
            borderRadius: 0,
          },
          [`& .${linearProgressClasses.bar}`]: {
            transition: 'transform 0.2s linear',
          },
        }}
      />
      <Box
        position="relative"
        flexGrow="1"
        minHeight="100%"
        minWidth={320}
      >
        {questions.map((question, i) => (
          <Fade
            appear={false}
            key={`${question.id}-slide`}
            in={i === currentQuestionIndex.value}
            timeout={600}
          >
            <Box position="absolute" top="0" left="0" height="100%" width="100%" pt={3}>
              <LongTextQuestion key={question.id} question={question} submitQuestion={submitQuestion} />
            </Box>
          </Fade>
        ))}
      </Box>
    </>
  );
};

const answers = signal<{ [key: string]: string }>({});

export const QuestionnaireDialog = ({
  open,
  setOpen: setOpen_,
  user,
}: {
  open: boolean,
  setOpen: (o: boolean) => void,
  user: APIUser,
}) => {
  const theme = useTheme();
  const l = useLabels();
  const analytics = useAnalytics();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [popupStage, setPopupStage] = useState<PopupStage>('start_review');
  const { isLoading: impersonateLoading, mutateAsync: impersonateOwner } = useImpersonate();
  const { data: questions, isLoading: questionsLoading } = useGetQuestions({
    formID: config.performanceReviewFormId,
    ownerEmail: user.email,
    enabled: open,
    subQuestionID: config.performanceReviewOwnerEmailQuestionID,
  });

  const { isLoading: submissionLoading, mutateAsync: createSubmission } = useCreateSubmission(config.performanceReviewFormId);

  const setOpen = (o: boolean) => {
    setOpen_(o);

    if (!o) {
      answers.value = {};
      setPopupStage('start_review');
      currentQuestionIndex.value = 0;
    }
  };

  const cancel = (buttonName: string) => {
    setOpen(false);

    analytics.track('Button Clicked', {
      buttonName: `Performance Review - ${buttonName}`,
    });
  };

  const buttonsDisabled = impersonateLoading || submissionLoading;

  const getQuestion = (name: HiddenQuestionName): Question | null => {
    const question = questions?.find((q) => q.name === name);

    if (!question) {
      console.error(`missing question ${name}`);

      return null;
    }

    return question;
  };

  const submitQuestionnaire = async () => {
    const data: typeof answers['value'] = {
      ...answers.value,
      [config.performanceReviewOwnerEmailQuestionID]: user.email,
    };

    analytics.track('Form Submitted', {
      formName: 'Performance Review',
      ...(Object.entries(data).reduce((acc, [id, ans]) => ({
        ...acc,
        [`question-${id}`]: ans,
      }), {})),
    });

    await createSubmission(Object.entries(data).map(([id, ans]) => ({ id, answer: ans })));
  };

  const answerQuestion = (question: Question, answer: string) => {
    answers.value[question.id] = answer;

    const startTimeQuestion = getQuestion('start_time');
    const endTimeQuestion = getQuestion('end_time');

    if (startTimeQuestion && endTimeQuestion) {
      const startTime = answers.value[startTimeQuestion.id];
      const endTime = answers.value[endTimeQuestion.id];

      if (startTime && endTime) {
        const timeDeltaQuestion = getQuestion('time_delta');

        if (timeDeltaQuestion) {
          answers.value[timeDeltaQuestion.id] = `${new Date(endTime).getTime() / 1000 - new Date(startTime).getTime() / 1000}`;
        } else {
          console.error('missing time delta question');
        }
      }
    }
  };

  const impersonate = async () => {
    analytics.track('User Impersonated', {
      userEmail: user.email,
      actionName: l.startReview,
    });

    let hadError = false;

    try {
      const impersonationToken = await impersonateOwner(user.email);
      window.open(`/?impersonation-token=${impersonationToken.data.token}`, '_blank');
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
      hadError = true;
    }

    return hadError;
  };

  const startReview = async () => {
    const startTimeQuestion = getQuestion('start_time');

    if (!startTimeQuestion) {
      toast.error(l['error.unknownError']);

      return;
    }

    const hadError = await impersonate();
    if (!hadError) {
      answerQuestion(startTimeQuestion, dateToUTC(new Date()).toISOString());
      setPopupStage('end_review');
    }
  };

  const endReview = async () => {
    const endTimeQuestion = getQuestion('end_time');

    if (!endTimeQuestion) {
      toast.error(l['error.unknownError']);

      return;
    }

    await answerQuestion(endTimeQuestion, dateToUTC(new Date()).toISOString());

    setPopupStage('questionnaire');
  };

  const displayQuestions = questions?.filter((question) => {
    const questionName = question.name as HiddenQuestionName;

    if (!acceptedQuestionTypes.includes(question.type) && !hiddenQuestionNames.includes(questionName)) {
      console.error(`unknown question type ${question.type}`);
    }

    return !hiddenQuestionNames.includes(questionName) && acceptedQuestionTypes.includes(question.type);
  }) ?? [];

  const isLastQuestion = currentQuestionIndex.value === displayQuestions.length - 1;

  const stageToButton: Record<PopupStage, { label: string, onClick: () => void }> = {
    start_review: {
      label: l.startReview,
      onClick: () => {
        startReview();

        const startTimeQuestion = getQuestion('start_time');

        analytics.track('Button Clicked', {
          buttonName: 'Performance Review - Start Review',
          startTime: startTimeQuestion && answers.value[startTimeQuestion.id],
        });
      },
    },
    end_review: {
      label: l.endReview,
      onClick: () => {
        endReview();

        const endTimeQuestion = getQuestion('end_time');

        analytics.track('Button Clicked', {
          buttonName: 'Performance Review - End Review',
          endTime: endTimeQuestion && answers.value[endTimeQuestion.id],
        });
      },
    },
    questionnaire: {
      label: isLastQuestion ? l.done : l.next,
      onClick: async () => {
        if (isLastQuestion) {
          await submitQuestionnaire();

          setOpen(false);
        } else {
          currentQuestionIndex.value += 1;
        }

        analytics.track('Button Clicked', {
          buttonName: `Performance Review - ${isLastQuestion ? l.done : l.next}`,
        });
      },
    },
  };

  const reviewButton = stageToButton[popupStage];

  const performanceReviewIntro = (
    <Stack pt={3}>
      <BoldTypography variant="subtitle1">
        {l['pm-dashboard.performance-review.intro.title']}
      </BoldTypography>
      <List sx={{ listStyle: 'decimal', pl: 4.5 }}>
        <ListItem sx={{ display: 'list-item', pt: 0 }}>{l['pm-dashboard.performance-review.intro.item1']}</ListItem>
        <ListItem sx={{ display: 'list-item', pt: 0 }}>{l['pm-dashboard.performance-review.intro.item2']}</ListItem>
        {user.roles.filter((r) => r === 'owner')
          && <ListItem sx={{ display: 'list-item', pt: 0 }}>{l['pm-dashboard.performance-review.intro.item3']}</ListItem>}
        <ListItem sx={{ display: 'list-item', pt: 0 }}>{l['pm-dashboard.performance-review.intro.item4']}</ListItem>
        <ListItem sx={{ display: 'list-item', pt: 0 }}>{l['pm-dashboard.performance-review.intro.item5']}</ListItem>
      </List>
    </Stack>
  );

  const stages: Record<PopupStage, ReactNode> = {
    start_review: performanceReviewIntro,
    end_review: performanceReviewIntro,
    questionnaire: <Questionnaire answerQuestion={answerQuestion} questions={displayQuestions} />,
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullScreen={isMobile}
      sx={{ zIndex: 1800 }}
    >
      <DialogTitle component={Stack} alignItems="flex-end" borderBottom={`1px solid ${theme.palette.divider}`} sx={{ p: 3 }}>
        <IconButton
          disabled={buttonsDisabled}
          aria-label="Close"
          onClick={() => cancel('Close (X)')}
          size="medium"
          color="secondary"
        >
          <MdClose />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          position="relative"
          flexGrow="1"
          minHeight={isMobile ? '100%' : '460px'}
          minWidth={500}
        >
          {questionsLoading || !questions ? (
            <Spinner size={40} />
          ) : (
            <>
              {Object.entries(stages).map(([stage, content]) => (
                <Fade
                  key={stage}
                  in={popupStage === stage}
                  timeout={600}
                >
                  <Box position="absolute" top="0" left="0" height="100%" width="100%">
                    {content}
                  </Box>
                </Fade>
              ))}
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          borderTop: `1px solid ${theme.palette.divider}`,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 3,
          pt: `${theme.spacing(3)} !important`,
        }}
      >
        <Button
          variant="outlined"
          type="submit"
          onClick={() => cancel('Cancel')}
          disabled={buttonsDisabled}
          sx={{ minWidth: 150, minHeight: 38 }}
        >
          {buttonsDisabled ? (
            <Spinner size={20} />
          ) : l.cancel}
        </Button>
        <Button
          variant="contained"
          type="submit"
          onClick={reviewButton.onClick}
          disabled={buttonsDisabled}
          sx={{ minWidth: 150, minHeight: 38 }}
        >
          {buttonsDisabled ? (
            <Spinner size={20} />
          ) : reviewButton.label}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
