import React from 'react';

import {
  Button, Dialog, DialogContent, Stack, Typography,
} from '@mui/material';

import { useLabels } from '../lib/translations';
import { Spinner } from '../spinner';

export const YesNoDialog = ({
  open,
  onClose,
  title,
  description,
  onSubmit,
  isLoading = false,
  centerDescription = true,
  actionID = undefined,
}: {
  open: boolean,
  onClose: () => void,
  title: string,
  description: React.ReactNode,
  onSubmit: () => void,
  isLoading?: boolean
  centerDescription?: boolean,
  actionID?: string,
}) => {
  const l = useLabels();
  const spinner = (
    <>
      &nbsp;
      <Spinner size={20} />
    </>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        zIndex: 1600,
      }}
    >
      <DialogContent>
        <Stack gap={3}>
          <Typography variant="h5" sx={{ textAlign: 'center' }}>
            {title}
          </Typography>
          <Typography variant="body1" sx={{ textAlign: centerDescription ? 'center' : undefined }}>
            {description}
          </Typography>
        </Stack>
        <Stack direction="row" gap={3} mt={8}>
          <Button variant="contained" id={actionID} onClick={onSubmit} style={{ flexGrow: 1 }} disabled={isLoading}>
            {isLoading ? spinner : l.yes}
          </Button>
          <Button variant="outlined" onClick={onClose} style={{ flexGrow: 1 }}>
            {l.no}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
