import dayjs from 'dayjs';
import { z } from 'zod';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useMutation, useQuery } from '@tanstack/react-query';

import { config } from '../../config/config';
import { useAuth } from '../../hooks/useAuth';
import { convertArrayToFirstElement } from '../../lib/convert';
import { QueryKey } from '../../types/enums';
import { queryGraphQL } from '../amplify';
import {
  ListOwnerLeadReferralLinksQuery,
  ListOwnerLeadWorkflowItemsByPmQuery,
  OwnerLeadReferralLink, OwnerLeadWorkflowItem,
} from '../API';
import { axiosWithPayloadContext, createHeaders } from '../axios';
import { listOwnerLeadReferralLinks, listOwnerLeads } from '../graphql/queries';

const matchedSubstringSchema = z.object({
  length: z.number(),
  offset: z.number(),
});

const termSchema = z.object({
  offset: z.number(),
  value: z.string(),
});

const structuredFormattingSchema = z.object({
  main_text: z.string(),
  main_text_matched_substrings: z.array(matchedSubstringSchema),
  secondary_text: z.string(),
});

export const placeSchema = z.object({
  description: z.string(),
  matched_substrings: z.array(matchedSubstringSchema),
  place_id: z.string(),
  structured_formatting: structuredFormattingSchema,
  terms: z.array(termSchema),
  types: z.array(z.string()),
});

export const addLeadFormSchema = z.object({
  email: z.string().email('This is not a valid email').min(1),
  firstName: z.string().trim().min(1, 'First name is required'),
  lastName: z.string().trim().min(1, 'Last name is required'),
  phone: z.string().trim().min(1, 'Phone number is required'),
  addresses: z.array(placeSchema.optional().nullable()),
  requestRentalAnalysis: z.boolean().optional(),
  forPartnerID: z.string().optional(),
});

export type AddLeadData = z.infer<typeof addLeadFormSchema>;

export const useCreateLead = () => {
  const { user, getAccessTokenSilently } = useAuth();

  return useMutation(async (data: AddLeadData) => {
    const token = await getAccessTokenSilently();

    const addresses = data.addresses.filter((address) => !(!address || address.description === '')).map((address) => {
      // this will never happen, this is to appease typescript gods
      if (!address) {
        return '';
      }
      return address.description;
    });

    const sendData = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
      getRentalAnalysis: data.requestRentalAnalysis,
      propertyAddress: addresses,
      pm: user?.pm || '',
      forPartnerID: data.forPartnerID,
    };

    await axiosWithPayloadContext({
      url: `${config.apiBaseURL}/owner-lead`,
      method: 'POST',
      headers: createHeaders(token),
      data: sendData,
    });
  });
};

export const useListOwnerLeads = () => {
  const { user, getAccessTokenSilently } = useAuth();

  return useQuery([QueryKey.OWNER_LEADS], async (): Promise<OwnerLeadWorkflowItem[]> => {
    const token = await getAccessTokenSilently();

    const res = await queryGraphQL({
      query: listOwnerLeads,
      authToken: token,
      variables: { pm: user?.pm ?? '' },
    }) as GraphQLResult<ListOwnerLeadWorkflowItemsByPmQuery>;

    if (!res.data?.listOwnerLeadWorkflowItemsByPm) {
      return [];
    }

    const ownerLeadWorkflowItems = (res.data.listOwnerLeadWorkflowItemsByPm.items as OwnerLeadWorkflowItem[])
      .sort((a: OwnerLeadWorkflowItem, b: OwnerLeadWorkflowItem) => dayjs(b.updatedTime).diff(dayjs(a.updatedTime)));
    return ownerLeadWorkflowItems as OwnerLeadWorkflowItem[];
  }, { enabled: !!user?.id });
};

export const useGetOwnerLeadsReferralLink = () => {
  const { user, getAccessTokenSilently } = useAuth();

  return useQuery([QueryKey.OWNER_LEADS_REFERRAL_LINKS], async (): Promise<OwnerLeadReferralLink | null> => {
    const token = await getAccessTokenSilently();

    const res = await queryGraphQL({
      query: listOwnerLeadReferralLinks,
      authToken: token,
      variables: {
        ownerId: user?.id,
      },
    }) as GraphQLResult<ListOwnerLeadReferralLinksQuery>;

    if (!res.data?.listOwnerLeadReferralLinks) {
      return null;
    }

    const ownerLeadReferralLinks = (res.data.listOwnerLeadReferralLinks.items as OwnerLeadReferralLink[])
      .sort((a: OwnerLeadReferralLink, b: OwnerLeadReferralLink) => dayjs(b.updatedTime).diff(dayjs(a.updatedTime)));

    const referralLink = convertArrayToFirstElement<OwnerLeadReferralLink>({
      items: ownerLeadReferralLinks,
    });
    return referralLink as OwnerLeadReferralLink;
  }, { enabled: !!user?.id });
};
