type Trend = {
  year: number,
  month: number,
};

export const sortTrend = (a: Trend, b: Trend) => {
  if (a.year > b.year) return 1;
  if (a.year < b.year) return -1;
  if (a.month > b.month) return 1;
  if (a.month < b.month) return -1;
  return 0;
};
