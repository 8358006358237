import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useMutation, useQuery } from '@tanstack/react-query';

import { convertPMPreferences } from './converters';
import { PMPreferences } from './types';
import { config } from '../../config/config';
import { useAuth } from '../../hooks/useAuth';
import { QueryKey } from '../../types/enums';
import { queryGraphQL } from '../amplify';
import { GetMarketplacePMPreferencesQuery } from '../API';
import { axiosWithPayloadContext, createHeaders } from '../axios';
import { getPMPreferencesQuery } from '../graphql/queries';

export const useGetPMPreferences = () => {
  const { user, getAccessTokenSilently } = useAuth();

  const pm = user?.pm;

  return useQuery([QueryKey.PM_PREFERENCES, pm], async (): Promise<PMPreferences | null> => {
    if (!pm) return null;

    const token = await getAccessTokenSilently();
    const res = await queryGraphQL({
      query: getPMPreferencesQuery,
      variables: { pm },
      authToken: token,
    }) as GraphQLResult<GetMarketplacePMPreferencesQuery>;

    if (!res.data?.getMarketplacePMPreferences) {
      return null;
    }

    return convertPMPreferences(res.data.getMarketplacePMPreferences);
  });
};

export const useUpdatePmPreferences = () => {
  const { user, getAccessTokenSilently } = useAuth();

  const pm = user?.pm;

  return useMutation(async ({
    leadsThirdPartyIntegrationRequested,
  }: {
    leadsThirdPartyIntegrationRequested: boolean,
  }) => {
    const token = await getAccessTokenSilently();

    await axiosWithPayloadContext({
      url: `${config.apiBaseURL}/pm-preferences/${pm}`,
      method: 'PUT',
      headers: createHeaders(token),
      data: {
        leadsThirdPartyIntegrationRequested,
      },
    });
  });
};
