import { useEffect } from 'react';

import {
  AgentProvidedBenefit, OwnerReferralsIncentive, useAnalytics, useFeatures,
} from 'lib';
import { Controller, UseFormReturn } from 'react-hook-form';
import {
  BoldTypography, ControlledFormattedNumberField, Spacer, useLabels,
} from 'ui';
import {
  Alert,
  Collapse, Divider, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, TextField, Typography, useTheme,
} from '@mui/material';

import { GrowthPolicyAutocompleteField } from './GrowthPolicyAutocompleteField';
import { GrowthPolicyBooleanField } from './GrowthPolicyBooleanField';
import { StyledField } from './styled';
import { GrowthPolicyData } from '../../api/suggested-partners/forms';

export const ReferralProgramForm = ({ form }: { form: UseFormReturn<GrowthPolicyData> }) => {
  const { isLoading: isLoadingFeatures, ...features } = useFeatures();

  return !isLoadingFeatures && (
    <Stack gap={6}>
      {!features.isFreemiumPM && <AgentRelatedQuestions form={form} />}
      <PropertyManagerRelatedQuestions form={form} />
      {!features.isFreemiumPM && <OwnerRelatedQuestions form={form} />}
    </Stack>
  );
};

const AgentRelatedQuestions = ({ form }: { form: UseFormReturn<GrowthPolicyData> }) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();

  const agentProvidedBenefitOptions = [
    { label: l['partners.referBackUponBuy'], value: AgentProvidedBenefit.REFER_BACK_UPON_BUY },
    { label: l['partners.referBackUponSell'], value: AgentProvidedBenefit.REFER_BACK_UPON_SELL },
    { label: l.other, value: AgentProvidedBenefit.OTHER },
  ];

  return (
    <Stack gap={4}>
      <BoldTypography variant="body1">{l.agents}</BoldTypography>
      <Stack sx={{ borderRadius: '10px', border: `1px solid ${theme.palette.divider}` }}>
        <StyledField id="agent-referral-fee-amount">
          <Typography variant="body1">
            {l['partners.wizard.question.agentReferralFeeAmount']}
          </Typography>
          <ControlledFormattedNumberField
            fullWidth
            control={form.control}
            name="agentReferralFeeAmount"
            placeholder={l.amount}
            useExternalValue
            externalValue={form.watch('agentReferralFeeAmount')}
            prefix="$"
            size="small"
            onBlur={() => {
              analytics.track('Input Changed', {
                inputName: 'Referral Program - Agent Referral Fee Amount',
                value: form.getValues('agentReferralFeeAmount') ?? 0,
              });
            }}
            onValueChange={() => {
              form.trigger('agentReferralFeeAmount');
            }}
            error={!!form.formState.errors.agentReferralFeeAmount}
            helperText={form.formState.errors.agentReferralFeeAmount?.message}
          />
        </StyledField>
        <Divider />
        <StyledField id="agent-provided-benefits">
          <Typography variant="body1">
            {l['partners.wizard.question.agentProvidedBenefits']}
          </Typography>
          <Stack>
            <GrowthPolicyAutocompleteField
              name="agentProvidedBenefits"
              form={form}
              options={agentProvidedBenefitOptions}
              placeholder={l.pleaseSpecify}
            />
            <Collapse in={!!form.watch('agentProvidedBenefits')?.includes(AgentProvidedBenefit.OTHER)}>
              <Spacer spacing={3} />
              <Controller
                name="agentProvidedBenefitsOther"
                control={form.control}
                render={({ field }) => (
                  <TextField
                    autoComplete="off"
                    label={l.other}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={!!form.formState.errors.agentProvidedBenefitsOther}
                    helperText={form.formState.errors.agentProvidedBenefitsOther?.message}
                    {...field}
                    value={field.value ?? ''}
                    onChange={(e, ...args) => {
                      field.onChange(e, ...args);

                      if (e.target.value) form.trigger('agentProvidedBenefitsOther');
                    }}
                  />
                )}
              />
            </Collapse>
          </Stack>
        </StyledField>
      </Stack>
    </Stack>
  );
};

const PropertyManagerRelatedQuestions = ({ form }: { form: UseFormReturn<GrowthPolicyData> }) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const features = useFeatures();

  useEffect(() => {
    if (features.isFreemiumPM) {
      form.setValue('enablePMReferralProgram', true);
    }
  }, [features.isFreemiumPM]);

  return (
    <Stack gap={4}>
      <BoldTypography variant="body1">{l.propertyManagers}</BoldTypography>
      <Stack sx={{ borderRadius: '10px', border: `1px solid ${theme.palette.divider}` }}>
        <StyledField id="enable-pm-referral-program">
          <Stack gap={2}>
            <Typography variant="body1">
              {l.dynamic['partners.wizard.question.enablePMReferralProgram.title'](features)}
            </Typography>
            <Typography variant="body2" color="secondary">
              {l['partners.wizard.question.enablePMReferralProgram.description']}
            </Typography>
          </Stack>
          <Stack gap={3}>
            <GrowthPolicyBooleanField
              hideButtonGroup={features.isFreemiumPM}
              name="enablePMReferralProgram"
              form={form}
              disabled={features.isFreemiumPM}
              showCollapsibleContent={!!form.watch('enablePMReferralProgram')}
              collapsibleContent={(
                <ControlledFormattedNumberField
                  fullWidth
                  control={form.control}
                  name="pmReferralsFeeAmount"
                  prefix="$"
                  size="small"
                  useExternalValue
                  externalValue={form.watch('pmReferralsFeeAmount')}
                  label={l.referralFeeAmount}
                  error={!!form.formState.errors.pmReferralsFeeAmount}
                  helperText={form.formState.errors.pmReferralsFeeAmount?.message}
                  onValueChange={(val) => {
                    if (typeof val === 'number') form.trigger('pmReferralsFeeAmount');
                  }}
                  onBlur={() => {
                    analytics.track('Input Changed', {
                      inputName: 'Referral Program - PM Referral Incentive Amount',
                      value: form.getValues('pmReferralsFeeAmount') ?? 0,
                    });
                  }}
                />
              )}
            />
          </Stack>
        </StyledField>
      </Stack>
    </Stack>
  );
};

const OwnerRelatedQuestions = ({ form }: { form: UseFormReturn<GrowthPolicyData> }) => {
  const l = useLabels();
  const theme = useTheme();

  const ownerReferralsIncentiveOptions = [
    {
      label: l['partners.wizard.question.ownerReferralsIncentive.oneMonth'],
      value: OwnerReferralsIncentive.ZERO_MGMT_FEE_ONE_MONTH,
    },
    {
      label: l['partners.wizard.question.ownerReferralsIncentive.twoMonths'],
      value: OwnerReferralsIncentive.ZERO_MGMT_FEE_TWO_MONTHS,
    },
    {
      label: l['partners.wizard.question.ownerReferralsIncentive.threeMonths'],
      value: OwnerReferralsIncentive.ZERO_MGMT_FEE_THREE_MONTHS,
    },
    {
      label: l['partners.wizard.question.ownerReferralsIncentive.noIncentive'],
      value: OwnerReferralsIncentive.NO_INCENTIVE,
    },
  ];

  return (
    <Stack gap={4}>
      <BoldTypography variant="body1">{l.owners}</BoldTypography>
      <Stack sx={{ borderRadius: '10px', border: `1px solid ${theme.palette.divider}` }}>
        <StyledField id="enable-owner-referral-program">
          <Stack gap={2}>
            <Typography variant="body1">
              {l['partners.wizard.question.enableOwnerReferralProgram.title']}
            </Typography>
            <Typography variant="body2" color="secondary">
              {l['partners.wizard.question.enableOwnerReferralProgram.description']}
            </Typography>
          </Stack>
          <Stack gap={3}>
            <GrowthPolicyBooleanField
              name="enableOwnerReferralProgram"
              form={form}
              showCollapsibleContent={!!form.watch('enableOwnerReferralProgram')}
              collapsibleContent={(
                <Controller
                  name="ownerReferralsIncentive"
                  control={form.control}
                  render={({ field }) => (
                    <FormControl fullWidth size="small">
                      <InputLabel id="ownerReferralsIncentive">
                        {l['partners.wizard.question.ownerReferralsIncentive.placeholder']}
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="ownerReferralsIncentive"
                        label={l['partners.wizard.question.ownerReferralsIncentive.placeholder']}
                        {...field}
                        onChange={(e, ...args) => {
                          field.onChange(e, ...args);

                          if (e.target.value) form.trigger('ownerReferralsIncentive');
                        }}
                        value={field.value ?? ''}
                      >
                        <MenuItem value="" disabled>
                          {l['partners.wizard.question.ownerReferralsIncentive.placeholder']}
                        </MenuItem>
                        {ownerReferralsIncentiveOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {form.formState.errors.ownerReferralsIncentive && (
                        <FormHelperText error>
                          {form.formState.errors.ownerReferralsIncentive?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              )}
            />
          </Stack>
        </StyledField>
      </Stack>
      <Alert severity="info">
        {l['partners.wizard.referralProgram.ownerReferralFee.disclaimer']}
      </Alert>
    </Stack>
  );
};
