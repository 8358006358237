export const listOwnerReports = /* GraphQL */ `
query ListOwnerReports($owner: String!) {
  listOwnerReportsByOwner(owner: $owner) {
         items {
        id
        year
        month
        owner
        reports {
          items {
            id
            name
            createdAt
          }
        }
      }
  }
}
`;

export const listOwnerReportsIds = /* GraphQL */ `
query ListReportIdsQuery($owner: String!) {
  listOwnerReportsByOwner(owner: $owner) {
    items {
      id
    }
  }
}
`;

export const getReport = /* GraphQL */ `
  query GetReport($id: ID!){
    getReport(id: $id){
      id
      appreciation
      name
      homeValue
      netCashFlow
      noi
      totalOccupiedUnits
      ownerReportReportsId
      reportType
      totalEquity
      totalUnits
      totalRent
      totalOtherIncome
      updatedAt
      version
      yearlyMeasures {
        capRate
        cashOnCash
      }
      previousMonthlyMeasures {
        appreciation
        homeValue
        totalEquity
      }
      expenses {
        insurance
        hoa
        managementFee
        mortgage
        other
        tax
        totalExpense
        utilities
        legalAndProfessionalFees
        rentalRegistration
        autoAndTravel
        supplies
        maintenance
        leasingAndMarketing
      }
    }
  }
`;

export const listProperties = /* GraphQL */ `
  query ListProperties(
    $filter: ModelDashboardPropertyFilterInput
    $nextToken: String
    $sortDirection: ModelSortDirection
    $owner: String!
  ) {
    listDashboardPropertyByOwner(
      owner: $owner
      filter: $filter
      limit: 5000
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      nextToken
      items {
        id
        owner
        name
        hoaMuted
        deleted
        purchaseHistory {
        items {
          id
          amount
          closingCost
          homeImprovementCost
          date
          createdTime
          updatedTime
          dashboardPropertyPurchaseHistoryId
        }
      }
      propertyValuation(sortDirection: DESC, limit: 1) {
        items {
          id
          date
          priceUpper
          priceLower
          priceMean
          fsd
          provider
        }
       },
         metrics(sortDirection: DESC, limit: 1) {
            items {
              NOI
              capRate
              cashOnCash
              annualExpense
              annualIncome
              cashInvested
              valuation
              cashFlow
              createdTime
              dashboardPropertyMetricsId
              id
              isDataPartial
              annualMetrics {
                income
                propertyTax
                propertyInsurance
                propertyHoa
              }
              trailingQ1 {
                income
                cashflow
                noi
                noiExpenses
                totalExpenses
              }
              trailingQ2 {
                income
                cashflow
                noi
                noiExpenses
                totalExpenses
              }
              trailingQ3 {
                income
                cashflow
                noi
                noiExpenses
                totalExpenses
              }
              trailingQ4 {
                income
                cashflow
                noi
                noiExpenses
                totalExpenses
              }
            }
          },
        transactions(limit: 5000) {
          items {
            id
            amount
            transactionDate
            owner
            type
            category
            label
            description
            isEditable
          }
          nextToken
        }
        leases(limit: 5000) {
          items {
            rent
            startDate
          }
        }
        userExpense {
          items {
            id
            amount
            startDate
            category
            owner
            createdTime
            updatedTime
            dashboardPropertyUserExpenseId
          }
          nextToken
        }
        amortizationTable(limit: 1, sortDirection: DESC) {
          items {
            id
            period
            transactionDate
            payment
            interest
            principal
            totalPrincipal
            mortgageId
          }
        }
        mortgage {
          items {
            id
            rate
            amount
            startDate
            mortgageDueDay
            mortgageType
            term
            owner
            grantor
            apn
            createdTime
            updatedTime
            dashboardPropertyMortgageId
          }
          nextToken
        }
        address {
          street1
          street2
          city
          state
          zipCode
        }
        property {
          id
          address {
            street1
            street2
            city
            state
            zipCode
          }
          units {
              items {
                  id
                  isVacant
              }
          }
          yearBuilt
          bedrooms
          bathrooms
          sqft
          isVacant
          owner
          createdTime
          updatedTime
        }
        createdTime
        updatedTime
        propertyDashboardPropertyId
      }
    }
  }
`;

export const searchActiveListingPropertyIDs = /* GraphQL */ `
  query searchMarketplaceProperties($nextToken: String) {
    searchMarketplacePropertyListingItems(filter: {status: {eq: "active"}}, nextToken: $nextToken, limit: 1000) {
      nextToken
      items {
        id
        marketplacePropertyListingItemsId
        property {
          id
        }
      }
    }
  }
`;
