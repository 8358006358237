import {
  Dialog, DialogActions, DialogContent, Stack, Typography, useMediaQuery, useTheme,
} from '@mui/material';

import { InvertedButton } from '../button/styled';
import {
  ConfettiCircle,
} from '../icons';
import { useLabels } from '../lib/translations';

export const ConfirmedDialog = ({
  open,
  onClose,
  message,
}: {
  open: boolean,
  onClose: () => void,
  message: string,
}) => {
  const l = useLabels();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickClose = (e: any, reason: 'backdropClick' | 'escapeKeyDown' | 'buttonClick') => {
    if (reason === 'backdropClick') return;

    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClickClose} fullScreen={isMobile} disableEscapeKeyDown>
      <DialogContent sx={{ p: 10 }}>
        <Stack justifyContent="center" alignItems="center" gap={3}>
          <ConfettiCircle />
          <Typography variant="body1" sx={{ textAlign: 'center' }}>{message}</Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ borderTop: `1px solid ${theme.palette.divider}`, p: `${theme.spacing(3)} !important` }}>
        <InvertedButton onClick={() => handleClickClose({}, 'buttonClick')}>
          {l.ok}
        </InvertedButton>
      </DialogActions>
    </Dialog>
  );
};
