import { ReactNode } from 'react';

import { Box } from '@mui/material';

import { Drawer } from './Drawer';
import { VerticalNavHeader } from './VerticalNavHeader';
import { HeaderLogoProps } from '../types';

type Props = {
  hidden: boolean
  navWidth: number
  navHover: boolean
  navVisible: boolean
  collapsedNavWidth: number
  navigationBorderWidth: number
  toggleNavVisibility: () => void
  setNavHover: (values: boolean) => void
  setNavVisible: (value: boolean) => void
  headerLogoProps: HeaderLogoProps
  navigationMenu: ReactNode
};

export const Navigation = ({ headerLogoProps: { logo, logoLoading, onClickLogo }, navigationMenu, ...props }: Props) => (
  <Drawer {...props}>
    {logoLoading ? null : <VerticalNavHeader logo={logo} onClickLogo={onClickLogo} />}
    <Box sx={{ position: 'relative', overflow: 'hidden', height: '100%' }}>
      {navigationMenu}
    </Box>
  </Drawer>
);
