import {
  useEffect, useMemo, useState,
} from 'react';

import { APIUser, useGetUsers } from 'lib';
import { toast } from 'react-toastify';
import {
  AdminView, useLabels,
} from 'ui';
import {
  Button,
  useMediaQuery, useTheme,
} from '@mui/material';
import {
  ColumnDef,
  createColumnHelper,
} from '@tanstack/react-table';

import { CreateTeamMemberDialog } from './CreateTeamMemberDialog';

const sortByActivated = (a: APIUser, b: APIUser) => {
  if (a.isActivated && !b.isActivated) {
    return -1;
  }

  if (!a.isActivated && b.isActivated) {
    return 1;
  }

  return 0;
};

export const Team = ({ showTitle = false }: { showTitle?: boolean }) => {
  const theme = useTheme();
  const l = useLabels();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [openCreateTeamMemberDialog, setCreateTeamMemberDialog] = useState(false);

  const {
    data,
    isLoading,
    isError,
  } = useGetUsers(['pm', 'agent', 'admin', 'portfolio_manager', 'sale_manager']);

  const columnHelper = createColumnHelper<APIUser>();
  const columns = useMemo(() => {
    const cols: ColumnDef<APIUser, string>[] = [
      columnHelper.accessor('name', {
        header: l['pm-dashboard.teamMember.name'],
        enableSorting: true,
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('email', {
        header: l['pm-dashboard.teamMember.email'],
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('phoneNumber', {
        header: l['pm-dashboard.teamMember.phone'],
        cell: (info) => info.getValue(),
      }),
    ];

    cols.push({
      header: 'Roles',
      accessorKey: 'roles',
      cell: (info) => {
        const roleDisplayMap:{ [index: string]: string } = {
          relationship_manager: 'Relationship Manager',
          growth_manager: 'Growth Manager',
          agent: 'Agent',
          pm: 'PM',
          prospect: 'Prospect',
          owner: 'Owner',
          admin: 'Admin',
          portfolio_manager: 'Portfolio Manager',
          sale_manager: 'Sale Manager',
        };

        const { companyRole } = info.row.original;
        const roles = info.row.original.roles
          .map((role) => roleDisplayMap[role] || role)
          .sort()
          .join(', ');
        return companyRole ? (roleDisplayMap[companyRole] || companyRole) : roles;
      },
    });

    cols.push({
      header: 'Activated',
      accessorKey: 'user-activated',
      cell: (info) => (info.row.original.isActivated ? 'Yes' : 'No'),
    });

    return cols;
  }, [isMobile]);

  useEffect(() => {
    if (isError) {
      toast.error(l['error.unknownError']);
    }
  }, [isError]);

  const teamMembers = useMemo(() => {
    if (data) {
      return data?.sort(sortByActivated) as APIUser[];
    }

    return [];
  }, [data]);

  return (
    <>
      <CreateTeamMemberDialog
        open={openCreateTeamMemberDialog}
        closeDialog={() => setCreateTeamMemberDialog(false)}
      />
      <AdminView
        title={showTitle ? l['menu-team'] : undefined}
        tableHeader={(
          <Button
            variant="contained"
            type="button"
            onClick={() => setCreateTeamMemberDialog(true)}
          >
            {isMobile ? l['pm-dashboard.teamMember.invite'] : l['pm-dashboard.teamMember.inviteTeamMember']}
          </Button>
        )}
        searchPlaceholder={l['pm-dashboard.teamMember.search']}
        data={isLoading ? [] : teamMembers}
        columns={columns}
        searchTermFilter={(currProperties, search) => currProperties.filter(
          (p) => (p.email + p.name + p.roles + p.companyRole).toLowerCase().includes(search.toLowerCase()),
        )}
      />
    </>
  );
};
