import { useEffect, useState } from 'react';

import {
  getOwnerFromID, useAnalytics, useFeatures,
} from 'lib';
import {
  MdArrowBackIosNew, MdClose,
} from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  BoldTypography, copyHtmlToRTF, InvertedButton, RichEditor, RichEditorInstance, RobotHead, Spinner, useConfetti, useLabels,
} from 'ui';
import {
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack,
  useTheme,
} from '@mui/material';
import { useSignal } from '@preact/signals-react';
import { useQueryClient } from '@tanstack/react-query';

import { useGenerateEmail, useUpdateRecommendationMitigations } from '../../../../api/owner-risk';
import { ExpandedOwnerRisk } from '../../../../api/properties/types';
import { CopyEmail } from '../../../../components/copy-email/CopyEmail';
import { UserFeedback } from '../../../../components/feedback/UserFeedback';
import { QueryKey } from '../../../../types/enums';
import { showOwnerRiskMovedToInProgressToast } from '../../state';

export const GenerateEmailDialog = ({
  ownerRisk = undefined,
  open,
  onClose,
  goBack,
}: {
  ownerRisk?: ExpandedOwnerRisk | null,
  open: boolean,
  onClose: () => void,
  goBack: () => void,
}) => {
  const l = useLabels();
  const theme = useTheme();
  const { isLoading: isLoadingFeatures, ...features } = useFeatures();
  const analytics = useAnalytics();
  const confetti = useConfetti();
  const queryClient = useQueryClient();
  const {
    mutateAsync: updateEmailHtml,
    isLoading: isUpdatingEmailHtml,
  } = useUpdateRecommendationMitigations();
  const {
    mutateAsync: generateEmail,
    isLoading: isGeneratingEmail,
  } = useGenerateEmail();
  const editorState = useSignal<RichEditorInstance | null>(null);

  const [emailContent, setEmailContent] = useState({
    html: '',
    text: '',
  });
  const ownerId = ownerRisk ? ownerRisk.ownerID : '';
  const owner = getOwnerFromID(ownerId);

  const handleClickRegenerate = async () => {
    analytics.track('Button Clicked', {
      buttonName: 'Regenerate Email',
      ownerID: ownerRisk!.ownerID,
      ownerRiskID: ownerRisk!.id,
    });

    try {
      const updatedRecommendation = await generateEmail({
        recommendationID: ownerRisk!.recommendation!.id,
        regenerate: true,
      });
      editorState.value!.commands.setContent(updatedRecommendation.emailHTML ?? '');
      await queryClient.invalidateQueries([QueryKey.OWNER_RISK, ownerRisk!.id]);
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
    }
  };

  const handleClickCopyAndSave = async () => {
    analytics.track('Button Clicked', {
      buttonName: 'Copy and save',
      ownerID: ownerRisk!.ownerID,
    });

    await copyHtmlToRTF(emailContent.html, emailContent.text);

    try {
      await updateEmailHtml({
        id: ownerRisk!.id!,
        emailHTML: emailContent.html,
        userSelectedMitigations: ownerRisk!.recommendation!.mitigations,
      });
      if (features.isFreemium) {
        confetti.trigger();
      }
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
    }

    handleClickClose(null, 'buttonClick');
  };

  const handleClickGoBack = () => {
    analytics.track('Button Clicked', {
      buttonName: 'Go Back',
      ownerID: ownerRisk!.ownerID,
    });

    goBack();
  };

  const handleClickClose = (e: any, reason: 'backdropClick' | 'escapeKeyDown' | 'buttonClick') => {
    if (reason === 'backdropClick') return;

    if (showOwnerRiskMovedToInProgressToast.value) {
      showOwnerRiskMovedToInProgressToast.value = false;
      toast.success(l['retention.ownerRisk.movedToInProgressNotice']);
    }

    onClose();
  };

  useEffect(() => {
    editorState.value?.commands.setContent(ownerRisk?.recommendation?.emailHTML ?? '');
  }, [ownerRisk?.recommendation?.emailHTML, editorState.value]);

  const spinner = (
    <>
      &nbsp;
      <Spinner size={20} />
    </>
  );

  if (!ownerRisk) return null;

  return (
    <Dialog open={open} onClose={handleClickClose} fullScreen={false} fullWidth maxWidth="md" disableEscapeKeyDown>
      <DialogTitle sx={{ borderBottom: `1px solid ${theme.palette.divider}`, py: 2 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" alignItems="center" gap={2}>
            <IconButton size="small" onClick={handleClickGoBack}>
              <MdArrowBackIosNew />
            </IconButton>
            <BoldTypography variant="h6">
              {l['retention.act.generateEmailTitle']}
            </BoldTypography>
          </Stack>
          <IconButton onClick={() => handleClickClose(null, 'buttonClick')}>
            <MdClose />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack mt={3} gap={4}>
          <RichEditor
            initialContent={emailContent.html}
            onContentChange={(html, text) => {
              setEmailContent({
                html,
                text,
              });
            }}
            onCreate={({ editor }) => {
              editorState.value = editor;
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ borderTop: `1px solid ${theme.palette.divider}`, py: 3 }}>
        <Stack direction="row" justifyContent="space-between" width="100%" pt={3}>
          <Stack gap={2} direction="row" alignItems="center">
            {!!ownerRisk && !!owner && (
              <CopyEmail email={owner.email} color="primary" />
            )}
          </Stack>
          <Stack direction="row" alignItems="center" gap={2}>
            <UserFeedback
              eventData={{
                ownerID: ownerRisk.ownerID,
                recommendationSummary: ownerRisk.recommendation?.summaryMarkdown,
                mitigations: ownerRisk.recommendation?.mitigations.join(','),
              }}
              feedbackName="Generate Email Dialog"
            />
            <Button
              disabled={isGeneratingEmail}
              variant="outlined"
              onClick={handleClickRegenerate}
              startIcon={!isGeneratingEmail && <RobotHead />}
            >
              {isGeneratingEmail ? spinner : l['retention.act.regenerate']}
            </Button>
            <InvertedButton
              disabled={isUpdatingEmailHtml}
              onClick={handleClickCopyAndSave}
            >
              {isUpdatingEmailHtml ? spinner : l['retention.generateEmail.copyAndSave']}
            </InvertedButton>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
