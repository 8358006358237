import { Method } from 'axios';
import {
  axiosWithPayloadContext,
  config,
  DashboardProperty as APIDashboardProperty,
  ListDashboardPropertyByOwnerQuery,
  ListingItem,
  queryGraphQL,
  SearchListingItemsQuery,
  useAuth,
} from 'lib';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import * as Sentry from '@sentry/react';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';

import { convertProperty } from './converters';
import {
  CreateMortgageRequest,
  CreatePurchaseHistoryRequest,
  RecurringSingleTransaction,
  RecurringTransaction,
  UpdateMortgageRequest,
  UpdatePurchaseHistoryRequest,
} from './types';
import { Property } from '../../types';
import { QueryKey } from '../../types/enums';
import { PropertyForUpdate } from '../../types/property';
import { RecurringTransactionPayload, TransactionPayload } from '../../views/transactions/types';
import { listProperties, searchActiveListingPropertyIDs } from '../graphql/queries';
import { createHeaders } from '..';

class MissingListingItemPropertiesError extends Error {
  name = 'MissingListingItemPropertiesError';
}

export const useListOwnerProperties = () => {
  const { user, getAccessTokenSilently } = useAuth();

  return useQuery([QueryKey.PROPERTIES], async (): Promise<{
    properties: Property[],
    deletedProperties: Property[],
  }> => {
    const token = await getAccessTokenSilently();

    const res = await queryGraphQL({
      query: listProperties,
      authToken: token,
      variables: { owner: user?.id ?? '' },
    }) as GraphQLResult<ListDashboardPropertyByOwnerQuery>;

    if (!res.data?.listDashboardPropertyByOwner) {
      return { properties: [], deletedProperties: [] };
    }

    const filtered = res.data.listDashboardPropertyByOwner.items.filter(Boolean) as APIDashboardProperty[];
    const converted = filtered.map(convertProperty).sort((a, b) => a.displayName.localeCompare(b.displayName));

    return {
      properties: converted.filter((p) => !p.deleted),
      deletedProperties: converted.filter((p) => p.deleted),
    };
  }, { enabled: !!user?.id });
};

export const useCountActiveListings = (enabled: boolean) => {
  const { getAccessTokenSilently } = useAuth();

  const query = useInfiniteQuery({
    enabled,
    queryKey: [QueryKey.ACTIVE_LISTING_IDS],
    queryFn: async ({ pageParam: nextToken }): Promise<{
      propertyIDs: Set<string>;
      nextToken: string | null | undefined;
    }> => {
      const token = await getAccessTokenSilently();

      let res;

      try {
        res = await queryGraphQL({
          query: searchActiveListingPropertyIDs,
          variables: { nextToken },
          authToken: token,
        }) as GraphQLResult<SearchListingItemsQuery>;
      } catch (e: any) {
        res = e; // in this case, the error type is GraphQLResult object and we want the process to continue.
      }

      if (!res.data?.searchMarketplacePropertyListingItems?.items?.length) {
        return { propertyIDs: new Set(), nextToken: null };
      }

      const propertyIDs = new Set<string>();

      const listingItemsWithMissingPropertyIDs = new Set<string>();

      res.data.searchMarketplacePropertyListingItems.items.forEach((item: ListingItem) => {
        if (!item?.marketplacePropertyListingItemsId) return;

        if (!item.property) {
          listingItemsWithMissingPropertyIDs.add(item.id);
          return;
        }

        propertyIDs.add(item.marketplacePropertyListingItemsId);
      });

      if (listingItemsWithMissingPropertyIDs.size > 0) {
        Sentry.withScope((scope) => {
          scope.setContext('errorData', {
            listingItemIDs: Array.from(listingItemsWithMissingPropertyIDs),
          });

          Sentry.captureException(new MissingListingItemPropertiesError('Missing properties in listing items'));
        });
      }

      return {
        propertyIDs,
        nextToken: res.data.searchMarketplacePropertyListingItems.nextToken,
      };
    },
    getNextPageParam: (lastPage) => lastPage.nextToken,
    onSuccess: (data) => {
      if (data.pages[data.pages.length - 1]?.nextToken) {
        query.fetchNextPage();
      }
    },
  });

  return query;
};

const createMutationHook = <Payload>(url: string, method: Method) => () => {
  const { getAccessTokenSilently } = useAuth();

  return useMutation(async (payload: Payload) => {
    const token = await getAccessTokenSilently();

    return axiosWithPayloadContext({
      url: `${config.apiBaseURL}${url}`,
      method,
      headers: createHeaders(token),
      data: payload,
    });
  });
};

const getRecurringExpenseHookByTransactionId = (url: string) => (id: string, isEnabled: boolean) => {
  const { getAccessTokenSilently } = useAuth();

  return useQuery([QueryKey.RECURRING_EXPENSE, id], async () => {
    const token = await getAccessTokenSilently();

    return axiosWithPayloadContext<RecurringTransaction>({
      url: `${config.apiBaseURL}${url}/?byTransactionId=${id}`,
      method: 'GET',
      headers: createHeaders(token),
    });
  }, { enabled: isEnabled });
};

const createExpenseHookDelete = (url: string) => () => {
  const { getAccessTokenSilently } = useAuth();

  return useMutation(async (id: string) => {
    const token = await getAccessTokenSilently();
    return axiosWithPayloadContext({
      url: `${config.apiBaseURL}${url}/${id}`,
      method: 'DELETE',
      headers: createHeaders(token),
    });
  });
};

const deleteSingleRecurringExpenseHook = (url: string) => () => {
  const { getAccessTokenSilently } = useAuth();

  return useMutation(async (recurringTransaction: RecurringSingleTransaction) => {
    const token = await getAccessTokenSilently();
    return axiosWithPayloadContext({
      url: `${config.apiBaseURL}${url}/${recurringTransaction.recurringId}/transaction/${recurringTransaction.transactionId}`,
      method: 'DELETE',
      headers: createHeaders(token),
    });
  });
};

export const useCreatePurchaseHistory = createMutationHook<CreatePurchaseHistoryRequest>('/purchase-history', 'POST');
export const useUpdatePurchaseHistory = createMutationHook<UpdatePurchaseHistoryRequest>('/purchase-history', 'PUT');
export const useCreateMortgage = createMutationHook<CreateMortgageRequest>('/property-mortgages', 'POST');
export const useUpdateMortgage = createMutationHook<UpdateMortgageRequest>('/property-mortgages', 'PUT');
export const useDeleteMortgage = createExpenseHookDelete('/property-mortgages');
export const useCreateExpenseOneTime = createMutationHook<TransactionPayload>('/balance', 'POST');
export const useCreateExpenseRecurrent = createMutationHook<RecurringTransactionPayload>('/property-user-expenses', 'POST');
export const useUpdateExpense = createMutationHook<TransactionPayload>('/balance', 'PUT');
export const useUpdateRecurringExpense = createMutationHook<RecurringTransactionPayload>('/property-user-expenses', 'PUT');
export const useDeleteExpense = createExpenseHookDelete('/balance');
export const useDeleteSingleRecurringExpense = deleteSingleRecurringExpenseHook('/property-user-expenses');
export const useDeleteRecurringExpense = createExpenseHookDelete('/property-user-expenses');
export const useGetRecurringExpenseByTransactionId = getRecurringExpenseHookByTransactionId('/property-user-expenses');
export const useUpdateProperty = createMutationHook<PropertyForUpdate>('/dashboard-property', 'PUT');
