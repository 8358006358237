import { useEffect, useState } from 'react';

import {
  useAnalytics, useUploadUsers,
} from 'lib';
import Papa from 'papaparse';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import {
  BoldTypography,
  ConfirmedDialog,
  Spinner,
  useLabels,
} from 'ui';
import {
  Box, Button,
  Dialog,
  DialogContent,
  keyframes, Stack, styled, Typography,
  useMediaQuery, useTheme,
} from '@mui/material';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export type UserImport = {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
};

export type ValidateUserImport = {
  isValid: boolean;
  message: string;
  user: UserImport
};

export const FooterActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: theme.spacing(3),
  padding: theme.spacing(3),
  borderTop: `1px solid ${theme.palette.divider}`,
  bottom: '-1px',
  background: theme.palette.background.paper,
  zIndex: '1600',
  '&.sticky': {
    position: 'sticky',
    animation: `${fadeIn} 0.3s ease-in-out`,
  },
}));

export const ImportProspectsDialog = ({ open, closeDialog }: { open: boolean, closeDialog: () => void, }) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const { mutateAsync: uploadUsers, isLoading: isUploadingUsers } = useUploadUsers();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [prospects, setProspects] = useState<ValidateUserImport[]>([]);
  const [validFile, setValidFile] = useState<File | null>(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const uploadDisabled = (
    isUploadingUsers || !validFile || prospects.filter((v) => !v.isValid).length > 0 || prospects.length === 0
  );

  const handleDownload = () => {
    const csvContent = 'email,firstName,lastName,phoneNumber';
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'template.csv';
    link.click();
    window.URL.revokeObjectURL(url);
  };

  const verifyUser = (importUser: UserImport): ValidateUserImport => {
    if (importUser.email === '') {
      return {
        message: 'email is empty',
        isValid: false,
        user: importUser,
      };
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isEmailValid = emailRegex.test(importUser.email);

    if (!isEmailValid) {
      return {
        message: 'email is not valid',
        isValid: false,
        user: importUser,
      };
    }

    return {
      isValid: true,
      message: '',
      user: importUser,
    };
  };

  const uploadFiles = (acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) return;

    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const csvData = e.target?.result as string;
      const usersToImport = Papa.parse<UserImport>(csvData, { header: true }).data;
      const prospectsFromFile = usersToImport.map((u) => verifyUser(u));
      setProspects(prospectsFromFile);
      if (!prospectsFromFile.some((p) => !p.isValid)) {
        setValidFile(file);
      }
    };
    reader.readAsText(file);
  };

  const save = async () => {
    analytics.track('Button Clicked', {
      buttonName: 'Bulk Invite - Import',
      numOfProspects: prospects.length,
    });

    if (uploadDisabled) return;

    try {
      await uploadUsers({ file: validFile!, type: 'prospects' });
      closeDialog();
      setConfirmDialogOpen(true);
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: uploadFiles, multiple: false });

  useEffect(() => () => {
    if (open) return;

    setProspects([]);
    setValidFile(null);
  }, [open]);

  return (
    <>
      <Dialog
        fullScreen={isMobile}
        open={open}
        maxWidth="sm"
        sx={{ zIndex: 1600 }}
      >
        <DialogContent sx={{ p: 0 }}>
          <Box
            p={4}
            position="relative"
            flexGrow="1"
            minHeight={isMobile ? '100%' : '400px'}
            width={isMobile ? undefined : theme.breakpoints.values.sm}
          >
            <Stack spacing={4}>
              <Stack
                justifyContent="flex-start"
                alignItems="left"
                spacing={1}
              >
                <Stack>
                  <BoldTypography>
                    {l['pm-dashboard.prospects.inviteProspects']}
                  </BoldTypography>
                </Stack>
                <Stack>
                  <Typography>
                    {l['pm-dashboard.prospects.inviteDescription']}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  pt={4}
                  justifyContent="flex-start"
                >
                  <Button
                    component="a"
                    sx={{ padding: 0, color: 'blue' }}
                    onClick={handleDownload}
                  >
                    {l['pm-dashboard.prospects.downloadTemplate']}
                  </Button>
                </Stack>
              </Stack>
              <Stack
                height="150px"
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Stack
                  width="100%"
                  height="100%"
                  {...getRootProps()}
                  sx={{
                    p: 2,
                    border: '2px dashed',
                    borderRadius: 1,
                    borderColor: isDragActive ? 'primary.main' : 'text.disabled',
                    textAlign: 'center',
                    cursor: 'pointer',
                    justifyContent: 'center',
                  }}
                >
                  <Box>
                    <input {...getInputProps()} />
                    <Typography>
                      {l['pm-dashboard.prospects.dragAndDrop']}
                    </Typography>
                  </Box>
                </Stack>
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Stack direction="column">
                  {prospects.length > 0 && prospects.filter((v) => !v.isValid).length === 0 && (
                    <Typography variant="body1">
                      <b>{prospects.filter((v) => v.isValid).length}</b>
                      {l['pm-dashboard.prospects.prospectsWillBeInvited']}
                    </Typography>
                  )}
                  {prospects.filter((v) => !v.isValid).length > 0 && (
                    <>
                      <Stack>
                        {l['pm-dashboard.prospects.invalidProspectsPart1']}
                        {prospects.filter((v) => !v.isValid).length}
                        {l['pm-dashboard.prospects.invalidProspectsPart2']}
                      </Stack>
                      {prospects.filter((v) => !v.isValid).map((inValidProspect) => (
                        <Stack direction="row" key={inValidProspect.user.email} gap={3}>
                          <Typography variant="body1">
                            {l.email}
                            :
                            {' '}
                            {inValidProspect.user.email}
                          </Typography>
                          <Typography variant="body1" color="error">
                            {inValidProspect.message}
                          </Typography>
                        </Stack>
                      ))}
                    </>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Box>
          <FooterActions className="sticky">
            <Button
              onClick={closeDialog}
              variant="text"
              sx={{ py: 3, color: theme.palette.text.primary }}
            >
              {l.cancel}
            </Button>
            <Button
              variant="contained"
              disabled={uploadDisabled}
              sx={{ py: 3 }}
              onClick={save}
            >
              {isUploadingUsers ? (
                <>
                &nbsp;
                  <Spinner size={20} />
                </>
              ) : l.import}
            </Button>
          </FooterActions>
        </DialogContent>
      </Dialog>
      <ConfirmedDialog
        message={l['pm-dashboard.prospects.importSuccess']}
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
      />
    </>
  );
};
