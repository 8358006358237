import { MdClose } from 'react-icons/md';
import { BoldTypography, MediumTypography, useLabels } from 'ui';
import {
  Box,
  Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, useMediaQuery, useTheme,
} from '@mui/material';

import welcomeToMarketplaceDesktop from '../../assets/welcome-marketplace-desktop.webp';
import welcomeToMarketplaceMobile from '../../assets/welcome-marketplace-mobile.webp';

type WelcomeToMarketplaceDialogProp = {
  open: boolean;
  closeDialog: () => void;
};

export const WelcomeToMarketplaceDialog = ({
  open, closeDialog,
}: WelcomeToMarketplaceDialogProp) => {
  const theme = useTheme();
  const l = useLabels();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      fullScreen={isMobile}
      open={open}
      maxWidth="sm"
      sx={{ zIndex: 1800 }}
    >
      <DialogTitle component={Stack} alignItems="flex-end" borderBottom={`1px solid ${theme.palette.divider}`} sx={{ p: 3 }}>
        <IconButton aria-label="Close" onClick={() => closeDialog()} size="medium" color="secondary">
          <MdClose />
        </IconButton>
      </DialogTitle>
      <Box>
        <img
          width="100%"
          src={isMobile ? welcomeToMarketplaceMobile : welcomeToMarketplaceDesktop}
          alt="Welcome to the Marketplace"
        />
      </Box>
      <Box
        component={DialogContent}
        sx={{
          mt: 10,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <BoldTypography variant="h5" sx={{ textAlign: 'center' }}>
          {l.welcomeToMarketPlaceTitle}
        </BoldTypography>
        <MediumTypography
          variant="body1"
          sx={{
            textAlign: 'center', mt: 3, whiteSpace: 'pre-line', px: 6,
          }}
        >
          {l.welcomeToMarketplaceInfo}
        </MediumTypography>
        <Button variant="contained" sx={{ mt: 8 }} onClick={closeDialog}>
          {l.continue}
        </Button>
      </Box>
    </Dialog>
  );
};
