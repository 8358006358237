/* eslint-disable no-console */
import {
  useState,
} from 'react';

import {
  SellableProperty,
  useAnalytics,
  useCreateExternalMarketplaceProperty,
  useCreateMarketplaceProperty,
} from 'lib';
import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  SellProperty,
  SellSelector,
  useLabels,
} from 'ui';
import {
  Box,
  Button,
  Container,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { FooterActions } from './styled';

export const SellPropertyContents = ({
  closeDialog,
  cancelDialog,
  title = '',
  sellableProperties,
  propertyIDs,
}: {
  closeDialog: () => void,
  cancelDialog: () => void,
  title?: string,
  sellableProperties: SellableProperty[],
  propertyIDs: string[]
}) => {
  const theme = useTheme();
  const l = useLabels();
  const analytics = useAnalytics();
  const [sellProperty, setSellProperty] = useState<SellProperty>();
  const [submitting, setSubmitting] = useState(false);

  const nextButtonDisabled = (submitting || !sellProperty?.id
      || (sellProperty.hasPriceInMind && !sellProperty.price))
      && !sellProperty?.externalPropertyAddress;

  const createMarketplacePropertyMutation = useCreateMarketplaceProperty();
  const createExternalListingMutation = useCreateExternalMarketplaceProperty();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const nextButtonText = submitting ? l.pleaseWait : l.continue;

  const handleSubmit = async () => {
    closeDialog();

    const originalPrice = sellableProperties.find((property) => property.id === sellProperty?.id)?.prices?.priceMean || '';

    analytics.track('Form Submitted', {
      formName: 'Sell Property - Sell',
      propertyId: sellProperty?.id,
      price: sellProperty?.price,
      originalPrice,
      idkPrice: sellProperty?.hasPriceInMind,
    });

    if (!sellProperty?.address && !sellProperty?.isOtherOptionSelected) return;
    setSubmitting(true);
    try {
      if (sellProperty.isOtherOptionSelected) {
        await createExternalListingMutation.mutateAsync({
          fullAddress: sellProperty.externalPropertyAddress,
          askingPrice: !sellProperty.price || !sellProperty.hasPriceInMind ? 0 : sellProperty.price,
        });
      } else if (sellProperty.address) {
        await createMarketplacePropertyMutation.mutateAsync({
          propertyId: sellProperty.id,
          address: sellProperty.address,
          askingPrice: !sellProperty.price || !sellProperty.hasPriceInMind ? 0 : sellProperty.price,
          category: 'unknown',
        });
      }
      closeDialog();
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container sx={{
      height: '100%', p: 0, flexGrow: 1,
    }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 3, backgroundColor: theme.palette.common.white }}
      >
        <Typography variant="h5">
          {title }
        </Typography>
        <IconButton onClick={() => {
          cancelDialog();
        }}
        >
          <MdClose />
        </IconButton>
      </Stack>
      <Box
        position="relative"
        flexGrow="1"
        minHeight={isMobile ? '100%' : '400px'}
        paddingRight={10}
        width={isMobile ? undefined : theme.breakpoints.values.sm}
      >
        <SellSelector
          onSellPropertyChanged={(sp:SellProperty) => setSellProperty(sp)}
          sellableProperties={sellableProperties.filter((sp) => !propertyIDs.includes(sp.id))}
        />
      </Box>
      <FooterActions className="sticky">
        <Button
          onClick={() => {
            analytics.track('Button Clicked', {
              buttonName: 'Sell Property - Cancel',
            });
            cancelDialog();
          }}
          variant="text"
          sx={{ py: 3, color: theme.palette.text.primary }}
        >
          {l.cancel}
        </Button>
        <Button
          disabled={nextButtonDisabled}
          onClick={handleSubmit}
          variant="contained"
          sx={{ py: 3 }}
        >
          {nextButtonText}
        </Button>
      </FooterActions>
    </Container>
  );
};
