import { useState } from 'react';

import { useAnalytics } from 'lib';
import {
  PiThumbsDown, PiThumbsDownFill, PiThumbsUp, PiThumbsUpFill,
} from 'react-icons/pi';
import { toast } from 'react-toastify';
import {
  Chip, InvertedButton, LightTooltip, useLabels,
} from 'ui';
import {
  Collapse, IconButton, Stack, TextField, Typography, useTheme,
} from '@mui/material';

export const UserFeedback = ({
  eventData,
  feedbackName,
}: {
  eventData: any,
  feedbackName: string,
}) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [recommendationFeedback, setRecommendationFeedback] = useState<{
    feedback: string,
    feedbackOther: string,
    isPositive: boolean | null,
  }>({
    feedback: '',
    feedbackOther: '',
    isPositive: null,
  });

  const submitFeedback = (feedback: typeof recommendationFeedback) => {
    analytics.track('AI Feedback Submitted', {
      feedbackName,
      ...eventData,
      ...feedback,
    });

    toast.success(l['retention.recommendation.feedbackSubmitted']);
    setTooltipOpen(false);
  };

  const handleClickThumbsUp = () => {
    const feedback = {
      ...recommendationFeedback,
      isPositive: true,
    };

    setRecommendationFeedback(feedback);
    submitFeedback(feedback);
  };

  const handleClickThumbsDown = () => {
    const feedback = {
      ...recommendationFeedback,
      isPositive: false,
    };

    setRecommendationFeedback(feedback);
    setTooltipOpen(true);
  };

  const handleClickFeedbackChip = (label: string) => {
    const feedback = {
      ...recommendationFeedback,
      feedback: label,
    };

    setRecommendationFeedback(feedback);
    if (label !== l['retention.recommendation.feedbackTextOther']) {
      submitFeedback(feedback);
    }
  };

  const labels = [
    l['retention.recommendation.feedbackText1'],
    l['retention.recommendation.feedbackText2'],
    l['retention.recommendation.feedbackTextOther'],
  ];

  return (
    <Stack gap={4}>
      <Stack direction="row" alignItems="center" justifyContent="flex-end" width="100%" gap={2}>
        <IconButton onClick={handleClickThumbsUp} size="small">
          {recommendationFeedback.isPositive ? (
            <PiThumbsUpFill color={theme.palette.primary.dark} size={24} />
          ) : <PiThumbsUp color={theme.palette.primary.main} size={24} />}
        </IconButton>
        <LightTooltip
          arrow
          open={tooltipOpen}
          title={(
            <Stack gap={2} p={1}>
              <Typography variant="body2">
                {l['retention.recommendation.tellUsMore']}
              </Typography>
              <Stack direction="row" gap={2} flexWrap="wrap">
                {labels.map((label) => {
                  const active = recommendationFeedback.feedback === label;

                  return (
                    <Chip
                      key={label}
                      label={label}
                      onClick={() => handleClickFeedbackChip(label)}
                      sx={{
                        color: active ? theme.palette.primary.contrastText : theme.palette.text.secondary,
                        backgroundColor: active ? theme.palette.primary.dark : theme.palette.background.paper,
                        border: `1px solid ${theme.palette.divider}`,
                        '&:hover': {
                          color: theme.palette.primary.contrastText,
                        },
                      }}
                      size="small"
                    />
                  );
                })}
                <Collapse
                  in={recommendationFeedback.feedback === l['retention.recommendation.feedbackTextOther']}
                  sx={{ width: '100%' }}
                >
                  <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2}>
                    <TextField
                      fullWidth
                      size="small"
                      label={l['retention.recommendation.feedbackTextOther']}
                      placeholder={l.pleaseSpecify}
                      value={recommendationFeedback.feedbackOther}
                      onChange={(e) => {
                        setRecommendationFeedback((prev) => ({
                          ...prev,
                          feedbackOther: e.target.value,
                        }));
                      }}
                    />
                    <InvertedButton onClick={() => submitFeedback(recommendationFeedback)}>
                      {l.submit}
                    </InvertedButton>
                  </Stack>
                </Collapse>
              </Stack>
            </Stack>
          )}
        >
          <IconButton
            onClick={handleClickThumbsDown}
            size="small"
          >
            {recommendationFeedback.isPositive === false ? (
              <PiThumbsDownFill color={theme.palette.primary.dark} size={24} />
            ) : <PiThumbsDown color={theme.palette.primary.main} size={24} />}
          </IconButton>
        </LightTooltip>
      </Stack>
    </Stack>
  );
};
