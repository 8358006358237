import { useEffect } from 'react';

import {
  buildPartnerReferralLink, OwnerReferralsIncentive, useAnalytics, useAppSettings, useAuth,
} from 'lib';
import { Controller, useForm } from 'react-hook-form';
import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  BoldTypography, CopyIcon, InvertedButton, Spinner, useConfetti, useLabels,
} from 'ui';
// eslint-disable-next-line import/no-extraneous-dependencies
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { CreateOwnerLeadData, createOwnerLeadSchema, useCreateOwnerLead } from '../../api/ownerLeads';

export const ReferAnOwnerDialog = ({
  open,
  onClose,
  b2cReferralIncentive,
}: {
  open: boolean,
  onClose: () => void,
  b2cReferralIncentive: OwnerReferralsIncentive,
}) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const confetti = useConfetti();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { user } = useAuth();
  const { data: appSettings } = useAppSettings();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
    trigger: validateForm,
  } = useForm<CreateOwnerLeadData>({
    resolver: zodResolver(createOwnerLeadSchema),
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
    },
  });
  const { mutateAsync: createOwnerLead, isLoading: isCreatingOwnerLead } = useCreateOwnerLead();

  const handleClickClose = (e: any, reason: 'backdropClick' | 'escapeKeyDown' | 'buttonClick') => {
    if (reason === 'backdropClick') return;

    analytics.track('Button Clicked', {
      buttonName: 'Close',
    });

    onClose();
  };

  const linkID = encodeURI(user?.email ?? '');
  const isLoading = isCreatingOwnerLead;
  const link = buildPartnerReferralLink(linkID);

  const handleClickAdd = async () => {
    analytics.track('Button Clicked', {
      buttonName: 'Create Owner Lead',
      ...getValues(),
    });

    const valid = await validateForm();

    if (!valid) return;

    try {
      await handleSubmit(async (data) => createOwnerLead(data))();
      toast.success(l['referAnOwner.ownerLead.ownerLeadCreated']);
      confetti.trigger();
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
    }

    onClose();
  };

  useEffect(() => {
    if (!open) reset();
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClickClose}
      fullScreen={isMobile}
      fullWidth
      maxWidth="sm"
      disableEscapeKeyDown
      sx={{
        zIndex: 2000,
      }}
    >
      <Stack pt={8}>
        <Stack alignItems="flex-end" flexGrow={1} position="relative">
          <IconButton
            onClick={() => handleClickClose({}, 'buttonClick')}
            sx={{ position: 'absolute', top: theme.spacing(-4), right: theme.spacing(4) }}
          >
            <MdClose />
          </IconButton>
        </Stack>
        <Stack alignItems="center">
          <BoldTypography variant="h6">
            {l['referAnOwner.referAndEarn']}
          </BoldTypography>
          <Typography variant="body3" color="secondary" sx={{ textAlign: 'center' }}>
            {l.dynamic['referAnOwner.referAndEarn.dialog.getSubtitle'](appSettings?.pmName ?? '', b2cReferralIncentive)}
          </Typography>
        </Stack>
      </Stack>
      <DialogContent sx={{ p: `${theme.spacing(8)} !important` }}>
        <Stack gap={4} width="100%">
          <BoldTypography variant="body1">{l['referAnOwner.shareAndInvite']}</BoldTypography>
          <Stack width="100%" direction="row" gap={4} alignItems="center" flexWrap="wrap">
            <TextField
              sx={{ flexGrow: 1 }}
              size="small"
              disabled
              value={link}
            />
            <CopyLinkButton link={link} />
          </Stack>
          <Stack direction="row" gap={3} alignItems="center" justifyContent="center" my={2}>
            <Box sx={{ flexGrow: 1, height: 2, background: theme.palette.divider }} />
            <Typography variant="body1" sx={{ textAlign: 'center', textTransform: 'uppercase' }} color="secondary">
              {l.or}
            </Typography>
            <Box sx={{ flexGrow: 1, height: 2, background: theme.palette.divider }} />
          </Stack>
          <Stack gap={4}>
            <Stack gap={1}>
              <BoldTypography variant="body1">{l['referAnOwner.referDirectly']}</BoldTypography>
              <Typography variant="body3" color="secondary">{l['referAnOwner.referDirectly.subtitle']}</Typography>
            </Stack>
            <Stack direction="row" gap={4} justifyContent="space-between" width="100%" flexWrap="wrap">
              <Stack gap={2} flexGrow={1}>
                <BoldTypography variant="body2">{l['referAnOwner.ownerLead.firstName']}</BoldTypography>
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      size="small"
                      placeholder={l['referAnOwner.ownerLead.firstName']}
                      fullWidth
                      autoComplete="off"
                      variant="outlined"
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      error={!!errors.firstName}
                      helperText={errors.firstName?.message}
                    />
                  )}
                />
              </Stack>
              <Stack gap={2} flexGrow={1}>
                <BoldTypography variant="body2">{l['referAnOwner.ownerLead.lastName']}</BoldTypography>
                <Controller
                  name="lastName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      size="small"
                      placeholder={l['referAnOwner.ownerLead.lastName']}
                      autoComplete="off"
                      variant="outlined"
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      error={!!errors.lastName}
                      helperText={errors.lastName?.message}
                    />
                  )}
                />
              </Stack>
            </Stack>
            <Stack direction="row" gap={4} justifyContent="space-between" width="100%" flexWrap="wrap">
              <Stack gap={2} flexGrow={1}>
                <BoldTypography variant="body2">{l['referAnOwner.ownerLead.email']}</BoldTypography>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      size="small"
                      placeholder={l['referAnOwner.ownerLead.email']}
                      autoComplete="off"
                      type="email"
                      variant="outlined"
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      error={!!errors.email}
                      helperText={errors.email?.message}
                    />
                  )}
                />
              </Stack>
              <Stack gap={2} flexGrow={1}>
                <BoldTypography variant="body2">{l['referral-partners.add.phoneNumber']}</BoldTypography>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      size="small"
                      placeholder={l['referral-partners.add.phoneNumber']}
                      autoComplete="off"
                      variant="outlined"
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      error={!!errors.phone}
                      helperText={errors.phone?.message}
                    />
                  )}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" alignItems="center" justifyContent="space-around" width="100%" pt={3} gap={2}>
          <InvertedButton
            onClick={handleClickAdd}
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                &nbsp;
                <Spinner size={20} />
              </>
            ) : l.invite}
          </InvertedButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export const CopyLinkButton = ({ link }: { link: string }) => {
  const theme = useTheme();
  const l = useLabels();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const copyToClipboard = () => {
    navigator.clipboard.writeText(link).then(() => {
      toast.success(l['referral-partners.copyText']);
    }).catch(() => {
      toast.error(l['error.unknownError']);
    });
  };

  const width = isMobile ? '100%' : '30%';

  return (
    <Button fullWidth={isMobile} sx={{ width }} variant="outlined" onClick={copyToClipboard} startIcon={<CopyIcon />}>
      {l['referral-partners.copyLink']}
    </Button>
  );
};
