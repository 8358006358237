import {
  PMMetrics as ApiPMMetrics,
} from 'lib';

import { PMMetrics } from './types';

export const convertPmMetrics = (pmMetrics?: ApiPMMetrics): PMMetrics => ({
  usersMetrics: pmMetrics?.usersMetrics ?? null,
  communicationMetrics: pmMetrics?.communicationMetrics ?? null,
  propertyMetrics: pmMetrics?.propertyMetrics ?? null,
  riskMetrics: pmMetrics?.riskMetrics ?? null,
  leadMetrics: pmMetrics?.leadMetrics ?? null,
});
