import {
  AgentProvidedBenefit, CompanyGuarantees, CompanyServices,
  ManagedPropertyType, OwnerReferralsIncentive,
} from 'lib';
import { z } from 'zod';

const fieldRequiredMessage = 'This field is required';
const numberField = z.number({ invalid_type_error: fieldRequiredMessage });

export const growthPolicySchema = z.object({
  // step 1
  agentReferralFeeAmount: numberField.optional(),
  agentProvidedBenefits: z.array(z.nativeEnum(AgentProvidedBenefit)).min(1, 'Please select at least one option'),
  agentProvidedBenefitsOther: z.string().optional(),
  enableOwnerReferralProgram: z.boolean().nullable(),
  ownerReferralsIncentive: z.nativeEnum(OwnerReferralsIncentive).nullable(),
  enablePMReferralProgram: z.boolean().nullable(),
  pmReferralsFeeAmount: numberField.optional(),

  // step 2
  companyDescription: z.string().min(1, fieldRequiredMessage),
  typeOfPropertiesManaged: z.array(z.nativeEnum(ManagedPropertyType)).min(1, 'Please select at least one option'),
  doorCount: numberField.min(1, fieldRequiredMessage),
  workingCounties: z.array(z.object({
    state: z.string(),
    county: z.string(),
  })).min(1, 'Please select at least one option'),
  website: z.string().url().or(z.literal('')).optional(),
  pmAvatarURL: z.string().optional(),
  pmFirstName: z.string().min(1, fieldRequiredMessage),
  pmLastName: z.string().min(1, fieldRequiredMessage),
  pmTitle: z.string().min(1, fieldRequiredMessage),
  companyGoogleReviews: numberField.min(1, fieldRequiredMessage),
  companyAVGDayOnMarket: numberField.min(1, fieldRequiredMessage),
  companyTenantRenewalRate: numberField.min(1, fieldRequiredMessage),
  companyServices: z.array(z.nativeEnum(CompanyServices)).min(1, 'Please select at least one option'),
  companyServicesOther: z.string().optional(),
  companyGuarantees: z.array(z.nativeEnum(CompanyGuarantees)).min(1, 'Please select at least one option'),
  companyGuaranteesOther: z.string().optional(),
  yearsInBusiness: numberField.min(1, fieldRequiredMessage),
})
  .refine((data) => isAgentReferralFeeAmountValid(data.agentReferralFeeAmount), {
    message: fieldRequiredMessage,
    path: ['agentReferralFeeAmount'],
  })
  .refine((data) => isAgentProvidedBenefitsOtherValid(data.agentProvidedBenefits, data.agentProvidedBenefitsOther ?? ''), {
    message: fieldRequiredMessage,
    path: ['agentProvidedBenefitsOther'],
  })
  .refine((data) => isOwnerReferralsIncentiveAmountValid(
    data.enableOwnerReferralProgram,
    data.ownerReferralsIncentive,
  ), {
    message: fieldRequiredMessage,
    path: ['ownerReferralsIncentive'],
  })
  .refine((data) => isPMReferralsFeeAmountValid(
    data.enablePMReferralProgram,
    data.pmReferralsFeeAmount,
  ), {
    message: fieldRequiredMessage,
    path: ['pmReferralsFeeAmount'],
  })
  .refine((data) => data.enableOwnerReferralProgram !== null, {
    message: fieldRequiredMessage,
    path: ['enableOwnerReferralProgram'],
  })
  .refine((data) => data.enablePMReferralProgram !== null, {
    message: fieldRequiredMessage,
    path: ['enablePMReferralProgram'],
  })
  .refine((data) => isCompanyServicesOtherValid(data.companyServices, data.companyServicesOther), {
    message: fieldRequiredMessage,
    path: ['companyServicesOther'],
  })
  .refine((data) => isCompanyGuaranteesOtherValid(data.companyGuarantees, data.companyGuaranteesOther), {
    message: fieldRequiredMessage,
    path: ['companyGuaranteesOther'],
  });

export type GrowthPolicyData = z.infer<typeof growthPolicySchema>;

const isAgentReferralFeeAmountValid = (agentReferralFeeAmount?: number | null): boolean => (
  typeof agentReferralFeeAmount === 'number'
);

const isAgentProvidedBenefitsOtherValid = (agentProvidedBenefits: AgentProvidedBenefit[] | null, other: string): boolean => (
  !(agentProvidedBenefits ?? []).includes(AgentProvidedBenefit.OTHER) || !!other.trim()
);

const isOwnerReferralsIncentiveAmountValid = (
  enableOwnerReferralProgram: boolean | null,
  ownerReferralsIncentive?: OwnerReferralsIncentive | null,
): boolean => !enableOwnerReferralProgram || (enableOwnerReferralProgram && ownerReferralsIncentive !== null);

const isPMReferralsFeeAmountValid = (
  enablePMReferralProgram: boolean | null,
  pmReferralsFeeAmount?: number | null,
): boolean => !enablePMReferralProgram || !!pmReferralsFeeAmount;

const isCompanyServicesOtherValid = (
  companyServices: CompanyServices[],
  companyServicesOther?: string,
): boolean => (
  !companyServices.includes(CompanyServices.OTHER) || !!companyServicesOther?.trim()
);

const isCompanyGuaranteesOtherValid = (
  companyGuarantees: CompanyGuarantees[],
  companyGuaranteesOther?: string,
): boolean => (
  !companyGuarantees.includes(CompanyGuarantees.OTHER) || !!companyGuaranteesOther?.trim()
);
