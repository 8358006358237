import { MdCheckCircle, MdClose } from 'react-icons/md';
import {
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { SuccessPopupContent } from './styled';
import {
  Avatar,
} from '../avatar/Avatar';
import {
  BoldTypography,
} from '../typography/BoldTypography';

export const SuccessDialog = ({
  open, closeDialog, title, description, btnText,
}: {
  open: boolean, closeDialog: ()=>void, title: string, description: string, btnText: string }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      open={open}
      fullScreen={isMobile}
      onClose={closeDialog}
      sx={{ zIndex: 1600 }}
      maxWidth="xs"
    >
      <DialogTitle component={Stack} alignItems="flex-end" borderBottom={`1px solid ${theme.palette.divider}`} sx={{ p: 3 }}>
        <IconButton aria-label="Close" onClick={closeDialog} size="medium" color="secondary">
          <MdClose />
        </IconButton>
      </DialogTitle>
      <SuccessPopupContent>
        <Avatar skin="light" variant="circular" color="info" sx={{ height: 96, width: 96, mb: 3 }}>
          <MdCheckCircle size={40} color={theme.palette.primary.main} />
        </Avatar>
        <BoldTypography variant="h5" sx={{ mb: 3 }}>
          {title}
        </BoldTypography>
        <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center', mb: 6 }}>
          {description}
        </Typography>
        <Button
          onClick={closeDialog}
          variant="text"
          sx={{ color: theme.palette.text.primary }}
        >
          {btnText}
        </Button>
      </SuccessPopupContent>
    </Dialog>
  );
};
