import { Theme } from '@mui/material/styles';

export const typography = (theme: Theme) => ({
  h1: {
    fontWeight: 700,
    letterSpacing: '-1.5px',
    color: theme.palette.text.primary,
    fontSize: '6rem',
    lineHeight: 1.1667,

  },
  h2: {
    fontWeight: 700,
    letterSpacing: '-0.5px',
    color: theme.palette.text.primary,
    fontSize: '3.75rem',
    lineHeight: 1.2,
  },
  h3: {
    fontWeight: 700,
    letterSpacing: 0,
    color: theme.palette.text.primary,
    fontSize: '3rem',
    lineHeight: 1.1667,
  },
  h4: {
    fontWeight: 700,
    letterSpacing: '0.25px',
    color: theme.palette.text.primary,
    fontSize: '2rem',
    lineHeight: 1.25,
  },
  h5: {
    fontWeight: 700,
    letterSpacing: 0,
    color: theme.palette.text.primary,
    fontSize: '1.5rem',
    lineHeight: 1.33,
  },
  h6: {
    fontWeight: 700,
    letterSpacing: '0.15px',
    color: theme.palette.text.primary,
    fontSize: '1.25rem',
  },
  subtitle1: {
    fontWeight: 600,
    letterSpacing: '0.15px',
    color: theme.palette.text.primary,
    fontSize: '1.125rem',
  },
  subtitle2: {
    fontWeight: 600,
    letterSpacing: '0.1px',
    color: theme.palette.text.primary,
    fontSize: '0.875rem',
  },
  body1: {
    fontWeight: 600,
    letterSpacing: '0.15px',
    color: theme.palette.text.primary,
    fontSize: '1rem',
  },
  body2: {
    fontWeight: 600,
    lineHeight: 1.429,
    letterSpacing: '0.15px',
    color: theme.palette.text.primary,
  },
  body3: {
    fontWeight: 600,
    fontSize: '0.75rem',
    letterSpacing: '0.15px',
    color: theme.palette.text.primary,
  },
  button: {
    letterSpacing: '0.4px',
    color: theme.palette.text.primary,
  },
  caption: {
    lineHeight: 1.25,
    letterSpacing: '0.4px',
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: '0.875rem',
  },
  overline: {
    letterSpacing: '1px',
    color: theme.palette.text.primary,
    fontSize: '0.75rem',
    fontWeight: 400,
  },
  body1bold: {
    fontWeight: 700,
    letterSpacing: '0.15px',
    color: theme.palette.text.primary,
    fontSize: '1rem',
  },
  body2bold: {
    fontWeight: 700,
    lineHeight: 1.429,
    letterSpacing: '0.15px',
    color: theme.palette.text.primary,
    fontSize: '0.875rem',
  },
});
