import { ReactNode } from 'react';

import { ApexOptions } from 'apexcharts';
import Apexcharts from 'react-apexcharts';
import {
  Stack, Theme, useTheme,
} from '@mui/material';

import { formatNumber, formatNumberToCurrency } from '../lib/formatFuncs';

export const getChartConfig = (theme: Theme, formatter?: (v: any) => string, tooltipAsDollars?: boolean): ApexOptions => ({
  chart: {
    type: 'donut',
    height: '100%',
  },
  states: {
    hover: {
      filter: {
        type: 'none',
      },
    },
    active: {
      filter: {
        type: 'none',
      },
    },
  },
  plotOptions: {
    pie: {
      expandOnClick: false,
      donut: {
        labels: {
          show: !!formatter,
          name: {
            show: false,
          },
          value: {
            offsetY: 15,
            fontSize: '14px',
            formatter: () => '',
          },
          total: {
            showAlways: true,
            label: '',
            show: true,
            fontSize: '14px',
            formatter,
          },
        },
      },
    },
  },
  stroke: {
    show: true,
    width: 1,
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  tooltip: {
    followCursor: false,
    y: {
      formatter: (val) => (tooltipAsDollars ? formatNumberToCurrency(val, 0) : formatNumber(val, 0)),
    },
  },
});

export const DonutChart = ({
  series,
  labels,
  colors,
  formatter = undefined,
  children = undefined,
  scale = 1,
  tooltipAsDollars = false,
}: {
  series: number[],
  labels: string[],
  colors: string[],
  formatter?: (v: any) => string,
  children?: ReactNode
  scale?: number
  tooltipAsDollars?: boolean
}) => {
  const theme = useTheme();

  const options = {
    labels,
    colors,
    ...getChartConfig(theme, formatter, tooltipAsDollars),
  };

  return (
    <Stack
      direction="row"
      gap={3}
      alignItems="center"
      justifyContent="space-between"
      flexWrap="wrap"
      position="relative"
    >
      <Apexcharts options={options} series={series} type="donut" height={(110 * scale)} width={(120 * scale)} />
      {children}
    </Stack>
  );
};
