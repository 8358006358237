import {
  BoldTypography, formatDecimalToPercentage, formatNumberToCurrency, InfoTooltip, useLabels,
} from 'ui';
import { Stack, Typography } from '@mui/material';

import { CalculatedRow, DetailRow } from './Row';
import {
  Form,
} from '../types';
import { useProjections } from '../useProjections';

export const CashFlow = ({ form }: { form: Form }) => {
  const l = useLabels();

  const projections = useProjections(form);

  return (
    <>
      <DetailRow
        rowID="cash-flow"
        bottomBorder={false}
        columns={[
          {
            id: 'cash-flow"',
            content: (
              <Stack direction="row" gap={3}>
                <BoldTypography variant="body1">
                  {l.cashFlow}
                </BoldTypography>
                <InfoTooltip
                  title={(
                    <Typography variant="body2">
                      {l['tooltip.proforma.netCashFlow']}
                    </Typography>
                  )}
                  isLight
                  isOutlined
                />
              </Stack>
            ),
          },
          ...projections.years.map(() => ({})),
        ]}
      />
      <CalculatedRow
        totalRow
        label={l.netCashFlow}
        values={projections.years.map((_, i) => formatNumberToCurrency(projections.calculations.getNetCashFlow(i) || 0, 0))}
      />
      <CalculatedRow
        label={l.cashOnCash}
        bottomBorder={false}
        values={projections.years.map((_, i) => formatDecimalToPercentage(projections.calculations.getCashOnCash(i) || 0, 2))}
      />
    </>
  );
};
