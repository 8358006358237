import { useLocation, useNavigate } from 'react-router-dom';

import {
  useFeatures,
} from 'lib';
import {
  MenuItem, ReferralsIcon, useLabels,
} from 'ui';

const paths = {
  dashboard: '/referral-partners',
};

const menuIconSize = 18;

export const ProductMenuItems = ({ onNavigate = () => { } }: { onNavigate?: () => void }) => {
  const l = useLabels();
  const location = useLocation();
  const { isLoading: isLoadingFeatures, ...features } = useFeatures();

  const navigate = useNavigate();
  const handleNavigate = (path: string) => {
    onNavigate();
    navigate(path);
  };

  if (isLoadingFeatures) return null;

  return features.isReferralPartnersAppEnabled && (
    <MenuItem
      icon={<ReferralsIcon height={menuIconSize} width={menuIconSize} />}
      label={l.referrals}
      onClick={() => handleNavigate(paths.dashboard)}
      isActive={location.pathname === paths.dashboard}
    />
  );
};
