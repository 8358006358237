import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import {
  BuyBoxLeadsWorkflowStatus, BuyBoxLeadWorkflowItem, useAnalytics,
} from 'lib';
import { MdPhone } from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  FallbackSpinner, formatPotentialTodayDate, ListBoard, RowGroup, RowGroupConfig, Spinner, useLabels,
} from 'ui';
import { Button, Stack } from '@mui/material';
import { signal } from '@preact/signals-react';
import { useQueryClient } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';

import { useMutatePhoneCall } from '../../../api/calls';
import { useListBuyBoxLeads, useMutateBuyBoxLead } from '../../../api/workflows';
import { QueryKey } from '../../../types/enums';
import { Contact } from '../components/Contact';
import { LeadReveal, LeadType } from '../components/LeadReveal';
import { StatusChanger } from '../components/StatusChanger';
import { markListItemAsRevealed, sortContacts } from '../utils';
import { useBuyBoxLeadStatuses } from '../WorkflowStatus';

const rowGroupsSignal = signal<RowGroup<BuyBoxLeadWorkflowItem>[]>([]);

export const BuyBoxLeadsList = ({ orderedStatuses }: { orderedStatuses: BuyBoxLeadsWorkflowStatus[] }) => {
  const l = useLabels();
  const { getStatus } = useBuyBoxLeadStatuses();
  const { data, isLoading } = useListBuyBoxLeads();

  useEffect(() => {
    const columns: ColumnDef<BuyBoxLeadWorkflowItem, string>[] = [
      {
        header: l.status,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => (
          <BuyBoxLeadStatusChanger item={info.row.original} />
        ),
        meta: { blurred: (row) => !row.original.revealed },
      },
      {
        header: l.name,
        id: l.name,
        accessorKey: 'leadContactDetails.email',
        sortingFn: (a, b) => sortContacts(a.original.leadContactDetails, b.original.leadContactDetails),
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => <Contact contact={info.row.original.leadContactDetails} />,
        meta: { blurred: (row) => !row.original.revealed },
      },
      {
        header: l.timeline,
        id: l.timeline,
        accessorKey: 'timeline',
        enableSorting: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => info.row.original.timeline,
        meta: { textNoWrap: true, shownAsText: true, blurred: (row) => !row.original.revealed },
      },
      {
        header: l.amount,
        id: l.amount,
        accessorKey: 'amount',
        enableSorting: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => info.row.original.amount,
        meta: { textNoWrap: true, shownAsText: true, blurred: (row) => !row.original.revealed },
      },
      {
        header: l.propertyType,
        id: l.propertyType,
        minSize: 120,
        accessorKey: 'propertyType',
        enableSorting: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => info.row.original.propertyType,
        meta: { textNoWrap: true, shownAsText: true, blurred: (row) => !row.original.revealed },
      },
      {
        header: l.financing,
        id: l.financing,
        accessorKey: 'financing',
        enableSorting: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => info.row.original.financing,
        meta: { textNoWrap: true, shownAsText: true, blurred: (row) => !row.original.revealed },
      },
      {
        id: l.updated,
        header: l.updated,
        accessorKey: 'updatedTime',
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => formatPotentialTodayDate(info.row.original.updatedTime),
        meta: { shownAsText: true, blurred: (row) => !row.original.revealed },
        sortingFn: (a, b) => dayjs(a.original.updatedTime).diff(dayjs(b.original.updatedTime)),
      },
      {
        header: '‎ ', // necessary to show separation between sticky and non-sticky columns
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => <BuyBoxLeadActions lead={info.row.original} />,
        meta: { sticky: true },
      },
    ];

    const leads = data?.pages.flatMap((page) => page.leads) ?? [];

    const groups: RowGroupConfig<BuyBoxLeadWorkflowItem>[] = [];

    orderedStatuses.forEach((status) => {
      const statusConfig = getStatus(status);

      groups.push({
        rows: leads?.filter((lead) => lead.status === status) ?? [],
        columnDefs: columns,
        id: status,
        color: statusConfig.color,
        icon: statusConfig.smallIcon,
        title: statusConfig.displayValue,
        tooltip: statusConfig.tooltip,
      });
    });

    rowGroupsSignal.value = groups;
  }, [data]);

  if (isLoading) return <FallbackSpinner />;

  return (
    <ListBoard rowGroups={rowGroupsSignal.value} columnSort={[{ id: l.updated, desc: true }]} />
  );
};

const BuyBoxLeadActions = ({ lead }: { lead: BuyBoxLeadWorkflowItem }) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const { mutateAsync: sendPhoneCallRequest, isLoading: isCalling } = useMutatePhoneCall();

  const call = async () => {
    analytics.track('Button Clicked', {
      buttonName: 'Call',
      called: 'BuyBox',
      id: lead.id,
    });

    if (!lead.leadContactDetails?.phoneNumber) {
      toast.error(l['error.missingContactPhone']);
      return;
    }

    try {
      await sendPhoneCallRequest(lead.leadContactDetails?.id);
      toast.success(l['leads.call.success']);
    } catch (err) {
      console.error(err);
      toast.error(l['error.unknownError']);
    }
  };

  const spinner = (
    <>
      &nbsp;
      <Spinner size={20} />
    </>
  );

  return !lead.revealed ? (
    <LeadReveal
      id={lead.id}
      revealed={!!lead.revealed}
      leadType={LeadType.BUY_BOX}
      onReveal={() => {
        markListItemAsRevealed(rowGroupsSignal, lead);
      }}
    />
  ) : (
    <Stack direction="row" gap={2} justifyContent="space-between" width="100%">
      <Button
        disabled={isCalling}
        color="primary"
        variant="outlined"
        size="small"
        onClick={call}
        startIcon={isCalling ? null : <MdPhone />}
      >
        {isCalling ? spinner : l.owner}
      </Button>
    </Stack>
  );
};

const BuyBoxLeadStatusChanger = ({ item }: { item: BuyBoxLeadWorkflowItem }) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const { statuses } = useBuyBoxLeadStatuses();
  const queryClient = useQueryClient();

  const [invalidatingQueries, setInvalidatingQueries] = useState(false);
  const { mutateAsync: updateStatus, isLoading: isUpdatingStatus } = useMutateBuyBoxLead();

  const handleStatusChange = async (status: BuyBoxLeadsWorkflowStatus) => {
    analytics.track('Selector Changed', {
      selectorName: 'BuyBox Lead Status',
      value: status,
    });

    try {
      await updateStatus({
        id: item.id,
        status,
      });
      setInvalidatingQueries(true);
      await queryClient.invalidateQueries([QueryKey.BUY_BOX_LEADS]);
      setInvalidatingQueries(false);
    } catch (err) {
      console.error(err);

      toast.error(l['error.unknownError']);
    }
  };

  const isLoading = invalidatingQueries || isUpdatingStatus;

  return (
    <StatusChanger
      id={item.id}
      status={item.status}
      statuses={statuses}
      changeStatus={handleStatusChange}
      isLoading={isLoading}
    />
  );
};
