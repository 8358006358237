import { MdSell } from 'react-icons/md';
import {
  Avatar, EmptyFullPageContainer, FallbackSpinner, PageTitle, useLabels,
} from 'ui';
import {
  Stack, Typography,
} from '@mui/material';

import { ActivityRow } from './activity';
import { useListMarketplacePropertyActivities, useListOfferActivities } from '../../api/activities';

export const Activities = () => {
  const l = useLabels();

  const {
    data: mpActivityPages,
    isLoading: mpActivitiesLoading,
    hasNextPage: mpActivitiesHasNextPage,
    isFetchingNextPage: mpActivitiesIsFetchingNextPage,
  } = useListMarketplacePropertyActivities();
  const {
    data: offerActivityPages,
    isLoading: offerActivitiesLoading,
    hasNextPage: offerActivitiesHasNextPage,
    isFetchingNextPage: offerActivitiesIsFetchingNextPage,
  } = useListOfferActivities();

  const mpActivities = mpActivityPages?.pages.flatMap((page) => page.activities) ?? [];
  const offerActivities = offerActivityPages?.pages.flatMap((page) => page.activities) ?? [];
  const activities = [...mpActivities, ...offerActivities];

  const loading = (
    mpActivitiesLoading || offerActivitiesLoading
    || mpActivitiesIsFetchingNextPage || offerActivitiesIsFetchingNextPage
    || mpActivitiesHasNextPage || offerActivitiesHasNextPage
  );

  if (loading) {
    return (
      <FallbackSpinner />
    );
  }

  if (activities.length === 0) {
    return (
      <EmptyFullPageContainer>
        <Avatar
          skin="light"
          variant="circular"
          color="primary"
          sx={{
            mr: 3, mb: 3, width: 72, height: 72,
          }}
        >
          <MdSell size={40} />
        </Avatar>
        <Typography variant="h6">
          {l['listings.activitiesEmptyTitle']}
        </Typography>
        <Typography variant="body2">
          {l['listings.activitiesEmptyDescription']}
        </Typography>
      </EmptyFullPageContainer>
    );
  }

  return (
    <Stack p={3}>
      <PageTitle title={l['listings.activitiesTitle']} />
      <Stack gap={2}>
        {activities.map((activity) => (
          <ActivityRow key={activity.id} activity={activity} />
        ))}
      </Stack>
    </Stack>
  );
};
