import {
  MdArrowBackIosNew, MdArrowForwardIos, MdClose, MdOutlineFullscreen,
} from 'react-icons/md';
import ImageGallery from 'react-image-gallery';
import { SliderButton, useLabels } from 'ui';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton,
  Stack, useTheme,
} from '@mui/material';

import 'react-image-gallery/styles/css/image-gallery.css';

type Props = {
  image: string,
  images: string[],
  open: boolean,
  onClose: () => void,
};

export const ImageGalleryPopup = ({
  image, images, open, onClose,
}: Props) => {
  const theme = useTheme();
  const l = useLabels();

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={onClose}
      sx={{ zIndex: 1600 }}
    >
      <DialogTitle component={Stack} alignItems="flex-end" borderBottom={`1px solid ${theme.palette.divider}`} sx={{ p: 3 }}>
        <IconButton aria-label="Close" onClick={onClose} size="medium" color="secondary">
          <MdClose />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ImageGallery
          showPlayButton={false}
          startIndex={images.indexOf(image)}
          items={images.map((img) => ({ thumbnail: img, original: img }))}
          renderLeftNav={(onClick, disabled) => (
            <SliderButton
              onClick={onClick}
              disabled={disabled}
              sx={{
                position: 'absolute', left: 10, top: 'calc(50% - 18px)', zIndex: 10,
              }}
            >
              <MdArrowBackIosNew size={12} color={theme.palette.secondary.main} />
            </SliderButton>
          )}
          renderRightNav={(onClick, disabled) => (
            <SliderButton
              onClick={onClick}
              disabled={disabled}
              sx={{
                position: 'absolute', right: 10, top: 'calc(50% - 18px)', zIndex: 10,
              }}
            >
              <MdArrowForwardIos size={12} color={theme.palette.secondary.main} />
            </SliderButton>
          )}
          renderFullscreenButton={(onClick) => (
            <SliderButton
              onClick={onClick}
              sx={{
                position: 'absolute', left: 10, bottom: 10, zIndex: 10,
              }}
            >
              <MdOutlineFullscreen size={24} color={theme.palette.secondary.main} />
            </SliderButton>
          )}
        />
      </DialogContent>
      <DialogActions sx={{ borderBottom: `1px solid ${theme.palette.divider}`, p: 0 }}>
        <Stack direction="row" justifyContent="space-between" p={3} width="100%">
          <Button
            onClick={onClose}
            variant="text"
            sx={{ py: 3, color: theme.palette.text.primary }}
          >
            {l.close}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
