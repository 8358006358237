import { MouseEvent, useState } from 'react';

import { useAnalytics, useFeatures } from 'lib';
import { IoIosLink } from 'react-icons/io';
import {
  MdCheck,
} from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  LightTooltip,
  useLabels,
} from 'ui';
import {
  Box,
  Button, IconButton, SxProps, Typography, useTheme,
} from '@mui/material';

export const ShareButton = ({
  listingID,
  showAsButton = false,
}: {
  listingID: string,
  showAsButton?: boolean
}) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const [copied, setCopied] = useState(false);
  const features = useFeatures();

  const disabled = !features.isShareListingEnabled;
  const showTooltip = features.isFreemiumPM;
  const shareURL = `${window.location.origin}/marketplace/${listingID}?utm_source=share&register=true`;

  const handleClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    analytics.track('Button Clicked', {
      url: shareURL,
      listingId: listingID,
      buttonName: 'Share Listing',
      disabled,
    });

    if (disabled) return;

    await navigator.clipboard.writeText(shareURL);

    toast.success(l['listings.shareConfirm']);

    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  const sx: SxProps = disabled ? {
    background: `${theme.palette.background.default} !important`,
    '&&&': {
      pointerEvents: 'auto',
    },
  } : {};
  const className = disabled ? 'Mui-disabled' : '';

  const btn = showAsButton ? (
    <Button
      variant="outlined"
      size="small"
      startIcon={copied ? <MdCheck /> : <IoIosLink />}
      onClick={handleClick}
      className={className}
    >
      {l.share}
    </Button>
  ) : (
    <IconButton
      onClick={handleClick}
      className={className}
      sx={{
        background: theme.palette.background.default,
        '& > svg': {
          transition: '0.3s',
        },
        '&:active,&:focus,&:hover,&:disabled': {
          background: theme.palette.background.paper,
        },
        '&:hover > svg': {
          fill: disabled ? undefined : theme.palette.primary.dark,
        },
        ...sx,
      }}
    >
      {copied ? <MdCheck /> : <IoIosLink />}
    </IconButton>
  );

  if (disabled && !showTooltip) return null;

  if (!disabled || !showTooltip) return btn;

  return (
    <LightTooltip
      arrow
      enterTouchDelay={0}
      title={(
        <Typography variant="body2" sx={{ p: 1 }}>
          {l['listings.share.disabled.tooltip']}
        </Typography>
      )}
    >
      <Box component="span" onClick={handleClick}>
        {btn}
      </Box>
    </LightTooltip>
  );
};
