import { MouseEvent, ReactNode } from 'react';

import { BoldTypography, SemiBoldTypography } from 'ui';
import {
  Grid, Stack, SxProps, useMediaQuery, useTheme,
} from '@mui/material';

import { editModeSignal } from '../state';

export const DetailRow = ({
  rowID, columns, totalRow = false, bottomBorder = true, alignTopOnEdit = false, onClickRow = undefined,
}: {
  rowID: string,
  bottomBorder?: boolean,
  totalRow?: boolean,
  columns: { content?: ReactNode, id?: string }[]
  alignTopOnEdit?: boolean
  onClickRow?: (e: MouseEvent) => void
}) => {
  const theme = useTheme();
  const editMode = editModeSignal.value;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const getBackground = (index: number) => {
    if (totalRow) {
      return '#EFEBF2';
    }

    if (index === 1) {
      return '#F7F7FA';
    }

    return theme.palette.background.paper;
  };

  const getSx = (index: number): SxProps => ({
    background: getBackground(index),
  });

  const totalWidth = 12;
  // in edit mode, the second column (idx 1) should be wider than the others (idx 2+)
  const editModeMultiplier = editMode ? 1.2 : 1;

  const getColumnWidth = (index: number) => {
    const firstColumnRatio = isMobile ? 1 : 1.3;
    const secondColumnBaseRatio = 5 / 6;
    const otherColumnsRatio = 13 / 18;

    const secondColumnRatio = secondColumnBaseRatio * editModeMultiplier;
    const totalRatio = firstColumnRatio + secondColumnRatio + (columns.length - 2) * otherColumnsRatio;

    if (index === 0) {
      return (firstColumnRatio / totalRatio) * totalWidth;
    }

    if (index === 1) {
      return (secondColumnRatio / totalRatio) * totalWidth;
    }

    return (otherColumnsRatio / totalRatio) * totalWidth;
  };

  const clickableSx = {
    cursor: 'pointer',
  };

  return (
    <Grid
      container
      borderBottom={bottomBorder ? `1px solid ${theme.palette.grey[300]}` : undefined}
      sx={onClickRow ? clickableSx : undefined}
      onClick={onClickRow}
    >
      {columns.map((column, index) => (
        <Grid
          item
          xs={getColumnWidth(index)}
          // eslint-disable-next-line react/no-array-index-key
          key={`row${rowID}-${column.id}-${index}`}
          py={1}
          sx={getSx(index)}
        >
          <Stack
            px={4}
            py={editMode ? 1.5 : 1}
            justifyContent={alignTopOnEdit && editMode ? 'flex-start' : 'center'}
            alignItems={index === 0 ? 'flex-start' : 'flex-end'}
            height="100%"
          >
            {column.content}
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
};

export const CalculatedRow = ({
  label,
  values,
  bottomBorder = true,
  totalRow = false,
  isInnerRow = false,
}: {
  label: string,
  values: string[],
  bottomBorder?: boolean,
  totalRow?: boolean,
  isInnerRow?: boolean,
}) => (
  <DetailRow
    rowID={label}
    bottomBorder={bottomBorder}
    totalRow={totalRow}
    columns={[
      {
        id: label,
        content: (
          <SemiBoldTypography variant="body1" sx={{ pl: isInnerRow ? 8 : 0 }}>
            {label}
          </SemiBoldTypography>
        ),
      },
      ...values.map((value, i) => ({
        id: `${label}-year-${i + 1}`,
        content: (
          <BoldTypography variant="body1" color="success.main">
            {value}
          </BoldTypography>
        ),
      })),
    ]}
  />
);
